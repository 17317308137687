import {
  Accessibility,
  DocumentsRequired,
  FeeStructureSource,
  HowIsServiceOffered,
  HowToAccessSupport,
  TargetPopulations,
} from 'types/data-management';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchAccessibility(): Promise<Accessibility[]> {
  try {
    const response = await http.get<BaseAPIResponse<Accessibility[]>>(
      '/reference?name=accessibility',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Accessibility Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Accessibility Reference: ${err}`);
  }
}

export async function fetchTargetPopulations(): Promise<TargetPopulations[]> {
  try {
    const response = await http.get<BaseAPIResponse<TargetPopulations[]>>(
      '/reference?name=targetPopulations',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List TargetPopulations Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List TargetPopulations Reference: ${err}`);
  }
}

export async function fetchDocumentsRequired(): Promise<DocumentsRequired[]> {
  try {
    const response = await http.get<BaseAPIResponse<DocumentsRequired[]>>(
      '/reference?name=documentsRequired',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List DocumentsRequired Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List DocumentsRequired Reference: ${err}`);
  }
}

export async function fetchFeeStructureSource(): Promise<FeeStructureSource[]> {
  try {
    const response = await http.get<BaseAPIResponse<FeeStructureSource[]>>(
      '/reference?name=feeStructureSource',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List FeeStructureSource Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List FeeStructureSource Reference: ${err}`);
  }
}

export async function fetchHowIsServiceOffered(): Promise<
  HowIsServiceOffered[]
> {
  try {
    const response = await http.get<BaseAPIResponse<HowIsServiceOffered[]>>(
      '/reference?name=howIsServiceOffered',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List HowIsServiceOffered Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List HowIsServiceOffered Reference: ${err}`);
  }
}

export async function fetchHowToAccessSupport(): Promise<HowToAccessSupport[]> {
  try {
    const response = await http.get<BaseAPIResponse<HowToAccessSupport[]>>(
      '/reference?name=howToAccessSupport',
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List HowToAccessSupport Reference');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List HowToAccessSupport Reference: ${err}`);
  }
}
