import { toast } from 'components/Toast/Toast';
import { Geocoding, GeocodingDto } from 'types/geocoding';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function getGeocodingData(location: Geocoding): Promise<GeocodingDto> {
  try {
    const response = await http.post<BaseAPIResponse<GeocodingDto>>(
      `/geocoding`,
      location
    );

    if (response.success) {
      toast.success('Latitude and Longitude have been populated', {
          position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error - Geocoding');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error - Geocoding: ${err}`);
  }
}