import { toast } from 'components/Toast/Toast';
import { Site, SitesDto } from 'types/entities/sites';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchSites(
  pageIndex: number,
  pageSize: number,
): Promise<SitesDto> {
  try {
    const response = await http.get<BaseAPIResponse<SitesDto>>(
      `/site?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}

export async function getSiteById(id: string): Promise<SitesDto> {
  try {
    const response = await http.get<BaseAPIResponse<SitesDto>>(`/site/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}

export async function getSiteAnalytics(
  startDate: string,
  endDate: string,
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  try {
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1),
    );
    const response = await http.get<BaseAPIResponse<Site>>(
      `/site/analytics/${startDate !== '' ? startDate : lastYear}/${
        endDate !== '' ? endDate : new Date()
      }/${type}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Site analytics');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get site analytics: ${err}`);
  }
}

export async function createSite(site: Site): Promise<Site> {
  try {
    const response = await http.post<BaseAPIResponse<Site>>(`/site`, site);

    if (response.success) {
      toast.success('The Site was created successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    toast.error('Error creating site. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}

export async function updateStatusSite(
  siteId: string,
  isActive: boolean,
): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/site/status/${siteId}`,
      {
        isActive,
      },
    );

    if (response.success) {
      toast.success('The Site was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    toast.error('Error updating site. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}

export async function updateSite(siteId: string, site: Site): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/site/${siteId}`,
      site,
    );

    if (response.success) {
      toast.success('The Site was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    toast.error('Error updating site. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}

export async function deleteSite(siteId: string): Promise<Site | undefined> {
  try {
    const response = await http.delete<BaseAPIResponse<Site>>(
      `/site/${siteId}`,
    );

    if (response.success) {
      toast.success('The Site was deleted', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Sites');
    }
  } catch (err: unknown) {
    toast.error('Error deleting site. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Sites: ${err}`);
  }
}
type SearchSite = Omit<SitesDto, 'activeCount' | 'inactiveCount' | 'nameEN'>;

export async function searchSites(
  type: string,
  value: string,
  agencyId: string | undefined = undefined,
  pageIndex = 1,
  pageSize = 100,
): Promise<SearchSite> {
  try {
    const response = await http.get<BaseAPIResponse<SearchSite>>(
      `/site/search/${type}/${value}/${
        agencyId || false
      }?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Searching Sites');
    }
  } catch (err: unknown) {
    console.error(err);
    toast.error('Error Searching Sites', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Searching Sites: ${err}`);
  }
}
