const applicationProcess = [
  {
    en: 'Application process unknown. A step in the right direction would be to call or email to connect and learn more.',
    fr: "Processus d'application inconnu. Un pas dans la bonne direction serait de téléphoner ou d'envoyer un courriel pour faire connaissance et en savoir plus.",
  },
  {
    en: 'Call',
    fr: 'Téléphoner',
  },
  {
    en: 'Text',
    fr: 'SMS',
  },
  {
    en: 'Email',
    fr: 'Envoyer un couriel',
  },
  {
    en: 'Appointment [required/optional]',
    fr: 'Rendez-vous [requis/facultatif]',
  },
  {
    en: 'Contact [name of referral agency, ] for referral: xxx-xxx-xxxx and/or example@example.ca',
    fr: "Contacter [nom de l'agence pour référence] pour référence : xxx-xxx-xxxx et/ou exemple@exemple.ca",
  },
  {
    en: '[Medical; Parental/Guardian; Professional (non-medical)] referral required',
    fr: 'Référence [médical; parentale/gardien; professionnelle (non-médical)] requis',
  },
  {
    en: 'Register in-person or online/email at: websitelink.com/email@email.com',
    fr: 'Inscris-toi en personne ou en ligne/par courriel à : siteweb.com/email@email.com',
  },
  {
    en: 'Drop-in',
    fr: 'Accès sans rendez-vous',
  },
  {
    en: 'Assessment/Intake required ',
    fr: "Évaluation ou demande d'admission requis",
  },
  {
    en: 'Complete [form type, i.e., application, contact form, referral] available on the website www.example.ca or drop by to pick up a [type] form. Submit form [online/in-person/ to person@email.ca]',
    fr: 'Remplir [le type de formulaire] disponible sur le site web www.exemple.ca ou présente-toi en personne pour ramasser un formulaire. Soumettre formulaire rempli [en ligne/en personne/à email@email.ca]',
  },
  {
    en: 'Direct message (DM) through Facebook/Instagram/Social Media [URL]',
    fr: 'Message direct sur médias sociaux [URL]',
  },
  {
    en: 'Download App',
    fr: "Télécharger l'application",
  },
  {
    en: 'Visit Website',
    fr: 'Visiter le site web',
  },
];

export default applicationProcess;
