import { createReducer } from '@reduxjs/toolkit';
import { StorageItem } from 'enums/storage-item.enum';
import {
  removeAuthTokenAction,
  removeLoggedUser,
  setAuthTokenAction,
  setLoggedUserAction,
} from 'global-state/actions/auth.actions';
import { storage } from 'helpers/storage.helpers';
import { User } from 'types/user.dto';

export type AuthState = {
  authToken: string | null;
  user: User | null;
};

const initialState: AuthState = {
  authToken: storage.session.get(StorageItem.AUTH_TOKEN),
  user: storage.session.get(StorageItem.LOGGED_USER),
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setAuthTokenAction, (state, action) => {
      state.authToken = action.payload;

      storage.session.set(StorageItem.AUTH_TOKEN, action.payload);
    })
    .addCase(removeAuthTokenAction, (state) => {
      state.authToken = null;

      storage.session.remove(StorageItem.AUTH_TOKEN);
    })
    .addCase(setLoggedUserAction, (state, action) => {
      state.user = action.payload;

      storage.session.set(StorageItem.LOGGED_USER, action.payload);
    })
    .addCase(removeLoggedUser, (state) => {
      state.user = null;

      storage.session.remove(StorageItem.LOGGED_USER);
    });
});
