import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { AgencyForm } from 'components/Shared/SharedForms/AgencyForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { palette } from 'design/theme/palette';
import { validateRequired } from 'helpers/validation.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DataManagement } from 'types/data-management';
import { Agency } from 'types/entities/agencies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const CreateAgenciesModal: FC<{
  onClose: () => void;
  onSubmit: (values: DataManagement) => void;
  open: boolean;
}> = ({ open, onClose, onSubmit }) => {
  const { columns: agencyColumns } = useAgencies();

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [agencyFormValues, setAgencyFormValues] = useState<Agency>(() =>
    agencyColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );
  const [selectedAgency, setSelectedAgency] = useState('');

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};

    // if (agencyFormValues.khpReferenceNumber <= 0) {
    //   isValidFormOnSubmit = false;
    //   validationErrosOnSubmit = {
    //     ...validationErrosOnSubmit,
    //     ['khpReferenceNumber']: 'This field is required',
    //   };
    // }

    // if (!(parseInt(agencyFormValues.agencyReferenceNumber) > 0)) {
    //   isValidFormOnSubmit = false;
    //   validationErrosOnSubmit = {
    //     ...validationErrosOnSubmit,
    //     ['agencyReferenceNumber']: 'This field is required',
    //   };
    // }

    if (
      !validateRequired(agencyFormValues.nameEN) &&
      !validateRequired(agencyFormValues.nameFR)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameEN']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameFR']: 'This field is required',
      };
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        intl.translate({
          id: 'Please fill all required fields',
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    onSubmit({
      Agency: agencyFormValues,
    });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Create Agency',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '80vh',
              '&  #tabpanel-0': {
                marginLeft: '100px',
              },
              '&  #tabpanel-1': {
                marginLeft: '100px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab
                label={intl.translate({
                  id: 'Agency',
                })}
                {...a11yProps(0)}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <AgencyForm
                showLabel={false}
                selectedAgency={selectedAgency}
                setSelectedAgency={setSelectedAgency}
                values={agencyFormValues}
                setValues={setAgencyFormValues}
                columns={agencyColumns}
                validationErrors={validationErrors}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
