import { Agency } from 'types/entities/agencies';
import { Resource } from 'types/entities/resources';
import { Site } from 'types/entities/sites';
import { Taxonomy } from './entities/taxonomies';
import { EnFr } from './utilities';

export enum EntityStatusEnum {
  PendingReview = 'PENDING_REVIEW',
  PendingApproval = 'PENDING_APPROVAL',
}

export type DataManagement = {
  SelectedResourceId?: string;
  Resource?: Resource;
  SelectedSiteIds?: string[];
  Site?: Site;
  SelectedAgencyId?: string;
  Agency?: Agency;
  SelectedTaxonomyIds?: string[][];
  Taxonomy?: Taxonomy;
};

export type PhoneNumber = {
  description: EnFr;
  type: string;
  isPrivate: string;
  name: string;
  number: string;
};

export type Contact = {
  type: string;
  name: string;
  title: string;
  phone: string;
  email: string;
};

export type Operations = {
  0: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  1: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  2: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  3: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  4: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  5: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  6: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  siteId: string;
  siteLabel: string;
};

export type OperationSite = {
  0: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  1: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  2: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  3: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  4: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  5: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
  6: {
    fr: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
    en: {
      day: string;
      hoursOfOperation: string;
      descriptionOfHours: string;
    };
  };
};

// References
export type Reference = {
  _id?: string;
  name: string;
  en: string;
  fr: string;
  createdAt: string;
  updatedAt: string;
  objectId: string;
};

export type Accessibility = Reference;
export type TargetPopulations = Reference;
export type DocumentsRequired = Reference;
export type FeeStructureSource = Reference;
export type HowIsServiceOffered = Reference;
export type HowToAccessSupport = Reference;
