/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
  styled,
} from '@mui/material';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table'; //https://mui.com/material-ui/react-table/
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Text } from 'design';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { intl } from 'utilities/i18n/intl.utility';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom'; // https://www.chartjs.org/chartjs-plugin-zoom/latest/guide/options.html
import { Bar, Line } from 'react-chartjs-2';

import { getAgencyAnalytics } from 'api/agencies/agencies.api';
import { getResourceAnalytics } from 'api/resources/resources.api';
import { getSiteAnalytics } from 'api/sites/sites.api';
import CATEGORIES from 'assets/constants/categories';
import cities from 'assets/constants/cities';
import {
  CHART_ENTITY_LABELS,
  CHART_MONTH_LABELS,
  CHART_OPTIONS,
  CHART_OPTIONS_CITY_ALTERNATIVE,
  CHART_RECORD_TYPE_LABELS,
  FILTERS,
} from 'assets/constants/dashboard';
import provinces from 'assets/constants/provinces';
import { palette } from 'design/theme/palette';
import { StyledLabel } from 'views/DataManagement/Download/Download';
import { searchTaxonomies } from 'api/taxonomies/taxonomies.api';
import { monthNames, yearsArray } from 'utilities/constants';
import citiesByProvince from 'assets/constants/cities-by-province';
import {
  getDateString,
  getFullYear,
  getPreviousYearDate,
} from 'utilities/constants/date';
import { toast } from 'react-toastify';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
);

const StyledTextH2 = styled(Text.h2)`
  font-weight: 700;
`;

const StyledTextH3 = styled(Text.h3)`
  font-weight: 400;
`;

export const Dashboard: FC = () => {
  const date = new Date();
  const chartDetails = {
    label: 'Records',
    data: [],
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
  };
  const [year, setYear] = useState(getFullYear(date));
  const [endDate, setEndDate] = useState<string>(getDateString(date));
  const [startDate, setStartDate] = useState<string>(getPreviousYearDate(date));
  const [status, setStatus] = useState<string>('');
  const [verificationSent, setVerificationSent] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [province, setProvince] = useState<string>('');
  const [selectedTaxonomies, setSelectedTaxonomies] = useState([]);

  const [filteredTaxonomies, setFilteredTaxonomies] = useState<
    { label: string; value: string }[]
  >([]);
  const [inputValue, setInputValue] = useState('');
  const [inputValueCity, setInputValueCity] = useState<string>('');

  const [charts, setCharts] = useState([
    {
      id: 0,
      filter: FILTERS.NUMBER,
      title: 'Number of records created',
      startDate: '',
      endDate: '',
      chartTypes: [
        FILTERS.NUMBER,
        FILTERS.REGION_PROVINCE,
        FILTERS.REGION_CITY,
        FILTERS.TYPE,
        FILTERS.LEVEL,
        FILTERS.TAXONOMY,
      ],
    },
    {
      id: 1,
      filter: FILTERS.NUMBER,
      title: 'Number of records updated',
      startDate: '',
      endDate: '',
      chartTypes: [
        FILTERS.NUMBER,
        FILTERS.REGION_PROVINCE,
        FILTERS.REGION_CITY,
        FILTERS.TYPE,
        FILTERS.LEVEL,
        FILTERS.TAXONOMY,
      ],
    },
    // {
    //   id: 2,
    //   filter: FILTERS.NUMBER,
    //   title: 'Number of active records',
    //   startDate: '',
    //   endDate: '',
    //   chartTypes: [
    //     FILTERS.NUMBER,
    //     FILTERS.REGION_PROVINCE,
    //     FILTERS.REGION_CITY,
    //     FILTERS.TYPE,
    //     FILTERS.LEVEL,
    //     FILTERS.TAXONOMY,
    //   ],
    // },
    // {
    //   id: 3,
    //   filter: FILTERS.NUMBER,
    //   title: 'Number of records verified',
    //   startDate: '',
    //   endDate: '',
    //   chartTypes: [
    //     FILTERS.NUMBER,
    //     FILTERS.REGION_PROVINCE,
    //     FILTERS.REGION_CITY,
    //     FILTERS.TYPE,
    //     FILTERS.LEVEL,
    //     FILTERS.TAXONOMY,
    //   ],
    // },
    // {
    //   id: 4,
    //   filter: FILTERS.NUMBER,
    //   title: 'Number of resources offered in English',
    //   startDate: '',
    //   endDate: '',
    //   chartTypes: [
    //     FILTERS.NUMBER,
    //     FILTERS.REGION_PROVINCE,
    //     FILTERS.REGION_CITY,
    //     FILTERS.TYPE,
    //     FILTERS.TAXONOMY,
    //   ],
    // },
    // {
    //   id: 5,
    //   filter: FILTERS.NUMBER,
    //   title: 'Number of resources offered in French',
    //   startDate: '',
    //   endDate: '',
    //   chartTypes: [
    //     FILTERS.NUMBER,
    //     FILTERS.REGION_PROVINCE,
    //     FILTERS.REGION_CITY,
    //     FILTERS.TYPE,
    //     FILTERS.TAXONOMY,
    //   ],
    // },
  ]);
  const [readyToFetch, setReadyToFetch] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyCreated, setDataByTaxonomyCreated] = useState<any[]>([]);
  const [dataCreated, setDataCreated] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceCreated, setDataByRegionProvinceCreated] =
    useState({
      labels: provinces,
      datasets: [
        {
          ...chartDetails,
        },
      ],
    });
  const [dataByRegionCityCreated, setDataByRegionCityCreated] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeCreated, setDataByTypeCreated] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [loading, setLoading] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [dataByLevelCreated, setDataByLevelCreated] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        label: 'Records',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyUpdated, setDataByTaxonomyUpdated] = useState<any[]>([]);
  const [dataUpdated, setDataUpdated] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceUpdated, setDataByRegionProvinceUpdated] =
    useState({
      labels: provinces,
      datasets: [
        {
          ...chartDetails,
        },
      ],
    });
  const [dataByRegionCityUpdated, setDataByRegionCityUpdated] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeUpdated, setDataByTypeUpdated] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByLevelUpdated, setDataByLevelUpdated] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyActive, setDataByTaxonomyActive] = useState<any[]>([]);
  const [dataActive, setDataActive] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceActive, setDataByRegionProvinceActive] = useState({
    labels: provinces,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });
  const [dataByRegionCityActive, setDataByRegionCityActive] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeActive, setDataByTypeActive] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByLevelActive, setDataByLevelActive] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyVerified, setDataByTaxonomyVerified] = useState<any[]>(
    [],
  );
  const [dataVerified, setDataVerified] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceVerified, setDataByRegionProvinceVerified] =
    useState({
      labels: provinces,
      datasets: [
        {
          ...chartDetails,
        },
      ],
    });
  const [dataByRegionCityVerified, setDataByRegionCityVerified] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeVerified, setDataByTypeVerified] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByLevelVerified, setDataByLevelVerified] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyEnglish, setDataByTaxonomyEnglish] = useState<any[]>([]);
  const [dataEnglish, setDataEnglish] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceEnglish, setDataByRegionProvinceEnglish] =
    useState({
      labels: provinces,
      datasets: [
        {
          ...chartDetails,
        },
      ],
    });
  const [dataByRegionCityEnglish, setDataByRegionCityEnglish] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeEnglish, setDataByTypeEnglish] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByLevelEnglish, setDataByLevelEnglish] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataByTaxonomyFrench, setDataByTaxonomyFrench] = useState<any[]>([]);
  const [dataFrench, setDataFrench] = useState({
    labels: CHART_MONTH_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByRegionProvinceFrench, setDataByRegionProvinceFrench] = useState({
    labels: provinces,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });
  const [dataByRegionCityFrench, setDataByRegionCityFrench] = useState({
    labels: cities.slice(0, 10),
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByTypeFrench, setDataByTypeFrench] = useState({
    labels: CHART_RECORD_TYPE_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [dataByLevelFrench, setDataByLevelFrench] = useState({
    labels: CHART_ENTITY_LABELS,
    datasets: [
      {
        ...chartDetails,
      },
    ],
  });

  const [createdCategory, setCreatedCategory] = useState(CATEGORIES.COUNT);
  const [activeCategory, setActiveCategory] = useState(CATEGORIES.COUNT);
  const [updatedCategory, setUpdatedCategory] = useState(CATEGORIES.COUNT);
  const [verifiedCategory, setVerifiedCategory] = useState(CATEGORIES.COUNT);
  const [englishCategory, setEnglishCategory] = useState(CATEGORIES.COUNT);
  const [frenchCategory, setFrenchCategory] = useState(CATEGORIES.COUNT);

  const handleFilterChange = async (searchText: string) => {
    let searchedTaxonomies: any[] = [];
    try {
      if (searchText.includes('En') && searchText.includes('Fr')) {
        const search = searchText.split('/')[0].split(': ')[1].trimEnd();
        if (search) {
          const { taxonomies } = await searchTaxonomies('nameEN', search);
          searchedTaxonomies = taxonomies;
        }
      } else {
        if (searchText) {
          const { taxonomies } = await searchTaxonomies('nameEN', searchText);
          searchedTaxonomies = taxonomies;
        }
      }
    } catch (error) {
      /*empty*/
    }

    const filteredTaxonomies = searchedTaxonomies.map((tax) => {
      return {
        label: `Code: ${tax.code} En: ${tax.nameEN} / Fr: ${tax.nameFR} -${
          tax?.objectId || tax?._id
        }`,
        value: tax?.objectId || tax?._id,
      };
    });

    // setTaxonomies(filteredTaxonomies)
    setFilteredTaxonomies(filteredTaxonomies);
  };

  const handleSelectTaxonomies = (selectedOptions: any) => {
    const newGroupOfTaxonomies = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];

    const allSelectedTaxonomies = [...selectedTaxonomies];
    // @ts-ignore
    allSelectedTaxonomies[0] = newGroupOfTaxonomies;
    setReadyToFetch(true);
    setSelectedTaxonomies(allSelectedTaxonomies);
  };

  useEffect(() => {
    if (readyToFetch) {
      setReadyToFetch(false);
      resourceAnalytics(
        startDate,
        endDate,
        'created',
        createdCategory,
        status,
        verificationSent,
        language,
        province,
        inputValueCity,
        selectedTaxonomies,
      );

      resourceAnalytics(
        startDate,
        endDate,
        'updated',
        updatedCategory,
        status,
        verificationSent,
        language,
        province,
        inputValueCity,
        selectedTaxonomies,
      );

      // resourceAnalytics(
      //   startDate,
      //   endDate,
      //   'active',
      //   updatedCategory,
      //   status,
      //   verificationSent,
      //   language,
      //   province,
      //   inputValueCity,
      //   selectedTaxonomies
      // );

      // resourceAnalytics(
      //   startDate,
      //   endDate,
      //   'verified',
      //   verifiedCategory,
      //   status,
      //   verificationSent,
      //   language,
      //   province,
      //   inputValueCity,
      //   selectedTaxonomies
      // );

      // resourceAnalytics(
      //   startDate,
      //   endDate,
      //   'english',
      //   englishCategory,
      //   status,
      //   verificationSent,
      //   language,
      //   province,
      //   inputValueCity,
      //   selectedTaxonomies
      // );

      // resourceAnalytics(
      //   startDate,
      //   endDate,
      //   'french',
      //   frenchCategory,
      //   status,
      //   verificationSent,
      //   language,
      //   province,
      //   inputValueCity,
      //   selectedTaxonomies
      // );
    }
    async function resourceAnalytics(
      startDate: string,
      endDate: string,
      type: string,
      category: string,
      status?: string,
      verificationStatus?: string,
      language?: string,
      province?: string,
      city?: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      taxonomies?: any,
    ) {
      try {
        setLoading([true, true, true, true, true, true]);
        const resourceAnalytics = await getResourceAnalytics(
          startDate,
          endDate,
          type,
          year,
          category,
          status,
          verificationStatus,
          language,
          province,
          city,
          taxonomies,
        );

        const siteAnalytics = await getSiteAnalytics(startDate, endDate, type);

        const agencyAnalytics = await getAgencyAnalytics(
          startDate,
          endDate,
          type,
        );

        if (type === 'created') {
          setDataByTaxonomyCreated(
            resourceAnalytics.created.resourcesCreatedTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.created.resourcesCreatedCount?.resourcesCreatedCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataCreatedByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.created.resourcesCreatedCount
              .resourcesCreatedCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataCreatedByMonth.push(group.count);
              }
            }
          }
          setDataCreated({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataCreatedByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceCreated({
            labels:
              resourceAnalytics.created.resourcesCreatedPerPrimaryProvince?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.created.resourcesCreatedPerPrimaryProvince?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityCreated({
            labels:
              resourceAnalytics.created.resourcesCreatedPerPrimaryCity?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.created.resourcesCreatedPerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeCreated({
            labels:
              resourceAnalytics.created.resourcesCreatedRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.created.resourcesCreatedRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelCreated({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.created.resourcesCreatedCount
                    ?.resourcesCreatedCount as never,
                  siteAnalytics.created.sitesRecordsCreatedCount
                    ?.recordsCreatedCount as never,
                  agencyAnalytics.created.agenciesRecordsCreatedCount
                    ?.recordsCreatedCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const updatedLoading = loading;
          updatedLoading[0] = false;
          setLoading(updatedLoading);
        } else if (type === 'updated') {
          setDataByTaxonomyUpdated(
            resourceAnalytics.updated.resourcesUpdatedTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.updated.resourcesUpdatedCount.resourcesUpdatedCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataUpdatedByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.updated.resourcesUpdatedCount
              .resourcesUpdatedCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataUpdatedByMonth.push(group.count);
              }
            }
          }
          setDataUpdated({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataUpdatedByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceUpdated({
            labels:
              resourceAnalytics.updated.resourcesUpdatedPerPrimaryProvince?.map(
                (group: any) => {
                  return group?._id;
                },
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.updated.resourcesUpdatedPerPrimaryProvince?.map(
                  (group: any) => {
                    return group.count;
                  },
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityUpdated({
            labels:
              resourceAnalytics.updated.resourcesUpdatedPerPrimaryCity?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.updated.resourcesUpdatedPerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeUpdated({
            labels:
              resourceAnalytics.updated.resourcesUpdatedRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.updated.resourcesUpdatedRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelUpdated({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.updated.resourcesUpdatedCount
                    ?.resourcesUpdatedCount as never,
                  siteAnalytics.updated.sitesRecordsUpdatedCount
                    ?.recordsUpdatedCount as never,
                  agencyAnalytics.updated.agenciesRecordsUpdatedCount
                    ?.recordsUpdatedCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const updatedLoading = loading;
          updatedLoading[1] = false;
          setLoading(updatedLoading);
        } else if (type === 'active') {
          setDataByTaxonomyActive(
            resourceAnalytics.active.resourcesActiveTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.active.resourcesActiveCount.resourcesActiveCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataActiveByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.active.resourcesActiveCount
              .resourcesActiveCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataActiveByMonth.push(group.count);
              }
            }
          }
          setDataActive({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataActiveByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceActive({
            labels:
              resourceAnalytics.active.resourcesActivePerPrimaryProvince?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.active.resourcesActivePerPrimaryProvince?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityActive({
            labels: resourceAnalytics.active.resourcesActivePerPrimaryCity?.map(
              (group: any) => group?._id,
            ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.active.resourcesActivePerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeActive({
            labels:
              resourceAnalytics.active.resourcesActiveRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.active.resourcesActiveRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelActive({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.active.resourcesActiveCount
                    ?.resourcesActiveCount as never,
                  siteAnalytics.active.sitesRecordsActiveCount
                    ?.recordsActiveCount as never,
                  agencyAnalytics.active.agenciesRecordsActiveCount
                    ?.recordsActiveCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const updatedLoading = loading;
          updatedLoading[2] = false;
          setLoading(updatedLoading);
        } else if (type === 'verified') {
          setDataByTaxonomyVerified(
            resourceAnalytics.verified.resourcesVerifiedTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.verified.resourcesVerifiedCount.resourcesVerifiedCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataVerifiedByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.verified
              .resourcesVerifiedCount.resourcesVerifiedCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataVerifiedByMonth.push(group.count);
              }
            }
          }
          setDataVerified({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataVerifiedByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceVerified({
            labels:
              resourceAnalytics.verified.resourcesVerifiedPerPrimaryProvince?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.verified.resourcesVerifiedPerPrimaryProvince?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityVerified({
            labels:
              resourceAnalytics.verified.resourcesVerifiedPerPrimaryCity?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.verified.resourcesVerifiedPerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeVerified({
            labels:
              resourceAnalytics.verified.resourcesVerifiedRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.verified.resourcesVerifiedRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelVerified({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.verified.resourcesVerifiedCount
                    ?.resourcesVerifiedCount as never,
                  siteAnalytics.verified.sitesRecordsVerifiedCount
                    ?.recordsVerifiedCount as never,
                  agencyAnalytics.verified.agenciesRecordsVerifiedCount
                    ?.recordsActiveCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const updatedLoading = loading;
          updatedLoading[3] = false;
          setLoading(updatedLoading);
        } else if (type === 'english') {
          setDataByTaxonomyEnglish(
            resourceAnalytics.english.resourcesEnglishTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.english.resourcesEnglishCount.resourcesEnglishCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataEnglishByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.english.resourcesEnglishCount
              .resourcesEnglishCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataEnglishByMonth.push(group.count);
              }
            }
          }
          setDataEnglish({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataEnglishByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceEnglish({
            labels:
              resourceAnalytics.english.resourcesEnglishPerPrimaryProvince?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.english.resourcesEnglishPerPrimaryProvince?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityEnglish({
            labels:
              resourceAnalytics.english.resourcesEnglishPerPrimaryCity?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.english.resourcesEnglishPerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeEnglish({
            labels:
              resourceAnalytics.english.resourcesEnglishRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.english.resourcesEnglishRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelEnglish({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.english.resourcesEnglishCount
                    ?.resourcesEnglishCount as never,
                  siteAnalytics.english.sitesRecordsEnglishCount
                    ?.recordsEnglishCount as never,
                  agencyAnalytics.english.agenciesRecordsEnglishCount
                    ?.recordsActiveCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
          const updatedLoading = loading;
          updatedLoading[4] = false;
          setLoading(updatedLoading);
        } else if (type === 'french') {
          setDataByTaxonomyFrench(
            resourceAnalytics.french.resourcesFrenchTaxonomyCounts,
          );

          const monthsIncluded =
            resourceAnalytics.french.resourcesFrenchCount.resourcesFrenchCountGroups?.map(
              (group: any) => {
                if (group?._id.year == year) {
                  return monthNames[group?._id.month - 1];
                }
              },
            );

          const monthsincluded = monthNames
            ?.map((month) => {
              if (monthsIncluded?.includes(month)) {
                return month;
              }
            })
            .filter((n) => n);

          const dataFrenchByMonth = [];
          for (const month of monthsincluded) {
            for (const group of resourceAnalytics.french.resourcesFrenchCount
              .resourcesFrenchCountGroups) {
              if (
                group?._id.year == year &&
                group?._id.month == monthNames.indexOf(month as string) + 1
              ) {
                dataFrenchByMonth.push(group.count);
              }
            }
          }
          setDataFrench({
            labels: monthsincluded as string[],
            datasets: [
              {
                label: 'Records',
                data: dataFrenchByMonth as never[],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionProvinceFrench({
            labels:
              resourceAnalytics.french.resourcesFrenchPerPrimaryProvince?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.french.resourcesFrenchPerPrimaryProvince?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByRegionCityFrench({
            labels: resourceAnalytics.french.resourcesFrenchPerPrimaryCity?.map(
              (group: any) => group?._id,
            ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.french.resourcesFrenchPerPrimaryCity?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByTypeFrench({
            labels:
              resourceAnalytics.french.resourcesFrenchRecordTypeCounts?.map(
                (group: any) => group?._id,
              ),
            datasets: [
              {
                label: 'Records',
                data: resourceAnalytics.french.resourcesFrenchRecordTypeCounts?.map(
                  (group: any) => group.count,
                ),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });

          setDataByLevelFrench({
            labels: CHART_ENTITY_LABELS,
            datasets: [
              {
                label: 'Records',
                data: [
                  resourceAnalytics.french.resourcesFrenchCount
                    ?.resourcesFrenchCount as never,
                  siteAnalytics.french.sitesRecordsFrenchCount
                    ?.recordsFrenchCount as never,
                  agencyAnalytics.french.agenciesRecordsFrenchCount
                    ?.recordsActiveCount as never,
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
        }

        const updatedLoading = loading;
        updatedLoading[5] = false;
        setLoading(updatedLoading);

        setReadyToFetch(false);
      } catch (e) {
        console.log(e);
      }
    }
  }, [
    charts,
    readyToFetch,
    year,
    activeCategory,
    verifiedCategory,
    createdCategory,
    frenchCategory,
    englishCategory,
    updatedCategory,
    loading,
    startDate,
    endDate,
    status,
    verificationSent,
    language,
    province,
    inputValueCity,
    selectedTaxonomies,
  ]);

  function handleChartFilterUpdate(filter: string, chartIndex: number) {
    setCharts(
      charts?.map((c) => {
        if (c.id === chartIndex) {
          return { ...c, filter };
        } else {
          return c;
        }
      }),
    );

    if (chartIndex === 0) {
      if (filter === 'Count') {
        setCreatedCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setCreatedCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setCreatedCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setCreatedCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setCreatedCategory(CATEGORIES.TAXONOMY);
      }
    } else if (chartIndex === 1) {
      if (filter === 'Count') {
        setUpdatedCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setUpdatedCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setUpdatedCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setUpdatedCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setUpdatedCategory(CATEGORIES.TAXONOMY);
      }
    } else if (chartIndex === 2) {
      if (filter === 'Count') {
        setActiveCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setActiveCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setActiveCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setActiveCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setActiveCategory(CATEGORIES.TAXONOMY);
      }
    } else if (chartIndex === 3) {
      if (filter === 'Count') {
        setVerifiedCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setVerifiedCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setVerifiedCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setVerifiedCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setVerifiedCategory(CATEGORIES.TAXONOMY);
      }
    } else if (chartIndex === 4) {
      if (filter === 'Count') {
        setEnglishCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setEnglishCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setEnglishCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setEnglishCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setEnglishCategory(CATEGORIES.TAXONOMY);
      }
    } else if (chartIndex === 5) {
      if (filter === 'Count') {
        setFrenchCategory(CATEGORIES.COUNT);
      } else if (filter === 'Region (Province)') {
        setFrenchCategory(CATEGORIES.PRIMARY_PROVINCE);
      } else if (filter === 'Region (City)') {
        setFrenchCategory(CATEGORIES.PRIMARY_CITY);
      } else if (filter === 'Record Type') {
        setFrenchCategory(CATEGORIES.RECORD_TYPE);
      } else if (filter === 'Taxonomy') {
        setFrenchCategory(CATEGORIES.TAXONOMY);
      }
    }
    setReadyToFetch(true);
  }

  function handleChartStartDateUpdate(startDate: string) {
    setCharts(
      charts?.map((c) => {
        return { ...c, startDate };
      }),
    );
  }

  function handleChartEndDateUpdate(endDate: string) {
    setCharts(
      charts?.map((c) => {
        return { ...c, endDate };
      }),
    );
  }

  function handleYearSelection(year: string) {
    setYear(year);
    setReadyToFetch(true);
  }

  return (
    <Box>
      <Box mx={0} my={2} p={0}>
        <StyledTextH2>
          {intl.translate({
            id: 'Dashboard',
          })}
        </StyledTextH2>
      </Box>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ padding: '8px' }}>
          <TextField
            select
            label={intl.translate({
              id: 'View Year',
            })}
            value={year}
            variant="standard"
            // fullWidth={true}
            onChange={(e) => {
              if (e.target?.value < endDate.substring(0, 4)) {
                const dateString = `${e.target?.value}${endDate.substring(
                  4,
                  endDate.length,
                )}`;
                setStartDate(getPreviousYearDate(dateString));
                setEndDate(getDateString(dateString));
              }
              handleYearSelection(e.target?.value);
            }}
          >
            {yearsArray().map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ padding: '8px' }}>
          <Grid item xs={3}>
            <StyledLabel>
              {intl.translate({
                id: 'Start Date:',
              })}
            </StyledLabel>
          </Grid>
          <Grid item xs={9}>
            <TextField
              // error={!!validationErrors.startDate}
              helperText={''}
              name="startDate"
              type="date"
              onChange={(e) => {
                setReadyToFetch(true);
                if (new Date(e.target?.value) <= new Date(endDate)) {
                  setStartDate(e.target?.value);
                  handleChartStartDateUpdate(e.target?.value);
                } else {
                  toast.error('Start date must be less than end Date', {
                    position: 'top-center',
                  });
                }
              }}
              value={startDate}
              variant="standard"
              fullWidth
              hiddenLabel
            />
          </Grid>
          <Grid item xs={3}>
            <StyledLabel>
              {intl.translate({
                id: 'End Date:',
              })}
            </StyledLabel>
          </Grid>
          <Grid item xs={9}>
            <TextField
              // error={!!validationErrors.endDate}
              helperText={'End date'}
              name="endDate"
              type="date"
              onChange={(e) => {
                setReadyToFetch(true);
                if (new Date(e.target?.value) >= new Date(startDate)) {
                  setEndDate(e.target?.value);
                  handleChartEndDateUpdate(e.target?.value);
                } else {
                  toast.error('End date must be greater than start Date', {
                    position: 'top-center',
                  });
                }
              }}
              value={endDate}
              variant="standard"
              fullWidth
              hiddenLabel
            />
          </Grid>
        </div>

        <div style={{ padding: '8px', minWidth: '125px' }}>
          <TextField
            select
            label={'Status'}
            value={status}
            variant="standard"
            fullWidth={true}
            onChange={(e) => {
              setReadyToFetch(true);
              setStatus(e.target?.value);
            }}
          >
            {['Active', 'Inactive'].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ padding: '8px', minWidth: '125px' }}>
          <TextField
            select
            label={intl.translate({
              id: 'Verification sent?',
            })}
            value={verificationSent}
            variant="standard"
            fullWidth={true}
            onChange={(e) => {
              setReadyToFetch(true);
              setVerificationSent(e.target?.value);
            }}
          >
            {['true', 'false']?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ padding: '8px', minWidth: '125px' }}>
          <TextField
            select
            label={intl.translate({
              id: 'Language',
            })}
            value={language}
            variant="standard"
            fullWidth={true}
            onChange={(e) => {
              setReadyToFetch(true);

              setLanguage(e.target?.value);
            }}
          >
            {['english', 'french']?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ padding: '8px', minWidth: '125px' }}>
          <TextField
            select
            label={intl.translate({
              id: 'Province',
            })}
            value={province}
            variant="standard"
            fullWidth={true}
            onChange={(e) => {
              setReadyToFetch(true);
              setInputValueCity('');
              setProvince(e.target?.value);
            }}
          >
            {provinces.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ padding: '8px', width: '100%' }}>
          <Autocomplete
            options={
              (province &&
                citiesByProvince
                  ?.filter((city) => city.province === province)
                  .map((city) => city.city)) ||
              []
            }
            fullWidth
            noOptionsText="Select a Province"
            value={inputValueCity}
            getOptionLabel={(option) => option}
            onChange={(_e, newValue) => {
              if (newValue) {
                setReadyToFetch(true);
                setInputValueCity(newValue);
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={'Select city'}
                  variant="outlined"
                />
              );
            }}
          />
        </div>
      </div>
      <div style={{ padding: '8px', width: '100%' }}>
        <Autocomplete
          options={filteredTaxonomies}
          multiple
          fullWidth
          noOptionsText="Enter a taxonomy name to search"
          getOptionLabel={(option) => option.label}
          onChange={(e, newValue) => {
            try {
              handleSelectTaxonomies(newValue);
            } catch (e) {
              console.log(e);
            }
          }}
          onInputChange={(e, newValue) => {
            setInputValue(newValue);
            handleFilterChange(newValue);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={
                  filteredTaxonomies.map((st) => st.label)?.toString() ||
                  'Select taxonomies'
                }
                variant="outlined"
                onKeyDown={(e) => {
                  if (
                    e.key === 'Enter' &&
                    filteredTaxonomies.findIndex(
                      (o) => o.label === inputValue,
                    ) === -1
                  ) {
                    setFilteredTaxonomies((o) =>
                      o.concat([
                        {
                          label: inputValue,
                          value:
                            filteredTaxonomies.find(
                              (value) => value.label === inputValue,
                            )?.value || '',
                        },
                      ]),
                    );
                  }
                }}
              />
            );
          }}
        />
      </div>

      <Align v-center>
        <Box
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {charts?.map((c, i) => {
            return (
              <Box
                key={i}
                my={2}
                p={5}
                style={{
                  width: '49%',
                  border: '3px rgba(0,0,0,0.1) solid',
                  borderRadius: '10px',
                  overflowX: 'scroll',
                  overflowY: 'scroll',
                  maxHeight: '60vh',
                }}
              >
                <StyledTextH3>{c.title}</StyledTextH3>
                <Box style={{ padding: '20px 0' }}>
                  <TextField
                    select
                    label={intl.translate({
                      id: 'Drill Down By',
                    })}
                    value={c.filter}
                    variant="standard"
                    fullWidth={true}
                    onChange={(e) =>
                      handleChartFilterUpdate(e.target?.value, i)
                    }
                  >
                    {c.chartTypes?.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                {i === 0 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataCreated} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyCreated?.map((row) => {
                                  return row.records?.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceCreated}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityCreated}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByTypeCreated} />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByLevelCreated}
                        />
                      )}
                  </div>
                )}
                {loading[i] && (
                  <div key={i}>
                    <BounceLoader color={palette.green.light} />
                  </div>
                )}
                {i === 1 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataUpdated} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyUpdated?.map((row) => {
                                  return row.records?.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceUpdated}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityUpdated}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByTypeUpdated} />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByLevelUpdated}
                        />
                      )}
                  </div>
                )}
                {/* {i === 2 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataActive} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyActive?.map((row) => {
                                  return row.records?.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                            {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceActive}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityActive}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByTypeActive} />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByLevelActive} />
                      )}
                  </div>
                )}
                {i === 3 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataVerified} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyVerified?.map((row) => {
                                  return row.records?.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                            {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceVerified}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityVerified}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByTypeVerified}
                        />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByLevelVerified}
                        />
                      )}
                  </div>
                )} */}
                {/* 
                {i === 4 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataEnglish} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyEnglish?.map((row) => {
                                  return row.records?.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                            {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceEnglish}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityEnglish}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByTypeEnglish} />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByLevelEnglish}
                        />
                      )}
                  </div>
                )}

                {i === 5 && !loading[i] && (
                  <div>
                    {c.filter == FILTERS.NUMBER && (
                      <Line options={CHART_OPTIONS} data={dataFrench} />
                    )}
                    {c.filter == FILTERS.TAXONOMY &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Box>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: '48%' }}
                              aria-label="filtered table displaying record count by taxonomy"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Taxonomy Code</TableCell>
                                  <TableCell>Taxonomy Name</TableCell>
                                  <TableCell align="right">Count</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataByTaxonomyFrench?.map((row) => {
                                  return row.records.map(
                                    (record: any, index: number) => {
                                      return (
                                        <TableRow
                                          // eslint-disable-next-line @typescript-eslint/no-var-requires
                                          key={`${record?.code}-${index}`}
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                            {
                                              border: 0,
                                            },
                                          }}
                                        >
                                          <TableCell component="th" scope="row">
                                            {record?.code}
                                          </TableCell>
                                          <TableCell>
                                            {record?.nameEN}
                                          </TableCell>
                                          <TableCell align="right">
                                            {row.count}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    },
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      )}
                    {c.filter == FILTERS.REGION_PROVINCE &&
                      c.startDate !== '' &&
                      c.endDate != '' && (
                        <Bar
                          options={CHART_OPTIONS}
                          data={dataByRegionProvinceFrench}
                        />
                      )}
                    {c.filter == FILTERS.REGION_CITY &&
                      c.startDate &&
                      c.endDate && (
                        <Bar
                          options={CHART_OPTIONS_CITY_ALTERNATIVE}
                          data={dataByRegionCityFrench}
                        />
                      )}
                    {c.filter == FILTERS.TYPE &&
                      c.id !== 4 &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByTypeFrench} />
                      )}
                    {c.filter == FILTERS.LEVEL &&
                      c.id !== 5 &&
                      c.startDate &&
                      c.endDate && (
                        <Bar options={CHART_OPTIONS} data={dataByLevelFrench} />
                      )}
                  </div>
                )} */}
              </Box>
            );
          })}
        </Box>
      </Align>
    </Box>
  );
};
