import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalParagraph } from 'components/Shared/ModalTitles/ModalParagraph';
import { ModalSubTitle } from 'components/Shared/ModalTitles/ModalSubTitle';
import { ModalTitle } from 'components/Shared/ModalTitles/ModalTitle';
import { Paragraph } from 'design/Text/Paragraph';
import { tableHiddenColumnsBulkImport as tableHiddenAgencies } from 'helpers/agencies.helpers';
import { tableHiddenColumnsBulkImport as tableHiddenResources } from 'helpers/resources.helpers';
import { tableHiddenColumnsBulkImport as tableHiddenSites } from 'helpers/sites.helpers';
import { tableHiddenColumnsBulkImport as tableHiddenTaxonomies } from 'helpers/taxonomies.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { useResources } from 'hooks/useResources';
import { useSites } from 'hooks/useSites';
import { useTaxonomies } from 'hooks/useTaxonomies';
import MaterialReactTable from 'material-react-table';
import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BulkImportData } from 'types/bulk-import';
import { Language, Resource } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';

const StyledStatusUpperCaseStrong = styled.strong`
  text-transform: uppercase;
`;

type Summary = {
  resourcesCount: number;
  sitesCount: number;
  agenciesCount: number;
  taxonomiesCount: number;
  english: number;
  french: number;
};

export const BulkFinishImportModal: FC<{
  onClose: () => void;
  onSubmit: (data: BulkImportData) => void;
  open: boolean;
  data: BulkImportData;
}> = ({ open, onClose, onSubmit, data }) => {
  const [summary, setSummary] = useState<Summary | null>(null);
  const { columns: agenciesColumns } = useAgencies();
  const { columns: sitesColumns } = useSites();
  const { columns: resourcesColumns } = useResources();
  const { columns: taxonomiesColumns } = useTaxonomies();

  const calculateSummary = useCallback((): Summary => {
    const resourcesCount = data.Resources?.length || 0;
    const sitesCount = data.Sites?.length || 0;
    const agenciesCount = data.Agencies?.length || 0;
    const taxonomiesCount = data.Taxonomies?.length || 0;
    let english = 0;
    let french = 0;

    if (data.Resources) {
      data.Resources.forEach((resource: Resource) => {
        if (resource.languages && resource.languages.length > 0) {
          resource.languages.forEach((language: Language) => {
            if (language.code === 'en') {
              english++;
            }

            if (language.code === 'fr') {
              french++;
            }
          });
        }
      });
    }

    return {
      resourcesCount,
      sitesCount,
      agenciesCount,
      taxonomiesCount,
      english,
      french,
    };
  }, [data]);

  const handleSubmit = () => {
    onSubmit(data);
    onClose();
  };

  useEffect(() => {
    setSummary(calculateSummary());
  }, [calculateSummary]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        <ModalTitle>
          {intl.translate({
            id: 'Bulk Import',
          })}
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            marginX: 'auto',
            marginBottom: '3rem',
          }}
        >
          <ModalSubTitle>
            {intl.translate({
              id: 'Summary',
            })}
          </ModalSubTitle>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Paragraph>
                {intl.translate({
                  id: 'Resources',
                })}
                :<strong>{summary?.resourcesCount}</strong>
              </Paragraph>
            </Grid>
            <Grid item xs={3}>
              <Paragraph>
                {intl.translate({
                  id: 'Sites',
                })}
                :<strong>{summary?.sitesCount}</strong>
              </Paragraph>
            </Grid>
            <Grid item xs={3}>
              <Paragraph>
                {intl.translate({
                  id: 'Agencies',
                })}
                :<strong>{summary?.agenciesCount}</strong>
              </Paragraph>
            </Grid>
            <Grid item xs={3}>
              <Paragraph>
                {intl.translate({
                  id: 'Taxonomies',
                })}
                :<strong>{summary?.taxonomiesCount}</strong>
              </Paragraph>
            </Grid>
            <Grid item xs={12}>
              <Paragraph>
                {intl.translate({
                  id: 'English',
                })}
                :<strong>{summary?.english}</strong>
              </Paragraph>
            </Grid>

            <Grid item xs={12}>
              <Paragraph>
                {intl.translate({
                  id: 'French',
                })}
                :<strong>{summary?.french}</strong>
              </Paragraph>
            </Grid>
          </Grid>
        </Box>
        <ModalParagraph>
          {intl.translate(
            {
              id: 'Review the following record details and confirm to <strong>import all</strong>:',
            },
            {
              strong: (label) => (
                <StyledStatusUpperCaseStrong>
                  {label}
                </StyledStatusUpperCaseStrong>
              ),
            },
          )}
        </ModalParagraph>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              paddingY: 2,
            }}
          >
            {data.Resources?.length > 0 && (
              <>
                <ModalSubTitle>
                  {intl.translate({
                    id: 'Resources',
                  })}
                </ModalSubTitle>
                <MaterialReactTable
                  columns={resourcesColumns}
                  data={data.Resources.slice(0, 10)}
                  initialState={{
                    columnVisibility: { ...tableHiddenResources },
                  }}
                />
              </>
            )}

            {data.Sites?.length > 0 && (
              <>
                <ModalSubTitle>
                  {intl.translate({
                    id: 'Sites',
                  })}
                </ModalSubTitle>
                <MaterialReactTable
                  columns={sitesColumns}
                  data={data.Sites.slice(0, 10)}
                  initialState={{
                    columnVisibility: { ...tableHiddenSites },
                  }}
                />
              </>
            )}

            {data.Agencies?.length > 0 && (
              <>
                <ModalSubTitle>
                  {intl.translate({
                    id: 'Agencies',
                  })}
                </ModalSubTitle>
                <MaterialReactTable
                  columns={agenciesColumns}
                  data={data.Agencies.slice(0, 10)}
                  initialState={{
                    columnVisibility: {
                      ...tableHiddenAgencies,
                    },
                  }}
                />
              </>
            )}

            {data.Taxonomies?.length > 0 && (
              <>
                <ModalSubTitle>
                  {intl.translate({
                    id: 'Taxonomies',
                  })}
                </ModalSubTitle>
                <MaterialReactTable
                  columns={taxonomiesColumns}
                  data={data.Taxonomies.slice(0, 10)}
                  initialState={{
                    columnVisibility: {
                      ...tableHiddenTaxonomies,
                    },
                  }}
                />
              </>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Import',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
