export const tableHiddenColumnsInitialState = {
  objectId: false,
  createdAt: false,
  updatedAt: false,
  nameFR: false,
};

export const tableHiddenColumnsBulkImport = {
  nameEN: true,
  nameFR: true,
  objectId: false,
  createdAt: false,
  updatedAt: false,
};
