import { Box } from '@mui/material';
import { DataManagementBaseEnum } from 'global-constants/data-management';
import { Align } from 'layout';
import { FC, useState } from 'react';
import { PendingChangesHeader } from './PendingChangesHeader';
import PendingChangesResourcesTable from './PendingChangesResourcesTable/PendingChangesResourcesTable';
import PendingChangesSitesTable from './PendingChangesSitesTable/PendingChangesSitesTable';
import PendingChangesAgenciesTable from './PendingChangesAgenciesTable/PendingChangesAgenciesTable';

export const PendingChanges: FC = () => {
  const [dataType, setDataType] = useState(DataManagementBaseEnum.Resources);

  const RenderSelectedDataTable = () => {
    switch (dataType) {
      case DataManagementBaseEnum.Resources:
        return (
          <Align v-center>
            <PendingChangesResourcesTable />
          </Align>
        );
      case DataManagementBaseEnum.Sites:
        return (
          <Align v-center>
            <PendingChangesSitesTable />
          </Align>
        );
      case DataManagementBaseEnum.Agencies:
        return (
          <Align v-center>
            <PendingChangesAgenciesTable />
          </Align>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <PendingChangesHeader dataType={dataType} setDataType={setDataType} />
      <RenderSelectedDataTable />
    </Box>
  );
};
