import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { newPhoneNumbers } from 'helpers/resources.helpers';
import React, { FC } from 'react';
import { PhoneNumber } from 'types/data-management';
import { YesNoEnum } from 'types/utilities';

const PhoneNumbersForm: FC<{
  value: PhoneNumber[];
  setValue: React.Dispatch<React.SetStateAction<PhoneNumber[]>>;
}> = ({ value, setValue }) => {
  const handleAddItem = () => {
    const arrayWithNewItem = [...value, { ...newPhoneNumbers }];
    setValue(arrayWithNewItem);
  };

  const handleRemoveItem = (index: number) => {
    const arrayRemovedInex = [...value];
    arrayRemovedInex.splice(index, 1);
    setValue(arrayRemovedInex);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <Grid container>
          {value.map((phoneNumber, index) => (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                <TextField
                  label={'Phone Number Name'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              name: e.target?.value,
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.name || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  aria-label="Add item"
                  component="label"
                  onClick={handleAddItem}
                >
                  <Add />
                </IconButton>
              </Grid>

              {index > 0 && (
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    aria-label="Remove Item"
                    component="label"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <Remove />
                  </IconButton>
                </Grid>
              )}

              <Grid item xs={10}>
                <TextField
                  label={'Phone Description En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              description: {
                                ...x.description,
                                en: e.target?.value,
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.description.en || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={'Phone Description Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              description: {
                                ...x.description,
                                fr: e.target?.value,
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.description.fr || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={10}>
                <TextField
                  label={'Type'}
                  select
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              type: e.target?.value,
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.type || 'phone'}
                  variant="standard"
                  fullWidth={true}
                >
                  {[
                    { label: 'Phone', value: 'phone' },
                    { label: 'Fax', value: 'fax' },
                    { label: 'Business', value: 'business' },
                    { label: 'Hotline', value: 'hotline' },
                    { label: 'Out of area', value: 'out of area' },
                    { label: 'TTY', value: 'tty' },
                    { label: 'Toll Free', value: 'toll free' },
                    { label: 'After Hours', value: 'after hours' },
                  ].map((type) => (
                    <MenuItem key={type.value} value={type.value || ''}>
                      {type.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label={'Is Private'}
                  select
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              isPrivate: e.target?.value,
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.isPrivate || YesNoEnum.No}
                  variant="standard"
                  fullWidth={true}
                >
                  {Object.values(YesNoEnum).map((item) => (
                    <MenuItem key={item} value={item || ''}>
                      {item === YesNoEnum.Yes ? 'Yes' : 'No'}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={10}>
                <TextField
                  label={'Number'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              number: e.target?.value,
                            }
                          : x,
                      );
                    });
                  }}
                  value={phoneNumber.number || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {index !== value.length - 1 && (
                <Grid item mt={2} mb={1} xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PhoneNumbersForm;
