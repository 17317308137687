import { DeleteOutline, Edit, Save } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import {
  deleteTaxonomy,
  fetchTaxonomies,
  updateTaxonomy,
  searchTaxonomies,
} from 'api/taxonomies/taxonomies.api';
import { selectUser } from 'global-state/selectors';
import { DateFormat, formatDate } from 'helpers/date.helpers';
import { tableHiddenColumnsInitialState } from 'helpers/taxonomies.helpers';
import { useTaxonomies } from 'hooks/useTaxonomies';
import MaterialReactTable, { MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataManagement } from 'types/data-management';
import { Taxonomy } from 'types/entities/taxonomies';
import { UserRoleEnum } from 'types/user-role.enum';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';
import { DeleteModal } from './Modals/DeleteModal/DeleteModal';
import { EditModal } from './Modals/EditModal/EditModal';
import { SaveEditedRowsModal } from './Modals/EditModal/SaveEditedRowsModal';
import TableSearch from 'components/Resources/ResourcesTable/TableSearch';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';

const TaxonomiesTable: FC = () => {
  const [tableData, setTableData] = useState<Taxonomy[]>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<MRT_Row<Taxonomy>>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState<Taxonomy[]>(() => []);

  const [saveEditModalOpen, setSaveEditModalOpen] = useState(false);
  const [rowsEditedToSave, setRowsEditedToSave] = useState<Taxonomy[]>([]);
  const [taxonomyActiveCount, setTaxonomyActiveCount] = useState<number>();
  const [taxonomyInactiveCount, setTaxonomyInactiveCount] = useState<number>();

  const { columns } = useTaxonomies();

  const loggedUser = useSelector(selectUser);

  const getTaxonomies = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const result = await fetchTaxonomies(pageIndex + 1, pageSize);

      setRowCount(result.totalResults);
      setTableData(result.taxonomies);
      setTaxonomyActiveCount(result.activeCount);
      setTaxonomyInactiveCount(result.inactiveCount);
    } catch (error) {
      toast.error('Error fetching taxonomies', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const getSearchedTaxonomies = async (
    type: string,
    value: string,
    pageIndex: number,
    pageSize: number,
  ) => {
    try {
      setLoading(true);
      const result = await searchTaxonomies(
        type,
        value,
        pageIndex + 1,
        pageSize,
      );
      setRowCount(result.totalResults);
      setTableData(result.taxonomies);
      setTaxonomyActiveCount(undefined);
      setTaxonomyInactiveCount(undefined);
    } catch (error) {
      setRowCount(0);
      setTableData([]);
      setTaxonomyActiveCount(undefined);
      setTaxonomyInactiveCount(undefined);
      toast.error('Unable to list taxonomies. Try again shortly', {
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      getTaxonomies(pagination.pageIndex, pagination.pageSize);
    } else {
      getSearchedTaxonomies(
        type,
        value,
        pagination.pageIndex,
        pagination.pageSize,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, isSearch]);

  const handleEditRow = (row: MRT_Row<Taxonomy>) => {
    setRowToEdit(row);
    setEditModalOpen(true);
  };

  const handleSaveRowEdits = async (
    rowIndex: number,
    values: DataManagement,
  ) => {
    setRowToEdit(undefined);

    if (values.Taxonomy) {
      tableData[rowIndex] = values.Taxonomy;
      //send/receive api updates here, then refetch or update local table data for re-render
      setTableData([...tableData]);
      setRowsEditedToSave([...rowsEditedToSave, values.Taxonomy]);
    }
  };

  const handleDeleteRows = (rows: MRT_Row<Taxonomy>[]) => {
    const rowsOriginalData = rows.map((row) => row.original);

    setRowsToDelete(rowsOriginalData);
    setDeleteModalOpen(true);
  };

  const modalDeleteRows = async () => {
    //send api delete request here, then refetch or update local table data for re-render
    for (let i = 0; i < tableData.length; i++) {
      const obj = tableData[i];
      try {
        if (rowsToDelete.indexOf(obj) !== -1) {
          await deleteTaxonomiesEntity(obj.objectId);
          tableData.splice(i, 1);
          i--;
        }
      } catch (error) {
        /*empty*/
      }
    }
    setTableData([...tableData]);
  };

  const deleteTaxonomiesEntity = async (objectId: string) => {
    try {
      await deleteTaxonomy(objectId);
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error(error);
      }
    }
  };

  const handleSaveChanges = () => {
    setSaveEditModalOpen(true);
  };

  const modalSaveChanges = async () => {
    const failedToSave = [];
    for (let i = 0; i < rowsEditedToSave.length; i++) {
      const row = rowsEditedToSave[i];
      try {
        await updateTaxonomy(row.objectId, row);
      } catch (_error) {
        failedToSave.push(row);
      }
    }
    try {
      await getTaxonomies(pagination.pageIndex, pagination.pageSize);
    } catch (error) {
      toast.error('Failed to get taxonomies', { position: 'top-center' });
    } finally {
      setRowsEditedToSave(failedToSave);
    }
  };

  const modalCancelSaveChanges = async () => {
    setRowsEditedToSave([]);
    try {
      await getTaxonomies(pagination.pageIndex, pagination.pageSize);
    } catch (_error) {
      /*empty*/
    }
  };

  // const csvOptions = {
  //   filename: `Taxonomies-Table-Export-${formatDate(
  //     new Date(),
  //     DateFormat.combinationOfLocalizadDateAndTime,
  //   )}`,
  //   fieldSeparator: ';',
  //   quoteStrings: '"',
  //   decimalSeparator: '.',
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: true,
  //   headers: columns.map((c) => c.header),
  // };

  // const csvExporter = new ExportToCsv(csvOptions);

  // const handleExportRows = (rows: MRT_Row<Taxonomy>[]) => {
  //   csvExporter.generateCsv(rows.map((row) => row.original));
  // };

  // const handleExportData = () => {
  //   csvExporter.generateCsv(tableData);
  // };

  const searchFiltersSubmit = async (type: string, value: string) => {
    try {
      setLoading(true);
      setType(type);
      setValue(value);
      setIsSearch(!!value);
      const pageIndex = 0;
      const pageSize = 10;
      const result = await searchTaxonomies(
        type,
        value,
        pageIndex + 1,
        pageSize,
      );
      setPagination((page) => {
        return { ...page, pageIndex, pageSize };
      });
      setRowCount(result.totalResults);
      setTableData(result.taxonomies);
      setTaxonomyActiveCount(undefined);
      setTaxonomyInactiveCount(undefined);
    } catch (err: unknown) {
      if (err instanceof Error) {
        logger.error(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setType('');
    setValue('');
    setIsSearch(false);
    getTaxonomies(pagination.pageIndex, pagination.pageSize);
  };

  return (
    <div style={{ width: '100%' }}>
      <TableSearch
        onSubmit={searchFiltersSubmit}
        reset={resetForm}
        taxonomySearch
        onlyNameSearch
      />
      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        columns={columns}
        enableColumnResizing
        defaultColumn={{
          minSize: 20,
          maxSize: 300,
          size: 160,
        }}
        data={tableData}
        enableColumnOrdering
        enableEditing
        enableHiding
        enableRowSelection
        positionActionsColumn="last"
        renderRowActions={
          loggedUser?.role === UserRoleEnum.Admin ||
          loggedUser?.role === UserRoleEnum.SuperAdmin
            ? ({ row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0rem',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip
                    arrow
                    title={intl.translate({
                      id: 'Edit',
                    })}
                  >
                    <IconButton
                      aria-label="edit button"
                      onClick={() => handleEditRow(row)}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            : () => <></>
        }
        renderTopToolbarCustomActions={
          loggedUser?.role === UserRoleEnum.Admin ||
          loggedUser?.role === UserRoleEnum.SuperAdmin
            ? ({ table }) => (
                <div style={{ display: 'grid' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      p: '0.5rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      color="success"
                      disabled={!(rowsEditedToSave.length > 0)}
                      startIcon={<Save />}
                      onClick={handleSaveChanges}
                      variant="contained"
                    >
                      {intl.translate({
                        id: 'Confirm changes',
                      })}
                    </Button>
                    <Button
                      disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                      }
                      onClick={() =>
                        handleDeleteRows(table.getSelectedRowModel().rows)
                      }
                      startIcon={<DeleteOutline />}
                      variant="outlined"
                      aria-label="delete"
                    >
                      {intl.translate({
                        id: 'Delete selected',
                      })}
                    </Button>
                  </Box>
                  {/* <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                p: '0.5rem',
                flexWrap: 'wrap',
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export All Data',
                })}
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export All Filtered Rows',
                })}
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export Page Rows',
                })}
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export Selected Rows',
                })}
              </Button>
            </Box> */}
                </div>
              )
            : () => <></>
        }
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50, 100],
        }}
        initialState={{
          density: 'compact',
          columnVisibility: { ...tableHiddenColumnsInitialState },
        }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading: loading,
          pagination,
        }}
      />

      {rowToEdit && (
        <EditModal
          row={rowToEdit}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          onSubmit={handleSaveRowEdits}
        />
      )}
      <DeleteModal
        rowsToDelete={rowsToDelete}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={modalDeleteRows}
      />
      <SaveEditedRowsModal
        rowsToSave={rowsEditedToSave}
        open={saveEditModalOpen}
        onClose={() => {
          setSaveEditModalOpen(false);
        }}
        onCancel={modalCancelSaveChanges}
        onSubmit={modalSaveChanges}
      />

      <Tooltip
        title={
          <div>
            <p>Active taxonomies: {taxonomyActiveCount}</p>
            <p>Inactive taxonomies: {taxonomyInactiveCount}</p>
          </div>
        }
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TaxonomiesTable;
