import { toast } from 'components/Toast/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Agency, AgencyDto } from 'types/entities/agencies';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchAgencies(
  pageIndex: number,
  pageSize: number,
): Promise<AgencyDto> {
  try {
    const response = await http.get<BaseAPIResponse<AgencyDto>>(
      `/agency?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Agencies');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Agencies: ${err}`);
  }
}

export async function getAgencyById(agencyId: string): Promise<Agency> {
  try {
    const response = await http.get<BaseAPIResponse<Agency>>(
      `/agency/${agencyId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Agency');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get Agency: ${err}`);
  }
}

export async function createAgency(agency: Agency): Promise<Agency> {
  try {
    const response = await http.post<BaseAPIResponse<Agency>>(
      `/agency`,
      agency,
    );

    if (response.success) {
      toast.success('The Agency was created successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error create Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error creating agency. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error create Agencies: ${err}`);
  }
}

export async function getAgencyAnalytics(
  startDate: string,
  endDate: string,
  type: string,
): Promise<any> {
  try {
    const lastYear = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1),
    );
    const response = await http.get<BaseAPIResponse<Agency>>(
      `/agency/analytics/${startDate !== '' ? startDate : lastYear}/${
        endDate !== '' ? endDate : new Date()
      }/${type}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Agency analytics');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get agency analytics: ${err}`);
  }
}

export async function updateStatusAgency(
  agencyId: string,
  isActive: boolean,
): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/agency/status/${agencyId}`,
      {
        isActive,
      },
    );

    if (response.success) {
      toast.success('The Agency was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error update Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error updating agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error update Agencies: ${err}`);
  }
}

export async function updateAgency(
  agencyId: string,
  agency: Agency,
): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/agency/${agencyId}`,
      agency,
    );

    if (response.success) {
      toast.success('The Agency was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error update Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error updating agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error update Agencies: ${err}`);
  }
}

export async function deleteAgency(agencyId: string): Promise<Agency> {
  try {
    const response = await http.delete<BaseAPIResponse<Agency>>(
      `/agency/${agencyId}`,
    );

    if (response.success) {
      toast.success('The Agency was deleted', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error delete Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error deleting agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error delete Agencies: ${err}`);
  }
}
type SearchAgency = Omit<AgencyDto, 'activeCount' | 'inactiveCount'>;

export async function searchAgencies(
  type: string,
  value: string,
  pageIndex = 1,
  pageSize = 100,
): Promise<SearchAgency> {
  try {
    const response = await http.get<BaseAPIResponse<SearchAgency>>(
      `/agency/search/${type}/${value}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Searching Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error Searching Agencies', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Searching Agencies: ${err}`);
  }
}
