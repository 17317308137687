import { Box } from '@mui/material';
import AgenciesTable from 'components/Agencies/AgenciesTable';
import CreationSection from 'components/Creation/CreationSection/CreationSection';
import ResourcesTable from 'components/Resources/ResourcesTable/ResourcesTable';
import SitesTable from 'components/Sites/SitesTable';
import { TabPanel } from 'components/TabPanel/TabPanel';
import TaxonomiesTable from 'components/Taxonomies/TaxonomiesTable';
import { DataManagementEnum } from 'global-constants/data-management';
import { Align } from 'layout';
import { FC, useState } from 'react';
import { Download } from 'views/DataManagement/Download/Download';
import { DataManagementHeader } from './DataManagementHeader';
import { DataManagementTabs } from './DataManagementTabs';
import { ErrorBoundary } from 'react-error-boundary';

export const DataManagement: FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const [dataType, setDataType] = useState(DataManagementEnum.Resources);

  const RenderSelectedDataTable = () => {
    switch (dataType) {
      case DataManagementEnum.Resources:
        return (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Align v-center>
              <ResourcesTable />
            </Align>
          </ErrorBoundary>
        );
      case DataManagementEnum.Sites:
        return (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Align v-center>
              <SitesTable />
            </Align>
          </ErrorBoundary>
        );
      case DataManagementEnum.Agencies:
        return (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Align v-center>
              <AgenciesTable />
            </Align>
          </ErrorBoundary>
        );
      case DataManagementEnum.Taxonomies:
        return (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Align v-center>
              <TaxonomiesTable />
            </Align>
          </ErrorBoundary>
        );
      default:
        return (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Align v-center>
              <ResourcesTable />
            </Align>
          </ErrorBoundary>
        );
    }
  };

  return (
    <Box>
      <DataManagementHeader dataType={dataType} setDataType={setDataType} />
      <DataManagementTabs tabValue={tabValue} setTabValue={setTabValue} />

      <TabPanel value={tabValue} index={0}>
        <RenderSelectedDataTable />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CreationSection selectedType={dataType} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Download dataType={dataType} />
      </TabPanel>
    </Box>
  );
};
