import { Box, MenuItem, TextField } from '@mui/material';
import { fetchHowIsServiceOffered } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { FC, useEffect, useState } from 'react';
import { HowIsServiceOffered } from 'types/data-management';
import { intl } from 'utilities/i18n/intl.utility';

const emptyHowIsServiceOffered: HowIsServiceOffered = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

const HowIsServiceOfferedForm: FC<{
  value: string[];
  setValue: (values: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValues: any[];
}> = ({ value, setValue, currentValues }) => {
  const [howIsServiceOffered, setHowIsServiceOffered] = useState([
    { ...emptyHowIsServiceOffered },
  ]);
  const [selectedHowIsServiceOffered, setSelectedHowIsServiceOffered] =
    useState(currentValues);

  useEffect(() => {
    const getHowIsServiceOffered = async () => {
      try {
        const howIsServiceOfferedResponse = await fetchHowIsServiceOffered();
        setHowIsServiceOffered(howIsServiceOfferedResponse);
      } catch (err) {
        toast.error(
          'Error getting how services are offered. Please, try again.',
          {
            position: 'top-center',
          },
        );
        setHowIsServiceOffered([{ ...emptyHowIsServiceOffered }]);
      }
    };

    getHowIsServiceOffered();
  }, []);

  useEffect(() => {
    setSelectedHowIsServiceOffered(
      howIsServiceOffered.filter((item) => value?.includes(item?.objectId)) || {
        ...emptyHowIsServiceOffered,
      },
    );
  }, [value, howIsServiceOffered]);

  const handleChangeDropDownSites = (value: string) => {
    setValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        {value && (
          <TextField
            label={intl.translate({
              id: 'How Is Service Offered',
            })}
            select
            SelectProps={{
              multiple: true,
            }}
            onChange={(e) => handleChangeDropDownSites(e.target?.value)}
            value={value?.length > 0 ? value : []}
            variant="standard"
            fullWidth={true}
          >
            {howIsServiceOffered?.map((reference) => (
              <MenuItem
                key={
                  reference?.objectId ||
                  reference?._id ||
                  `${String(new Date().getTime())}${Math.random()}`
                }
                value={reference?.objectId || reference?._id || ''}
                // style={getStyles(reference.objectId, value, theme)}
              >
                {`En: ${reference?.en}[Fr: ${reference?.fr}]`}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      {selectedHowIsServiceOffered?.length > 0 &&
        selectedHowIsServiceOffered?.map((reference) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '50ch' },
            }}
            key={
              reference?.objectId || `${String(new Date())}-${Math.random()}`
            }
          >
            <TextField
              label={intl.translate({
                id: 'En',
              })}
              value={reference?.en || ''}
              disabled
              variant="standard"
            />
            <TextField
              label={intl.translate({
                id: 'Fr',
              })}
              value={reference?.fr || ''}
              disabled
              variant="standard"
            />
          </Box>
        ))}
    </>
  );
};

export default HowIsServiceOfferedForm;
