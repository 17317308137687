/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserEditApi } from 'types/user-edit-api.dto';
import { UserRequestApi } from 'types/user-request-api.dto';
import { UserResetPass } from 'types/user-reset-pass.dto';
import { User } from 'types/user.dto';
import { http } from 'utilities/http/http';

export async function login(
  email: string,
  password: string,
): Promise<{ user: User; token: string }> {
  try {
    const response = await http.post<{
      success: boolean;
      data: { user: User; token: string };
    }>('/auth/login/', {
      email,
      password,
    });

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Login');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function listUser(): Promise<Array<User>> {
  try {
    const response = await http.get<{
      success: boolean;
      data: Array<User>;
    }>('/user/');
    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Users');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function createUser(user: UserRequestApi): Promise<User> {
  try {
    const response = await http.post<{
      success: boolean;
      data: User;
    }>('/user/', user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Crate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function editUser(id: string, user: UserEditApi): Promise<User> {
  try {
    const response = await http.put<{
      success: boolean;
      data: User;
    }>(`/user/${id}`, user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Edit User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function deleteUser(id: string): Promise<User> {
  try {
    const response = await http.delete<{
      success: boolean;
      data: User;
    }>(`/user/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Delete User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function activateUser(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/activate/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Activate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function deactivateUser(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/deactivate/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Deactivate User');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function setUserPassword(
  token: string,
  password: string,
): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>('/user/set-password', { token, password });

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Set Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function revokeInvitation(id: string): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>(`/user/revoke/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Set Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}

export async function resetUserPassword(user: UserResetPass): Promise<User> {
  try {
    const response = await http.patch<{
      success: boolean;
      data: User;
    }>('/user/reset-password', user);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Reset Password');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
}
