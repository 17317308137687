import { FC } from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.black.main};

  font-size: 1.5em;
`;

export const ModalTitle: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <StyledSpan>{children}</StyledSpan>;
};
