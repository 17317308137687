import { login, resetUserPassword } from 'api/user/user.api';
import { Form } from 'components/Form/Form';
import { toast } from 'components/Toast/Toast';
import { Button, Card, TextInput } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { setAuthTokenAction, setLoggedUserAction } from 'global-state/actions';
import { selectAuthToken } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { isPath } from 'helpers/navigation.helpers';
import { validateEmail } from 'helpers/validation.helpers';
import { Align } from 'layout';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { UserResetPass } from 'types/user-reset-pass.dto';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';
import * as Yup from 'yup';

const StyledTitleContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 60px;
  z-index: 9999;
  mix-blend-mode: plus-lighter;
  color: #004947 !important;
  margin: 0;
`;

const StyledTitle = styled.h1`
  position: relative;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 54px;
  font-weight: 700;
  text-align: left;
`;

const StyledWhiteTitle = styled.span`
  color: ${({ theme }) => theme.palette.white.main};
`;

const StyledAlign = styled(Align)`
  height: calc(100vh - 9rem);
  width: 100%;
`;

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email').required('Required field'),
  password: Yup.string()
    .min(6, 'Must be at least 6 charactes long')
    .required('Required field'),
});

type FormValues = {
  email: string;
  password: string;
};

export const Login: FC<FcDefaultProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      const { user, token } = await login(
        values.email.toLowerCase(),
        values.password,
      );

      dispatch(setAuthTokenAction(token));
      dispatch(setLoggedUserAction(user));

      const userInfo = user;

      logger.info('User logged in', { userInfo });

      helpers.resetForm();
    } catch (error) {
      genericErrorHandler(error);
      toast.error(
        intl.translate({
          id: 'Invalid credentials',
        }),
        { position: 'top-center' },
      );
    }
  };

  useEffect(() => {
    if (authToken) {
      const path = (location.state as { origin: unknown })?.origin;

      if (isPath(path)) {
        navigate(path);
      } else {
        navigate(AppRoute.Dashboard);
      }
    }
  }, [authToken, location.state, navigate]);

  const handleResetPassword = async (emailToResetPass: string) => {
    if (!emailToResetPass || !validateEmail(emailToResetPass)) {
      toast.error(
        intl.translate({
          id: 'Enter valid email to reset the password',
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    try {
      const user: UserResetPass = {
        email: emailToResetPass,
      };
      await resetUserPassword(user);
      toast.success('An email has been sent to reset the password', {
        position: 'top-center',
      });
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  return (
    <>
      <StyledTitleContainer>
        <StyledTitle>
          {intl.translate(
            {
              id: '<span>Resource</span> Management Portal',
            },
            {
              span: (label) => <StyledWhiteTitle>{label}</StyledWhiteTitle>,
            },
          )}
        </StyledTitle>
      </StyledTitleContainer>
      <StyledAlign v-center h-start>
        <Card.Base>
          <Form
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <>
                <Card.Body>
                  <Align column gap={1} style={{ minWidth: '350px' }}>
                    <TextInput
                      label={intl.translate({
                        id: 'Email',
                      })}
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      value={formik.values.email || ''}
                      error={formik.errors.email || ''}
                      variant="outlined"
                      fullWidth
                    />

                    <TextInput
                      label={intl.translate({
                        id: 'Password',
                      })}
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password || ''}
                      error={formik.errors.password}
                      variant="outlined"
                      fullWidth
                    />

                    <Button type="submit" variant="outlined" fullWidth>
                      {intl.translate({
                        id: 'Login',
                      })}
                    </Button>
                  </Align>
                </Card.Body>

                <Card.Footer></Card.Footer>
                <Button
                  variant="default"
                  color="info"
                  fullWidth
                  onClick={() => handleResetPassword(formik.values.email)}
                >
                  {intl.translate({
                    id: 'Reset Password',
                  })}
                </Button>
              </>
            )}
          </Form>
        </Card.Base>
      </StyledAlign>
    </>
  );
};
