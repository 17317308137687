const cities = [
  'Abbey',
  'Abbotsford',
  'Abercorn',
  'Aberdeen No. 373',
  'Aberdeen',
  'Abernethy No. 186',
  'Abernethy',
  'Abrams Village',
  'Acme',
  'Acton Vale',
  'Addington Highlands',
  'Adelaide-Metcalfe',
  'Adjala-Tosorontio',
  'Admaston/Bromley',
  'Admirals Beach',
  'Adstock',
  'Aguanish',
  'Airdrie',
  'Ajax',
  'Alameda',
  'Albanel',
  'Alberta Beach',
  'Alberton',
  'Albertville',
  'Alert Bay',
  'Alexander',
  'Alfred and Plantagenet',
  'Algoma',
  'Algonquin Highlands',
  'Alida',
  'Alix',
  'Allan',
  'Alleyn-et-Cawood',
  'Alliance',
  'Alma',
  'Alnwick/Haldimand',
  'Alonsa',
  'Altona',
  'Alvena',
  'Amaranth',
  'Amherst',
  'Amherstburg',
  'Amisk',
  'Amos',
  'Amqui',
  'Anchor Point',
  'Andrew',
  'Ange-Gardien',
  'Angliers',
  'Anmore',
  'Annaheim',
  'Annapolis Royal',
  'Antelope Park No. 322',
  'Antigonish',
  'Antler No. 61',
  'Appleton',
  'Aquaforte',
  'Arborfield No. 456',
  'Arborfield',
  'Arborg',
  'Archerwill',
  'Arcola',
  'Argyle No. 1',
  'Argyle',
  'Arlington No. 79',
  'Arm River No. 252',
  'Armagh',
  'Armour',
  'Armstrong',
  "Arnold's Cove",
  'Arnprior',
  'Aroostook',
  'Arran',
  'Arran-Elderslie',
  'Arrowwood',
  'Asbestos',
  'Ascot Corner',
  'Ashcroft',
  'Ashfield-Colborne-Wawanosh',
  'Asphodel-Norwood',
  'Asquith',
  'Assiginack',
  'Assiniboia',
  'Aston-Jonction',
  'Athabasca',
  'Athens',
  'Atholville',
  'Atikokan',
  'Atwater',
  'Auclair',
  'Audet',
  'Augusta',
  'Aurora',
  'Austin',
  'Authier',
  'Authier-Nord',
  'Auvergne No. 76',
  'Avondale',
  'Avonlea',
  "Ayer's Cliff",
  'Aylesbury',
  'Aylmer',
  'Aylsham',
  'Badger',
  'Baffin',

  'Baie Verte',
  'Baie-Atibenne',
  'Baie-Comeau',
  "Baie-d'Hudson",
  "Baie-D'Urfé",
  'Baie-de-la-Bouteille',
  'Baie-des-Chaloupes',
  'Baie-des-Sables',
  'Baie-du-Febvre',
  'Baie-Johan-Beetz',
  'Baie-Obaoca',
  'Baie-Saint-Paul',
  'Baie-Sainte-Catherine',
  'Baie-Trinité',
  'Baildon No. 131',
  'Baine Harbour',
  'Baker-Brook',
  'Balcarres',
  'Baldwin',
  'Balgonie',
  'Balmoral',
  'Bancroft',
  'Banff',
  'Bangor',
  'Barkmere',
  'Barnston-Ouest',
  'Barnwell',
  'Barons',
  'Barraute',
  'Barrhead',
  'Barrie',
  'Barrier Valley No. 397',
  'Bas-Caraquet',
  'Bashaw',
  'Bassano',
  'Bath',
  'Bathurst',
  'Batiscan',
  'Battle River No. 438',
  'Battleford',
  'Bauline',
  'Bawlf',
  'Bay Bulls',
  'Bay de Verde',
  "Bay L'Argent",
  'Bay Roberts',
  'Bayham',
  'Bayne No. 371',
  'Baytona',
  'Beachside',
  'Beaconsfield',
  'Beatty',
  'Beauceville',
  'Beauharnois',
  'Beaulac-Garthby',
  'Beaumont',
  'Beaupré',
  'Beausejour',
  'Beaver River No. 622',
  'Beaverlodge',
  'Beckwith',
  'Bedeque and Area',
  'Bedford',
  'Beechy',
  'Beiseker',
  'Belcarra',
  'Belcourt',
  'Bellburns',
  'Belle Plaine',
  'Belle-Rivière',
  'Belledune',
  'Belleoram',
  'Belleterre',
  'Belleville',
  'Beloeil',
  'Bengough No. 40',
  'Bengough',
  'Benson No. 35',
  'Bentley',
  'Beresford',
  'Berry',
  'Berthier-sur-Mer',
  'Berthierville',
  'Bertrand',
  'Berwick',
  'Berwyn',
  'Bethune',
  'Biencourt',
  'Bienfait',
  'Bifrost-Riverton',
  'Big Arm No. 251',
  'Big Quill No. 308',
  'Big River No. 555',
  'Big River',
  'Big Stick No. 141',
  'Big Valley',
  'Biggar No. 347',
  'Biggar',
  'Billings',
  'Birch Hills No. 460',
  'Birch Hills',
  'Birchy Bay',
  'Bird Cove',
  "Bishop's Cove",
  "Bishop's Falls",
  'Bittern Lake',
  'Bjorkdale No. 426',
  'Bjorkdale',
  'Black Diamond',
  'Black River-Matheson',
  'Blackfalds',
  'Blacks Harbour',
  'Blackville',
  'Bladworth',
  'Blaine Lake No. 434',
  'Blaine Lake',
  'Blainville',
  'Blanc-Sablon',
  'Blandford-Blenheim',
  'Blind River',
  'Blucher No. 343',
  'Blue Sea',
  'Bluewater',
  'Boileau',
  'Bois-des-Filion',
  'Bois-Franc',
  'Boisbriand',
  'Boischatel',
  'Boissevain-Morton',
  'Bolton-Est',
  'Bolton-Ouest',
  'Bon Accord',
  'Bonaventure',
  'Bonavista',
  'Bone Creek No. 108',
  'Bonfield',
  'Bonne-Espérance',
  'Bonnechere Valley',
  'Bonnyville',
  'Bonsecours',
  'Borden',
  'Borden-Carleton',
  'Botha',
  'Botwood',
  'Boucherville',
  'Bouchette',
  'Bouctouche',
  'Bow Island',
  'Bowden',
  'Bowman',
  'Boyle',
  'Bracebridge',
  'Bracken',
  'Brackley',
  'Bradford West Gwillimbury',
  'Bradwell',
  'Brampton',
  'Branch',
  'Brandon',
  'Brant',
  'Brantford',
  "Bratt's Lake No. 129",
  'Breadalbane',
  'Bredenbury',
  'Brenda-Waskada',
  "Brent's Cove",
  'Brethour',
  'Breton',
  'Bridgewater',
  'Briercrest',
  'Brigham',
  'Brighton',
  'Brigus',
  'Bristol',
  'Britannia No. 502',
  'Broadview',
  'Brock No. 64',
  'Brock',
  'Brockton',
  'Brockville',
  'Broderick',
  'Brokenhead',
  'Brokenshell No. 68',
  'Brome',
  'Bromont',
  'Brooke-Alvinston',
  'Brooks',
  'Brossard',
  'Browning No. 34',
  'Brownlee',
  'Brownsburg-Chatham',
  'Bruce Mines',
  'Brudenell',

  'Bruderheim',
  'Bruno',
  "Bryant's Cove",
  'Bryson',
  'Buchanan No. 304',
  'Buchanan',
  'Buchans',
  'Buckland No. 491',
  'Buena Vista',
  'Buffalo No. 409',
  'Bulyea',
  'Burgeo',
  'Burin',
  "Burk's Falls",
  'Burlington',
  'Burnaby',
  'Burns Lake',
  'Burnt Islands',
  'Burpee and Mills',
  'Burstall',
  'Bury',
  'Béarn',
  'Bécancour',
  'Bégin',
  'Béthanie',
  'Cabri',
  'Cache Creek',
  'Cacouna',
  'Cadillac',
  'Calder No. 241',
  'Calder',
  'Caledon',
  'Caledonia No. 99',
  'Calgary',
  'Calixa-Lavallée',
  'Callander',
  'Calmar',
  'Calvin',
  'Cambria No. 6',
  'Cambridge',
  'Cambridge-Narrows',
  'Campbell River',
  "Campbell's Bay",
  'Campbellton',
  'Camrose',
  'Cana No. 214',
  'Canaan No. 225',
  'Canal Flats',
  'Candiac',
  'Caniapiscau',
  'Canmore',
  'Canora',
  'Canterbury',
  'Cantley',
  'Canwood No. 494',
  'Canwood',
  'Cap-Chat',
  'Cap-Pelé',
  'Cap-Saint-Ignace',
  'Cap-Santé',
  'Cape Broyle',
  'Cape St. George',
  'Caplan',
  'Caraquet',
  'Carberry',
  'Carbon',
  'Carbonear',
  'Cardigan',
  'Cardston',
  'Carievale',
  'Carignan',
  'Carleton Place',
  'Carleton-sur-Mer',
  'Carling',
  'Carlow/Mayo',
  'Carlyle',
  'Carmacks',
  'Carman',
  'Carmangay',
  'Carmanville',
  'Carmichael No. 109',
  'Carmichael',
  'Carnduff',
  'Caroline',
  'Caron No. 162',
  'Caronport',
  'Carrot River',
  'Carstairs',
  'Cartier',
  'Cartwright',

  'Cartwright-Roblin',
  'Cascades-Malignes',
  'Cascapédia--Saint-Jules',
  'Casey',
  'Casselman',
  'Castlegar',
  'Castor',
  'Causapscal',
  'Cavan Monaghan',
  'Cayamant',
  'Central Butte',
  'Central Elgin',
  'Central Frontenac',
  'Central Huron',
  'Central Manitoulin',
  'Centre Hastings',
  'Centre Wellington',
  'Centreville',
  'Centreville-Wareham-Trinity',
  'Cereal',
  'Ceylon',
  'Chamberlain',
  'Chambly',
  'Chambord',
  'Champion',
  'Champlain',
  'Champneuf',
  'Chance Cove',
  'Chandler',
  'Change Islands',
  'Channel-Port aux Basques',
  'Chapais',
  'Chapel Arm',
  'Chapleau',
  'Chaplin No. 164',
  'Chaplin',
  'Chapple',
  'Charette',
  'Charlemagne',
  'Charlo',
  'Charlottetown (Labrador)',
  'Charlottetown',
  'Charlton and Dack',
  'Chartierville',
  'Chase',
  'Chatham-Kent',
  'Chatsworth',
  'Chauvin',
  'Chazel',
  'Chelsea',
  'Chertsey',
  'Chester No. 125',
  'Chesterfield No. 261',
  'Chestermere',
  'Chesterville',
  'Chibougamau',
  'Chilliwack',
  'Chipman',
  'Chisholm',
  'Choiceland',
  'Christopher Lake',
  'Churchbridge No. 211',
  'Churchbridge',
  'Churchill',
  'Chute-aux-Outardes',
  'Chute-Saint-Philippe',
  'Château-Richer',
  'Châteauguay',
  'Chénéville',
  'Clair',
  'Clanwilliam-Erickson',
  'Clarence-Rockland',
  'Clarendon',
  'Clarenville',
  'Claresholm',
  'Clarington',
  "Clark's Harbour",
  "Clarke's Beach",
  'Clavet',
  'Clayton No. 333',
  'Clearview',
  'Clermont',
  'Clerval',
  'Climax',
  'Clinton',
  'Clinworth No. 230',
  'Clive',
  'Clyde River',
  'Clyde',
  "Coachman's Cove",
  'Coaldale',
  'Coalfields No. 4',
  'Coalhurst',
  'Coaticook',
  'Cobalt',
  'Cobourg',
  'Cochrane',
  'Cockburn Island',
  'Coderre',
  'Codette',
  'Cold Lake',
  'Coldwell',
  'Coleman',
  'Coleville',
  'Colinet',
  'Colliers',
  'Collines-du-Basque',
  'Collingwood',
  'Colombier',
  'Colonsay No. 342',
  'Colonsay',
  'Colwood',
  'Come By Chance',
  'Comfort Cove-Newstead',
  'Comox',
  'Compton',
  'Conception Bay South',
  'Conception Harbour',
  'Conche',
  'Conmee',
  'Connaught No. 457',
  'Conquest',
  'Consort',
  'Consul',
  'Contrecoeur',
  "Cook's Harbour",
  'Cookshire-Eaton',
  'Coquitlam',
  'Cormack',
  'Corman Park No. 344',
  'Corner Brook',
  'Cornwall',
  'Cornwallis',
  'Coronach',
  'Coronation',
  'Cote No. 271',
  'Coteau No. 255',
  'Coteau-du-Lac',
  'Cottlesville',
  'Coulee No. 136',
  'Coulée-des-Adolphe',
  'Courcelles',
  'Courtenay',
  'Coutts',
  'Cow Head',
  'Cowansville',
  'Cowley',
  "Cox's Cove",
  'Crabtree',
  'Craik No. 222',
  'Craik',
  'Cramahe',
  'Cranbrook',
  'Crapaud',
  'Craven',
  'Creelman',
  'Creighton',
  'Cremona',
  'Creston',
  'Crossfield',
  'Crow Head',
  'Cudworth',
  'Cumberland',
  'Cupar No. 218',
  'Cupar',
  'Cupids',
  'Cut Knife No. 439',
  'Cut Knife',
  'Cymri No. 36',
  'Czar',
  'Côte-Nord-du-Golfe-du-Saint-Laurent',
  'Côte-Saint-Luc',
  'Dafoe',
  'Dalhousie',
  'Dalmeny',
  "Daniel's Harbour",
  'Danville',
  'Dauphin',
  'Daveluyville',
  'Davidson',
  'Dawn-Euphemia',
  'Dawson Creek',
  'Dawson',
  'Daysland',
  'De Salaberry',
  'Debden',
  'Deep River',
  'Deer Forks No. 232',
  'Deer Lake',
  'Delburne',
  'Delia',
  'Delisle',
  'Deloraine-Winchester',
  'Delson',
  'Denholm',
  'Denzil',
  'Desbiens',
  'Deschaillons-sur-Saint-Laurent',
  'Deschambault-Grondines',
  'Deseronto',
  'Deux-Montagnes',
  'Devon',
  'Dewberry',
  'Didsbury',
  'Dieppe',
  'Digby',
  'Dilke',
  'Dinsmore',
  'Disley',
  'Disraeli',
  'Dixville',
  'Doaktown',
  'Dodsland',
  'Dolbeau-Mistassini',
  'Dollard-Des Ormeaux',
  'Donalda',
  'Donnacona',
  'Donnelly',
  'Dorchester',
  'Dorintosh',
  'Dorion',
  'Dorval',
  'Dosquet',
  'Douglas No. 436',
  'Douro-Dummer',
  'Dover',
  'Drake',
  'Drayton Valley',
  'Drinkwater',
  'Drumheller',
  'Drummond',
  'Drummond/North Elmsley',
  'Drummondville',
  'Dryden',
  'Dubreuilville',
  'Dubuc',
  'Duchess',
  'Duck Lake No. 463',
  'Duck Lake',
  'Dudswell',
  'Duff',
  'Dufferin No. 190',
  'Dufferin',
  'Duhamel',
  'Duhamel-Ouest',
  'Duncan',
  'Dundurn No. 314',
  'Dundurn',
  'Dunham',
  'Dunnottar',
  'Duntara',
  'Duparquet',
  'Dupuy',
  'Durham-Sud',
  'Dutton/Dunwich',
  'Duval',
  'Dysart et al',
  'Dysart',
  'Dégelis',
  'Déléage',
  'Dépôt-Échouani',
  'Eagle Creek No. 376',
  'Ear Falls',
  'Earl Grey',
  'East Angus',
  'East Broughton',
  'East Farnham',
  'East Ferris',
  'East Garafraxa',
  'East Gwillimbury',
  'East Hawkesbury',
  'East Hereford',
  'East St. Paul',
  'East Zorra-Tavistock',
  'Eastend',
  'Eastman',
  'Eastport',
  'Eatonia',
  'Ebenezer',
  'Eckville',
  'Edam',
  'Edberg',
  'Edenwold No. 158',
  'Edenwold',
  'Edgerton',
  'Edmonton',
  'Edmundston',
  'Edson',
  'Edwardsburgh/Cardinal',
  'Eel River Crossing',
  'Eeyou Istchee Baie-James',
  'Egan-Sud',
  'Elbow',
  'Elcapo No. 154',
  'Eldon No. 471',
  'Elfros No. 307',
  'Elfros',
  'Elgin',
  'Elizabethtown-Kitley',
  'Elk Point',
  'Ellice-Archie',
  'Elliot Lake',
  'Elliston',
  'Elmsthorpe No. 100',
  'Elnora',
  'Elrose',
  'Elton',
  'Embree',
  'Emerald No. 277',
  'Emerson-Franklin',
  'Emo',
  'Empress',
  'Endeavour',
  'Enderby',
  'Enfield No. 194',
  'Englee',
  'Englefeld',
  'Englehart',
  'English Harbour East',
  'Enniskillen No. 3',
  'Enniskillen',
  'Enterprise No. 142',
  'Entrelacs',
  'Erin',
  'Ernfold',
  'Escuminac',
  'Espanola',
  'Esprit-Saint',
  'Essa',
  'Essex',
  'Esterhazy',
  'Estevan No. 5',
  'Estevan',
  'Eston',
  'Estérel',
  'Ethelbert',
  'Evanturel',
  'Excel No. 71',
  'Excelsior No. 166',
  'Eye Hill No. 382',
  'Eyebrow No. 193',
  'Eyebrow',
  'Fairlight',
  'Fairview',
  'Falher',
  'Faraday',
  'Farnham',
  'Faro',
  'Fassett',
  'Fauquier-Strickland',
  'Fenwood',
  'Ferintosh',
  'Ferland-et-Boilleau',
  'Ferme-Neuve',
  'Fermeuse',
  'Fermont',
  'Fernie',
  'Ferryland',
  'Fertile Belt No. 183',
  'Fertile Valley No. 285',
  'Fillmore No. 96',
  'Fillmore',
  'Findlater',
  'Fish Creek No. 402',
  'Fisher',
  'Flatrock',
  'Flaxcombe',
  'Fleming',
  "Flett's Springs No. 429",
  'Fleur de Lys',
  'Flin Flon (Part)',
  'Florenceville-Bristol',
  "Flower's Cove",
  'Foam Lake No. 276',
  'Foam Lake',
  'Fogo Island',
  'Foremost',
  'Forestburg',
  'Forestville',
  'Forget',
  'Fort Erie',
  'Fort Frances',
  'Fort Macleod',
  "Fort Qu'Appelle",
  'Fort Saskatchewan',
  'Fort Simpson',
  'Fort Smith',
  'Fort St. John',
  'Fort-Coulonge',
  'Forteau',
  'Fortierville',
  'Fortune',
  'Fossambault-sur-le-Lac',
  'Fosston',
  'Fox Cove-Mortier',
  'Fox Creek',
  'Fox Harbour',
  'Fox Valley No. 171',
  'Fox Valley',
  'Frampton',
  'Francis No. 127',
  'Francis',
  'Franklin',
  'Franquelin',
  'Fraser Lake',
  'Fredericton Junction',
  'Fredericton',
  'Frelighsburg',
  'French River',
  'Frenchman Butte No. 501',
  "Frenchman's Cove",
  'Frobisher',
  'Front of Yonge',
  'Frontenac Islands',
  'Frontenac',
  'Frontier No. 19',
  'Frontier',
  'Fruitvale',
  'Fugèreville',
  'Gadsby',
  'Gagetown',
  'Gainsborough',
  'Gallants',
  'Gallichan',
  'Gambo',
  'Gananoque',
  'Gander',
  'Garden River No. 490',
  'Garnish',
  'Garry No. 245',
  'Gaskiers-Point La Haye',
  'Gaspé',
  'Gatineau',
  'Gaultois',
  'Gauthier',
  'Georgetown',
  'Georgian Bay',
  'Georgian Bluffs',
  'Georgina',
  'Gerald',
  'Gibbons',
  'Gibsons',
  'Gilbert Plains',
  'Gillam',
  'Gillams',
  'Gillies',
  'Gimli',
  'Girardville',
  'Girouxville',
  'Glaslyn',
  'Glen Bain No. 105',
  'Glen Ewen',
  'Glen McPherson No. 46',
  'Glenavon',
  'Glenboro-South Cypress',
  'Glenburnie-Birchy Head-Shoal Brook',
  'Glendon',
  'Glenella-Lansdowne',
  'Glenside No. 377',
  'Glenside',
  'Glenwood',
  'Glovertown',
  'Godbout',
  'Goderich',
  'Gold River',
  'Golden Prairie',
  'Golden West No. 95',
  'Golden',
  'Good Lake No. 274',
  'Goodeve',
  'Goodsoil',
  'Goodwater',
  'Goose Cove East',
  'Gordon/Barrie Island',
  'Gore Bay',
  'Govan',
  'Gracefield',
  'Grahamdale',
  'Granby',
  'Grand Bank',
  'Grand Bay-Westfield',
  'Grand Coulee',
  'Grand Falls',
  'Grand Falls-Windsor',
  'Grand Forks',
  'Grand le Pierre',
  'Grand Manan',
  'Grand Rapids',
  'Grand Valley',
  'Grand-Métis',
  'Grand-Remous',
  'Grand-Saint-Esprit',
  'Grande Cache',
  'Grande Prairie',
  'Grande-Anse',
  'Grande-Rivière',
  'Grande-Vallée',
  'Grandes-Piles',
  'Grandview No. 349',
  'Grandview',
  'Granisle',
  'Grant No. 372',
  'Granum',
  'Grass Lake No. 381',
  'Grassland',
  'Grassy Creek No. 78',
  'Gravelbourg No. 104',
  'Gravelbourg',
  'Gravenhurst',
  'Grayson No. 184',
  'Grayson',
  'Great Bend No. 405',
  'Greater Madawaska',
  'Greater Napanee',
  'Greater Sudbury',
  'Greenspond',
  'Greenstone',
  'Greenwood',
  'Grenfell',
  'Grenville',
  'Grenville-sur-la-Rouge',
  'Grey Highlands',
  'Grey',
  'Griffin No. 66',
  'Grimsby',
  'Grimshaw',
  'Gros-Mécatina',
  'Grosse-Île',
  'Grosses-Roches',
  'Guelph',
  'Guelph/Eramosa',
  'Gull Lake No. 139',
  'Gull Lake',
  'Hafford',
  'Hague',
  'Haines Junction',
  'Halbrite',
  'Haldimand County',
  'Halkirk',
  'Halton Hills',
  'Ham-Sud',
  'Hamilton',
  'Hamiota',
  'Hampden',
  'Hampstead',
  'Hampton',
  'Hanley',
  'Hanna',
  'Hanover',
  "Hant's Harbour",
  'Happy Adventure',
  'Happy Valley No. 10',
  'Happy Valley-Goose Bay',
  'Happyland No. 231',
  'Harbour Breton',
  'Harbour Grace',
  "Harbour Main-Chapel's Cove-Lakeview",
  'Hardisty',
  'Hare Bay',
  'Harley',
  'Harris No. 316',
  'Harris',
  'Harrison Hot Springs',
  'Harrison Park',
  'Hart Butte No. 11',
  'Hartland',
  'Harvey',
  'Hastings Highlands',
  'Hatley',
  'Havelock-Belmont-Methuen',
  'Havre-Saint-Pierre',
  'Hawarden',
  "Hawke's Bay",
  'Hawkesbury',
  'Hay Lakes',
  'Hay River',
  'Hazel Dell No. 335',
  'Hazelton',
  'Hazelwood No. 94',
  'Hazenmore',
  'Hazlet',
  'Head',

  'Headingley',
  'Hearst',
  "Heart's Content",
  "Heart's Delight-Islington",
  "Heart's Desire",
  "Heart's Hill No. 352",
  'Heisler',
  'Hemmingford',
  'Henryville',
  'Hepburn',
  'Herbert',
  'Hermitage-Sandyville',
  'Heward',
  'High Level',
  'High Prairie',
  'High River',
  'Highlands East',
  'Hill Spring',
  'Hilliard',
  'Hillsborough No. 132',
  'Hillsborough',
  'Hillsburg-Roblin-Shell River',
  'Hillsdale No. 440',
  'Hilton Beach',
  'Hilton',
  'Hinchinbrooke',
  'Hines Creek',
  'Hinton',
  'Hodgeville',
  'Holden',
  'Holdfast',
  'Holyrood',
  'Honfleur',
  'Hoodoo No. 401',
  'Hope Town',
  'Hopedale',
  'Hornepayne',
  'Horton',
  'Howick',
  'Howley',
  'Hubbard',
  'Huberdeau',
  'Hudson Bay No. 394',
  'Hudson Bay',
  'Hudson',
  'Hughenden',
  'Hughes Brook',
  'Humber Arm South',
  'Humboldt No. 370',
  'Humboldt',
  'Hunter River',
  'Huntingdon',
  'Huntsville',
  'Huron East',
  'Huron No. 223',
  'Huron Shores',
  'Huron-Kinloss',
  'Hussar',
  'Hyas',
  'Hythe',
  'Hébertville',
  'Hébertville-Station',
  'Ignace',
  'Imperial',
  'Indian Bay',
  'Indian Head No. 156',
  'Indian Head',
  'Ingersoll',
  'Innisfail',
  'Innisfil',
  'Innisfree',
  'Insinger No. 275',
  'Inuvik',
  'Invergordon No. 430',
  'Invermay No. 305',
  'Invermay',
  'Inverness',
  'Iqaluit',
  'Irishtown-Summerside',
  'Irlande',
  'Irma',
  'Iroquois Falls',
  'Irricana',
  'Isle aux Morts',
  'Ituna Bon Accord No. 246',
  'Ituna',
  'Ivry-sur-le-Lac',
  "Jackson's Arm",
  'James',
  'Jansen',
  'Jocelyn',
  'Johnson',
  'Joliette',
  'Joly',
  'Kamloops',
  'Kamouraska',
  'Kamsack',
  'Kapuskasing',
  'Kaslo',
  'Kawartha Lakes',
  'Kazabazua',
  'Kearney',
  'Keeler',
  'Keels',
  'Keewatin',

  'Kelliher',
  'Kellross No. 247',
  'Kelowna',
  'Kelsey',
  'Kelvington No. 366',
  'Kelvington',
  'Kenaston',
  'Kendal',
  'Kennedy',
  'Kenora',

  'Kenosee Lake',
  'Kensington',
  'Kentville',
  'Keremeos',
  'Kerns',
  'Kerrobert',
  'Key West No. 70',
  'Keys No. 303',
  'Kiamika',
  'Killaloe',

  'Killaly',
  'Killam',
  'Killarney - Turtle Mountain',
  'Killarney',
  'Kimberley',
  'Kincaid',
  'Kincardine',
  'Kindersley No. 290',
  'Kindersley',
  'King George No. 256',
  'King',
  "King's Cove",
  "King's Point",
  'Kingsbury',
  'Kingsey Falls',
  'Kingsley No. 124',
  'Kingston',
  'Kingsville',
  'Kinistino No. 459',
  'Kinistino',
  'Kinkora',
  'Kinley',
  "Kinnear's Mills",
  'Kipawa',
  'Kipling',
  'Kippens',
  'Kirkland Lake',
  'Kirkland',
  'Kisbey',
  'Kitchener',
  'Kitikmeot',

  'Kitscoty',
  'Krydor',
  'Kyle',
  "L'Ancienne-Lorette",
  "L'Ange-Gardien",
  "L'Anse au Loup",
  "L'Anse-au-Clair",
  "L'Anse-Saint-Jean",
  "L'Ascension",
  "L'Ascension-de-Patapédia",
  "L'Assomption",
  "L'Avenir",
  "L'Isle-aux-Allumettes",
  "L'Isle-aux-Coudres",
  "L'Isle-Verte",
  "L'Islet",
  "L'Épiphanie",
  "L'Île-Cadieux",
  "L'Île-d'Anticosti",
  "L'Île-Dorval",
  "L'Île-du-Grand-Calumet",
  "L'Île-Perrot",
  'La Bostonnais',
  'La Broquerie',
  'La Conception',
  'La Corne',
  'La Guadeloupe',
  'La Macaza',
  'La Malbaie',
  'La Martre',
  'La Minerve',
  'La Morandière',
  'La Motte',
  'La Patrie',
  'La Pocatière',
  'La Prairie',
  'La Présentation',
  'La Pêche',
  'La Reine',
  'La Ronge',
  'La Sarre',
  'La Tuque',
  'La Vallee',
  "La Visitation-de-l'Île-Dupas",
  'La Visitation-de-Yamaska',
  'Labelle',
  'Labrador City',
  'Labrecque',
  'Lac Baker',
  'Lac du Bonnet',
  'Lac Pelletier No. 107',
  'Lac-Achouakan',
  'Lac-Akonapwehikan',
  'Lac-Alfred',
  'Lac-Ashuapmushuan',
  'Lac-au-Brochet',
  'Lac-au-Saumon',
  'Lac-Bazinet',
  'Lac-Beauport',
  'Lac-Blanc',
  'Lac-Boisbouscache',
  'Lac-Bouchette',
  'Lac-Boulé',
  'Lac-Brome',
  'Lac-Cabasta',
  'Lac-Casault',
  'Lac-Chicobi',
  'Lac-Croche',
  'Lac-De La Bidière',
  'Lac-de-la-Maison-de-Pierre',
  'Lac-de-la-Pomme',
  'Lac-Delage',
  'Lac-des-Aigles',
  'Lac-des-Dix-Milles',
  'Lac-des-Eaux-Mortes',
  'Lac-des-Plages',
  'Lac-des-Seize-Îles',
  'Lac-des-Écorces',
  'Lac-Despinassy',
  'Lac-Devenyns',
  'Lac-Douaire',
  'Lac-Drolet',
  'Lac-du-Cerf',
  'Lac-du-Taureau',
  'Lac-Duparquet',
  'Lac-Ernest',
  'Lac-Etchemin',
  'Lac-Frontière',
  'Lac-Granet',
  'Lac-Huron',
  'Lac-Jacques-Cartier',
  'Lac-Juillet',
  'Lac-Jérôme',
  'Lac-Lapeyrère',
  'Lac-Legendre',
  'Lac-Lenôtre',
  'Lac-Marguerite',
  'Lac-Masketsi',
  'Lac-Matapédia',
  'Lac-Matawin',
  'Lac-Metei',
  'Lac-Minaki',
  'Lac-Ministuk',
  'Lac-Moncouche',
  'Lac-Moselle',
  'Lac-Mégantic',
  'Lac-Nilgaut',
  'Lac-Normand',
  'Lac-Oscar',
  'Lac-Pikauba',
  'Lac-Poulin',
  'Lac-Pythonga',
  'Lac-Saguay',
  'Lac-Saint-Joseph',
  'Lac-Saint-Paul',
  'Lac-Sainte-Marie',
  'Lac-Santé',
  'Lac-Sergent',
  'Lac-Simon',
  'Lac-Supérieur',
  'Lac-Tremblant-Nord',
  'Lac-Vacher',
  'Lac-Wagwabika',
  'Lac-Walker',
  'Lac-à-la-Croix',
  'Lac-Édouard',
  'Lacadena No. 228',
  'Lachute',
  'Lacolle',
  'Lacombe',
  'Ladysmith',
  'Lafleche',
  'Laforce',
  'Laird No. 404',
  'Laird',
  'Lajord No. 128',
  'Lake Alma No. 8',
  'Lake Alma',
  'Lake Cowichan',
  'Lake Johnston No. 102',
  'Lake Lenore No. 399',
  'Lake Lenore',
  'Lake of Bays',
  'Lake of the Rivers No. 72',
  'Lake of the Woods',
  'Lakeland No. 521',
  'Lakeshore',
  'Lakeside No. 338',
  'Lakeview No. 337',
  'Lalemant',
  'Lamaline',
  'Lamarche',
  'Lambton Shores',
  'Lambton',
  'Lamont',
  'Lampman',
  'Lamèque',
  'Lanark Highlands',
  'Lancer',
  'Landis',
  'Lang',
  'Langenburg No. 181',
  'Langenburg',
  'Langford',
  'Langham',
  'Langley',
  'Laniel',
  'Lanigan',
  'Lanoraie',
  'Lantier',
  'Larder Lake',
  'Lark Harbour',
  'Larouche',
  'LaSalle',
  'LaScie',
  'Lashburn',
  'Last Mountain Valley No. 250',
  'Latchford',
  'Laurentian Hills',
  'Laurentian Valley',
  'Laurier No. 38',
  'Laurier-Station',
  'Laurierville',
  'Laval',
  'Lavaltrie',
  'Laverlochère',
  'Lawn',
  'Lawrenceville',
  'Lawtonia No. 135',
  'Le Goulet',
  'Leader',
  'Leading Tickles',
  'Leaf Rapids',
  'Leamington',
  'Leask No. 464',
  'Leask',
  'Lebel-sur-Quévillon',
  'Lebret',
  'Leclercville',
  'Leduc',
  'Leeds and the Thousand Islands',
  'Lefebvre',
  'Legal',
  'Lejeune',
  'Lemberg',
  'Lemieux',
  'Leoville',
  'Leross',
  'Leroy No. 339',
  'Leroy',
  'Les Bergeronnes',
  'Les Coteaux',
  'Les Cèdres',
  'Les Escoumins',
  'Les Hauteurs',
  'Les Lacs-du-Témiscamingue',
  'Les Méchins',
  'Les Éboulements',
  'Les Îles-de-la-Madeleine',
  'Lestock',
  'Lethbridge',
  "Lewin's Cove",
  'Lewisporte',
  'Liberty',
  'Limerick',
  'Lincoln',
  'Linden',
  'Linkletter',
  'Lintlaw',
  'Linton',
  'Lions Bay',
  'Lipton No. 217',
  'Lipton',
  'Litchfield',
  'Little Bay East',
  'Little Bay Islands',
  'Little Bay',
  'Little Burnt Bay',
  'Livingston No. 331',
  'Lloydminster (Part)',
  'Lockeport',
  'Logy Bay-Middle Cove-Outer Cove',
  'Lomond No. 37',
  'Lomond',
  'London',
  'Lone Tree No. 18',
  'Long Harbour-Mount Arlington Heights',
  'Longlaketon No. 219',
  'Longue-Pointe-de-Mingan',
  'Longue-Rive',
  'Longueuil',
  'Longview',
  'Loon Lake No. 561',
  'Loon Lake',
  "Lord's Cove",
  'Loreburn No. 254',
  'Loreburn',
  'Lorne',
  'Lorraine',
  'Lorrainville',
  'Lost River No. 313',
  'Lotbinière',
  'Lougheed',
  'Louise',
  'Louiseville',
  'Lourdes',
  'Love',
  'Loyalist',
  'Lucan Biddulph',
  'Lucky Lake',
  'Lumby',
  'Lumsden No. 189',
  'Lumsden',
  'Lunenburg',
  'Luseland',
  'Lushes Bight-Beaumont-Beaumont North',
  'Lynn Lake',
  'Lyster',
  'Lytton',
  'Léry',
  'Lévis',
  'Macamic',
  'Macdonald',

  'Machar',
  'Machin',
  'Macklin',
  'MacNutt',
  'Macoun',
  'Macpherson-Grizzly Valley',
  'Macrorie',
  'Madawaska Valley',
  'Maddington Falls',
  'Madoc',
  'Magnetawan',
  'Magog',
  'Magrath',
  'Mahone Bay',
  'Maidstone',
  'Main Brook',
  'Maisonnette',
  'Major',
  'Makkovik',
  'Makwa',
  'Malahide',
  'Malartic',
  'Mandeville',
  'Manitou Lake No. 442',
  'Manitoulin',

  'Manitouwadge',
  'Maniwaki',
  'Mankota No. 45',
  'Mankota',
  'Manning',
  'Mannville',
  'Manor',
  'Manseau',
  'Mansfield-et-Pontefract',
  'Maple Bush No. 224',
  'Maple Creek No. 111',
  'Maple Creek',
  'Maple Ridge',
  'Mapleton',
  'Marathon',
  'Marcelin',
  'Marengo',
  'Margo',
  'Maria',
  'Maricourt',
  'Marieville',
  'Mariposa No. 350',
  'Markham',
  'Markinch',
  'Markstay-Warren',
  'Marmora and Lake',
  'Marquis No. 191',
  'Marquis',
  'Marriott No. 317',
  'Marsden',
  'Marsh Lake',
  'Marshall',
  'Marsoui',
  'Martensville',
  'Martin No. 122',
  'Martinville',
  'Marwayne',
  "Mary's Harbour",
  'Maryfield No. 91',
  'Maryfield',
  'Marystown',
  'Mascouche',
  'Maskinongé',
  'Masset',
  'Massey Drive',
  'Massueville',
  'Matachewan',
  'Matagami',
  'Matane',
  'Matapédia',
  'Matchi-Manitou',
  'Mattawa',
  'Mattawan',
  'Mattice-Val Côté',
  'Mayerthorpe',
  'Mayfield No. 406',
  'Maymont',
  'Mayo',
  'McAdam',
  'McBride',
  'McCraney No. 282',
  'McCreary',
  'McDougall',
  'McGarry',
  'McIvers',
  'McKellar',
  'McKillop No. 220',
  'McLean',
  'McLennan',
  'McLeod No. 185',
  'McMasterville',
  'McMurrich/Monteith',
  'McNab/Braeside',
  'McTaggart',
  'Meacham',
  'Meadow Lake No. 588',
  'Meadow Lake',
  'Meadowbank',
  'Meadows',
  'Meaford',
  'Meath Park',
  'Medicine Hat',
  'Medstead No. 497',
  'Medstead',
  'Meductic',
  'Meeting Lake No. 466',
  'Melancthon',
  'Melfort',
  'Melita',
  'Melville',
  'Memramcook',
  'Mendham',
  'Meota No. 468',
  'Meota',
  'Mercier',
  'Merrickville-Wolford',
  'Merritt',
  'Mervin No. 499',
  'Mervin',
  'Messines',
  'Midale',
  'Middle Arm',
  'Middle Lake',
  'Middlesex Centre',
  'Middleton',
  'Midland',
  'Midway',
  'Milan',
  'Milden No. 286',
  'Milden',
  'Miles Cove',
  'Milestone',
  'Milk River',
  'Mille-Isles',
  'Millertown',
  'Millet',
  "Milltown-Head of Bay d'Espoir",
  'Millville',
  'Milo',
  'Milton No. 292',
  'Milton',
  'Miltonvale Park',
  'Miminegash',
  'Minden Hills',
  "Ming's Bight",
  'Minitonas-Bowsman',
  'Minnedosa',
  'Minto',
  'Minto-Odanah',
  'Minton',
  'Mirabel',
  'Miramichi',
  'Miry Creek No. 229',
  'Miscouche',
  'Mississauga',
  'Mississippi Mills',
  'Mistatim',
  'Moffet',
  'Moncton',
  'Monet No. 257',
  'Mono',
  'Mont-Albert',
  'Mont-Alexandre',
  'Mont-Apica',
  'Mont-Carmel',
  'Mont-Joli',
  'Mont-Laurier',
  'Mont-Royal',
  'Mont-Saint-Grégoire',
  'Mont-Saint-Hilaire',
  'Mont-Saint-Michel',
  'Mont-Saint-Pierre',
  'Mont-Tremblant',
  'Mont-Valin',
  'Mont-Élie',
  'Montague',
  'Montcalm',
  'Montcerf-Lytton',
  'Montebello',
  'Montmagny',
  'Montmartre No. 126',
  'Montmartre',
  'Montpellier',
  'Montrose No. 315',
  'Montrose',
  'Montréal',
  'Montréal-Est',
  'Montréal-Ouest',
  'Moonbeam',
  'Moose Creek No. 33',
  'Moose Jaw No. 161',
  'Moose Jaw',
  'Moose Mountain No. 63',
  'Moose Range No. 486',
  'Moosomin No. 121',
  'Moosomin',
  'Moosonee',
  'Morden',
  'Morell',
  'Morin-Heights',
  'Morinville',
  'Morley',
  'Morrin',
  'Morris No. 312',
  'Morris',
  'Morris-Turnberry',
  'Morrisville',
  'Morse No. 165',
  'Morse',
  'Mortlach',
  'Mossbank',
  'Mossey River',
  "Mount Carmel-Mitchells Brook-St. Catherine's",
  'Mount Hope No. 279',
  'Mount Moriah',
  'Mount Pearl',
  'Mount Pleasant No. 2',
  'Mount Stewart',
  'Mountain (North)',
  'Mountain (South)',
  'Mountain View No. 318',
  'Muenster',
  'Mulgrave',
  'Mulgrave-et-Derry',
  'Mulmur',
  'Mundare',
  'Munson',
  'Murdochville',
  'Murray Harbour',
  'Murray River',
  'Musgrave Harbour',
  'Musgravetown',
  'Muskoka Lakes',
  'Myrnam',
  'Métabetchouan--Lac-à-la-Croix',
  'Métis-sur-Mer',
  'Nackawic',
  'Naicam',
  'Nain',
  'Nairn and Hyman',
  'Nakusp',
  'Nampa',
  'Namur',
  'Nanaimo',
  'Nantes',
  'Nanton',
  'Napierville',
  'Neebing',
  'Neepawa',
  'Neguac',
  'Neilburg',
  'Nelson',
  'Netherhill',
  'Neudorf',
  'Neuville',
  'Neville',
  'New Carlisle',
  'New Denver',
  'New Glasgow',
  'New Maryland',
  'New Perlican',
  'New Richmond',
  'New Tecumseth',
  'New Westminster',
  'New-Wes-Valley',
  'Newbury',
  'Newcombe No. 260',
  'Newmarket',
  'Newport',
  'Niagara Falls',
  'Niagara-on-the-Lake',
  'Nicolet',
  'Nigadoo',
  'Nipawin No. 487',
  'Nipawin',
  'Nipigon',
  'Nipissing',
  'Nippers Harbour',
  'Niverville',
  'Nobleford',
  'Nokomis',
  'Nominingue',
  'Norfolk County',
  'Norfolk-Treherne',
  'Norman Wells',
  "Norman's Cove-Long Cove",
  'Normandin',
  'Normétal',
  'Norquay',
  'Norris Arm',
  'Norris Point',
  'North Algona Wilberforce',
  'North Battleford No. 437',
  'North Battleford',
  'North Bay',
  'North Cypress-Langford',
  'North Dumfries',
  'North Dundas',
  'North Frontenac',
  'North Glengarry',
  'North Grenville',
  'North Hatley',
  'North Huron',
  'North Kawartha',
  'North Middlesex',
  'North Norfolk',
  'North Perth',
  'North Portal',
  "North Qu'Appelle No. 187",
  'North River',
  'North Rustico',
  'North Stormont',
  'North Vancouver',
  'North West River',
  'Northeastern Manitoulin and the Islands',
  'Northern Arm',
  'Northern Bruce Peninsula',
  'Norton No. 69',
  'Norton',
  'Norwich',
  'Notre-Dame-de-Bonsecours',
  'Notre-Dame-de-Ham',
  "Notre-Dame-de-l'Île-Perrot",
  'Notre-Dame-de-la-Merci',
  'Notre-Dame-de-la-Paix',
  'Notre-Dame-de-la-Salette',
  'Notre-Dame-de-Lorette',
  'Notre-Dame-de-Lourdes',
  'Notre-Dame-de-Montauban',
  'Notre-Dame-de-Pontmain',
  'Notre-Dame-de-Stanbridge',
  'Notre-Dame-des-Bois',
  'Notre-Dame-des-Monts',
  'Notre-Dame-des-Neiges',
  'Notre-Dame-des-Prairies',
  'Notre-Dame-du-Bon-Conseil',
  'Notre-Dame-du-Laus',
  'Notre-Dame-du-Nord',
  'Notre-Dame-du-Portage',
  'Notre-Dame-du-Rosaire',
  'Nouvelle',
  'Noyan',
  "O'Connor",
  "O'Leary",
  'Oakdale No. 320',
  'Oakland-Wawanesa',
  'Oakview',
  'Oakville',
  'Odessa',
  'Ogden',
  'Ogema',
  'Oil Springs',
  'Oka',
  'Okotoks',
  'Old Perlican',
  'Old Post No. 43',
  'Olds',
  'Oliver Paipoonge',
  'Oliver',
  'Onoway',
  'Opasatika',
  'Orangeville',
  'Orillia',
  'Orkney No. 244',
  'Ormstown',
  'Oro-Medonte',
  'Oromocto',
  'Osage',
  'Oshawa',
  'Osler',
  'Osoyoos',
  'Otonabee-South Monaghan',
  'Ottawa',
  'Otter Lake',
  'Otterburn Park',
  'Outlook',
  'Owen Sound',
  'Oxbow',
  'Oxford',
  'Oyen',
  'Pacquet',
  'Paddockwood No. 520',
  'Paddockwood',
  'Padoue',
  'Palmarolle',
  'Pangman',
  'Papineau-Cameron',
  'Papineauville',
  'Paquetville',
  'Paradise Hill',
  'Paradise Valley',
  'Paradise',
  'Parkdale No. 498',
  'Parkers Cove',
  'Parkside',
  'Parksville',
  'Parrsboro',
  'Parry Sound',
  "Parson's Pond",
  'Pasadena',
  'Paspébiac',
  'Passes-Dangereuses',
  'Paynton No. 470',
  'Paynton',
  'Peace River',
  'Pelee',
  'Pelham',
  'Pelly',
  'Pemberton',
  'Pembina',
  'Pembroke',
  'Penetanguishene',
  'Penhold',
  'Pennant',
  'Pense No. 160',
  'Pense',
  'Penticton',
  'Percé',
  'Perdue No. 346',
  'Perdue',
  'Perry',
  'Perth East',
  'Perth South',
  'Perth',
  'Perth-Andover',
  'Petawawa',
  'Peterborough',
  'Peterview',
  'Petit-Lac-Sainte-Anne',
  'Petit-Mécatina',
  'Petit-Rocher',
  'Petit-Saguenay',
  'Petitcodiac',
  'Petite-Rivière-Saint-François',
  'Petite-Vallée',
  'Petrolia',
  'Petty Harbour-Maddox Cove',
  'Piapot No. 110',
  'Picard',
  'Pickering',
  'Pickle Lake',
  'Pictou',
  'Picture Butte',
  'Piedmont',
  'Pierceland',
  'Pierreville',
  'Pike River',
  'Pilger',
  "Pilley's Island",
  'Pilot Butte',
  'Pincher Creek',
  'Pincourt',
  'Piney',
  'Pinto Creek No. 75',
  'Pinware',
  'Piopolis',
  'Pipestone',
  'Pitt Meadows',
  'Pittville No. 169',
  'Placentia',
  'Plaisance',
  'Plaster Rock',
  'Pleasant Valley No. 288',
  'Pleasantdale No. 398',
  'Pleasantdale',
  'Plenty',
  'Plessisville',
  'Plummer Additional',
  'Plunkett',
  'Plympton-Wyoming',
  'Pohénégamook',
  'Point au Gaul',
  'Point Edward',
  'Point Lance',
  'Point Leamington',
  'Point May',
  'Point of Bay',
  'Pointe-aux-Outardes',
  'Pointe-Calumet',
  'Pointe-Claire',
  'Pointe-des-Cascades',
  'Pointe-Fortune',
  'Pointe-Lebel',
  'Pointe-Verte',
  'Pointe-à-la-Croix',
  'Ponass Lake No. 367',
  'Ponoka',
  'Pont-Rouge',
  'Ponteix',
  'Pontiac',
  "Pool's Cove",
  'Poplar Valley No. 12',
  'Porcupine No. 395',
  'Porcupine Plain',
  'Port Alberni',
  'Port Alice',
  'Port Anson',
  'Port au Choix',
  'Port au Port East',
  'Port au Port West-Aguathuna-Felix Cove',
  'Port Blandford',
  'Port Clements',
  'Port Colborne',
  'Port Coquitlam',
  'Port Elgin',
  'Port Hawkesbury',
  'Port Hope Simpson',
  'Port Hope',
  'Port Kirwan',
  'Port McNeill',
  'Port Moody',
  'Port Rexton',
  'Port Saunders',
  'Port-Cartier',
  'Port-Daniel--Gascons',
  'Portage la Prairie',
  'Portage-du-Fort',
  'Portneuf',
  'Portneuf-sur-Mer',
  "Portugal Cove-St. Philip's",
  'Postville',
  'Pouce Coupe',
  'Pouch Cove',
  'Poularies',
  'Powassan',
  'Powell River',
  'Powerview-Pine Falls',
  'Prairie Lakes',
  'Prairie Rose No. 309',
  'Prairie View',
  'Prairiedale No. 321',
  'Preeceville No. 334',
  'Preeceville',
  'Preissac',
  'Prelate',
  'Prescott',
  'Price',
  'Primate',
  'Prince Albert National Park',
  'Prince Albert No. 461',
  'Prince Albert',
  'Prince Edward County',
  'Prince George',
  'Prince Rupert',
  'Prince',
  'Princeton',
  'Princeville',
  'Progress No. 351',
  'Provost',
  "Prud'Homme",
  'Prévost',
  'Punnichy',
  'Puslinch',
  'Péribonka',
  "Qu'Appelle",
  'Qualicum Beach',
  'Queen Charlotte',
  'Quesnel',
  'Quill Lake',
  'Quinte West',
  'Quinton',
  'Quispamsis',
  'Québec',
  'Racine',
  'Radisson',
  'Radium Hot Springs',
  'Radville',
  'Rainbow Lake',
  'Rainy River',
  'Raleigh',
  'Rama',
  'Ramara',
  'Ramea',
  'Rapide-Danseur',
  'Rapides-des-Joachims',
  'Rawdon',
  'Raymond',
  'Raymore',
  'Reciprocity No. 32',
  'Red Bay',
  'Red Deer',
  'Red Harbour',
  'Red Lake',
  'Red Rock',
  'Redberry No. 435',
  'Redburn No. 130',
  'Redcliff',
  'Redvers',
  'Redwater',
  'Reford No. 379',
  'Regina Beach',
  'Regina',
  'Reidville',
  'Rencontre East',
  'Renews-Cappahayden',
  'Renfrew',
  'Reno No. 51',
  'Repentigny',
  'Resort Mun. Stan.B.-Hope R.-Bayv.-Cavend.-N.Rust.',
  'Revelstoke',
  'Rexton',
  'Reynolds',
  'Rhein',
  'Rhineland',
  'Richard',
  'Richelieu',
  'Richibucto',
  'Richmond Hill',
  'Richmond',
  'Richmound',
  'Rideau Lakes',
  'Ridgedale',
  'Riding Mountain West',
  'Rigaud',
  'Rigolet',
  'Rimbey',
  'Rimouski',
  'Ripon',
  'Ritchot',
  'River of Ponds',
  'Riverdale',
  'Riverhead',
  'Riverhurst',
  'Riverside No. 168',
  'Riverside-Albert',
  'Riverview',
  'Rivière-au-Tonnerre',
  'Rivière-aux-Outardes',
  'Rivière-Beaudette',
  'Rivière-Bleue',
  'Rivière-Bonaventure',
  'Rivière-Bonjour',
  'Rivière-de-la-Savane',
  'Rivière-du-Loup',
  'Rivière-Héva',
  'Rivière-Koksoak',
  'Rivière-Mistassini',
  'Rivière-Mouchalagane',
  'Rivière-Nipissis',
  'Rivière-Nouvelle',
  'Rivière-Ojima',
  'Rivière-Ouelle',
  'Rivière-Patapédia-Est',
  'Rivière-Rouge',
  'Rivière-Saint-Jean',
  'Rivière-Vaseuse',
  'Rivière-Verte',
  'Rivière-à-Claude',
  'Rivière-à-Pierre',
  'Rivière-Éternité',
  "Robert's Arm",
  'Roberval',
  'Rocanville No. 151',
  'Rocanville',
  'Roche Percée',
  'Rochebaucourt',
  'Rockglen',
  'Rockwood',
  'Rocky Harbour',
  'Rocky Mountain House',
  'Rockyford',
  'Roddickton-Bide Arm',
  'Rodgers No. 133',
  'Rogersville',
  'Roland',
  'Roquemaure',
  'Rosalind',
  'Rose Blanche-Harbour le Cou',
  'Rose Valley',
  'Rosedale No. 283',
  'Rosedale',
  'Rosemary',
  'Rosemount No. 378',
  'Rosemère',
  'Rosetown',
  'Rossburn',
  'Rosser',
  'Rossland',
  'Rosthern No. 403',
  'Rosthern',
  'Rothesay',
  'Rougemont',
  'Rouleau',
  'Round Hill No. 467',
  'Round Valley No. 410',
  'Routhierville',
  'Rouyn-Noranda',
  'Roxton Falls',
  'Roxton Pond',
  'Ruddell',
  'Rudy No. 284',
  'Ruisseau-des-Mineurs',
  'Ruisseau-Ferguson',
  'Rush Lake',
  'Rushoon',
  'Russell',
  'Russell-Binscarth',
  'Rycroft',
  'Ryerson',
  'Ryley',
  'Rémigny',
  'Réservoir-Dozois',
  'Sables-Spanish Rivers',
  'Sackville',
  'Sacré-Coeur',
  'Sagard',
  'Saguenay',
  'Saint Andrews',
  'Saint John',
  'Saint-Adalbert',
  "Saint-Adolphe-d'Howard",
  'Saint-Adrien',
  "Saint-Adrien-d'Irlande",
  'Saint-Agapit',
  'Saint-Aimé',
  'Saint-Aimé-des-Lacs',
  'Saint-Aimé-du-Lac-des-Îles',
  'Saint-Alban',
  'Saint-Albert',
  'Saint-Alexandre',
  'Saint-Alexandre-de-Kamouraska',
  'Saint-Alexis',
  'Saint-Alexis-de-Matapédia',
  'Saint-Alfred',
  'Saint-Alphonse',
  'Saint-Alphonse-de-Granby',
  'Saint-Alphonse-Rodriguez',
  'Saint-Amable',
  'Saint-Ambroise',
  'Saint-Ambroise-de-Kildare',
  'Saint-André',
  'Saint-André-Avellin',
  "Saint-André-d'Argenteuil",
  'Saint-André-de-Restigouche',
  'Saint-André-du-Lac-Saint-Jean',
  'Saint-Anicet',
  'Saint-Anselme',
  'Saint-Antoine',
  'Saint-Antoine-de-Tilly',
  'Saint-Antoine-sur-Richelieu',
  'Saint-Antonin',
  'Saint-Apollinaire',
  'Saint-Armand',
  'Saint-Athanase',
  'Saint-Aubert',
  'Saint-Augustin',
  'Saint-Augustin-de-Desmaures',
  'Saint-Barnabé-Sud',
  'Saint-Basile',
  'Saint-Basile-le-Grand',
  'Saint-Benjamin',
  'Saint-Benoît-du-Lac',
  'Saint-Benoît-Labre',
  'Saint-Bernard',
  'Saint-Bernard-de-Michaudville',
  'Saint-Blaise-sur-Richelieu',
  'Saint-Bonaventure',
  'Saint-Boniface',
  'Saint-Bruno',
  'Saint-Bruno-de-Guigues',
  'Saint-Bruno-de-Kamouraska',
  'Saint-Bruno-de-Montarville',
  'Saint-Calixte',
  'Saint-Casimir',
  'Saint-Charles-Borromée',
  'Saint-Charles-de-Bellechasse',
  'Saint-Charles-de-Bourget',
  'Saint-Charles-sur-Richelieu',
  'Saint-Chrysostome',
  'Saint-Claude',
  'Saint-Clet',
  'Saint-Cléophas-de-Brandon',
  'Saint-Colomban',
  'Saint-Constant',
  'Saint-Cuthbert',
  'Saint-Cyprien',
  'Saint-Cyprien-de-Napierville',
  'Saint-Cyrille-de-Wendover',
  'Saint-Célestin',
  'Saint-Césaire',
  'Saint-Côme--Linière',
  'Saint-Damase',
  "Saint-Damase-de-L'Islet",
  'Saint-David',
  'Saint-David-de-Falardeau',
  'Saint-Denis-De La Bouteillerie',
  'Saint-Denis-de-Brompton',
  'Saint-Denis-sur-Richelieu',
  'Saint-Dominique',
  'Saint-Dominique-du-Rosaire',
  'Saint-Donat',
  'Saint-Edmond-les-Plaines',
  'Saint-Elzéar',
  'Saint-Elzéar-de-Témiscouata',
  'Saint-Esprit',
  'Saint-Eugène',
  "Saint-Eugène-d'Argentenay",
  'Saint-Eugène-de-Guigues',
  'Saint-Eustache',
  'Saint-Faustin--Lac-Carré',
  'Saint-Ferdinand',
  'Saint-Ferréol-les-Neiges',
  'Saint-Flavien',
  'Saint-Fortunat',
  'Saint-François de Madawaska',
  "Saint-François-d'Assise",
  "Saint-François-de-l'Île-d'Orléans",
  'Saint-François-de-la-Rivière-du-Sud',
  'Saint-François-de-Sales',
  'Saint-François-du-Lac',
  'Saint-François-Xavier-de-Brompton',
  'Saint-François-Xavier-de-Viger',
  'Saint-Fulgence',
  'Saint-Félicien',
  "Saint-Félix-d'Otis",
  'Saint-Félix-de-Dalquier',
  'Saint-Félix-de-Kingsey',
  'Saint-Félix-de-Valois',
  'Saint-Gabriel',
  'Saint-Gabriel-de-Brandon',
  'Saint-Gabriel-de-Rimouski',
  'Saint-Gabriel-de-Valcartier',
  'Saint-Gabriel-Lalemant',
  'Saint-Georges',
  'Saint-Georges-de-Clarenceville',
  'Saint-Georges-de-Windsor',
  'Saint-Germain-de-Grantham',
  'Saint-Gervais',
  'Saint-Guillaume',
  'Saint-Guillaume-Nord',
  'Saint-Guy',
  'Saint-Gédéon',
  'Saint-Gédéon-de-Beauce',
  'Saint-Henri',
  'Saint-Henri-de-Taillon',
  'Saint-Herménégilde',
  'Saint-Hippolyte',
  'Saint-Honoré',
  'Saint-Honoré-de-Shenley',
  'Saint-Honoré-de-Témiscouata',
  'Saint-Hubert-de-Rivière-du-Loup',
  'Saint-Hugues',
  'Saint-Hyacinthe',
  'Saint-Ignace-de-Loyola',
  'Saint-Ignace-de-Stanbridge',
  'Saint-Isidore',
  'Saint-Isidore-de-Clifton',
  'Saint-Jacques',
  'Saint-Jacques-de-Leeds',
  'Saint-Jacques-le-Mineur',
  'Saint-Janvier-de-Joly',
  'Saint-Jean-Baptiste',
  'Saint-Jean-de-Brébeuf',
  'Saint-Jean-de-Dieu',
  "Saint-Jean-de-l'Île-d'Orléans",
  'Saint-Jean-de-la-Lande',
  'Saint-Jean-de-Matha',
  'Saint-Jean-Port-Joli',
  'Saint-Jean-sur-Richelieu',
  'Saint-Joachim-de-Shefford',
  'Saint-Joseph-de-Beauce',
  'Saint-Joseph-de-Coleraine',
  'Saint-Joseph-de-Sorel',
  'Saint-Joseph-des-Érables',
  'Saint-Joseph-du-Lac',
  'Saint-Jude',
  'Saint-Julien',
  'Saint-Just-de-Bretenières',
  'Saint-Juste-du-Lac',
  'Saint-Justin',
  'Saint-Jérôme',
  'Saint-Lambert',
  'Saint-Lambert-de-Lauzon',
  "Saint-Laurent-de-l'Île-d'Orléans",
  'Saint-Lazare',
  'Saint-Lazare-de-Bellechasse',
  'Saint-Liboire',
  'Saint-Lin--Laurentides',
  'Saint-Louis de Kent',
  'Saint-Louis',
  'Saint-Louis-de-Blandford',
  'Saint-Louis-de-Gonzague',
  'Saint-Luc-de-Bellechasse',
  'Saint-Luc-de-Vincennes',
  'Saint-Lucien',
  'Saint-Ludger',
  'Saint-Ludger-de-Milot',
  'Saint-Léolin',
  'Saint-Léonard',
  "Saint-Léonard-d'Aston",
  'Saint-Léonard-de-Portneuf',
  'Saint-Magloire',
  'Saint-Malo',
  'Saint-Marc-des-Carrières',
  'Saint-Marc-sur-Richelieu',
  'Saint-Marcel',
  'Saint-Marcel-de-Richelieu',
  'Saint-Mathias-sur-Richelieu',
  'Saint-Mathieu',
  "Saint-Mathieu-d'Harricana",
  'Saint-Mathieu-de-Beloeil',
  'Saint-Mathieu-du-Parc',
  'Saint-Maxime-du-Mont-Louis',
  'Saint-Michel',
  'Saint-Michel-de-Bellechasse',
  'Saint-Michel-des-Saints',
  'Saint-Michel-du-Squatec',
  'Saint-Modeste',
  'Saint-Médard',
  'Saint-Nazaire',
  "Saint-Norbert-d'Arthabaska",
  'Saint-Noël',
  'Saint-Nérée-de-Bellechasse',
  'Saint-Omer',
  "Saint-Onésime-d'Ixworth",
  'Saint-Ours',
  'Saint-Pacôme',
  'Saint-Pamphile',
  'Saint-Pascal',
  'Saint-Patrice-de-Beaurivage',
  'Saint-Patrice-de-Sherrington',
  'Saint-Paul',
  "Saint-Paul-d'Abbotsford",
  "Saint-Paul-de-l'Île-aux-Noix",
  'Saint-Paul-de-Montminy',
  'Saint-Paulin',
  'Saint-Philibert',
  'Saint-Philippe',
  'Saint-Pie',
  'Saint-Pierre',
  'Saint-Pierre-de-Broughton',
  "Saint-Pierre-de-l'Île-d'Orléans",
  'Saint-Pierre-de-Lamy',
  'Saint-Pierre-les-Becquets',
  'Saint-Placide',
  'Saint-Polycarpe',
  'Saint-Prime',
  'Saint-Prosper',
  'Saint-Prosper-de-Champlain',
  'Saint-Quentin',
  'Saint-Raphaël',
  'Saint-Raymond',
  'Saint-René-de-Matane',
  'Saint-Robert',
  'Saint-Robert-Bellarmin',
  "Saint-Roch-de-l'Achigan",
  'Saint-Roch-de-Richelieu',
  'Saint-Roch-Ouest',
  'Saint-Romain',
  'Saint-Rémi',
  'Saint-Rémi-de-Tingwick',
  'Saint-Samuel',
  'Saint-Sauveur',
  'Saint-Simon',
  'Saint-Simon-les-Mines',
  'Saint-Siméon',
  'Saint-Sixte',
  'Saint-Stanislas',
  'Saint-Stanislas-de-Kostka',
  'Saint-Sylvestre',
  'Saint-Sylvère',
  'Saint-Sébastien',
  'Saint-Thomas',
  'Saint-Thomas-Didyme',
  "Saint-Théodore-d'Acton",
  'Saint-Théophile',
  'Saint-Tite',
  'Saint-Tite-des-Caps',
  'Saint-Télesphore',
  'Saint-Ubalde',
  'Saint-Ulric',
  'Saint-Urbain-Premier',
  'Saint-Valentin',
  'Saint-Vallier',
  'Saint-Valère',
  'Saint-Valérien-de-Milton',
  'Saint-Venant-de-Paquette',
  'Saint-Vianney',
  'Saint-Victor',
  'Saint-Wenceslas',
  'Saint-Zacharie',
  'Saint-Zotique',
  'Saint-Zénon',
  'Saint-Édouard',
  'Saint-Édouard-de-Maskinongé',
  'Saint-Élie-de-Caxton',
  'Saint-Émile-de-Suffolk',
  'Saint-Éphrem-de-Beauce',
  'Saint-Épiphane',
  'Saint-Étienne-de-Beauharnois',
  'Saint-Étienne-de-Bolton',
  'Saint-Évariste-de-Forsyth',
  'Sainte-Adèle',
  'Sainte-Agathe-de-Lotbinière',
  'Sainte-Agathe-des-Monts',
  'Sainte-Angèle-de-Monnoir',
  'Sainte-Angèle-de-Mérici',
  'Sainte-Angèle-de-Prémont',
  'Sainte-Anne-de-Beaupré',
  'Sainte-Anne-de-Bellevue',
  'Sainte-Anne-de-la-Pérade',
  'Sainte-Anne-de-la-Rochelle',
  'Sainte-Anne-de-Madawaska',
  'Sainte-Anne-de-Sorel',
  'Sainte-Anne-des-Monts',
  'Sainte-Anne-des-Plaines',
  'Sainte-Anne-du-Lac',
  'Sainte-Anne-du-Sault',
  'Sainte-Aurélie',
  'Sainte-Barbe',
  "Sainte-Brigide-d'Iberville",
  'Sainte-Brigitte-de-Laval',
  'Sainte-Béatrix',
  'Sainte-Catherine',
  'Sainte-Catherine-de-Hatley',
  'Sainte-Catherine-de-la-Jacques-Cartier',
  "Sainte-Christine-d'Auvergne",
  'Sainte-Claire',
  'Sainte-Clotilde',
  'Sainte-Clotilde-de-Beauce',
  'Sainte-Clotilde-de-Horton',
  'Sainte-Croix',
  'Sainte-Cécile-de-Milton',
  'Sainte-Cécile-de-Whitton',
  'Sainte-Eulalie',
  'Sainte-Euphémie-sur-Rivière-du-Sud',
  'Sainte-Florence',
  'Sainte-Françoise',
  'Sainte-Félicité',
  'Sainte-Geneviève-de-Berthier',
  'Sainte-Germaine-Boulé',
  'Sainte-Gertrude-Manneville',
  'Sainte-Hedwidge',
  'Sainte-Hélène-de-Bagot',
  'Sainte-Hélène-de-Chester',
  'Sainte-Hélène-de-Kamouraska',
  "Sainte-Jeanne-d'Arc",
  'Sainte-Julie',
  'Sainte-Julienne',
  'Sainte-Justine',
  'Sainte-Justine-de-Newton',
  'Sainte-Luce',
  'Sainte-Lucie-de-Beauregard',
  'Sainte-Lucie-des-Laurentides',
  'Sainte-Madeleine',
  'Sainte-Madeleine-de-la-Rivière-Madeleine',
  'Sainte-Marcelline-de-Kildare',
  'Sainte-Marguerite-du-Lac-Masson',
  'Sainte-Marguerite-Marie',
  'Sainte-Marie',
  'Sainte-Marie-de-Blandford',
  'Sainte-Marie-Saint-Raphaël',
  'Sainte-Marthe',
  'Sainte-Marthe-sur-le-Lac',
  'Sainte-Martine',
  'Sainte-Monique',
  'Sainte-Mélanie',
  'Sainte-Paule',
  'Sainte-Perpétue',
  'Sainte-Pétronille',
  'Sainte-Rita',
  'Sainte-Rose-de-Watford',
  'Sainte-Sabine',
  'Sainte-Sophie',
  "Sainte-Sophie-d'Halifax",
  'Sainte-Thècle',
  'Sainte-Thérèse',
  'Sainte-Thérèse-de-Gaspé',
  'Sainte-Thérèse-de-la-Gatineau',
  'Sainte-Victoire-de-Sorel',
  'Sainte-Élisabeth',
  'Sainte-Élizabeth-de-Warwick',
  "Sainte-Émélie-de-l'Énergie",
  'Salaberry-de-Valleyfield',
  'Salisbury',
  "Sally's Cove",
  'Salmo',
  'Salmon Arm',
  'Salmon Cove',
  'Saltcoats No. 213',
  'Saltcoats',
  'Salvage',
  'Sandringham',
  'Sandy Cove',
  'Sarnia No. 221',
  'Sarnia',
  'Saskatchewan Landing No. 167',
  'Saskatoon',
  'Sasman No. 336',
  'Saugeen Shores',
  'Sault Ste. Marie',
  'Sault-au-Cochon',
  'Sayabec',
  'Sayward',
  'Sceptre',
  'Schefferville',
  'Schreiber',
  'Scotstown',
  'Scott No. 98',
  'Scott',
  'Scugog',
  'Seal Cove (Fortune Bay)',
  'Seal Cove (White Bay)',
  'Sedgewick',
  'Sedley',
  'Seguin',
  'Selkirk',
  'Selwyn',
  'Semans',
  'Senlac No. 411',
  'Senlac',
  'Senneterre',
  'Senneville',
  'Sept-Îles',
  'Severn',
  'Sexsmith',
  'Shamrock No. 134',
  'Shamrock',
  'Shannon',
  'Shaunavon',
  'Shawinigan',
  'Shawville',
  'Shediac',
  'Sheenboro',
  'Sheho',
  'Shelburne',
  'Shell Lake',
  'Shellbrook No. 493',
  'Shellbrook',
  'Sherbrooke',
  'Sherwood No. 159',
  'Shigawake',
  'Shippagan',
  'Shuniah',
  'Sidney',
  'Sifton',
  'Silton',
  'Silverton',
  'Silverwood No. 123',
  'Simpson',
  'Sintaluta',
  'Sioux Lookout',
  'Sioux Narrows-Nestor Falls',
  'Slave Lake',
  'Sliding Hills No. 273',
  'Slocan',
  "Small Point-Adam's Cove-Blackhead-Broad Cove",
  'Smeaton',
  'Smiley',
  'Smithers',
  'Smiths Falls',
  'Smoky Lake',
  'Smooth Rock Falls',
  'Snipe Lake No. 259',
  'Snow Lake',
  'Sorel-Tracy',
  'Souris Valley No. 7',
  'Souris',
  'Souris-Glenwood',
  'South Algonquin',
  'South Brook',
  'South Bruce Peninsula',
  'South Bruce',
  'South Dundas',
  'South Frontenac',
  'South Glengarry',
  'South Huron',
  "South Qu'Appelle No. 157",
  'South River',
  'South Stormont',
  'South-West Oxford',
  'Southern Harbour',
  'Southey',
  'Southgate',
  'Southwest Middlesex',
  'Southwold',
  'Spalding No. 368',
  'Spalding',
  "Spaniard's Bay",
  'Spanish',
  'Special Area No. 2',
  'Special Area No. 3',
  'Special Area No. 4',
  'Speers',
  'Spirit River',
  'Spiritwood No. 496',
  'Spiritwood',
  'Spring Lake',
  'Springdale',
  'Springfield',
  'Springside',
  'Springwater',
  'Spruce Grove',
  'Spy Hill No. 152',
  'Spy Hill',
  'St-Pierre-Jolys',
  "St. Alban's",
  'St. Albert',
  'St. Andrews No. 287',
  'St. Andrews',
  'St. Anthony',
  'St. Benedict',
  "St. Bernard's-Jacques Fontaine",
  "St. Brendan's",
  "St. Bride's",
  'St. Brieux',
  'St. Catharines',
  'St. Clair',
  'St. Clements',
  'St. François Xavier',
  'St. George',
  "St. George's",
  'St. Gregor',
  'St. Hilaire',
  "St. Jacques-Coomb's Cove",
  "St. John's",
  'St. Joseph',
  "St. Joseph's",
  'St. Laurent',
  'St. Lawrence',
  'St. Lewis',
  'St. Louis No. 431',
  'St. Louis',
  'St. Lunaire-Griquet',
  'St. Martins',
  "St. Mary's",
  'St. Marys',
  'St. Paul',
  'St. Pauls',
  'St. Peter No. 369',
  'St. Peters Bay',
  'St. Philips No. 301',
  "St. Shott's",
  'St. Stephen',
  'St. Thomas',
  "St. Vincent's-St. Stephen's-Peter's River",
  'St. Walburg',
  'St.-Charles',
  'Stanbridge East',
  'Stanbridge Station',
  'Standard',
  'Stanley No. 215',
  'Stanley',
  'Stanstead',
  'Stanstead-Est',
  'Star City No. 428',
  'Star City',
  'Stavely',
  'Ste. Anne',
  'Ste. Rose',
  'Steady Brook',
  'Steinbach',
  'Stellarton',
  'Stenen',
  'Stephenville Crossing',
  'Stephenville',
  'Stettler',
  'Stewart Valley',
  'Stewiacke',
  'Stirling',
  'Stirling-Rawdon',
  'Stockholm',
  'Stoke',
  'Stone Mills',
  'Stonehenge No. 73',
  'Stonewall',
  'Stony Plain',
  'Stornoway',
  'Storthoaks No. 31',
  'Storthoaks',
  'Stoughton',
  'Strasbourg',
  'Stratford',
  'Strathmore',
  'Strathroy-Caradoc',
  'Strong',
  'Strongfield',
  'Stuartburn',
  'Stukely-Sud',
  'Sturgis',
  'Success',
  'Sudbury',

  'Summerford',
  'Summerside',
  'Sun Peaks Mountain',
  'Sundre',
  'Sundridge',
  'Sunnyside',
  'Surprise Valley No. 9',
  'Surrey',
  'Sussex Corner',
  'Sussex',
  'Sutton No. 103',
  'Sutton',
  'Swan Hills',
  'Swan River',
  'Swan Valley West',
  'Swift Current No. 137',
  'Swift Current',
  'Sylvan Lake',
  'Taber',
  'Taché',
  'Tadoussac',
  'Tahsis',
  'Tantallon',
  'Tarbutt and Tarbutt Additional',
  'Taschereau',
  'Tay Valley',
  'Tay',
  'Tecumseh No. 65',
  'Tecumseh',
  'Tehkummah',
  'Telkwa',
  'Temagami',
  'Temiskaming Shores',
  'Terra Nova',
  'Terrace Bay',
  'Terrace',
  'Terrasse-Vaudreuil',
  'Terrebonne',
  'Terrell No. 101',
  'Terrenceville',
  'Teslin',
  'Tessier',
  'Teulon',
  'Thames Centre',
  'The Archipelago',
  'The Blue Mountains',
  'The Gap No. 39',
  'The Nation',
  'The North Shore',
  'The Pas',
  'Theodore',
  'Thessalon',
  'Thetford Mines',
  'Thompson',
  'Thorne',
  'Thornloe',
  'Thorold',
  'Thorsby',
  'Three Hills',
  'Three Lakes No. 400',
  'Thunder Bay',
  'Thurso',
  'Tide Head',
  'Tignish',
  'Tillsonburg',
  'Tilt Cove',
  'Timiskaming',

  'Timmins',
  'Tingwick',
  'Tiny',
  'Tisdale No. 427',
  'Tisdale',
  'Tofield',
  'Togo',
  'Tompkins',
  'Torbay',
  'Torch River No. 488',
  'Toronto',
  'Torquay',
  'Touchwood No. 248',
  'Tourville',
  'Tracy',
  'Trail',
  'Tramping Lake No. 380',
  'Tramping Lake',
  'Traytown',
  'Trent Hills',
  'Trent Lakes',
  'Trenton',
  'Trepassey',
  'Tribune',
  'Tring-Jonction',
  'Trinity (Trinity Bay)',
  'Trinity Bay North',
  'Triton',
  'Trochu',
  'Trois-Pistoles',
  'Trois-Rives',
  'Trois-Rivières',
  'Trout River',
  'Truro',
  'Très-Saint-Rédempteur',
  'Tudor and Cashel',
  'Tugaske',
  'Tullymet No. 216',
  'Turner Valley',
  'Turtle River No. 469',
  'Turtleford',
  'Tuxford',
  'Tweed',
  'Twillingate',
  'Two Borders',
  'Two Hills',
  'Tyendinaga',
  'Tyne Valley',
  'Témiscaming',
  'Témiscouata-sur-le-Lac',
  'Ulverton',
  'Union Road',
  'Unity',
  'Upper Island Cove',
  'Upton',
  'Usborne No. 310',
  'Uxbridge',
  'Val Marie No. 17',
  'Val Marie',
  'Val Rita-Harty',
  'Val-Alain',
  'Val-Brillant',
  "Val-d'Or",
  'Val-David',
  'Val-des-Bois',
  'Val-des-Lacs',
  'Val-des-Monts',
  'Val-Joli',
  'Val-Morin',
  'Val-Racine',
  'Val-Saint-Gilles',
  'Valcourt',
  'Valemount',
  'Valleyview',
  'Vallée-Jonction',
  'Valparaiso',
  'Vancouver',
  'Vanguard',
  'Vanscoy No. 345',
  'Vanscoy',
  'Varennes',
  'Vaudreuil-Dorion',
  'Vaudreuil-sur-le-Lac',
  'Vaughan',
  'Vauxhall',
  'Vegreville',
  'Venise-en-Québec',
  'Verchères',
  'Vermilion',
  'Vernon',
  'Veteran',
  'Vibank',
  'Victoria Beach',
  'Victoria',
  'Victoriaville',
  'Victory No. 226',
  'View Royal',
  'Viking',
  'Ville-Marie',
  'Villeroy',
  'Vilna',
  'Virden',
  'Viscount No. 341',
  'Viscount',
  'Vonda',
  'Vulcan',
  'Wabamun',
  'Wabana',
  'Wabush',
  'Wadena',
  'Wainfleet',
  'Wainwright',
  'Wakaw',
  'Waldeck',
  'Waldheim',
  'Waldron',
  'Wallace No. 243',
  'Wallace-Woodworth',
  'Walpole No. 92',
  'Waltham',
  'Wapella',
  'Warburg',
  'Warden',
  'Warfield',
  'Warman',
  'Warner',
  'Warren Grove',
  'Warwick',
  'Wasaga Beach',
  'Waseca',
  'Waskatenau',
  'Waterloo',
  'Waterville',
  'Watrous',
  'Watson Lake',
  'Watson',
  'Waverley No. 44',
  'Wawa',
  'Wawken No. 93',
  'Wawota',
  'Webb No. 138',
  'Webb',
  'Weedon',
  'Weekes',
  'Weirdale',
  'Weldon',
  'Welland',
  'Wellesley',
  'Wellington No. 97',
  'Wellington North',
  'Wellington',
  'Welwyn',
  'Wembley',
  'Wentworth-Nord',
  'West Elgin',
  'West Grey',
  'West Interlake',
  'West Lincoln',
  'West Nipissing',
  'West Perth',
  'West St. Modeste',
  'West St. Paul',
  'WestLake-Gladstone',
  'Westlock',
  'Westmount',
  'Westport',
  'Westville',
  'Wetaskiwin',
  'Weyburn No. 67',
  'Weyburn',
  'Wheatlands No. 163',
  'Whiska Creek No. 106',
  'Whitbourne',
  'Whitby',
  'Whitchurch-Stouffville',
  'White City',
  'White Fox',
  'White River',
  'White Rock',
  'White Valley No. 49',
  'Whitecourt',
  'Whitehead',
  'Whitehorse',
  'Whitemouth',
  'Whitestone',
  'Whitewater Region',
  'Whiteway',
  'Whitewood',
  'Wickham',
  'Wilcox',
  'Wilkie',
  'Williams Lake',
  'Willingdon',
  'Willner No. 253',
  'Willow Bunch No. 42',
  'Willow Bunch',
  'Willow Creek No. 458',
  'Willowdale No. 153',
  'Wilmot',
  'Wilton No. 472',
  'Windsor',
  'Windthorst',
  'Winkler',
  'Winnipeg Beach',
  'Winnipeg',
  'Winsloe South',
  'Winslow No. 319',
  'Winterland',
  'Winterton',
  'Wise Creek No. 77',
  'Wiseton',
  'Witless Bay',
  'Wolfville',
  'Wollaston',
  'Wolseley No. 155',
  'Wolseley',
  'Wolverine No. 340',
  'Wood Creek No. 281',
  'Wood Mountain',
  'Wood River No. 74',
  'Woodlands',
  'Woodstock',
  'Woody Point',
  'Woolwich',
  'Wotton',
  'Wreford No. 280',
  'Wynyard',
  'Yamachiche',
  'Yamaska',
  'Yarbo',
  'Yarmouth',
  'Yellow Grass',
  'Yellowhead',
  'Yellowknife',
  'York Harbour',
  'Yorkton',
  'Young',
  'Youngstown',
  'Yukon',
  'Zealandia',
  'Zeballos',
  'Zelma',
  'Zenon Park',
  'Zorra',
  'Portugal Cove South',
  'Bonne Bay',
];

export default cities;
