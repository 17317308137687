import {
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { fetchVerificationsByObjectId } from 'api/verifications/verifications.api';
import { FC, useEffect, useState, useCallback } from 'react';
import { Verification } from 'types/entities/verifications';
import { logger } from 'utilities/logger/Logger';
import { MRT_ColumnDef } from 'material-react-table';
import { YesNoEnum } from 'types/utilities';

type VerificationsFormProps = {
  objectId?: string;
  objectIds?: string[];
  columns: MRT_ColumnDef<Verification>[];
  values: Verification;
  setValues: (values: Verification) => void;
  original?: Verification[];
};

const emptyVerification: Verification = {
  objectId: '',
  name: '',
  email: '',
  phone: '',
  title: '',
  requestSent: false,
  hasVerified: false,
  updatedPropertyNames: [],
  completionDate: new Date(),
  internalConfirmationDate: new Date(),
  secondaryVerifierEmail: '',
  secondaryVerifierName: '',
  secondaryVerifierPhone: '',
  secondaryVerifierTitle: '',
  agencyId: '',
  siteId: '',
  resourceId: '',
};

const renderDisabledTextField = (label: string, value: string) => {
  return (
    <TextField
      label={label}
      name={label}
      disabled
      value={value || ''}
      variant="standard"
    />
  );
};

const renderTextField = (
  column: MRT_ColumnDef<Verification>,
  values: Verification,
  setValues: (values: Verification) => void,
  isRequired?: boolean,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      required={isRequired}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target?.name) {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }
      }}
      value={values?.[column.accessorKey as keyof Verification]}
      variant="standard"
    />
  );
};

const renderYesNoDropdownField = (
  column: MRT_ColumnDef<Verification>,
  values: Verification,
  setValues: (values: Verification) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values?.[column.accessorKey as keyof Verification] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {Object.values(YesNoEnum).map((item) => (
        <MenuItem key={item} value={item}>
          {item === YesNoEnum.Yes ? 'Yes' : 'No'}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const VerificationsForm: FC<VerificationsFormProps> = ({
  objectId,
  values,
  setValues,
  columns,
  original,
  objectIds,
}) => {
  const [loading, setLoading] = useState(false);
  const [verification, setVerification] = useState<Verification>({
    ...emptyVerification,
  });

  useEffect(() => {
    const fetchVerification = async () => {
      if (objectId) {
        try {
          setLoading(true);
          const verifications = await fetchVerificationsByObjectId(objectId);
          if (verifications) {
            // TODO: Clean-up
            setVerification(verifications);
            setValues(verifications);
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            logger.error(error);
          }
        } finally {
          setLoading(false);
        }

        setLoading(false);
      } else if (objectIds) {
        try {
          const verifications = [];
          for (const id of objectIds) {
            const verification = await fetchVerificationsByObjectId(id);
            if (verification) {
              verifications.push(verification);
            }
          }
          if (verifications) {
            setValues(verifications[0]);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (original && original.length > 0) {
      setValues(original[original.length - 1]); // latest verification record
    } else {
      fetchVerification();
    }
  }, [objectId, original, setValues]);

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Verification>;
    },
    [columns],
  );

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      {loading ? (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : (
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderDisabledTextField('Id', values?.objectId || '')}
          {renderTextField(
            getColumnByAccessorKey('name'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('email'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('title'),
            values,
            setValues,
            false,
          )}
          {renderTextField(
            getColumnByAccessorKey('phone'),
            values,
            setValues,
            false,
          )}
          {renderTextField(
            getColumnByAccessorKey('secondaryVerifierName'),
            values,
            setValues,
            false,
          )}
          {renderTextField(
            getColumnByAccessorKey('secondaryVerifierEmail'),
            values,
            setValues,
            false,
          )}
          {renderTextField(
            getColumnByAccessorKey('secondaryVerifierPhone'),
            values,
            setValues,
            false,
          )}
          {renderTextField(
            getColumnByAccessorKey('secondaryVerifierTitle'),
            values,
            setValues,
            false,
          )}
          {renderYesNoDropdownField(
            getColumnByAccessorKey('hasVerified'),
            values,
            setValues,
          )}
          {renderDisabledTextField(
            'Request Sent',
            (values?.requestSent || '')?.toString(),
          )}
          {renderDisabledTextField(
            'Completion Date',
            (values?.completionDate || '')?.toString(),
          )}
          {renderDisabledTextField(
            'Internal Confirmation Date',
            (values?.internalConfirmationDate || '')?.toString(),
          )}
          {renderDisabledTextField(
            'Updated Properties',
            values?.updatedPropertyNames?.toString() || 'N/A',
          )}
        </Box>
      )}
    </Stack>
  );
};
