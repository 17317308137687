/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalBottomSection } from 'components/Shared/ModalContent/ModalBottomSection';
import { AgencyForm } from 'components/Shared/SharedForms/AgencyForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { palette } from 'design/theme/palette';
import { selectUser } from 'global-state/selectors';
// import { validateRequired } from 'helpers/validation.helpers';
import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Agency } from 'types/entities/agencies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const EditModal: FC<{
  columns: MRT_ColumnDef<Agency>[];
  onClose: () => void;
  onSubmit: (rowIndex: number, values: Agency) => void;
  open: boolean;
  row: MRT_Row<Agency>;
  changedProperties: string[];
}> = ({ row, open, columns, onClose, onSubmit, changedProperties }) => {
  const loggedUser = useSelector(selectUser);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [agenciesFormValues, setAgenciesFormValues] = useState<Agency>(
    row.original,
  );

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!agenciesFormValues) {
      return;
    }
  }, [agenciesFormValues]);

  const handleSubmit = () => {
    const isValidFormOnSubmit = true;
    const validationErrosOnSubmit: { [cellId: string]: string } = {};

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        intl.translate({
          id: 'Please fill all required fields',
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    const updatedAgency: Agency = {
      ...agenciesFormValues,
      lastUpdatedBy: loggedUser?.email || '',
    };
    onSubmit(row.index, updatedAgency);

    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Edit Agency',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '80vh',
              '&  #tabpanel-0': {
                marginLeft: '100px',
              },
              '&  #tabpanel-1': {
                marginLeft: '100px',
              },
              '&  #tabpanel-2': {
                marginLeft: '100px',
              },
              '&  #tabpanel-3': {
                marginLeft: '100px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab label="Agency" {...a11yProps(0)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <AgencyForm
                showLabel={false}
                columns={columns}
                values={agenciesFormValues}
                setValues={setAgenciesFormValues}
                validationErrors={validationErrors}
                changedProperties={changedProperties}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <ModalBottomSection showUpdatedLabel={true}>
          <CancelButton handleClick={onClose}>
            {intl.translate({
              id: 'Cancel',
            })}
          </CancelButton>
          <ConfirmButton handleClick={handleSubmit}>
            <strong>
              {intl.translate({
                id: 'Save Changes',
              })}
            </strong>
          </ConfirmButton>
        </ModalBottomSection>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
