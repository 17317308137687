/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Remove } from '@mui/icons-material';
import { Autocomplete, Box, Grid, IconButton, TextField } from '@mui/material';
import { newOperations } from 'helpers/resources.helpers';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Operations } from 'types/data-management';

const OperationsForm: FC<{
  value: Operations[];
  setValue: React.Dispatch<React.SetStateAction<Operations[]>>;
  currentSites: any;
}> = ({ value, setValue, currentSites }) => {
  if (value.length === 0) {
    setValue([
      {
        0: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        1: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        2: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        3: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        4: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        5: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        6: {
          fr: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
          en: { hoursOfOperation: '', descriptionOfHours: '', day: '' },
        },
        siteId: '',
        siteLabel: '',
      },
    ]);
  }

  const [sites, setSites] = useState<string[]>([]);

  const handleAddItem = () => {
    const arrayWithNewItem = [...value, { ...newOperations }];
    setValue(arrayWithNewItem);
  };

  const handleRemoveItem = (index: number) => {
    const arrayRemovedInex = [...value];
    arrayRemovedInex.splice(index, 1);
    setValue(arrayRemovedInex);
  };

  const getSites = useCallback(async () => {
    const sitesList = currentSites;
    const sitesLabelValue = sitesList?.map(
      (site: any) =>
        `En: ${site.nameEN} / Fr: ${site.nameFR} -  (${
          site?._id || site?.objectId
        })`,
    );
    setSites(sitesLabelValue);
  }, [currentSites]);

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '102ch' },
      }}
    >
      <Grid container spacing={1}>
        {value.map((operations, index) => {
          return (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                <Autocomplete
                  value={
                    operations.siteLabel ||
                    sites?.find((x) => x.includes(operations.siteId)) ||
                    ''
                  }
                  onChange={(event: unknown, newValue: string | null) => {
                    const idValue = newValue?.split('ID: ')[1];
                    setValue((prev) => {
                      return prev.map((x: Operations, i: number) =>
                        i === index
                          ? {
                              ...x,
                              siteId: idValue || '',
                              siteLabel: newValue || '',
                            }
                          : x,
                      );
                    });
                  }}
                  options={sites || []}
                  renderInput={(params) => (
                    <TextField {...params} label="Site ID" variant="standard" />
                  )}
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  aria-label="Add item"
                  component="label"
                  onClick={handleAddItem}
                >
                  <Add />
                </IconButton>
              </Grid>

              {index > 0 && (
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    aria-label="Remove Item"
                    component="label"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <Remove />
                  </IconButton>
                </Grid>
              )}

              {/* 1 */}
              <Grid item xs={5}>
                <TextField
                  label={'Monday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                en: {
                                  ...x['0']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Lundi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                fr: {
                                  ...x['0']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Monday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                en: {
                                  ...x['0']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Lundi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                fr: {
                                  ...x['0']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Monday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                en: {
                                  ...x['0']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Lundi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '0': {
                                ...x['0'],
                                fr: {
                                  ...x['0']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[0]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 1 */}
              <Grid item xs={5}>
                <TextField
                  label={'Tuesday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                en: {
                                  ...x['1']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Mardi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                fr: {
                                  ...x['1']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Tuesday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                en: {
                                  ...x['1']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Mardi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                fr: {
                                  ...x['1']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Tuesday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                en: {
                                  ...x['1']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Mardi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '1': {
                                ...x['1'],
                                fr: {
                                  ...x['1']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[1]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 2 */}
              <Grid item xs={5}>
                <TextField
                  label={'Wednesday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                en: {
                                  ...x['2']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Mercredi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                fr: {
                                  ...x['2']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Wednesday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                en: {
                                  ...x['2']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Mercredi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                fr: {
                                  ...x['2']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Wednesday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                en: {
                                  ...x['2']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Mercredi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '2': {
                                ...x['2'],
                                fr: {
                                  ...x['2']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[2]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 3 */}
              <Grid item xs={5}>
                <TextField
                  label={'Thursday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                en: {
                                  ...x['3']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Jeudi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                fr: {
                                  ...x['3']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Thursday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                en: {
                                  ...x['3']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Jeudi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                fr: {
                                  ...x['3']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Thursday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                en: {
                                  ...x['3']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Jeudi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '3': {
                                ...x['3'],
                                fr: {
                                  ...x['3']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[3]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 4 */}
              <Grid item xs={5}>
                <TextField
                  label={'Friday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                en: {
                                  ...x['4']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Vendredi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                fr: {
                                  ...x['4']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Friday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                en: {
                                  ...x['4']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Vendredi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                fr: {
                                  ...x['4']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Friday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                en: {
                                  ...x['4']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Vendredi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '4': {
                                ...x['4'],
                                fr: {
                                  ...x['4']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[4]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 5 */}
              <Grid item xs={5}>
                <TextField
                  label={'Saturday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                en: {
                                  ...x['5']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Samedi'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                fr: {
                                  ...x['5']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Saturday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                en: {
                                  ...x['5']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Samedi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                fr: {
                                  ...x['5']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Saturday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                en: {
                                  ...x['5']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Samedi Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '5': {
                                ...x['5'],
                                fr: {
                                  ...x['5']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[5]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              {/* 6 */}
              <Grid item xs={5}>
                <TextField
                  label={'Sunday'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                en: {
                                  ...x['6']?.en,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.en?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Dimanche'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                fr: {
                                  ...x['6']?.fr,
                                  day: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.fr?.day || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Sunday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                en: {
                                  ...x['6']?.en,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.en.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label={'Description of Hours Dimanche Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                fr: {
                                  ...x['6']?.fr,
                                  descriptionOfHours: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.fr.descriptionOfHours || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={'Hours of Operation Sunday En'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                en: {
                                  ...x['6']?.en,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.en.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={5} mb={2}>
                <TextField
                  label={'Hours of Operation Dimanche Fr'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index
                          ? {
                              ...x,
                              '6': {
                                ...x['6'],
                                fr: {
                                  ...x['6']?.fr,
                                  hoursOfOperation: e.target?.value,
                                },
                              },
                            }
                          : x,
                      );
                    });
                  }}
                  value={operations[6]?.fr.hoursOfOperation || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default OperationsForm;
