const citiesByProvince = [
  { city: 'Consort', province: 'Alberta' },
  { city: 'Youngstown', province: 'Alberta' },
  { city: 'Athabasca', province: 'Alberta' },
  { city: 'Barrhead', province: 'Alberta' },
  { city: 'Atikameg', province: 'Alberta' },
  { city: 'Cadotte Lake', province: 'Alberta' },
  { city: 'Chateh', province: 'Alberta' },
  { city: 'Driftpile', province: 'Alberta' },
  { city: 'Enilda', province: 'Alberta' },
  { city: 'Fox Lake', province: 'Alberta' },
  { city: 'Garden River', province: 'Alberta' },
  { city: 'Gift Lake', province: 'Alberta' },
  { city: 'Grouard', province: 'Alberta' },
  { city: 'High Level', province: 'Alberta' },
  { city: 'High Prairie', province: 'Alberta' },
  { city: "John D'or Prairie", province: 'Alberta' },
  { city: 'Kinuso', province: 'Alberta' },
  { city: 'Paddle Prairie', province: 'Alberta' },
  { city: 'Rainbow Lake', province: 'Alberta' },
  { city: 'Swan Hills', province: 'Alberta' },
  { city: 'Bonnyville', province: 'Alberta' },
  { city: 'Glendon', province: 'Alberta' },
  { city: 'Sputinow', province: 'Alberta' },
  { city: 'Breton', province: 'Alberta' },
  { city: 'Calgary', province: 'Alberta' },
  { city: 'Chestermere', province: 'Alberta' },
  { city: 'Crossfield', province: 'Alberta' },
  { city: 'Eden Valley', province: 'Alberta' },
  { city: 'High River', province: 'Alberta' },
  { city: 'Langdon', province: 'Alberta' },
  { city: 'Olds', province: 'Alberta' },
  { city: "Tsuu T'ina", province: 'Alberta' },
  { city: 'Camrose', province: 'Alberta' },
  { city: 'Lloydminster', province: 'Alberta' },
  { city: 'Mannville', province: 'Alberta' },
  { city: 'Minburn', province: 'Alberta' },
  { city: 'New Norway', province: 'Alberta' },
  { city: 'Banff', province: 'Alberta' },
  { city: 'Canmore', province: 'Alberta' },
  { city: 'Morley', province: 'Alberta' },
  { city: 'Cardston', province: 'Alberta' },
  { city: 'Magrath', province: 'Alberta' },
  { city: 'Worsley', province: 'Alberta' },
  { city: 'Bowden', province: 'Alberta' },
  { city: 'Caroline', province: 'Alberta' },
  { city: 'Nordegg', province: 'Alberta' },
  { city: 'Cold Lake', province: 'Alberta' },
  { city: 'Elizabeth Metis Settlement', province: 'Alberta' },
  { city: 'Onefour', province: 'Alberta' },
  { city: 'Ralston', province: 'Alberta' },
  { city: 'Beaumont', province: 'Alberta' },
  { city: 'Devon', province: 'Alberta' },
  { city: 'Drayton Valley', province: 'Alberta' },
  { city: 'Edmonton', province: 'Alberta' },
  { city: 'Enoch', province: 'Alberta' },
  { city: 'Ma-Me-O Beach', province: 'Alberta' },
  { city: 'Fairview', province: 'Alberta' },
  { city: 'Castor', province: 'Alberta' },
  { city: 'Daysland', province: 'Alberta' },
  { city: 'Killam', province: 'Alberta' },
  { city: 'Sedgewick', province: 'Alberta' },
  { city: 'Viking', province: 'Alberta' },
  { city: 'Black Diamond', province: 'Alberta' },
  { city: 'Okotoks', province: 'Alberta' },
  { city: 'Priddis', province: 'Alberta' },
  { city: 'Turner Valley', province: 'Alberta' },
  { city: 'Brocket', province: 'Alberta' },
  { city: 'Stand Off', province: 'Alberta' },
  { city: 'Chard', province: 'Alberta' },
  { city: 'Fort Mackay', province: 'Alberta' },
  { city: 'Fort Saskatchewan', province: 'Alberta' },
  { city: 'Bow Island', province: 'Alberta' },
  { city: 'Beaverlodge', province: 'Alberta' },
  { city: 'Falher', province: 'Alberta' },
  { city: 'Grande Prairie', province: 'Alberta' },
  { city: 'Grande Prarie', province: 'Alberta' },
  { city: 'Hythe', province: 'Alberta' },
  { city: 'La Glace', province: 'Alberta' },
  { city: 'Peace River', province: 'Alberta' },
  { city: 'Calais', province: 'Alberta' },
  { city: 'Fox Creek', province: 'Alberta' },
  { city: 'Grande Cache', province: 'Alberta' },
  { city: 'Valleyview', province: 'Alberta' },
  { city: 'Jasper', province: 'Alberta' },
  { city: 'Drumheller', province: 'Alberta' },
  { city: 'Elnora', province: 'Alberta' },
  { city: 'Oyen', province: 'Alberta' },
  { city: 'Three Hills', province: 'Alberta' },
  { city: 'Trochu', province: 'Alberta' },
  { city: 'Glenevis', province: 'Alberta' },
  { city: 'Mayerthorpe', province: 'Alberta' },
  { city: 'Alix', province: 'Alberta' },
  { city: 'Lacombe', province: 'Alberta' },
  { city: 'Boyle', province: 'Alberta' },
  { city: 'Lamont', province: 'Alberta' },
  { city: 'Leduc', province: 'Alberta' },
  { city: 'Millet', province: 'Alberta' },
  { city: 'Nisku', province: 'Alberta' },
  { city: 'Slave Lake', province: 'Alberta' },
  { city: 'Brooks', province: 'Alberta' },
  { city: 'Coaldale', province: 'Alberta' },
  { city: 'Lethbridge', province: 'Alberta' },
  { city: 'Picture Butte', province: 'Alberta' },
  { city: 'Fort Vermilion', province: 'Alberta' },
  { city: 'La Crete', province: 'Alberta' },
  { city: 'Zama City', province: 'Alberta' },
  { city: 'Dunmore', province: 'Alberta' },
  { city: 'Medicine Hat', province: 'Alberta' },
  { city: 'Vegreville', province: 'Alberta' },
  { city: 'Acme', province: 'Alberta' },
  { city: 'Sundre', province: 'Alberta' },
  { city: 'Bassano', province: 'Alberta' },
  { city: 'Manning', province: 'Alberta' },
  { city: 'Calling Lake', province: 'Alberta' },
  { city: 'Peerless Lake', province: 'Alberta' },
  { city: 'Red Earth Creek', province: 'Alberta' },
  { city: 'Wabasca', province: 'Alberta' },
  { city: 'Coronation', province: 'Alberta' },
  { city: 'Duffield', province: 'Alberta' },
  { city: 'Entwistle', province: 'Alberta' },
  { city: 'Onoway', province: 'Alberta' },
  { city: 'Stony Plain', province: 'Alberta' },
  { city: 'Benito', province: 'Alberta' },
  { city: 'Binscarth', province: 'Alberta' },
  { city: 'Birch River', province: 'Alberta' },
  { city: 'Camperville', province: 'Alberta' },
  { city: 'Crane River', province: 'Alberta' },
  { city: 'Dauphin', province: 'Alberta' },
  { city: 'Duck Bay', province: 'Alberta' },
  { city: 'Ebb And Flow', province: 'Alberta' },
  { city: 'Erickson', province: 'Alberta' },
  { city: 'Ethelbert', province: 'Alberta' },
  { city: 'Gilbert Plains', province: 'Alberta' },
  { city: 'Grandview', province: 'Alberta' },
  { city: 'Mccreary', province: 'Alberta' },
  { city: 'Pelican Rapids', province: 'Alberta' },
  { city: 'Roblin', province: 'Alberta' },
  { city: 'Rossburn', province: 'Alberta' },
  { city: 'Russell', province: 'Alberta' },
  { city: 'Shortdale', province: 'Alberta' },
  { city: 'Ste Rose Du Lac', province: 'Alberta' },
  { city: 'Ste.rose du lac,mb', province: 'Alberta' },
  { city: 'Swan River', province: 'Alberta' },
  { city: 'Waterhen', province: 'Alberta' },
  { city: 'Waywayseecappo', province: 'Alberta' },
  { city: 'Winnipegosis', province: 'Alberta' },
  { city: 'Brownvale', province: 'Alberta' },
  { city: 'Grimshaw', province: 'Alberta' },
  { city: 'Blairmore', province: 'Alberta' },
  { city: 'Bashaw', province: 'Alberta' },
  { city: 'Maskwacis', province: 'Alberta' },
  { city: 'Rimbey', province: 'Alberta' },
  { city: 'Provost', province: 'Alberta' },
  { city: 'Blackfalds', province: 'Alberta' },
  { city: 'College Heights', province: 'Alberta' },
  { city: 'Didsbury', province: 'Alberta' },
  { city: 'Eckville', province: 'Alberta' },
  { city: 'Innisfail', province: 'Alberta' },
  { city: 'Penhold', province: 'Alberta' },
  { city: 'Ponoka', province: 'Alberta' },
  { city: 'Red Deer', province: 'Alberta' },
  { city: 'Sylvan Lake', province: 'Alberta' },
  { city: 'Rocky Mountain House', province: 'Alberta' },
  { city: 'Airdrie', province: 'Alberta' },
  { city: 'Beiseker', province: 'Alberta' },
  { city: 'Irricana', province: 'Alberta' },
  { city: 'Lyalta', province: 'Alberta' },
  { city: 'Buffalo Lake Settlement', province: 'Alberta' },
  { city: 'Caslan', province: 'Alberta' },
  { city: 'Kikino', province: 'Alberta' },
  { city: 'Lac La Biche', province: 'Alberta' },
  { city: 'Smoky Lake', province: 'Alberta' },
  { city: 'Vilna', province: 'Alberta' },
  { city: 'Mclennan', province: 'Alberta' },
  { city: 'Rycroft', province: 'Alberta' },
  { city: 'Spirit River', province: 'Alberta' },
  { city: 'Spruce Grove', province: 'Alberta' },
  { city: 'ALB', province: 'Alberta' },
  { city: 'St Albert', province: 'Alberta' },
  { city: 'Elk Point', province: 'Alberta' },
  { city: 'Saddle Lake', province: 'Alberta' },
  { city: 'St Paul', province: 'Alberta' },
  { city: 'Hanna', province: 'Alberta' },
  { city: 'Stettler', province: 'Alberta' },
  { city: 'Sherwood Park', province: 'Alberta' },
  { city: 'Tofield', province: 'Alberta' },
  { city: 'Campbell River', province: 'Alberta' },
  { city: 'Gold River', province: 'Alberta' },
  { city: 'Quadra Island', province: 'Alberta' },
  { city: 'Quathiaski Cove', province: 'Alberta' },
  { city: 'Squirrel Cove', province: 'Alberta' },
  { city: 'Tahsis', province: 'Alberta' },
  { city: 'Zeballos', province: 'Alberta' },
  { city: 'Carbon', province: 'Alberta' },
  { city: 'Linden', province: 'Alberta' },
  { city: 'Siksika', province: 'Alberta' },
  { city: 'Gibbons', province: 'Alberta' },
  { city: 'Lancaster Park', province: 'Alberta' },
  { city: 'Morinville', province: 'Alberta' },
  { city: 'Taber', province: 'Alberta' },
  { city: 'Vauxhall', province: 'Alberta' },
  { city: 'Redwater', province: 'Alberta' },
  { city: 'Thorhild', province: 'Alberta' },
  { city: 'Two Hills', province: 'Alberta' },
  { city: 'Blackfoot', province: 'Alberta' },
  { city: 'Kitscoty', province: 'Alberta' },
  { city: 'Vermilion', province: 'Alberta' },
  { city: 'Vulcan', province: 'Alberta' },
  { city: 'Denwood', province: 'Alberta' },
  { city: 'Hardisty', province: 'Alberta' },
  { city: 'Wainwright', province: 'Alberta' },
  { city: 'Foremost', province: 'Alberta' },
  { city: 'Milk River', province: 'Alberta' },
  { city: 'Raymond', province: 'Alberta' },
  { city: 'Westlock', province: 'Alberta' },
  { city: 'Alder Flats', province: 'Alberta' },
  { city: 'Wetaskiwin', province: 'Alberta' },
  { city: 'Winfield', province: 'Alberta' },
  { city: 'Carseland', province: 'Alberta' },
  { city: 'Cluny', province: 'Alberta' },
  { city: 'Gleichen', province: 'Alberta' },
  { city: 'Hussar', province: 'Alberta' },
  { city: 'Redcliff', province: 'Alberta' },
  { city: 'Rockyford', province: 'Alberta' },
  { city: 'Strathmore', province: 'Alberta' },
  { city: 'Claresholm', province: 'Alberta' },
  { city: 'Fort Macleod', province: 'Alberta' },
  { city: 'Granum', province: 'Alberta' },
  { city: 'Nanton', province: 'Alberta' },
  { city: 'Pincher Creek', province: 'Alberta' },
  { city: 'Anzac', province: 'Alberta' },
  { city: 'Fort Chipewyan', province: 'Alberta' },
  { city: 'Fort Mcmurray', province: 'Alberta' },
  { city: 'Frog Lake', province: 'Alberta' },
  { city: 'Goodfish Lake', province: 'Alberta' },
  { city: 'Kehewin', province: 'Alberta' },
  { city: 'Peers', province: 'Alberta' },
  { city: 'Whitecourt', province: 'Alberta' },
  { city: 'Edson', province: 'Alberta' },
  { city: 'Hinton', province: 'Alberta' },
  { city: 'Ahousat', province: 'British Columbia' },
  { city: 'Bamfield', province: 'British Columbia' },
  { city: 'Kyuquot', province: 'British Columbia' },
  { city: 'Port Alberni', province: 'British Columbia' },
  { city: 'Tofino', province: 'British Columbia' },
  { city: 'Ucluelet', province: 'British Columbia' },
  { city: 'Burns Lake', province: 'British Columbia' },
  { city: 'Fort Fraser', province: 'British Columbia' },
  { city: 'Fort St James', province: 'British Columbia' },
  { city: 'Fraser Lake', province: 'British Columbia' },
  { city: 'Granisle', province: 'British Columbia' },
  { city: 'Houston', province: 'British Columbia' },
  { city: 'Smithers', province: 'British Columbia' },
  { city: 'Southbank', province: 'British Columbia' },
  { city: 'Takla Landing', province: 'British Columbia' },
  { city: 'Vanderhoof', province: 'British Columbia' },
  { city: 'Brentwood Bay', province: 'British Columbia' },
  { city: 'Esquimalt', province: 'British Columbia' },
  { city: 'Galiano Island', province: 'British Columbia' },
  { city: 'Langford', province: 'British Columbia' },
  { city: 'Mayne Island', province: 'British Columbia' },
  { city: 'North Saanich', province: 'British Columbia' },
  { city: 'Port Renfrew', province: 'British Columbia' },
  { city: 'Saanich', province: 'British Columbia' },
  { city: 'Saanichton', province: 'British Columbia' },
  { city: 'Salt Spring Island', province: 'British Columbia' },
  { city: 'Saltspring Island', province: 'British Columbia' },
  { city: 'Sidney', province: 'British Columbia' },
  { city: 'Sooke', province: 'British Columbia' },
  { city: 'Victoria', province: 'British Columbia' },
  { city: '100 Mile House', province: 'British Columbia' },
  { city: 'Alexis Creek', province: 'British Columbia' },
  { city: 'Alkali Lake', province: 'British Columbia' },
  { city: 'Anahim Lake', province: 'British Columbia' },
  { city: 'Canim Lake', province: 'British Columbia' },
  { city: 'Chilanko Forks', province: 'British Columbia' },
  { city: 'Dog Creek', province: 'British Columbia' },
  { city: 'Hanceville', province: 'British Columbia' },
  { city: 'Nemaiah Valley', province: 'British Columbia' },
  { city: 'Quesnel', province: 'British Columbia' },
  { city: 'Riske Creek', province: 'British Columbia' },
  { city: 'Tatla Lake', province: 'British Columbia' },
  { city: 'Williams Lake', province: 'British Columbia' },
  { city: 'Bella Bella', province: 'British Columbia' },
  { city: 'Bella Coola', province: 'British Columbia' },
  { city: 'Klemtu', province: 'British Columbia' },
  { city: 'Waglisla', province: 'British Columbia' },
  { city: 'Castlegar', province: 'British Columbia' },
  { city: 'Crawford Bay', province: 'British Columbia' },
  { city: 'Crescent Valley', province: 'British Columbia' },
  { city: 'Creston', province: 'British Columbia' },
  { city: 'Edgewood', province: 'British Columbia' },
  { city: 'Kaslo', province: 'British Columbia' },
  { city: 'Nakusp', province: 'British Columbia' },
  { city: 'Nelson', province: 'British Columbia' },
  { city: 'New Denver', province: 'British Columbia' },
  { city: 'Salmo', province: 'British Columbia' },
  { city: 'Slocan', province: 'British Columbia' },
  { city: 'Kelowna', province: 'British Columbia' },
  { city: 'Peachland', province: 'British Columbia' },
  { city: 'West Kelowna', province: 'British Columbia' },
  { city: 'Westbank', province: 'British Columbia' },
  { city: 'Anglemont', province: 'British Columbia' },
  { city: 'Douglas Lake', province: 'British Columbia' },
  { city: 'Golden', province: 'British Columbia' },
  { city: 'Revelstoke', province: 'British Columbia' },
  { city: 'Salmon Arm', province: 'British Columbia' },
  { city: 'Sicamous', province: 'British Columbia' },
  { city: 'Sorrento', province: 'British Columbia' },
  { city: 'Comox', province: 'British Columbia' },
  { city: 'Courtenay', province: 'British Columbia' },
  { city: 'Denman Island', province: 'British Columbia' },
  { city: 'Hornby Island', province: 'British Columbia' },
  { city: 'Lazo', province: 'British Columbia' },
  { city: 'Chemainus', province: 'British Columbia' },
  { city: 'Cowichan Bay', province: 'British Columbia' },
  { city: 'Duncan', province: 'British Columbia' },
  { city: 'Ladysmith', province: 'British Columbia' },
  { city: 'Lake Cowichan', province: 'British Columbia' },
  { city: 'Mill Bay', province: 'British Columbia' },
  { city: 'Athalmer', province: 'British Columbia' },
  { city: 'Cranbrook', province: 'British Columbia' },
  { city: 'Elkford', province: 'British Columbia' },
  { city: 'Fernie', province: 'British Columbia' },
  { city: 'Grasmere', province: 'British Columbia' },
  { city: 'Invermere', province: 'British Columbia' },
  { city: 'Kimberley', province: 'British Columbia' },
  { city: 'Sparwood', province: 'British Columbia' },
  { city: 'Windermere', province: 'British Columbia' },
  { city: 'Abbotsford', province: 'British Columbia' },
  { city: 'Agassiz', province: 'British Columbia' },
  { city: 'Boston Bar', province: 'British Columbia' },
  { city: 'Chilliwack', province: 'British Columbia' },
  { city: 'Cultus Lake', province: 'British Columbia' },
  { city: 'Deroche', province: 'British Columbia' },
  { city: 'Hope', province: 'British Columbia' },
  { city: 'Lake Errock', province: 'British Columbia' },
  { city: 'Mission', province: 'British Columbia' },
  { city: 'Rosedale', province: 'British Columbia' },
  { city: 'Sardis', province: 'British Columbia' },
  { city: 'Spuzzum', province: 'British Columbia' },
  { city: 'Mackenzie', province: 'British Columbia' },
  { city: 'Mcbride', province: 'British Columbia' },
  { city: 'Mcleod Lake', province: 'British Columbia' },
  { city: 'Prince George', province: 'British Columbia' },
  { city: 'Valemount', province: 'British Columbia' },
  { city: 'Aiyansh', province: 'British Columbia' },
  { city: 'Gitanmaax', province: 'British Columbia' },
  { city: 'Haisla', province: 'British Columbia' },
  { city: 'Hartley Bay', province: 'British Columbia' },
  { city: 'Hazelton', province: 'British Columbia' },
  { city: 'Kispiox', province: 'British Columbia' },
  { city: 'Kitamaat Village', province: 'British Columbia' },
  { city: 'Kitimat', province: 'British Columbia' },
  { city: 'Kitwanga', province: 'British Columbia' },
  { city: 'New Aiyansh', province: 'British Columbia' },
  { city: 'New Hazelton', province: 'British Columbia' },
  { city: 'Sik-E-Dakh', province: 'British Columbia' },
  { city: 'South Hazelton', province: 'British Columbia' },
  { city: 'Stewart', province: 'British Columbia' },
  { city: 'Telegraph Creek', province: 'British Columbia' },
  { city: 'Terrace', province: 'British Columbia' },
  { city: 'Grand Forks', province: 'British Columbia' },
  { city: 'Midway', province: 'British Columbia' },
  { city: 'Rock Creek', province: 'British Columbia' },
  { city: 'Trail', province: 'British Columbia' },
  { city: 'Aldergrove', province: 'British Columbia' },
  { city: 'Bowen Island', province: 'British Columbia' },
  { city: 'Burnaby', province: 'British Columbia' },
  { city: 'Coquitlam', province: 'British Columbia' },
  { city: 'Langley', province: 'British Columbia' },
  { city: 'Maple Ridge', province: 'British Columbia' },
  { city: 'New Westminster', province: 'British Columbia' },
  { city: 'North Vancouver', province: 'British Columbia' },
  { city: 'Pitt Meadows', province: 'British Columbia' },
  { city: 'Port Coquitlam', province: 'British Columbia' },
  { city: 'Port Moody', province: 'British Columbia' },
  { city: 'Richmond', province: 'British Columbia' },
  { city: 'Surrey', province: 'British Columbia' },
  { city: 'Vancouver', province: 'British Columbia' },
  { city: 'West Vancouver', province: 'British Columbia' },
  { city: 'White Rock', province: 'British Columbia' },
  { city: 'Alert Bay', province: 'British Columbia' },
  { city: 'Coal Harbour', province: 'British Columbia' },
  { city: 'Kingcome Inlet', province: 'British Columbia' },
  { city: 'Port Alice', province: 'British Columbia' },
  { city: 'Port Hardy', province: 'British Columbia' },
  { city: 'Port Mcneill', province: 'British Columbia' },
  { city: 'Port NcNeill', province: 'British Columbia' },
  { city: 'Sointula', province: 'British Columbia' },
  { city: 'Gabriola Island', province: 'British Columbia' },
  { city: 'Lantzville', province: 'British Columbia' },
  { city: 'Nanaimo', province: 'British Columbia' },
  { city: 'Parksville', province: 'British Columbia' },
  { city: 'Armstrong', province: 'British Columbia' },
  { city: 'Coldstream', province: 'British Columbia' },
  { city: 'Enderby', province: 'British Columbia' },
  { city: 'Lumby', province: 'British Columbia' },
  { city: 'Vernon', province: 'British Columbia' },
  { city: 'Fort Nelson', province: 'British Columbia' },
  { city: 'Cawston', province: 'British Columbia' },
  { city: 'Hedley', province: 'British Columbia' },
  { city: 'Keremeos', province: 'British Columbia' },
  { city: 'Lake Country', province: 'British Columbia' },
  { city: 'Oliver', province: 'British Columbia' },
  { city: 'Osoyoos', province: 'British Columbia' },
  { city: 'Penticton', province: 'British Columbia' },
  { city: 'Summerland', province: 'British Columbia' },
  { city: 'West Kootenays', province: 'British Columbia' },
  { city: 'Buick', province: 'British Columbia' },
  { city: 'Chetwynd', province: 'British Columbia' },
  { city: 'Dawson Creek', province: 'British Columbia' },
  { city: 'Farmington', province: 'British Columbia' },
  { city: 'Fort St John', province: 'British Columbia' },
  { city: 'Hudsons Hope', province: 'British Columbia' },
  { city: 'Moberly Lake', province: 'British Columbia' },
  { city: 'Rose Prairie', province: 'British Columbia' },
  { city: 'Tumbler Ridge', province: 'British Columbia' },
  { city: 'Ware', province: 'British Columbia' },
  { city: 'Wonowon', province: 'British Columbia' },
  { city: 'Powell River', province: 'British Columbia' },
  { city: 'Daajing Giids', province: 'British Columbia' },
  { city: 'Gitwinksihlkw', province: 'British Columbia' },
  { city: 'Greenville', province: 'British Columbia' },
  { city: 'Kincolith', province: 'British Columbia' },
  { city: 'Kitkatla', province: 'British Columbia' },
  { city: "Lax Kw'alaams", province: 'British Columbia' },
  { city: 'Masset', province: 'British Columbia' },
  { city: 'Prince Rupert', province: 'British Columbia' },
  { city: "D'arcy", province: 'British Columbia' },
  { city: 'Darcy', province: 'British Columbia' },
  { city: 'Lillooet', province: 'British Columbia' },
  { city: 'Mount Currie', province: 'British Columbia' },
  { city: 'Pemberton', province: 'British Columbia' },
  { city: 'Shalalth', province: 'British Columbia' },
  { city: 'Squamish', province: 'British Columbia' },
  { city: 'Whistler', province: 'British Columbia' },
  { city: 'Atlin', province: 'British Columbia' },
  { city: 'Dease Lake', province: 'British Columbia' },
  { city: 'Good Hope Lake', province: 'British Columbia' },
  { city: 'Iskut', province: 'British Columbia' },
  { city: 'Sherwood Park', province: 'British Columbia' },
  { city: 'Tofield', province: 'British Columbia' },
  { city: 'Campbell River', province: 'British Columbia' },
  { city: 'Gold River', province: 'British Columbia' },
  { city: 'Quadra Island', province: 'British Columbia' },
  { city: 'Quathiaski Cove', province: 'British Columbia' },
  { city: 'Squirrel Cove', province: 'British Columbia' },
  { city: 'Tahsis', province: 'British Columbia' },
  { city: 'Zeballos', province: 'British Columbia' },
  { city: 'Garden Bay', province: 'British Columbia' },
  { city: 'Gibsons', province: 'British Columbia' },
  { city: 'Madeira Park', province: 'British Columbia' },
  { city: 'Sechelt', province: 'British Columbia' },
  { city: 'Ashcroft', province: 'British Columbia' },
  { city: 'Barriere', province: 'British Columbia' },
  { city: 'Blue River', province: 'British Columbia' },
  { city: 'Cache Creek', province: 'British Columbia' },
  { city: 'Celista', province: 'British Columbia' },
  { city: 'Chase', province: 'British Columbia' },
  { city: 'Clearwater', province: 'British Columbia' },
  { city: 'Kagawong', province: 'British Columbia' },
  { city: 'Kamloop', province: 'British Columbia' },
  { city: 'Kamloops', province: 'British Columbia' },
  { city: 'Little Fort', province: 'British Columbia' },
  { city: 'Logan Lake', province: 'British Columbia' },
  { city: 'Lytton', province: 'British Columbia' },
  { city: 'Meritt', province: 'British Columbia' },
  { city: 'Merritt', province: 'British Columbia' },
  { city: 'Savona', province: 'British Columbia' },
  { city: 'Spences Bridge', province: 'British Columbia' },
  { city: 'Walhachin', province: 'British Columbia' },
  { city: '108 Mile House', province: 'British Columbia' },
  { city: 'Aggassiz', province: 'British Columbia' },
  { city: 'Esquimalt', province: 'British Columbia' },
  { city: 'Qualicum', province: 'British Columbia' },
  { city: 'Opaskwayak', province: 'British Columbia' },
  { city: 'Eel river', province: 'British Columbia' },
  { city: 'Sackville', province: 'British Columbia' },
  { city: "Lutsel k''e", province: 'British Columbia' },
  { city: 'Rae lakes', province: 'British Columbia' },
  { city: 'Millbrook', province: 'British Columbia' },
  { city: 'Bloomfield Station', province: 'British Columbia' },
  { city: 'Coleman', province: 'British Columbia' },
  { city: 'St-Peters Bay', province: 'British Columbia' },
  { city: 'Beauport', province: 'British Columbia' },
  { city: 'Beaupre', province: 'British Columbia' },
  { city: 'Bernieres', province: 'British Columbia' },
  { city: 'Black lake,qu', province: 'British Columbia' },
  { city: 'Chateauguay', province: 'British Columbia' },
  { city: 'Dolbeau', province: 'British Columbia' },
  { city: 'Fatima', province: 'British Columbia' },
  { city: 'Gascons', province: 'British Columbia' },
  { city: 'Gentilly', province: 'British Columbia' },
  { city: 'Gesgapegiag', province: 'British Columbia' },
  { city: 'Grande-Rivi re', province: 'British Columbia' },
  { city: 'Iles-de-la-madeleine', province: 'British Columbia' },
  { city: 'Kingsey-falls', province: 'British Columbia' },
  { city: 'La peche', province: 'British Columbia' },
  { city: 'Lac-st-charles', province: 'British Columbia' },
  { city: 'Laurentides', province: 'British Columbia' },
  { city: 'Le gardeur', province: 'British Columbia' },
  { city: 'Listuguj', province: 'British Columbia' },
  { city: 'Lyster', province: 'British Columbia' },
  { city: 'Pohenegamook', province: 'British Columbia' },
  { city: 'Quebec', province: 'British Columbia' },
  { city: 'Riviere-au-tonnerre', province: 'British Columbia' },
  { city: 'Riviere-Du-Loup', province: 'British Columbia' },
  { city: 'Saint-alphonse', province: 'British Columbia' },
  { city: 'Saint-basile le grand', province: 'British Columbia' },
  { city: 'Saint-elie-de-caxton', province: 'British Columbia' },
  { city: 'Saint- tienne', province: 'British Columbia' },
  { city: 'Saint-ferreol les neige', province: 'British Columbia' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'British Columbia' },
  { city: 'Creighton', province: 'British Columbia' },
  { city: 'Spiritwood', province: 'British Columbia' },
  { city: 'Alonsa', province: 'Manitoba' },
  { city: 'Dakota Tipi', province: 'Manitoba' },
  { city: 'Elie', province: 'Manitoba' },
  { city: 'Gladstone', province: 'Manitoba' },
  { city: 'Macgregor', province: 'Manitoba' },
  { city: 'Marius', province: 'Manitoba' },
  { city: 'Notre Dame De Lourdes', province: 'Manitoba' },
  { city: 'Portage La Prairie', province: 'Manitoba' },
  { city: 'Rossendale', province: 'Manitoba' },
  { city: 'Southport', province: 'Manitoba' },
  { city: 'St Claude', province: 'Manitoba' },
  { city: 'Treherne', province: 'Manitoba' },
  { city: 'Tootinaowaziibeeng', province: 'Manitoba' },
  { city: 'Beausejour', province: 'Manitoba' },
  { city: 'Berens River', province: 'Manitoba' },
  { city: 'Buffalo Point', province: 'Manitoba' },
  { city: 'Dominion City', province: 'Manitoba' },
  { city: 'Fort Alexander', province: 'Manitoba' },
  { city: 'La Broquerie', province: 'Manitoba' },
  { city: 'La broquerie,mb', province: 'Manitoba' },
  { city: 'Lac Du Bonnet', province: 'Manitoba' },
  { city: 'Little Grand Rapids', province: 'Manitoba' },
  { city: 'Negginan', province: 'Manitoba' },
  { city: "O'Hanley", province: 'Manitoba' },
  { city: "O'hanly", province: 'Manitoba' },
  { city: 'Pauingassi', province: 'Manitoba' },
  { city: 'Pinawa', province: 'Manitoba' },
  { city: 'Pine Falls', province: 'Manitoba' },
  { city: 'Powerview', province: 'Manitoba' },
  { city: 'Saint Pierre-Jolys', province: 'Manitoba' },
  { city: 'Sprague', province: 'Manitoba' },
  { city: 'St Pierre Jolys', province: 'Manitoba' },
  { city: 'Ste Anne', province: 'Manitoba' },
  { city: 'Steinbach', province: 'Manitoba' },
  { city: 'Vita', province: 'Manitoba' },
  { city: 'Wanipigow', province: 'Manitoba' },
  { city: 'Whitemouth', province: 'Manitoba' },
  { city: 'Arborg', province: 'Manitoba' },
  { city: 'Ashern', province: 'Manitoba' },
  { city: 'Bloodvein', province: 'Manitoba' },
  { city: 'Dallas', province: 'Manitoba' },
  { city: 'Eriksdale', province: 'Manitoba' },
  { city: 'Gimli', province: 'Manitoba' },
  { city: 'Gypsumville', province: 'Manitoba' },
  { city: 'Hodgson', province: 'Manitoba' },
  { city: 'Koostatak', province: 'Manitoba' },
  { city: 'Lundar', province: 'Manitoba' },
  { city: 'Peguis', province: 'Manitoba' },
  { city: 'Riverton', province: 'Manitoba' },
  { city: 'Scanterbury', province: 'Manitoba' },
  { city: 'Selkirk', province: 'Manitoba' },
  { city: 'Skownan', province: 'Manitoba' },
  { city: 'St Laurent', province: 'Manitoba' },
  { city: 'St Martin', province: 'Manitoba' },
  { city: 'Stonewall', province: 'Manitoba' },
  { city: 'Teulon', province: 'Manitoba' },
  { city: 'Warren', province: 'Manitoba' },
  { city: 'Lake Manitoba First Nation', province: 'Manitoba' },
  { city: 'Brochet', province: 'Manitoba' },
  { city: 'Churchill', province: 'Manitoba' },
  { city: 'Cormorant', province: 'Manitoba' },
  { city: 'Cranberry Portage', province: 'Manitoba' },
  { city: 'Cross Lake', province: 'Manitoba' },
  { city: 'Easterville', province: 'Manitoba' },
  { city: 'Flin Flon', province: 'Manitoba' },
  { city: 'Gillam', province: 'Manitoba' },
  { city: 'Gods Lake Narrows', province: 'Manitoba' },
  { city: 'Gods River', province: 'Manitoba' },
  { city: 'Grand Rapids', province: 'Manitoba' },
  { city: 'Ilford', province: 'Manitoba' },
  { city: 'Island Lake', province: 'Manitoba' },
  { city: 'Lac Brochet', province: 'Manitoba' },
  { city: 'Leaf Rapids', province: 'Manitoba' },
  { city: 'Lynn Lake', province: 'Manitoba' },
  { city: 'Moose Lake', province: 'Manitoba' },
  { city: 'Nelson House', province: 'Manitoba' },
  { city: 'Norway House', province: 'Manitoba' },
  { city: 'Oxford House', province: 'Manitoba' },
  { city: 'Pikwitonei', province: 'Manitoba' },
  { city: 'Pukatawagan', province: 'Manitoba' },
  { city: 'Red Sucker Lake', province: 'Manitoba' },
  { city: 'Shamattawa', province: 'Manitoba' },
  { city: 'Sherridon', province: 'Manitoba' },
  { city: 'Snow Lake', province: 'Manitoba' },
  { city: 'South Indian Lake', province: 'Manitoba' },
  { city: 'Split Lake', province: 'Manitoba' },
  { city: 'St Theresa Point', province: 'Manitoba' },
  { city: 'Tadoule Lake', province: 'Manitoba' },
  { city: 'The Pas', province: 'Manitoba' },
  { city: 'Thicket Portage', province: 'Manitoba' },
  { city: 'Thompson', province: 'Manitoba' },
  { city: 'Waasagamach', province: 'Manitoba' },
  { city: 'Waasagomach', province: 'Manitoba' },
  { city: 'Wabowden', province: 'Manitoba' },
  { city: 'York Landing', province: 'Manitoba' },
  { city: 'Old crow', province: 'Manitoba' },
  { city: 'Dawson', province: 'Manitoba' },
  { city: 'Duffield', province: 'Manitoba' },
  { city: 'Entwistle', province: 'Manitoba' },
  { city: 'Onoway', province: 'Manitoba' },
  { city: 'Stony Plain', province: 'Manitoba' },
  { city: 'Benito', province: 'Manitoba' },
  { city: 'Binscarth', province: 'Manitoba' },
  { city: 'Birch River', province: 'Manitoba' },
  { city: 'Camperville', province: 'Manitoba' },
  { city: 'Crane River', province: 'Manitoba' },
  { city: 'Dauphin', province: 'Manitoba' },
  { city: 'Duck Bay', province: 'Manitoba' },
  { city: 'Ebb And Flow', province: 'Manitoba' },
  { city: 'Erickson', province: 'Manitoba' },
  { city: 'Ethelbert', province: 'Manitoba' },
  { city: 'Gilbert Plains', province: 'Manitoba' },
  { city: 'Grandview', province: 'Manitoba' },
  { city: 'Mccreary', province: 'Manitoba' },
  { city: 'Pelican Rapids', province: 'Manitoba' },
  { city: 'Roblin', province: 'Manitoba' },
  { city: 'Rossburn', province: 'Manitoba' },
  { city: 'Russell', province: 'Manitoba' },
  { city: 'Shortdale', province: 'Manitoba' },
  { city: 'Ste Rose Du Lac', province: 'Manitoba' },
  { city: 'Ste.rose du lac,mb', province: 'Manitoba' },
  { city: 'Swan River', province: 'Manitoba' },
  { city: 'Waterhen', province: 'Manitoba' },
  { city: 'Waywayseecappo', province: 'Manitoba' },
  { city: 'Winnipegosis', province: 'Manitoba' },
  { city: 'Altona', province: 'Manitoba' },
  { city: 'Carman', province: 'Manitoba' },
  { city: 'Emerson', province: 'Manitoba' },
  { city: 'Ginew', province: 'Manitoba' },
  { city: 'Manitou', province: 'Manitoba' },
  { city: 'Morden', province: 'Manitoba' },
  { city: 'Morris', province: 'Manitoba' },
  { city: 'Rosenort', province: 'Manitoba' },
  { city: 'Somerset', province: 'Manitoba' },
  { city: 'St Jean Baptiste', province: 'Manitoba' },
  { city: 'Swan Lake', province: 'Manitoba' },
  { city: 'Winkler', province: 'Manitoba' },
  { city: 'Brokenhead Reserve', province: 'Manitoba' },
  { city: 'Niverville', province: 'Manitoba' },
  { city: 'Elva', province: 'Manitoba' },
  { city: 'Baldur', province: 'Manitoba' },
  { city: 'Beulah', province: 'Manitoba' },
  { city: 'Birtle', province: 'Manitoba' },
  { city: 'Boissevain', province: 'Manitoba' },
  { city: 'Brandon', province: 'Manitoba' },
  { city: 'Carberry', province: 'Manitoba' },
  { city: 'Crystal City', province: 'Manitoba' },
  { city: 'Deloraine', province: 'Manitoba' },
  { city: 'Elkhorn', province: 'Manitoba' },
  { city: 'Glenboro', province: 'Manitoba' },
  { city: 'Griswold', province: 'Manitoba' },
  { city: 'Hamiota', province: 'Manitoba' },
  { city: 'Hartney', province: 'Manitoba' },
  { city: 'Killarney', province: 'Manitoba' },
  { city: 'Melita', province: 'Manitoba' },
  { city: 'Minnedosa', province: 'Manitoba' },
  { city: 'Neepawa', province: 'Manitoba' },
  { city: 'Pipestone', province: 'Manitoba' },
  { city: 'Reston', province: 'Manitoba' },
  { city: 'Rivers', province: 'Manitoba' },
  { city: 'Shilo', province: 'Manitoba' },
  { city: 'Shoal Lake', province: 'Manitoba' },
  { city: 'Virden', province: 'Manitoba' },
  { city: 'Wawanesa', province: 'Manitoba' },
  { city: 'La Salle', province: 'Manitoba' },
  { city: 'Headingley', province: 'Manitoba' },
  { city: 'Oakbank', province: 'Manitoba' },
  { city: 'Winnipeg', province: 'Manitoba' },
  { city: '108 Mile House', province: 'Manitoba' },
  { city: 'Aggassiz', province: 'Manitoba' },
  { city: 'Esquimalt', province: 'Manitoba' },
  { city: 'Qualicum', province: 'Manitoba' },
  { city: 'Opaskwayak', province: 'Manitoba' },
  { city: 'Eel river', province: 'Manitoba' },
  { city: 'Sackville', province: 'Manitoba' },
  { city: "Lutsel k''e", province: 'Manitoba' },
  { city: 'Rae lakes', province: 'Manitoba' },
  { city: 'Millbrook', province: 'Manitoba' },
  { city: 'Bloomfield Station', province: 'Manitoba' },
  { city: 'Coleman', province: 'Manitoba' },
  { city: 'St-Peters Bay', province: 'Manitoba' },
  { city: 'Beauport', province: 'Manitoba' },
  { city: 'Beaupre', province: 'Manitoba' },
  { city: 'Bernieres', province: 'Manitoba' },
  { city: 'Black lake,qu', province: 'Manitoba' },
  { city: 'Chateauguay', province: 'Manitoba' },
  { city: 'Dolbeau', province: 'Manitoba' },
  { city: 'Fatima', province: 'Manitoba' },
  { city: 'Gascons', province: 'Manitoba' },
  { city: 'Gentilly', province: 'Manitoba' },
  { city: 'Gesgapegiag', province: 'Manitoba' },
  { city: 'Grande-Rivi re', province: 'Manitoba' },
  { city: 'Iles-de-la-madeleine', province: 'Manitoba' },
  { city: 'Kingsey-falls', province: 'Manitoba' },
  { city: 'La peche', province: 'Manitoba' },
  { city: 'Lac-st-charles', province: 'Manitoba' },
  { city: 'Laurentides', province: 'Manitoba' },
  { city: 'Le gardeur', province: 'Manitoba' },
  { city: 'Listuguj', province: 'Manitoba' },
  { city: 'Lyster', province: 'Manitoba' },
  { city: 'Pohenegamook', province: 'Manitoba' },
  { city: 'Quebec', province: 'Manitoba' },
  { city: 'Riviere-au-tonnerre', province: 'Manitoba' },
  { city: 'Riviere-Du-Loup', province: 'Manitoba' },
  { city: 'Saint-alphonse', province: 'Manitoba' },
  { city: 'Saint-basile le grand', province: 'Manitoba' },
  { city: 'Saint-elie-de-caxton', province: 'Manitoba' },
  { city: 'Saint- tienne', province: 'Manitoba' },
  { city: 'Saint-ferreol les neige', province: 'Manitoba' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Manitoba' },
  { city: 'Creighton', province: 'Manitoba' },
  { city: 'Spiritwood', province: 'Manitoba' },
  { city: 'Beausejour', province: 'undefined' },
  { city: 'Berens River', province: 'undefined' },
  { city: 'Buffalo Point', province: 'undefined' },
  { city: 'Dominion City', province: 'undefined' },
  { city: 'Fort Alexander', province: 'undefined' },
  { city: 'La Broquerie', province: 'undefined' },
  { city: 'La broquerie,mb', province: 'undefined' },
  { city: 'Lac Du Bonnet', province: 'undefined' },
  { city: 'Little Grand Rapids', province: 'undefined' },
  { city: 'Negginan', province: 'undefined' },
  { city: "O'Hanley", province: 'undefined' },
  { city: "O'hanly", province: 'undefined' },
  { city: 'Pauingassi', province: 'undefined' },
  { city: 'Pinawa', province: 'undefined' },
  { city: 'Pine Falls', province: 'undefined' },
  { city: 'Powerview', province: 'undefined' },
  { city: 'Saint Pierre-Jolys', province: 'undefined' },
  { city: 'Sprague', province: 'undefined' },
  { city: 'St Pierre Jolys', province: 'undefined' },
  { city: 'Ste Anne', province: 'undefined' },
  { city: 'Steinbach', province: 'undefined' },
  { city: 'Vita', province: 'undefined' },
  { city: 'Wanipigow', province: 'undefined' },
  { city: 'Whitemouth', province: 'undefined' },
  { city: 'Duffield', province: 'undefined' },
  { city: 'Entwistle', province: 'undefined' },
  { city: 'Onoway', province: 'undefined' },
  { city: 'Stony Plain', province: 'undefined' },
  { city: 'Benito', province: 'undefined' },
  { city: 'Binscarth', province: 'undefined' },
  { city: 'Birch River', province: 'undefined' },
  { city: 'Camperville', province: 'undefined' },
  { city: 'Crane River', province: 'undefined' },
  { city: 'Dauphin', province: 'undefined' },
  { city: 'Duck Bay', province: 'undefined' },
  { city: 'Ebb And Flow', province: 'undefined' },
  { city: 'Erickson', province: 'undefined' },
  { city: 'Ethelbert', province: 'undefined' },
  { city: 'Gilbert Plains', province: 'undefined' },
  { city: 'Grandview', province: 'undefined' },
  { city: 'Mccreary', province: 'undefined' },
  { city: 'Pelican Rapids', province: 'undefined' },
  { city: 'Roblin', province: 'undefined' },
  { city: 'Rossburn', province: 'undefined' },
  { city: 'Russell', province: 'undefined' },
  { city: 'Shortdale', province: 'undefined' },
  { city: 'Ste Rose Du Lac', province: 'undefined' },
  { city: 'Ste.rose du lac,mb', province: 'undefined' },
  { city: 'Swan River', province: 'undefined' },
  { city: 'Waterhen', province: 'undefined' },
  { city: 'Waywayseecappo', province: 'undefined' },
  { city: 'Winnipegosis', province: 'undefined' },
  { city: 'Altona', province: 'undefined' },
  { city: 'Carman', province: 'undefined' },
  { city: 'Emerson', province: 'undefined' },
  { city: 'Ginew', province: 'undefined' },
  { city: 'Manitou', province: 'undefined' },
  { city: 'Morden', province: 'undefined' },
  { city: 'Morris', province: 'undefined' },
  { city: 'Rosenort', province: 'undefined' },
  { city: 'Somerset', province: 'undefined' },
  { city: 'St Jean Baptiste', province: 'undefined' },
  { city: 'Swan Lake', province: 'undefined' },
  { city: 'Winkler', province: 'undefined' },
  { city: 'New York', province: 'undefined' },
  { city: 'Watson lake', province: 'undefined' },
  { city: 'Faro', province: 'undefined' },
  { city: 'Ross River', province: 'undefined' },
  { city: 'Brochet', province: 'undefined' },
  { city: 'Churchill', province: 'undefined' },
  { city: 'Cormorant', province: 'undefined' },
  { city: 'Cranberry Portage', province: 'undefined' },
  { city: 'Cross Lake', province: 'undefined' },
  { city: 'Easterville', province: 'undefined' },
  { city: 'Flin Flon', province: 'undefined' },
  { city: 'Gillam', province: 'undefined' },
  { city: 'Gods Lake Narrows', province: 'undefined' },
  { city: 'Gods River', province: 'undefined' },
  { city: 'Grand Rapids', province: 'undefined' },
  { city: 'Ilford', province: 'undefined' },
  { city: 'Island Lake', province: 'undefined' },
  { city: 'Lac Brochet', province: 'undefined' },
  { city: 'Leaf Rapids', province: 'undefined' },
  { city: 'Lynn Lake', province: 'undefined' },
  { city: 'Moose Lake', province: 'undefined' },
  { city: 'Nelson House', province: 'undefined' },
  { city: 'Norway House', province: 'undefined' },
  { city: 'Oxford House', province: 'undefined' },
  { city: 'Pikwitonei', province: 'undefined' },
  { city: 'Pukatawagan', province: 'undefined' },
  { city: 'Red Sucker Lake', province: 'undefined' },
  { city: 'Shamattawa', province: 'undefined' },
  { city: 'Sherridon', province: 'undefined' },
  { city: 'Snow Lake', province: 'undefined' },
  { city: 'South Indian Lake', province: 'undefined' },
  { city: 'Split Lake', province: 'undefined' },
  { city: 'St Theresa Point', province: 'undefined' },
  { city: 'Tadoule Lake', province: 'undefined' },
  { city: 'The Pas', province: 'undefined' },
  { city: 'Thicket Portage', province: 'undefined' },
  { city: 'Thompson', province: 'undefined' },
  { city: 'Waasagamach', province: 'undefined' },
  { city: 'Waasagomach', province: 'undefined' },
  { city: 'Wabowden', province: 'undefined' },
  { city: 'York Landing', province: 'undefined' },
  { city: 'Old crow', province: 'undefined' },
  { city: 'Dawson', province: 'undefined' },
  { city: 'Hillsborough', province: 'New Brunswick' },
  { city: 'Riverside-Albert', province: 'New Brunswick' },
  { city: 'Riverview', province: 'New Brunswick' },
  { city: 'Bristol', province: 'New Brunswick' },
  { city: 'Hartland', province: 'New Brunswick' },
  { city: 'Waterville Carleton Co', province: 'New Brunswick' },
  { city: 'Woodstock First Nation', province: 'New Brunswick' },
  { city: 'Woodstock,nb', province: 'New Brunswick' },
  { city: 'Blacks Harbour', province: 'New Brunswick' },
  { city: 'Fairhaven', province: 'New Brunswick' },
  { city: 'Grand Manan', province: 'New Brunswick' },
  { city: 'Oak Bay', province: 'New Brunswick' },
  { city: 'Old Ridge', province: 'New Brunswick' },
  { city: 'Richardson', province: 'New Brunswick' },
  { city: 'St George', province: 'New Brunswick' },
  { city: 'St Stephen', province: 'New Brunswick' },
  { city: 'Welshpool', province: 'New Brunswick' },
  { city: 'St. Andrews', province: 'New Brunswick' },
  { city: 'St. Stephen', province: 'New Brunswick' },
  { city: 'St.stephen', province: 'New Brunswick' },
  { city: 'Ststephen', province: 'New Brunswick' },
  { city: 'Allardville', province: 'New Brunswick' },
  { city: 'Bathurst', province: 'New Brunswick' },
  { city: 'Beresford', province: 'New Brunswick' },
  { city: 'Bertrand', province: 'New Brunswick' },
  { city: 'Caraquet', province: 'New Brunswick' },
  { city: 'Lameque', province: 'New Brunswick' },
  { city: 'Lam que', province: 'New Brunswick' },
  { city: 'Miscou', province: 'New Brunswick' },
  { city: 'Pabineau First Nation', province: 'New Brunswick' },
  { city: 'Paquetville', province: 'New Brunswick' },
  { city: 'Pointe-Verte', province: 'New Brunswick' },
  { city: 'Saint-Isidore', province: 'New Brunswick' },
  { city: 'Shippagan', province: 'New Brunswick' },
  { city: 'Tracadie Sheila', province: 'New Brunswick' },
  { city: 'Tracadie-Sheila', province: 'New Brunswick' },
  { city: 'Tracadie', province: 'New Brunswick' },
  { city: 'Bouctouche', province: 'New Brunswick' },
  { city: 'Bouctouche Reserve', province: 'New Brunswick' },
  { city: 'Elsipogtog First Nation', province: 'New Brunswick' },
  { city: 'Indian Island', province: 'New Brunswick' },
  { city: 'Rexton', province: 'New Brunswick' },
  { city: 'Richibucto', province: 'New Brunswick' },
  { city: 'Saint-Paul', province: 'New Brunswick' },
  { city: 'Sainte-Anne-De-Kent', province: 'New Brunswick' },
  { city: 'Hampton', province: 'New Brunswick' },
  { city: 'Norton', province: 'New Brunswick' },
  { city: 'Quispamsis', province: 'New Brunswick' },
  { city: 'Rothesay', province: 'New Brunswick' },
  { city: 'Sussex', province: 'New Brunswick' },
  { city: 'Auburn', province: 'New Brunswick' },
  { city: 'Berwick', province: 'New Brunswick' },
  { city: 'Cambridge Station', province: 'New Brunswick' },
  { city: 'Canning', province: 'New Brunswick' },
  { city: 'Coldbrook', province: 'New Brunswick' },
  { city: 'Greenwood', province: 'New Brunswick' },
  { city: 'Kentville', province: 'New Brunswick' },
  { city: 'Waterville', province: 'New Brunswick' },
  { city: 'Wolfville', province: 'New Brunswick' },
  { city: 'Annandale', province: 'New Brunswick' },
  { city: 'Lennox Island', province: 'New Brunswick' },
  { city: 'Lower Montague', province: 'New Brunswick' },
  { city: 'Montague', province: 'New Brunswick' },
  { city: 'Murray Harbour', province: 'New Brunswick' },
  { city: 'Souris', province: 'New Brunswick' },
  { city: 'Clair', province: 'New Brunswick' },
  { city: 'Edmundston', province: 'New Brunswick' },
  { city: 'Madawaska Maliseet First Nation', province: 'New Brunswick' },
  { city: 'Saint-Andre', province: 'New Brunswick' },
  { city: 'Sainte-Anne-De-Madawaska', province: 'New Brunswick' },
  { city: 'Blackville', province: 'New Brunswick' },
  { city: 'Boiestown', province: 'New Brunswick' },
  { city: 'Burnt Church First Nation', province: 'New Brunswick' },
  { city: 'Doaktown', province: 'New Brunswick' },
  { city: 'Eel Ground', province: 'New Brunswick' },
  { city: 'Miramichi', province: 'New Brunswick' },
  { city: 'Neguac', province: 'New Brunswick' },
  { city: 'Red Bank', province: 'New Brunswick' },
  { city: 'Rogersville', province: 'New Brunswick' },
  { city: 'Bewdley', province: 'New Brunswick' },
  { city: 'Brighton', province: 'New Brunswick' },
  { city: 'Campbellford', province: 'New Brunswick' },
  { city: 'Cobourg', province: 'New Brunswick' },
  { city: 'Colborne', province: 'New Brunswick' },
  { city: 'Port Hope', province: 'New Brunswick' },
  { city: 'Roseneath', province: 'New Brunswick' },
  { city: 'Chipman', province: 'New Brunswick' },
  { city: 'Caledonia', province: 'New Brunswick' },
  { city: 'Liverpool', province: 'New Brunswick' },
  { city: 'Charlottetown', province: 'New Brunswick' },
  { city: 'Hunter River', province: 'New Brunswick' },
  { city: 'Mount Stewart', province: 'New Brunswick' },
  { city: 'North Rustico', province: 'New Brunswick' },
  { city: 'Minto,nb', province: 'New Brunswick' },
  { city: 'Belledune', province: 'New Brunswick' },
  { city: 'Campbellton', province: 'New Brunswick' },
  { city: 'Dalhousie', province: 'New Brunswick' },
  { city: 'Eel River Bar First Nation', province: 'New Brunswick' },
  { city: 'Kedgwick', province: 'New Brunswick' },
  { city: 'Saint-Quentin', province: 'New Brunswick' },
  { city: 'Grand bay', province: 'New Brunswick' },
  { city: 'St.john (saint john,nb)', province: 'New Brunswick' },
  { city: 'Saint John', province: 'New Brunswick' },
  { city: 'Oromocto', province: 'New Brunswick' },
  { city: 'Grand Falls', province: 'New Brunswick' },
  { city: 'Grand-Sault/Grand Falls', province: 'New Brunswick' },
  { city: 'Perth-Andover', province: 'New Brunswick' },
  { city: 'Plaster Rock', province: 'New Brunswick' },
  { city: 'Tobique First Nation', province: 'New Brunswick' },
  { city: 'Baddeck', province: 'New Brunswick' },
  { city: 'Ingonish', province: 'New Brunswick' },
  { city: 'Ingonish Beach', province: 'New Brunswick' },
  { city: 'Neils Harbour', province: 'New Brunswick' },
  { city: 'Wagmatcook', province: 'New Brunswick' },
  { city: 'Grand falls (grand-sault)', province: 'New Brunswick' },
  { city: 'Cap-Pele', province: 'New Brunswick' },
  { city: 'Port elgin,nb', province: 'New Brunswick' },
  { city: 'Dieppe', province: 'New Brunswick' },
  { city: 'Dorchester', province: 'New Brunswick' },
  { city: 'Memramcook', province: 'New Brunswick' },
  { city: 'Moncton', province: 'New Brunswick' },
  { city: 'Petitcodiac', province: 'New Brunswick' },
  { city: 'Shediac', province: 'New Brunswick' },
  { city: 'Fredericton', province: 'New Brunswick' },
  { city: 'Harvey Station', province: 'New Brunswick' },
  { city: 'Kingsclear First Nation', province: 'New Brunswick' },
  { city: 'Kingsley', province: 'New Brunswick' },
  { city: 'Mcadam', province: 'New Brunswick' },
  { city: 'Nackawic', province: 'New Brunswick' },
  { city: 'Scotch Lake', province: 'New Brunswick' },
  { city: 'St Croix', province: 'New Brunswick' },
  { city: 'Stanley', province: 'New Brunswick' },
  { city: 'Aurora', province: 'New Brunswick' },
  { city: 'Concord', province: 'New Brunswick' },
  { city: 'Holland Landing', province: 'New Brunswick' },
  { city: 'Keswick', province: 'New Brunswick' },
  { city: 'King City', province: 'New Brunswick' },
  { city: 'Maple', province: 'New Brunswick' },
  { city: 'Markham', province: 'New Brunswick' },
  { city: 'Newmarket', province: 'New Brunswick' },
  { city: 'Richmond Hill', province: 'New Brunswick' },
  { city: 'Stouffville', province: 'New Brunswick' },
  { city: 'Sutton West', province: 'New Brunswick' },
  { city: 'Thornhill', province: 'New Brunswick' },
  { city: 'Vaughan', province: 'New Brunswick' },
  { city: 'Woodbridge', province: 'New Brunswick' },
  { city: 'Fredicton', province: 'New Brunswick' },
  { city: 'Fredricton', province: 'New Brunswick' },
  { city: '108 Mile House', province: 'New Brunswick' },
  { city: 'Aggassiz', province: 'New Brunswick' },
  { city: 'Esquimalt', province: 'New Brunswick' },
  { city: 'Qualicum', province: 'New Brunswick' },
  { city: 'Opaskwayak', province: 'New Brunswick' },
  { city: 'Eel river', province: 'New Brunswick' },
  { city: 'Sackville', province: 'New Brunswick' },
  { city: "Lutsel k''e", province: 'New Brunswick' },
  { city: 'Rae lakes', province: 'New Brunswick' },
  { city: 'Millbrook', province: 'New Brunswick' },
  { city: 'Bloomfield Station', province: 'New Brunswick' },
  { city: 'Coleman', province: 'New Brunswick' },
  { city: 'St-Peters Bay', province: 'New Brunswick' },
  { city: 'Beauport', province: 'New Brunswick' },
  { city: 'Beaupre', province: 'New Brunswick' },
  { city: 'Bernieres', province: 'New Brunswick' },
  { city: 'Black lake,qu', province: 'New Brunswick' },
  { city: 'Chateauguay', province: 'New Brunswick' },
  { city: 'Dolbeau', province: 'New Brunswick' },
  { city: 'Fatima', province: 'New Brunswick' },
  { city: 'Gascons', province: 'New Brunswick' },
  { city: 'Gentilly', province: 'New Brunswick' },
  { city: 'Gesgapegiag', province: 'New Brunswick' },
  { city: 'Grande-Rivi re', province: 'New Brunswick' },
  { city: 'Iles-de-la-madeleine', province: 'New Brunswick' },
  { city: 'Kingsey-falls', province: 'New Brunswick' },
  { city: 'La peche', province: 'New Brunswick' },
  { city: 'Lac-st-charles', province: 'New Brunswick' },
  { city: 'Laurentides', province: 'New Brunswick' },
  { city: 'Le gardeur', province: 'New Brunswick' },
  { city: 'Listuguj', province: 'New Brunswick' },
  { city: 'Lyster', province: 'New Brunswick' },
  { city: 'Pohenegamook', province: 'New Brunswick' },
  { city: 'Quebec', province: 'New Brunswick' },
  { city: 'Riviere-au-tonnerre', province: 'New Brunswick' },
  { city: 'Riviere-Du-Loup', province: 'New Brunswick' },
  { city: 'Saint-alphonse', province: 'New Brunswick' },
  { city: 'Saint-basile le grand', province: 'New Brunswick' },
  { city: 'Saint-elie-de-caxton', province: 'New Brunswick' },
  { city: 'Saint- tienne', province: 'New Brunswick' },
  { city: 'Saint-ferreol les neige', province: 'New Brunswick' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'New Brunswick' },
  { city: 'Creighton', province: 'New Brunswick' },
  { city: 'Spiritwood', province: 'New Brunswick' },
  { city: "Bay Robert's", province: 'Newfoundland and Labrador' },
  { city: 'Bay Roberts', province: 'Newfoundland and Labrador' },
  { city: 'Bell Island', province: 'Newfoundland and Labrador' },
  { city: 'Carbonear', province: 'Newfoundland and Labrador' },
  { city: 'Conception bay', province: 'Newfoundland and Labrador' },
  { city: 'Conception Bay South', province: 'Newfoundland and Labrador' },
  { city: 'Dunville', province: 'Newfoundland and Labrador' },
  { city: 'Ferryland', province: 'Newfoundland and Labrador' },
  { city: 'Goulds', province: 'Newfoundland and Labrador' },
  { city: 'Harbour Grace', province: 'Newfoundland and Labrador' },
  { city: 'Holyrood', province: 'Newfoundland and Labrador' },
  { city: 'Mobile', province: 'Newfoundland and Labrador' },
  { city: 'Mount Pearl', province: 'Newfoundland and Labrador' },
  { city: 'Old Perlican', province: 'Newfoundland and Labrador' },
  { city: 'Placentia', province: 'Newfoundland and Labrador' },
  { city: 'Shea Heights', province: 'Newfoundland and Labrador' },
  { city: "St John's", province: 'Newfoundland and Labrador' },
  { city: 'St Johns', province: 'Newfoundland and Labrador' },
  { city: "St. John's", province: 'Newfoundland and Labrador' },
  { city: 'Torbay', province: 'Newfoundland and Labrador' },
  { city: 'Trepassey', province: 'Newfoundland and Labrador' },
  { city: 'Whitbourne', province: 'Newfoundland and Labrador' },
  { city: 'Witless Bay', province: 'Newfoundland and Labrador' },
  { city: 'Baie Verte', province: 'Newfoundland and Labrador' },
  { city: 'Belleoram', province: 'Newfoundland and Labrador' },
  { city: 'Botwood', province: 'Newfoundland and Labrador' },
  { city: 'Brookfield', province: 'Newfoundland and Labrador' },
  { city: 'Buchans', province: 'Newfoundland and Labrador' },
  { city: 'Change Island', province: 'Newfoundland and Labrador' },
  { city: 'Change Islands', province: 'Newfoundland and Labrador' },
  { city: 'Conne River', province: 'Newfoundland and Labrador' },
  { city: 'Dover', province: 'Newfoundland and Labrador' },
  { city: 'Eastport', province: 'Newfoundland and Labrador' },
  { city: 'English Harbour West', province: 'Newfoundland and Labrador' },
  { city: 'Fogo', province: 'Newfoundland and Labrador' },
  { city: 'Gambo', province: 'Newfoundland and Labrador' },
  { city: 'Gander', province: 'Newfoundland and Labrador' },
  { city: 'Glenwood', province: 'Newfoundland and Labrador' },
  { city: 'Glovertown', province: 'Newfoundland and Labrador' },
  { city: 'Glovertown,nf', province: 'Newfoundland and Labrador' },
  { city: 'Grand Falls-Windsor', province: 'Newfoundland and Labrador' },
  { city: 'Harbour Breton', province: 'Newfoundland and Labrador' },
  { city: 'Hermitage', province: 'Newfoundland and Labrador' },
  { city: 'Herring Neck', province: 'Newfoundland and Labrador' },
  { city: 'La Scie', province: 'Newfoundland and Labrador' },
  { city: 'Lewisporte', province: 'Newfoundland and Labrador' },
  { city: 'Mccallum', province: 'Newfoundland and Labrador' },
  { city: 'Musgrave Harbour', province: 'Newfoundland and Labrador' },
  { city: 'Norris Arm', province: 'Newfoundland and Labrador' },
  { city: 'Peterview', province: 'Newfoundland and Labrador' },
  { city: 'Ramea', province: 'Newfoundland and Labrador' },
  { city: 'Roberts Arm', province: 'Newfoundland and Labrador' },
  { city: 'Springdale', province: 'Newfoundland and Labrador' },
  { city: 'St Albans', province: 'Newfoundland and Labrador' },
  { city: 'St Brendans', province: 'Newfoundland and Labrador' },
  { city: 'Summerford', province: 'Newfoundland and Labrador' },
  { city: 'Twillingate', province: 'Newfoundland and Labrador' },
  { city: 'Victoria Cove', province: 'Newfoundland and Labrador' },
  { city: 'Wareham-Centreville', province: 'Newfoundland and Labrador' },
  { city: 'Port-aux-basques', province: 'Newfoundland and Labrador' },
  { city: 'Windsor,nf', province: 'Newfoundland and Labrador' },
  { city: 'Paradise', province: 'Newfoundland and Labrador' },
  { city: 'Corner Brook', province: 'Newfoundland and Labrador' },
  { city: "Bay L'argent", province: 'Newfoundland and Labrador' },
  { city: 'Bonavista', province: 'Newfoundland and Labrador' },
  { city: 'Burin', province: 'Newfoundland and Labrador' },
  { city: 'Clarenville', province: 'Newfoundland and Labrador' },
  { city: 'Garnish', province: 'Newfoundland and Labrador' },
  { city: 'Grand Bank', province: 'Newfoundland and Labrador' },
  { city: 'Lamaline', province: 'Newfoundland and Labrador' },
  { city: 'Marystown', province: 'Newfoundland and Labrador' },
  { city: 'South East Bight', province: 'Newfoundland and Labrador' },
  { city: 'St Lawrence', province: 'Newfoundland and Labrador' },
  { city: 'Terrenceville', province: 'Newfoundland and Labrador' },
  { city: 'Trinity Tb', province: 'Newfoundland and Labrador' },
  { city: 'Black Tickle', province: 'Newfoundland and Labrador' },
  { city: 'Cartwright', province: 'Newfoundland and Labrador' },
  { city: 'Churchill Falls', province: 'Newfoundland and Labrador' },
  { city: 'Forteau', province: 'Newfoundland and Labrador' },
  {
    city: 'Happy Valley - Goose Bay',
    province: 'Newfoundland and Labrador',
  },
  { city: 'Happy Valley-Goose Bay', province: 'Newfoundland and Labrador' },
  { city: 'Hopedale', province: 'Newfoundland and Labrador' },
  { city: 'Makkovik', province: 'Newfoundland and Labrador' },
  { city: 'Marys Harbour', province: 'Newfoundland and Labrador' },
  { city: 'Nain', province: 'Newfoundland and Labrador' },
  { city: 'Natuashish', province: 'Newfoundland and Labrador' },
  { city: 'North West River', province: 'Newfoundland and Labrador' },
  { city: 'Port Hope Simpson', province: 'Newfoundland and Labrador' },
  { city: 'Postville', province: 'Newfoundland and Labrador' },
  { city: 'Rigolet', province: 'Newfoundland and Labrador' },
  { city: 'St Lewis', province: 'Newfoundland and Labrador' },
  { city: 'Wabush', province: 'Newfoundland and Labrador' },
  { city: 'Labrador City', province: 'Newfoundland and Labrador' },
  { city: 'Benoits Cove', province: 'Newfoundland and Labrador' },
  { city: 'Bonne Bay', province: 'Newfoundland and Labrador' },
  { city: 'Burgeo', province: 'Newfoundland and Labrador' },
  { city: 'Cape Ray', province: 'Newfoundland and Labrador' },
  { city: 'Cape St George', province: 'Newfoundland and Labrador' },
  {
    city: 'Channel-Port-Aux-Basques',
    province: 'Newfoundland and Labrador',
  },
  { city: 'Charlottetown Lab', province: 'Newfoundland and Labrador' },
  { city: 'Cow Head', province: 'Newfoundland and Labrador' },
  { city: 'Daniels Harbour', province: 'Newfoundland and Labrador' },
  { city: 'Deer Lake', province: 'Newfoundland and Labrador' },
  { city: 'Doyles', province: 'Newfoundland and Labrador' },
  { city: 'Flowers Cove', province: 'Newfoundland and Labrador' },
  { city: 'Francois', province: 'Newfoundland and Labrador' },
  { city: 'Grand Bay East', province: 'Newfoundland and Labrador' },
  { city: 'Grey River', province: 'Newfoundland and Labrador' },
  { city: 'Hampden', province: 'Newfoundland and Labrador' },
  { city: 'Jacksons Arm', province: 'Newfoundland and Labrador' },
  { city: 'Jeffreys', province: 'Newfoundland and Labrador' },
  { city: 'La Poile', province: 'Newfoundland and Labrador' },
  { city: 'Lourdes', province: 'Newfoundland and Labrador' },
  { city: 'Norris Point', province: 'Newfoundland and Labrador' },
  { city: 'Parsons Pond', province: 'Newfoundland and Labrador' },
  { city: 'Pollards Point', province: 'Newfoundland and Labrador' },
  { city: 'Port Au Port', province: 'Newfoundland and Labrador' },
  { city: 'Port Saunders', province: 'Newfoundland and Labrador' },
  { city: 'Roddickton', province: 'Newfoundland and Labrador' },
  { city: 'Rose Blanche', province: 'Newfoundland and Labrador' },
  { city: 'St Anthony', province: 'Newfoundland and Labrador' },
  { city: 'St Georges', province: 'Newfoundland and Labrador' },
  { city: "St.george's,nf", province: 'Newfoundland and Labrador' },
  { city: 'Stephenville', province: 'Newfoundland and Labrador' },
  { city: 'Stephenville Crossing', province: 'Newfoundland and Labrador' },
  { city: 'Trout River', province: 'Newfoundland and Labrador' },
  { city: 'St.anthony', province: 'Newfoundland and Labrador' },
  { city: 'Annapolis Royal', province: 'Nova Scotia' },
  { city: 'Bridgetown', province: 'Nova Scotia' },
  { city: 'Cornwallis', province: 'Nova Scotia' },
  { city: 'Lawrencetown', province: 'Nova Scotia' },
  { city: 'Middleton', province: 'Nova Scotia' },
  { city: 'Afton Station', province: 'Nova Scotia' },
  { city: 'Antigonish', province: 'Nova Scotia' },
  { city: 'Monastery', province: 'Nova Scotia' },
  { city: 'St Andrews', province: 'Nova Scotia' },
  { city: 'Eskasoni', province: 'Nova Scotia' },
  { city: 'Glace Bay', province: 'Nova Scotia' },
  { city: 'Membertou', province: 'Nova Scotia' },
  { city: 'New Waterford', province: 'Nova Scotia' },
  { city: 'North Sydney', province: 'Nova Scotia' },
  { city: 'Sydney', province: 'Nova Scotia' },
  { city: 'Sydney Mines', province: 'Nova Scotia' },
  { city: 'Earltown', province: 'Nova Scotia' },
  { city: 'Tatamagouche', province: 'Nova Scotia' },
  { city: 'Truro', province: 'Nova Scotia' },
  { city: 'Truro Heights', province: 'Nova Scotia' },
  { city: 'Advocate Harbour', province: 'Nova Scotia' },
  { city: 'Amherst', province: 'Nova Scotia' },
  { city: 'Parrsboro', province: 'Nova Scotia' },
  { city: 'Pugwash', province: 'Nova Scotia' },
  { city: 'River Hebert', province: 'Nova Scotia' },
  { city: 'Springhill', province: 'Nova Scotia' },
  { city: 'Bear River', province: 'Nova Scotia' },
  { city: 'Church Point', province: 'Nova Scotia' },
  { city: 'Digby', province: 'Nova Scotia' },
  { city: 'Meteghan Centre', province: 'Nova Scotia' },
  { city: 'Weymouth', province: 'Nova Scotia' },
  { city: 'Canso', province: 'Nova Scotia' },
  { city: 'Guysborough', province: 'Nova Scotia' },
  { city: 'Bedford', province: 'Nova Scotia' },
  { city: 'Cole Harbour', province: 'Nova Scotia' },
  { city: 'Dartmouth', province: 'Nova Scotia' },
  { city: 'East Preston', province: 'Nova Scotia' },
  { city: 'Eastern Passage', province: 'Nova Scotia' },
  { city: 'Fall River', province: 'Nova Scotia' },
  { city: 'Halibut Bay', province: 'Nova Scotia' },
  { city: 'Halifax', province: 'Nova Scotia' },
  { city: 'Hammonds Plains', province: 'Nova Scotia' },
  { city: 'Hatchet Lake', province: 'Nova Scotia' },
  { city: 'Hubbards', province: 'Nova Scotia' },
  { city: 'Hubley', province: 'Nova Scotia' },
  { city: 'Lakeside', province: 'Nova Scotia' },
  { city: 'Lower Sackville', province: 'Nova Scotia' },
  { city: 'Middle Musquodoboit', province: 'Nova Scotia' },
  { city: 'Musquodoboit Harbour', province: 'Nova Scotia' },
  { city: 'North Preston', province: 'Nova Scotia' },
  { city: 'Porters Lake', province: 'Nova Scotia' },
  { city: 'Shearwater', province: 'Nova Scotia' },
  { city: 'Sheet Harbour', province: 'Nova Scotia' },
  { city: 'Timberlea', province: 'Nova Scotia' },
  { city: 'Upper Tantallon', province: 'Nova Scotia' },
  { city: 'Waverley', province: 'Nova Scotia' },
  { city: 'Elmsdale', province: 'Nova Scotia' },
  { city: 'Falmouth', province: 'Nova Scotia' },
  { city: 'Hantsport', province: 'Nova Scotia' },
  { city: 'Kennetcook', province: 'Nova Scotia' },
  { city: 'Milford Station', province: 'Nova Scotia' },
  { city: 'Newport', province: 'Nova Scotia' },
  { city: 'Shubenacadie', province: 'Nova Scotia' },
  { city: 'Windsor', province: 'Nova Scotia' },
  { city: 'Cheticamp', province: 'Nova Scotia' },
  { city: 'Inverness', province: 'Nova Scotia' },
  { city: 'Port Hawkesbury', province: 'Nova Scotia' },
  { city: 'Whycocomagh', province: 'Nova Scotia' },
  { city: 'Hampton', province: 'Nova Scotia' },
  { city: 'Norton', province: 'Nova Scotia' },
  { city: 'Quispamsis', province: 'Nova Scotia' },
  { city: 'Rothesay', province: 'Nova Scotia' },
  { city: 'Sussex', province: 'Nova Scotia' },
  { city: 'Auburn', province: 'Nova Scotia' },
  { city: 'Berwick', province: 'Nova Scotia' },
  { city: 'Cambridge Station', province: 'Nova Scotia' },
  { city: 'Canning', province: 'Nova Scotia' },
  { city: 'Coldbrook', province: 'Nova Scotia' },
  { city: 'Greenwood', province: 'Nova Scotia' },
  { city: 'Kentville', province: 'Nova Scotia' },
  { city: 'Waterville', province: 'Nova Scotia' },
  { city: 'Wolfville', province: 'Nova Scotia' },
  { city: 'Annandale', province: 'Nova Scotia' },
  { city: 'Lennox Island', province: 'Nova Scotia' },
  { city: 'Lower Montague', province: 'Nova Scotia' },
  { city: 'Montague', province: 'Nova Scotia' },
  { city: 'Murray Harbour', province: 'Nova Scotia' },
  { city: 'Souris', province: 'Nova Scotia' },
  { city: 'Bridgewater', province: 'Nova Scotia' },
  { city: 'Chester', province: 'Nova Scotia' },
  { city: 'Chester Basin', province: 'Nova Scotia' },
  { city: 'Cookville', province: 'Nova Scotia' },
  { city: 'Lunenburg', province: 'Nova Scotia' },
  { city: 'New Ross', province: 'Nova Scotia' },
  { city: 'Micmac', province: 'Nova Scotia' },
  { city: 'New Glasgow', province: 'Nova Scotia' },
  { city: 'Pictou', province: 'Nova Scotia' },
  { city: 'Stellarton', province: 'Nova Scotia' },
  { city: 'Thorburn', province: 'Nova Scotia' },
  { city: 'Westville', province: 'Nova Scotia' },
  { city: 'Chipman', province: 'Nova Scotia' },
  { city: 'Caledonia', province: 'Nova Scotia' },
  { city: 'Liverpool', province: 'Nova Scotia' },
  { city: 'Charlottetown', province: 'Nova Scotia' },
  { city: 'Hunter River', province: 'Nova Scotia' },
  { city: 'Mount Stewart', province: 'Nova Scotia' },
  { city: 'North Rustico', province: 'Nova Scotia' },
  { city: 'Arichat', province: 'Nova Scotia' },
  { city: 'Cleveland', province: 'Nova Scotia' },
  { city: 'Louisdale', province: 'Nova Scotia' },
  { city: 'St Peters', province: 'Nova Scotia' },
  { city: 'Barrington', province: 'Nova Scotia' },
  { city: 'Barrington Passage', province: 'Nova Scotia' },
  { city: 'Clam Point', province: 'Nova Scotia' },
  { city: 'Lockeport', province: 'Nova Scotia' },
  { city: 'Shelburne', province: 'Nova Scotia' },
  { city: 'Grand Falls', province: 'Nova Scotia' },
  { city: 'Grand-Sault/Grand Falls', province: 'Nova Scotia' },
  { city: 'Perth-Andover', province: 'Nova Scotia' },
  { city: 'Plaster Rock', province: 'Nova Scotia' },
  { city: 'Tobique First Nation', province: 'Nova Scotia' },
  { city: 'Baddeck', province: 'Nova Scotia' },
  { city: 'Ingonish', province: 'Nova Scotia' },
  { city: 'Ingonish Beach', province: 'Nova Scotia' },
  { city: 'Neils Harbour', province: 'Nova Scotia' },
  { city: 'Wagmatcook', province: 'Nova Scotia' },
  { city: 'Arcadia', province: 'Nova Scotia' },
  { city: 'Tusket', province: 'Nova Scotia' },
  { city: 'Yarmouth', province: 'Nova Scotia' },
  { city: 'Yarmouth 33', province: 'Nova Scotia' },
  { city: 'Fort Liard', province: 'Northwest Territories' },
  { city: 'Fort Simpson', province: 'Northwest Territories' },
  { city: 'Jean Marie River', province: 'Northwest Territories' },
  { city: 'Nahanni Butte', province: 'Northwest Territories' },
  { city: 'Trout Lake', province: 'Northwest Territories' },
  { city: 'Wrigley', province: 'Northwest Territories' },
  { city: 'Aklavik', province: 'Northwest Territories' },
  { city: 'Fort Mcpherson', province: 'Northwest Territories' },
  { city: 'Inuvik', province: 'Northwest Territories' },
  { city: 'Paulatuk', province: 'Northwest Territories' },
  { city: 'Sachs Harbour', province: 'Northwest Territories' },
  { city: 'Tsiigehtchic', province: 'Northwest Territories' },
  { city: 'Tuktoyaktuk', province: 'Northwest Territories' },
  { city: 'Ulukhaktok', province: 'Northwest Territories' },
  { city: 'Behchoko', province: 'Northwest Territories' },
  { city: 'Behchoko`', province: 'Northwest Territories' },
  { city: 'Gameti', province: 'Northwest Territories' },
  { city: "Lutselk'e", province: 'Northwest Territories' },
  { city: 'Lutselke', province: 'Northwest Territories' },
  { city: 'Wekweti', province: 'Northwest Territories' },
  { city: 'Whati', province: 'Northwest Territories' },
  { city: 'Yellowknife', province: 'Northwest Territories' },
  { city: 'Colville Lake', province: 'Northwest Territories' },
  { city: 'Deline', province: 'Northwest Territories' },
  { city: 'Fort Good Hope', province: 'Northwest Territories' },
  { city: 'Norman Wells', province: 'Northwest Territories' },
  { city: 'Tulita', province: 'Northwest Territories' },
  { city: 'Fort Providence', province: 'Northwest Territories' },
  { city: 'Fort Resolution', province: 'Northwest Territories' },
  { city: 'Fort Smith', province: 'Northwest Territories' },
  { city: 'Hay River', province: 'Northwest Territories' },
  { city: '108 Mile House', province: 'Northwest Territories' },
  { city: 'Aggassiz', province: 'Northwest Territories' },
  { city: 'Esquimalt', province: 'Northwest Territories' },
  { city: 'Qualicum', province: 'Northwest Territories' },
  { city: 'Opaskwayak', province: 'Northwest Territories' },
  { city: 'Eel river', province: 'Northwest Territories' },
  { city: 'Sackville', province: 'Northwest Territories' },
  { city: "Lutsel k''e", province: 'Northwest Territories' },
  { city: 'Rae lakes', province: 'Northwest Territories' },
  { city: 'Millbrook', province: 'Northwest Territories' },
  { city: 'Bloomfield Station', province: 'Northwest Territories' },
  { city: 'Coleman', province: 'Northwest Territories' },
  { city: 'St-Peters Bay', province: 'Northwest Territories' },
  { city: 'Beauport', province: 'Northwest Territories' },
  { city: 'Beaupre', province: 'Northwest Territories' },
  { city: 'Bernieres', province: 'Northwest Territories' },
  { city: 'Black lake,qu', province: 'Northwest Territories' },
  { city: 'Chateauguay', province: 'Northwest Territories' },
  { city: 'Dolbeau', province: 'Northwest Territories' },
  { city: 'Fatima', province: 'Northwest Territories' },
  { city: 'Gascons', province: 'Northwest Territories' },
  { city: 'Gentilly', province: 'Northwest Territories' },
  { city: 'Gesgapegiag', province: 'Northwest Territories' },
  { city: 'Grande-Rivi re', province: 'Northwest Territories' },
  { city: 'Iles-de-la-madeleine', province: 'Northwest Territories' },
  { city: 'Kingsey-falls', province: 'Northwest Territories' },
  { city: 'La peche', province: 'Northwest Territories' },
  { city: 'Lac-st-charles', province: 'Northwest Territories' },
  { city: 'Laurentides', province: 'Northwest Territories' },
  { city: 'Le gardeur', province: 'Northwest Territories' },
  { city: 'Listuguj', province: 'Northwest Territories' },
  { city: 'Lyster', province: 'Northwest Territories' },
  { city: 'Pohenegamook', province: 'Northwest Territories' },
  { city: 'Quebec', province: 'Northwest Territories' },
  { city: 'Riviere-au-tonnerre', province: 'Northwest Territories' },
  { city: 'Riviere-Du-Loup', province: 'Northwest Territories' },
  { city: 'Saint-alphonse', province: 'Northwest Territories' },
  { city: 'Saint-basile le grand', province: 'Northwest Territories' },
  { city: 'Saint-elie-de-caxton', province: 'Northwest Territories' },
  { city: 'Saint- tienne', province: 'Northwest Territories' },
  { city: 'Saint-ferreol les neige', province: 'Northwest Territories' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Northwest Territories' },
  { city: 'Creighton', province: 'Northwest Territories' },
  { city: 'Spiritwood', province: 'Northwest Territories' },
  { city: 'Cambridge Bay', province: 'Nunavut' },
  { city: 'Gjoa Haven', province: 'Nunavut' },
  { city: 'Kugaaruk', province: 'Nunavut' },
  { city: 'Kugluktuk', province: 'Nunavut' },
  { city: 'Taloyoak', province: 'Nunavut' },
  { city: 'Arviat', province: 'Nunavut' },
  { city: 'Baker Lake', province: 'Nunavut' },
  { city: 'Chesterfield Inlet', province: 'Nunavut' },
  { city: 'Coral Harbour', province: 'Nunavut' },
  { city: 'Naujaat', province: 'Nunavut' },
  { city: 'Rankin Inlet', province: 'Nunavut' },
  { city: 'Repulse Bay', province: 'Nunavut' },
  { city: 'Whale Cove', province: 'Nunavut' },
  { city: 'Arctic Bay', province: 'Nunavut' },
  { city: 'Clyde River', province: 'Nunavut' },
  { city: 'Grise Fiord', province: 'Nunavut' },
  { city: 'Igloolik', province: 'Nunavut' },
  { city: 'Iqaluit', province: 'Nunavut' },
  { city: 'Kimmirut', province: 'Nunavut' },
  { city: 'Kinngait', province: 'Nunavut' },
  { city: 'Nanisivik', province: 'Nunavut' },
  { city: 'Pangnirtung', province: 'Nunavut' },
  { city: 'Pond Inlet', province: 'Nunavut' },
  { city: 'Qikiqtarjuaq', province: 'Nunavut' },
  { city: 'Resolute', province: 'Nunavut' },
  { city: 'Sanikiluaq', province: 'Nunavut' },
  { city: 'Sanirajak', province: 'Nunavut' },
  { city: 'Batchewana First Nation', province: 'Ontario' },
  { city: 'Blind River', province: 'Ontario' },
  { city: 'Cutler', province: 'Ontario' },
  { city: 'Dubreuilville', province: 'Ontario' },
  { city: 'Elliot Lake', province: 'Ontario' },
  { city: 'Garden River First Nation', province: 'Ontario' },
  { city: 'Hornepayne', province: 'Ontario' },
  { city: 'Hornpayne First Nation', province: 'Ontario' },
  { city: 'Michipicoten First Nation', province: 'Ontario' },
  { city: 'Missanabie', province: 'Ontario' },
  { city: 'Missanabie Cree First Nation', province: 'Ontario' },
  { city: 'Mississauga First Nation', province: 'Ontario' },
  { city: 'Rankin Location 15D First Nation', province: 'Ontario' },
  { city: 'Richards Landing', province: 'Ontario' },
  { city: 'Sagamok First Nation', province: 'Ontario' },
  { city: 'Sault Ste Marie', province: 'Ontario' },
  { city: 'Serpent River First Natin', province: 'Ontario' },
  { city: 'Serpent River First Nation', province: 'Ontario' },
  { city: 'Thessalon', province: 'Ontario' },
  { city: 'Wawa', province: 'Ontario' },
  { city: 'White River', province: 'Ontario' },
  { city: 'Brantford', province: 'Ontario' },
  { city: 'Ohsweken', province: 'Ontario' },
  { city: 'Paris', province: 'Ontario' },
  { city: 'Cape Croker', province: 'Ontario' },
  { city: 'Chesley', province: 'Ontario' },
  { city: 'Kincardine', province: 'Ontario' },
  { city: 'Lions Head', province: 'Ontario' },
  { city: 'Port Elgin', province: 'Ontario' },
  { city: 'Southampton', province: 'Ontario' },
  { city: 'Tobermory', province: 'Ontario' },
  { city: 'Walkerton', province: 'Ontario' },
  { city: 'Wiarton', province: 'Ontario' },
  { city: 'Blenheim', province: 'Ontario' },
  { city: 'Bothwell', province: 'Ontario' },
  { city: 'Chatham', province: 'Ontario' },
  { city: 'Dover Centre', province: 'Ontario' },
  { city: 'Dresden', province: 'Ontario' },
  { city: 'Ridgetown', province: 'Ontario' },
  { city: 'Thamesville', province: 'Ontario' },
  { city: 'Tilbury', province: 'Ontario' },
  { city: 'Wallaceburg', province: 'Ontario' },
  { city: 'Calstock', province: 'Ontario' },
  { city: 'Cochrane', province: 'Ontario' },
  { city: 'Constance Lake', province: 'Ontario' },
  { city: 'Costance Lake', province: 'Ontario' },
  { city: 'Fort albany', province: 'Ontario' },
  { city: 'Fort Albany First Nation', province: 'Ontario' },
  { city: 'Hearst', province: 'Ontario' },
  { city: 'Iroquois Falls', province: 'Ontario' },
  { city: 'Iroquois Falls A', province: 'Ontario' },
  { city: 'Kapuskasing', province: 'Ontario' },
  { city: 'Kashechewan First Nation', province: 'Ontario' },
  { city: 'Marten Falls First Nation', province: 'Ontario' },
  { city: 'Matheson', province: 'Ontario' },
  { city: 'Moose Factory', province: 'Ontario' },
  { city: 'Moosonee', province: 'Ontario' },
  { city: 'Opasatika', province: 'Ontario' },
  { city: 'Schumacher', province: 'Ontario' },
  { city: 'Smooth Rock Falls', province: 'Ontario' },
  { city: 'South Porcupine', province: 'Ontario' },
  { city: 'Timmins', province: 'Ontario' },
  { city: 'Melancthon', province: 'Ontario' },
  { city: 'Orangeville', province: 'Ontario' },
  { city: 'Ajax', province: 'Ontario' },
  { city: 'Beaverton', province: 'Ontario' },
  { city: 'Bowmanville', province: 'Ontario' },
  { city: 'Cannington', province: 'Ontario' },
  { city: 'Courtice', province: 'Ontario' },
  { city: 'Newcastle', province: 'Ontario' },
  { city: 'Oshawa', province: 'Ontario' },
  { city: 'Pickering', province: 'Ontario' },
  { city: 'Port Perry', province: 'Ontario' },
  { city: 'Sunderland', province: 'Ontario' },
  { city: 'Uxbridge', province: 'Ontario' },
  { city: 'Whitby', province: 'Ontario' },
  { city: 'Aylmer', province: 'Ontario' },
  { city: 'Springfield', province: 'Ontario' },
  { city: 'St Thomas', province: 'Ontario' },
  { city: 'StThomas', province: 'Ontario' },
  { city: 'West Lorne', province: 'Ontario' },
  { city: 'Amherstburg', province: 'Ontario' },
  { city: 'Belle River', province: 'Ontario' },
  { city: 'Essex', province: 'Ontario' },
  { city: 'Harrow', province: 'Ontario' },
  { city: 'Kingsville', province: 'Ontario' },
  { city: 'Leamington', province: 'Ontario' },
  { city: 'Pelee island', province: 'Ontario' },
  { city: 'Harrowsmith', province: 'Ontario' },
  { city: 'Hinchinbrooke', province: 'Ontario' },
  { city: 'Sharbot Lake', province: 'Ontario' },
  { city: 'Sharbot lake', province: 'Ontario' },
  { city: 'Verona', province: 'Ontario' },
  { city: 'Chatsworth', province: 'Ontario' },
  { city: 'Dornoch', province: 'Ontario' },
  { city: 'Dundalk', province: 'Ontario' },
  { city: 'Durham', province: 'Ontario' },
  { city: 'Hanover', province: 'Ontario' },
  { city: 'Markdale', province: 'Ontario' },
  { city: 'Meaford', province: 'Ontario' },
  { city: 'Owen Sound', province: 'Ontario' },
  { city: 'Cayuga', province: 'Ontario' },
  { city: 'Dunnville', province: 'Ontario' },
  { city: 'Hagersville', province: 'Ontario' },
  { city: 'Langton', province: 'Ontario' },
  { city: 'Simcoe', province: 'Ontario' },
  { city: 'Townsend', province: 'Ontario' },
  { city: 'Eagle Lake', province: 'Ontario' },
  { city: 'Haliburton', province: 'Ontario' },
  { city: 'Minden', province: 'Ontario' },
  { city: 'Acton', province: 'Ontario' },
  { city: 'Burlington', province: 'Ontario' },
  { city: 'Georgetown', province: 'Ontario' },
  { city: 'Milton', province: 'Ontario' },
  { city: 'Oakville', province: 'Ontario' },
  { city: 'Ancaster', province: 'Ontario' },
  { city: 'Dundas', province: 'Ontario' },
  { city: 'Flamborough', province: 'Ontario' },
  { city: 'Hamilton', province: 'Ontario' },
  { city: 'Jerseyville', province: 'Ontario' },
  { city: 'Millgrove', province: 'Ontario' },
  { city: 'Stoney Creek', province: 'Ontario' },
  { city: 'Waterdown', province: 'Ontario' },
  { city: 'Binbrook', province: 'Ontario' },
  { city: 'Astra', province: 'Ontario' },
  { city: 'Bancroft', province: 'Ontario' },
  { city: 'Belleville', province: 'Ontario' },
  { city: 'Deseronto', province: 'Ontario' },
  { city: 'Madoc', province: 'Ontario' },
  { city: 'Marmora', province: 'Ontario' },
  { city: 'Shannonville', province: 'Ontario' },
  { city: 'Stirling', province: 'Ontario' },
  { city: 'Thomasburg', province: 'Ontario' },
  { city: 'Trenton', province: 'Ontario' },
  { city: 'Bayfield', province: 'Ontario' },
  { city: 'Brussels', province: 'Ontario' },
  { city: 'Clinton', province: 'Ontario' },
  { city: 'Dublin', province: 'Ontario' },
  { city: 'Exeter', province: 'Ontario' },
  { city: 'Goderich', province: 'Ontario' },
  { city: 'Seaforth', province: 'Ontario' },
  { city: 'Stratford', province: 'Ontario' },
  { city: 'Wingham', province: 'Ontario' },
  { city: 'Cambray', province: 'Ontario' },
  { city: 'Lindsay', province: 'Ontario' },
  { city: 'Angling Lake First Nation', province: 'Ontario' },
  { city: 'Attawapiskat First Nation', province: 'Ontario' },
  { city: 'Bearskin lake', province: 'Ontario' },
  { city: 'Bearskin Lake First Nation', province: 'Ontario' },
  { city: 'Big trout lake', province: 'Ontario' },
  { city: 'Big Trout Lake First Nation', province: 'Ontario' },
  { city: 'Cat Lake First Nation', province: 'Ontario' },
  { city: 'Dalles First Nation', province: 'Ontario' },
  { city: 'Deer Lake First Nation', province: 'Ontario' },
  { city: 'Dryden', province: 'Ontario' },
  { city: 'Eagle Lake First Nation', province: 'Ontario' },
  { city: 'Eagle River', province: 'Ontario' },
  { city: 'Ear Falls', province: 'Ontario' },
  { city: 'Fort Hope First Nation (Eabametoong)', province: 'Ontario' },
  { city: 'Fort Severn First Nation', province: 'Ontario' },
  { city: 'Grassy Narrows First Nation', province: 'Ontario' },
  { city: 'Hudson', province: 'Ontario' },
  { city: 'Ignace', province: 'Ontario' },
  { city: 'Kasabonika First Nation', province: 'Ontario' },
  { city: 'Keewatin', province: 'Ontario' },
  { city: 'Keewaywin First Nation', province: 'Ontario' },
  { city: 'Kejick First Nation', province: 'Ontario' },
  { city: 'Kenora', province: 'Ontario' },
  { city: 'Kingfisher Lake First Nation', province: 'Ontario' },
  { city: 'Lac Seul First Nation', province: 'Ontario' },
  { city: 'Lansdowne House First Nation', province: 'Ontario' },
  { city: 'Migisi Sahgaigan', province: 'Ontario' },
  { city: 'Minaki', province: 'Ontario' },
  { city: 'Muskrat Dam First Nation', province: 'Ontario' },
  { city: 'Nestor Falls', province: 'Ontario' },
  { city: 'North Caribou Lake First Nation', province: 'Ontario' },
  { city: 'North Spirit Lake First Nation', province: 'Ontario' },
  { city: 'Osnaburgh House', province: 'Ontario' },
  { city: 'Pawitik', province: 'Ontario' },
  { city: 'Peawanuck', province: 'Ontario' },
  { city: 'Perrault Falls', province: 'Ontario' },
  { city: 'Pickle Lake', province: 'Ontario' },
  { city: 'Pikangikum First Nation', province: 'Ontario' },
  { city: 'Poplar Hill First Nation', province: 'Ontario' },
  { city: 'Red Lake', province: 'Ontario' },
  { city: 'Sachigo Lake First Nation', province: 'Ontario' },
  { city: 'Sandy Lake First Nation', province: 'Ontario' },
  { city: 'Sioux Lookout', province: 'Ontario' },
  { city: 'Sioux Narrows', province: 'Ontario' },
  { city: 'Slate Falls First Nation', province: 'Ontario' },
  { city: 'Summer Beaver First Nation', province: 'Ontario' },
  { city: 'Vermilion Bay', province: 'Ontario' },
  { city: 'Webequie First Nation', province: 'Ontario' },
  { city: 'Whitedog First Nation (Wabaseemoong)', province: 'Ontario' },
  { city: 'Wunnumin Lake First Nation', province: 'Ontario' },
  { city: "Bright's Grove", province: 'Ontario' },
  { city: 'Forest', province: 'Ontario' },
  { city: 'Grand Bend', province: 'Ontario' },
  { city: 'Kettle And Stony Point', province: 'Ontario' },
  { city: 'Petrolia', province: 'Ontario' },
  { city: 'Point edward', province: 'Ontario' },
  { city: 'Sarnia', province: 'Ontario' },
  { city: 'Watford', province: 'Ontario' },
  { city: 'Wyoming', province: 'Ontario' },
  { city: 'Almonte', province: 'Ontario' },
  { city: 'Carleton Place', province: 'Ontario' },
  { city: 'Lanark', province: 'Ontario' },
  { city: 'Perth', province: 'Ontario' },
  { city: 'Smith falls', province: 'Ontario' },
  { city: 'Smiths Falls', province: 'Ontario' },
  { city: 'Brockville', province: 'Ontario' },
  { city: 'Delta', province: 'Ontario' },
  { city: 'Elgin', province: 'Ontario' },
  { city: 'Gananoque', province: 'Ontario' },
  { city: 'Kemptville', province: 'Ontario' },
  { city: 'Merrickville', province: 'Ontario' },
  { city: 'Prescott', province: 'Ontario' },
  { city: 'Westport', province: 'Ontario' },
  { city: 'Amherstview', province: 'Ontario' },
  { city: 'Cloyne', province: 'Ontario' },
  { city: 'Kingston', province: 'Ontario' },
  { city: 'Napanee', province: 'Ontario' },
  { city: 'Northbrook', province: 'Ontario' },
  { city: 'Odessa', province: 'Ontario' },
  { city: 'Birch Island', province: 'Ontario' },
  { city: 'Gordon/Barrie Island', province: 'Ontario' },
  { city: 'Little Current', province: 'Ontario' },
  { city: 'Mchigeeng First Nation', province: 'Ontario' },
  { city: 'Mindemoya', province: 'Ontario' },
  { city: 'Sheguiandah First Nation', province: 'Ontario' },
  { city: 'Sheshegwaning First Nation', province: 'Ontario' },
  { city: 'Silver Water', province: 'Ontario' },
  { city: 'Wikwemikong', province: 'Ontario' },
  { city: 'Wikwemikong First Nation', province: 'Ontario' },
  { city: 'Ailsa Craig', province: 'Ontario' },
  { city: 'London', province: 'Ontario' },
  { city: 'Mount brydges', province: 'Ontario' },
  { city: 'Muncey', province: 'Ontario' },
  { city: 'Newbury', province: 'Ontario' },
  { city: 'Southwold', province: 'Ontario' },
  { city: 'Strathroy', province: 'Ontario' },
  { city: 'Bala', province: 'Ontario' },
  { city: 'Bracebridge', province: 'Ontario' },
  { city: 'Gravenhurst', province: 'Ontario' },
  { city: 'Huntsville', province: 'Ontario' },
  { city: 'Mactier', province: 'Ontario' },
  { city: 'Beamsville', province: 'Ontario' },
  { city: 'Crystal Beach', province: 'Ontario' },
  { city: 'Fort Erie', province: 'Ontario' },
  { city: 'Grimsby', province: 'Ontario' },
  { city: 'Niagara Falls', province: 'Ontario' },
  { city: 'Niagara On The Lake', province: 'Ontario' },
  { city: 'Port Colborne', province: 'Ontario' },
  { city: 'Port Robinson', province: 'Ontario' },
  { city: 'Smithville', province: 'Ontario' },
  { city: 'St Catharines', province: 'Ontario' },
  { city: 'St. Catharines', province: 'Ontario' },
  { city: 'Thorold', province: 'Ontario' },
  { city: 'Welland', province: 'Ontario' },
  { city: 'Bear Island First Nation', province: 'Ontario' },
  { city: 'Calvin', province: 'Ontario' },
  { city: 'Garden Village', province: 'Ontario' },
  { city: 'Hornell Heights', province: 'Ontario' },
  { city: 'Marten River', province: 'Ontario' },
  { city: 'Mattawa', province: 'Ontario' },
  { city: 'Monetville', province: 'Ontario' },
  { city: 'North Bay', province: 'Ontario' },
  { city: 'Sturgeon Falls', province: 'Ontario' },
  { city: 'Whitney', province: 'Ontario' },
  { city: 'Blackville', province: 'Ontario' },
  { city: 'Boiestown', province: 'Ontario' },
  { city: 'Burnt Church First Nation', province: 'Ontario' },
  { city: 'Doaktown', province: 'Ontario' },
  { city: 'Eel Ground', province: 'Ontario' },
  { city: 'Miramichi', province: 'Ontario' },
  { city: 'Neguac', province: 'Ontario' },
  { city: 'Red Bank', province: 'Ontario' },
  { city: 'Rogersville', province: 'Ontario' },
  { city: 'Bewdley', province: 'Ontario' },
  { city: 'Brighton', province: 'Ontario' },
  { city: 'Campbellford', province: 'Ontario' },
  { city: 'Cobourg', province: 'Ontario' },
  { city: 'Colborne', province: 'Ontario' },
  { city: 'Port Hope', province: 'Ontario' },
  { city: 'Roseneath', province: 'Ontario' },
  { city: 'Carp', province: 'Ontario' },
  { city: 'Gloucester', province: 'Ontario' },
  { city: 'Kanata', province: 'Ontario' },
  { city: 'Nepean', province: 'Ontario' },
  { city: 'Orleans', province: 'Ontario' },
  { city: 'Osgoode', province: 'Ontario' },
  { city: 'Ottawa', province: 'Ontario' },
  { city: 'Ottawa-Vanier', province: 'Ontario' },
  { city: 'Vanier', province: 'Ontario' },
  { city: 'Ingersoll', province: 'Ontario' },
  { city: 'Norwich', province: 'Ontario' },
  { city: 'Plattsville', province: 'Ontario' },
  { city: 'Princeton', province: 'Ontario' },
  { city: 'Tavistock', province: 'Ontario' },
  { city: 'Thamesford', province: 'Ontario' },
  { city: 'Tillsonburg', province: 'Ontario' },
  { city: 'Woodstock', province: 'Ontario' },
  { city: 'Ahmic Harbour', province: 'Ontario' },
  { city: 'Britt', province: 'Ontario' },
  { city: 'Burks Falls', province: 'Ontario' },
  { city: 'Dokis First Nation', province: 'Ontario' },
  { city: 'Nobel', province: 'Ontario' },
  { city: 'Parry Sound', province: 'Ontario' },
  { city: 'Powassan', province: 'Ontario' },
  { city: 'South River', province: 'Ontario' },
  { city: 'Sundridge', province: 'Ontario' },
  { city: 'Wasauksing First Nation', province: 'Ontario' },
  { city: 'Bolton', province: 'Ontario' },
  { city: 'Brampton', province: 'Ontario' },
  { city: 'Caledon', province: 'Ontario' },
  { city: 'Caledon East', province: 'Ontario' },
  { city: 'Inglewood', province: 'Ontario' },
  { city: 'Mississauga', province: 'Ontario' },
  { city: 'Listowel', province: 'Ontario' },
  { city: 'St Marys', province: 'Ontario' },
  { city: 'St. Marys', province: 'Ontario' },
  { city: 'Apsley', province: 'Ontario' },
  { city: 'Bobcaygeon', province: 'Ontario' },
  { city: 'Cavan Monaghan', province: 'Ontario' },
  { city: 'Curve Lake', province: 'Ontario' },
  { city: 'Ennismore', province: 'Ontario' },
  { city: 'Hastings', province: 'Ontario' },
  { city: 'Hiawatha', province: 'Ontario' },
  { city: 'Keene', province: 'Ontario' },
  { city: 'Lakefield', province: 'Ontario' },
  { city: 'Peterborough', province: 'Ontario' },
  { city: 'Casselman', province: 'Ontario' },
  { city: 'Clarence-Rockland', province: 'Ontario' },
  { city: 'Embrun', province: 'Ontario' },
  { city: 'Hawkesbury', province: 'Ontario' },
  { city: "L'orignal", province: 'Ontario' },
  { city: 'Plantagenet', province: 'Ontario' },
  { city: 'Rockland', province: 'Ontario' },
  { city: 'Demorestville', province: 'Ontario' },
  { city: 'Picton', province: 'Ontario' },
  { city: 'Atikokan', province: 'Ontario' },
  { city: 'Devlin', province: 'Ontario' },
  { city: 'Emo', province: 'Ontario' },
  { city: 'Fort Frances', province: 'Ontario' },
  { city: 'Lac La Croix First Nation', province: 'Ontario' },
  { city: 'Mine Centre', province: 'Ontario' },
  { city: 'Morson', province: 'Ontario' },
  { city: 'Rainy River', province: 'Ontario' },
  { city: 'Arnprior', province: 'Ontario' },
  { city: "Barry''s bay", province: 'Ontario' },
  { city: 'Barrys Bay', province: 'Ontario' },
  { city: 'Deep River', province: 'Ontario' },
  { city: 'Douglas', province: 'Ontario' },
  { city: 'Eganville', province: 'Ontario' },
  { city: 'Golden Lake', province: 'Ontario' },
  { city: 'Killaloe', province: 'Ontario' },
  { city: 'Pembroke', province: 'Ontario' },
  { city: 'Petawawa', province: 'Ontario' },
  { city: 'Renfrew', province: 'Ontario' },
  { city: 'Alliston', province: 'Ontario' },
  { city: 'Angus', province: 'Ontario' },
  { city: 'Barrie', province: 'Ontario' },
  { city: 'Borden', province: 'Ontario' },
  { city: 'Bradford', province: 'Ontario' },
  { city: 'Christian Island', province: 'Ontario' },
  { city: 'Collingwood', province: 'Ontario' },
  { city: 'Cookstown', province: 'Ontario' },
  { city: 'Hawkestone', province: 'Ontario' },
  { city: 'Innisfil', province: 'Ontario' },
  { city: 'Midhurst', province: 'Ontario' },
  { city: 'Midland', province: 'Ontario' },
  { city: 'Nottawa', province: 'Ontario' },
  { city: 'Orillia', province: 'Ontario' },
  { city: 'Penetanguishene', province: 'Ontario' },
  { city: 'Rama', province: 'Ontario' },
  { city: 'Tottenham', province: 'Ontario' },
  { city: 'Victoria Harbour', province: 'Ontario' },
  { city: 'Wasaga Beach', province: 'Ontario' },
  { city: 'Alexandria', province: 'Ontario' },
  { city: 'Cornwall', province: 'Ontario' },
  { city: 'Morrisburg', province: 'Ontario' },
  { city: 'Winchester', province: 'Ontario' },
  { city: 'Alban', province: 'Ontario' },
  { city: 'Capreol', province: 'Ontario' },
  { city: 'Chapleau', province: 'Ontario' },
  { city: 'Chelmsford', province: 'Ontario' },
  { city: 'Dowling', province: 'Ontario' },
  { city: 'Espanola', province: 'Ontario' },
  { city: 'Gogama', province: 'Ontario' },
  { city: 'Hanmer', province: 'Ontario' },
  { city: 'Lively', province: 'Ontario' },
  { city: 'Massey', province: 'Ontario' },
  { city: 'Naughton', province: 'Ontario' },
  { city: 'Noelville', province: 'Ontario' },
  { city: 'Pickerel', province: 'Ontario' },
  { city: 'St Charles', province: 'Ontario' },
  { city: 'Sudbury', province: 'Ontario' },
  { city: 'Val Caron', province: 'Ontario' },
  { city: 'Whitefish', province: 'Ontario' },
  { city: 'Aroland', province: 'Ontario' },
  { city: 'Aroland First Nation', province: 'Ontario' },
  { city: 'Beardmore', province: 'Ontario' },
  { city: 'Fort William First Nation', province: 'Ontario' },
  { city: 'Geraldton', province: 'Ontario' },
  { city: 'Greenstone', province: 'Ontario' },
  { city: 'Heron Bay', province: 'Ontario' },
  { city: 'Longlac', province: 'Ontario' },
  { city: 'Macdiarmid', province: 'Ontario' },
  { city: 'Manitouwadge', province: 'Ontario' },
  { city: 'Marathon', province: 'Ontario' },
  { city: 'Mobert First Nation', province: 'Ontario' },
  { city: 'Nakina', province: 'Ontario' },
  { city: 'Nipigon', province: 'Ontario' },
  { city: 'Pays Plat First Nation', province: 'Ontario' },
  { city: 'Savant Lake', province: 'Ontario' },
  { city: 'Schreiber', province: 'Ontario' },
  { city: 'Terrace Bay', province: 'Ontario' },
  { city: 'Thunder Bay', province: 'Ontario' },
  { city: 'Brethour', province: 'Ontario' },
  { city: 'Chamberlain', province: 'Ontario' },
  { city: 'Earlton', province: 'Ontario' },
  { city: 'Elk Lake', province: 'Ontario' },
  { city: 'Englehart', province: 'Ontario' },
  { city: 'Haileybury', province: 'Ontario' },
  { city: 'Harley', province: 'Ontario' },
  { city: 'Harris', province: 'Ontario' },
  { city: 'King Kirkland', province: 'Ontario' },
  { city: 'Kirkland Lake', province: 'Ontario' },
  { city: 'Larder Lake', province: 'Ontario' },
  { city: 'Matachewan', province: 'Ontario' },
  { city: 'New Liskeard', province: 'Ontario' },
  { city: 'Virginiatown', province: 'Ontario' },
  { city: 'East York', province: 'Ontario' },
  { city: 'Etobicoke', province: 'Ontario' },
  { city: 'North York', province: 'Ontario' },
  { city: 'Scarborough', province: 'Ontario' },
  { city: 'Toron', province: 'Ontario' },
  { city: 'Toronto', province: 'Ontario' },
  { city: 'York', province: 'Ontario' },
  { city: 'Ayr', province: 'Ontario' },
  { city: 'Baden', province: 'Ontario' },
  { city: 'Cambridge', province: 'Ontario' },
  { city: 'Elmira', province: 'Ontario' },
  { city: 'Kitchener', province: 'Ontario' },
  { city: 'New Hamburg', province: 'Ontario' },
  { city: 'Waterloo', province: 'Ontario' },
  { city: 'Wellesley', province: 'Ontario' },
  { city: 'Centre Wellington', province: 'Ontario' },
  { city: 'Elora', province: 'Ontario' },
  { city: 'Erin', province: 'Ontario' },
  { city: 'Fergus', province: 'Ontario' },
  { city: 'Guelph', province: 'Ontario' },
  { city: 'Harriston', province: 'Ontario' },
  { city: 'Hillsburgh', province: 'Ontario' },
  { city: 'Minto', province: 'Ontario' },
  { city: 'Mount Forest', province: 'Ontario' },
  { city: 'Palmerston', province: 'Ontario' },
  { city: 'Fredericton', province: 'Ontario' },
  { city: 'Harvey Station', province: 'Ontario' },
  { city: 'Kingsclear First Nation', province: 'Ontario' },
  { city: 'Kingsley', province: 'Ontario' },
  { city: 'Mcadam', province: 'Ontario' },
  { city: 'Nackawic', province: 'Ontario' },
  { city: 'Scotch Lake', province: 'Ontario' },
  { city: 'St Croix', province: 'Ontario' },
  { city: 'Stanley', province: 'Ontario' },
  { city: 'Aurora', province: 'Ontario' },
  { city: 'Concord', province: 'Ontario' },
  { city: 'Holland Landing', province: 'Ontario' },
  { city: 'Keswick', province: 'Ontario' },
  { city: 'King City', province: 'Ontario' },
  { city: 'Maple', province: 'Ontario' },
  { city: 'Markham', province: 'Ontario' },
  { city: 'Newmarket', province: 'Ontario' },
  { city: 'Richmond Hill', province: 'Ontario' },
  { city: 'Stouffville', province: 'Ontario' },
  { city: 'Sutton West', province: 'Ontario' },
  { city: 'Thornhill', province: 'Ontario' },
  { city: 'Vaughan', province: 'Ontario' },
  { city: 'Woodbridge', province: 'Ontario' },
  { city: '108 Mile House', province: 'Ontario' },
  { city: 'Aggassiz', province: 'Ontario' },
  { city: 'Esquimalt', province: 'Ontario' },
  { city: 'Qualicum', province: 'Ontario' },
  { city: 'Opaskwayak', province: 'Ontario' },
  { city: 'Eel river', province: 'Ontario' },
  { city: 'Sackville', province: 'Ontario' },
  { city: "Lutsel k''e", province: 'Ontario' },
  { city: 'Rae lakes', province: 'Ontario' },
  { city: 'Millbrook', province: 'Ontario' },
  { city: 'Bloomfield Station', province: 'Ontario' },
  { city: 'Coleman', province: 'Ontario' },
  { city: 'St-Peters Bay', province: 'Ontario' },
  { city: 'Beauport', province: 'Ontario' },
  { city: 'Beaupre', province: 'Ontario' },
  { city: 'Bernieres', province: 'Ontario' },
  { city: 'Black lake,qu', province: 'Ontario' },
  { city: 'Chateauguay', province: 'Ontario' },
  { city: 'Dolbeau', province: 'Ontario' },
  { city: 'Fatima', province: 'Ontario' },
  { city: 'Gascons', province: 'Ontario' },
  { city: 'Gentilly', province: 'Ontario' },
  { city: 'Gesgapegiag', province: 'Ontario' },
  { city: 'Grande-Rivi re', province: 'Ontario' },
  { city: 'Iles-de-la-madeleine', province: 'Ontario' },
  { city: 'Kingsey-falls', province: 'Ontario' },
  { city: 'La peche', province: 'Ontario' },
  { city: 'Lac-st-charles', province: 'Ontario' },
  { city: 'Laurentides', province: 'Ontario' },
  { city: 'Le gardeur', province: 'Ontario' },
  { city: 'Listuguj', province: 'Ontario' },
  { city: 'Lyster', province: 'Ontario' },
  { city: 'Pohenegamook', province: 'Ontario' },
  { city: 'Quebec', province: 'Ontario' },
  { city: 'Riviere-au-tonnerre', province: 'Ontario' },
  { city: 'Riviere-Du-Loup', province: 'Ontario' },
  { city: 'Saint-alphonse', province: 'Ontario' },
  { city: 'Saint-basile le grand', province: 'Ontario' },
  { city: 'Saint-elie-de-caxton', province: 'Ontario' },
  { city: 'Saint- tienne', province: 'Ontario' },
  { city: 'Saint-ferreol les neige', province: 'Ontario' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Ontario' },
  { city: 'Creighton', province: 'Ontario' },
  { city: 'Spiritwood', province: 'Ontario' },
  { city: 'Hampton', province: 'Prince Edward Island' },
  { city: 'Norton', province: 'Prince Edward Island' },
  { city: 'Quispamsis', province: 'Prince Edward Island' },
  { city: 'Rothesay', province: 'Prince Edward Island' },
  { city: 'Sussex', province: 'Prince Edward Island' },
  { city: 'Auburn', province: 'Prince Edward Island' },
  { city: 'Berwick', province: 'Prince Edward Island' },
  { city: 'Cambridge Station', province: 'Prince Edward Island' },
  { city: 'Canning', province: 'Prince Edward Island' },
  { city: 'Coldbrook', province: 'Prince Edward Island' },
  { city: 'Greenwood', province: 'Prince Edward Island' },
  { city: 'Kentville', province: 'Prince Edward Island' },
  { city: 'Waterville', province: 'Prince Edward Island' },
  { city: 'Wolfville', province: 'Prince Edward Island' },
  { city: 'Annandale', province: 'Prince Edward Island' },
  { city: 'Lennox Island', province: 'Prince Edward Island' },
  { city: 'Lower Montague', province: 'Prince Edward Island' },
  { city: 'Montague', province: 'Prince Edward Island' },
  { city: 'Murray Harbour', province: 'Prince Edward Island' },
  { city: 'Souris', province: 'Prince Edward Island' },
  { city: 'Abram-Village', province: 'Prince Edward Island' },
  { city: 'Alberton', province: 'Prince Edward Island' },
  { city: 'Baltic', province: 'Prince Edward Island' },
  { city: 'Bideford', province: 'Prince Edward Island' },
  {
    city: 'Charlottetown and Summerside',
    province: 'Prince Edward Island',
  },
  { city: 'Greenmount', province: 'Prince Edward Island' },
  { city: 'Kensington', province: 'Prince Edward Island' },
  { city: "O'leary", province: 'Prince Edward Island' },
  { city: 'Oleary', province: 'Prince Edward Island' },
  { city: 'Summerside', province: 'Prince Edward Island' },
  { city: 'Tignish', province: 'Prince Edward Island' },
  { city: 'Tyne Valley', province: 'Prince Edward Island' },
  { city: 'Wellington Station', province: 'Prince Edward Island' },
  { city: 'Chipman', province: 'Prince Edward Island' },
  { city: 'Caledonia', province: 'Prince Edward Island' },
  { city: 'Liverpool', province: 'Prince Edward Island' },
  { city: 'Charlottetown', province: 'Prince Edward Island' },
  { city: 'Hunter River', province: 'Prince Edward Island' },
  { city: 'Mount Stewart', province: 'Prince Edward Island' },
  { city: 'North Rustico', province: 'Prince Edward Island' },
  { city: '108 Mile House', province: 'Prince Edward Island' },
  { city: 'Aggassiz', province: 'Prince Edward Island' },
  { city: 'Esquimalt', province: 'Prince Edward Island' },
  { city: 'Qualicum', province: 'Prince Edward Island' },
  { city: 'Opaskwayak', province: 'Prince Edward Island' },
  { city: 'Eel river', province: 'Prince Edward Island' },
  { city: 'Sackville', province: 'Prince Edward Island' },
  { city: "Lutsel k''e", province: 'Prince Edward Island' },
  { city: 'Rae lakes', province: 'Prince Edward Island' },
  { city: 'Millbrook', province: 'Prince Edward Island' },
  { city: 'Bloomfield Station', province: 'Prince Edward Island' },
  { city: 'Coleman', province: 'Prince Edward Island' },
  { city: 'St-Peters Bay', province: 'Prince Edward Island' },
  { city: 'Beauport', province: 'Prince Edward Island' },
  { city: 'Beaupre', province: 'Prince Edward Island' },
  { city: 'Bernieres', province: 'Prince Edward Island' },
  { city: 'Black lake,qu', province: 'Prince Edward Island' },
  { city: 'Chateauguay', province: 'Prince Edward Island' },
  { city: 'Dolbeau', province: 'Prince Edward Island' },
  { city: 'Fatima', province: 'Prince Edward Island' },
  { city: 'Gascons', province: 'Prince Edward Island' },
  { city: 'Gentilly', province: 'Prince Edward Island' },
  { city: 'Gesgapegiag', province: 'Prince Edward Island' },
  { city: 'Grande-Rivi re', province: 'Prince Edward Island' },
  { city: 'Iles-de-la-madeleine', province: 'Prince Edward Island' },
  { city: 'Kingsey-falls', province: 'Prince Edward Island' },
  { city: 'La peche', province: 'Prince Edward Island' },
  { city: 'Lac-st-charles', province: 'Prince Edward Island' },
  { city: 'Laurentides', province: 'Prince Edward Island' },
  { city: 'Le gardeur', province: 'Prince Edward Island' },
  { city: 'Listuguj', province: 'Prince Edward Island' },
  { city: 'Lyster', province: 'Prince Edward Island' },
  { city: 'Pohenegamook', province: 'Prince Edward Island' },
  { city: 'Quebec', province: 'Prince Edward Island' },
  { city: 'Riviere-au-tonnerre', province: 'Prince Edward Island' },
  { city: 'Riviere-Du-Loup', province: 'Prince Edward Island' },
  { city: 'Saint-alphonse', province: 'Prince Edward Island' },
  { city: 'Saint-basile le grand', province: 'Prince Edward Island' },
  { city: 'Saint-elie-de-caxton', province: 'Prince Edward Island' },
  { city: 'Saint- tienne', province: 'Prince Edward Island' },
  { city: 'Saint-ferreol les neige', province: 'Prince Edward Island' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Prince Edward Island' },
  { city: 'Creighton', province: 'Prince Edward Island' },
  { city: 'Spiritwood', province: 'Prince Edward Island' },
  { city: 'Noranda', province: 'Quebec' },
  { city: 'Temiscamingue', province: 'Quebec' },
  { city: "Val d''or", province: 'Quebec' },
  { city: 'Amos', province: 'Quebec' },
  { city: 'Barraute', province: 'Quebec' },
  { city: 'Belcourt', province: 'Quebec' },
  { city: 'Belleterre', province: 'Quebec' },
  { city: 'Berry', province: 'Quebec' },
  { city: 'Cadillac', province: 'Quebec' },
  { city: 'Cl ricy', province: 'Quebec' },
  { city: 'Duparquet', province: 'Quebec' },
  { city: 'Dupuy', province: 'Quebec' },
  { city: 'Gallichan', province: 'Quebec' },
  { city: 'Guyenne', province: 'Quebec' },
  { city: "Hunter's Point", province: 'Quebec' },
  { city: 'Kipawa', province: 'Quebec' },
  { city: 'Kitcisakik', province: 'Quebec' },
  { city: 'La Corne', province: 'Quebec' },
  { city: 'La Motte', province: 'Quebec' },
  { city: 'La Sarre', province: 'Quebec' },
  { city: 'Lac-Simon', province: 'Quebec' },
  { city: 'Laforce', province: 'Quebec' },
  { city: 'Landrienne', province: 'Quebec' },
  { city: 'Latulipe', province: 'Quebec' },
  { city: 'Launay', province: 'Quebec' },
  { city: 'Macamic', province: 'Quebec' },
  { city: 'MALARTIC', province: 'Quebec' },
  { city: 'Moffet', province: 'Quebec' },
  { city: 'Montbeillard', province: 'Quebec' },
  { city: 'N d lec', province: 'Quebec' },
  { city: 'Norm tal', province: 'Quebec' },
  { city: 'Notre-Dame-Du-Nord', province: 'Quebec' },
  { city: 'Palmarolle', province: 'Quebec' },
  { city: 'Preissac', province: 'Quebec' },
  { city: 'R migny', province: 'Quebec' },
  { city: 'Rochebaucourt', province: 'Quebec' },
  { city: 'Rouyn-Noranda', province: 'Quebec' },
  { city: 'Saint-Dominique-Du-Rosaire', province: 'Quebec' },
  { city: 'Saint-F lix-De-Dalquier', province: 'Quebec' },
  { city: 'Saint-Marc-De-Figuery', province: 'Quebec' },
  { city: "Saint-Mathieu-D'harricana", province: 'Quebec' },
  { city: 'Sainte-Gertrude-De-Villeneuve', province: 'Quebec' },
  { city: 'SENNETERRE', province: 'Quebec' },
  { city: 'T miscaming', province: 'Quebec' },
  { city: "Val-D'or", province: 'Quebec' },
  { city: 'VAL-DOR', province: 'Quebec' },
  { city: 'Villemontel', province: 'Quebec' },
  { city: 'Amqui', province: 'Quebec' },
  { city: 'Baie-Des-Sables', province: 'Quebec' },
  { city: 'Cabano', province: 'Quebec' },
  { city: 'Cacouna', province: 'Quebec' },
  { city: 'D gelis', province: 'Quebec' },
  { city: "L'isle-Verte", province: 'Quebec' },
  { city: 'La Pocati re', province: 'Quebec' },
  { city: 'Lac-Des-Aigles', province: 'Quebec' },
  { city: 'Le Bic', province: 'Quebec' },
  { city: 'Les Hauteurs', province: 'Quebec' },
  { city: 'Les M chins', province: 'Quebec' },
  { city: 'Matane', province: 'Quebec' },
  { city: 'Mont-Joli', province: 'Quebec' },
  { city: 'Notre-Dame-Du-Lac', province: 'Quebec' },
  { city: 'Poh n gamook', province: 'Quebec' },
  { city: 'Rimouski', province: 'Quebec' },
  { city: 'Rivi re-Bleue', province: 'Quebec' },
  { city: 'Rivi re-Du-Loup', province: 'Quebec' },
  { city: 'Saint-Andr ', province: 'Quebec' },
  { city: 'Saint-Cl ment', province: 'Quebec' },
  { city: 'Saint-Cyprien', province: 'Quebec' },
  { city: 'Saint- piphane', province: 'Quebec' },
  { city: 'Saint-Fabien', province: 'Quebec' },
  { city: 'Saint-Jean-De-Dieu', province: 'Quebec' },
  { city: 'Saint-Marcellin', province: 'Quebec' },
  { city: 'Saint-Michel-du-Squatec', province: 'Quebec' },
  { city: 'Saint-Mo se', province: 'Quebec' },
  { city: 'Saint-Narcisse', province: 'Quebec' },
  { city: 'Saint-Narcisse-De-Rimouski', province: 'Quebec' },
  { city: 'Saint-Pascal', province: 'Quebec' },
  { city: 'Sayabec', province: 'Quebec' },
  { city: 'Temiscouata-Sur-Le-Lac', province: 'Quebec' },
  { city: 'Trois-Pistoles', province: 'Quebec' },
  { city: 'Baie-Saint-Paul', province: 'Quebec' },
  { city: 'Baie-Sainte-Catherine', province: 'Quebec' },
  { city: 'Beaupr ', province: 'Quebec' },
  { city: 'Courcelette', province: 'Quebec' },
  { city: 'Donnacona', province: 'Quebec' },
  { city: "L'ancienne-Lorette", province: 'Quebec' },
  { city: 'La Malbaie', province: 'Quebec' },
  { city: 'Limoilou', province: 'Quebec' },
  { city: 'Pont-Rouge', province: 'Quebec' },
  { city: 'Portneuf', province: 'Quebec' },
  { city: 'Qu bec', province: 'Quebec' },
  { city: 'Rivi re- -Pierre', province: 'Quebec' },
  { city: 'SAINT-AUGUSTIN-DE-DESMAURES', province: 'Quebec' },
  { city: 'Saint-Basile', province: 'Quebec' },
  { city: 'Saint-Bernard-Sur-Mer', province: 'Quebec' },
  { city: 'Saint-Ferr ol-Les-Neiges', province: 'Quebec' },
  { city: 'Saint-Marc-des-Carri res', province: 'Quebec' },
  { city: "Saint-Pierre-de-L' le-D'Orl ans", province: 'Quebec' },
  { city: 'Saint-Raymond', province: 'Quebec' },
  { city: 'Saint-Sim on', province: 'Quebec' },
  { city: 'Saint-Ubalde', province: 'Quebec' },
  { city: 'Sainte-Anne-De-Beaupr ', province: 'Quebec' },
  { city: 'Sainte-Catherine-De-La-Jacques-Cartier', province: 'Quebec' },
  { city: 'Ste-Catherine-De-La-J-Cartier', province: 'Quebec' },
  { city: 'Wendake', province: 'Quebec' },
  { city: 'Becancour', province: 'Quebec' },
  { city: 'Bernierville', province: 'Quebec' },
  { city: 'Inverness,qu', province: 'Quebec' },
  { city: 'Princeville', province: 'Quebec' },
  { city: 'Sainte-monique', province: 'Quebec' },
  { city: 'B cancour', province: 'Quebec' },
  { city: 'Drummondville', province: 'Quebec' },
  { city: 'Fortierville', province: 'Quebec' },
  { city: 'Ham-Nord', province: 'Quebec' },
  { city: 'Kingsey Falls', province: 'Quebec' },
  { city: 'Nicolet', province: 'Quebec' },
  { city: 'Notre-Dame-du-Bon-Conseil', province: 'Quebec' },
  { city: 'Odanak', province: 'Quebec' },
  { city: 'Pierreville', province: 'Quebec' },
  { city: 'Saint-Guillaume', province: 'Quebec' },
  { city: "Saint-L onard-D'Aston", province: 'Quebec' },
  { city: 'Sainte- lizabeth-de-Warwick', province: 'Quebec' },
  { city: 'Wickham', province: 'Quebec' },
  { city: 'W linak', province: 'Quebec' },
  { city: 'Drummondville', province: 'Quebec' },
  { city: 'Plessisville', province: 'Quebec' },
  { city: 'Saint-Ferdinand', province: 'Quebec' },
  { city: 'Victoriaville', province: 'Quebec' },
  { city: 'Warwick', province: 'Quebec' },
  { city: 'Levis', province: 'Quebec' },
  { city: 'Lislet', province: 'Quebec' },
  { city: 'Saint-georges-de-beauce', province: 'Quebec' },
  { city: 'Saint-jean-chrysostome', province: 'Quebec' },
  { city: 'Saint-michel-de-bellechasse', province: 'Quebec' },
  { city: 'Adstock', province: 'Quebec' },
  { city: 'Armagh', province: 'Quebec' },
  { city: 'Beauceville', province: 'Quebec' },
  { city: 'Charny', province: 'Quebec' },
  { city: 'Disraeli', province: 'Quebec' },
  { city: 'East Broughton', province: 'Quebec' },
  { city: 'East Broughton Station', province: 'Quebec' },
  { city: "L'islet", province: 'Quebec' },
  { city: "L'isletville", province: 'Quebec' },
  { city: 'La Guadeloupe', province: 'Quebec' },
  { city: 'Lac-Etchemin', province: 'Quebec' },
  { city: 'Lamartine', province: 'Quebec' },
  { city: 'Laurier-Station', province: 'Quebec' },
  { city: 'L vis', province: 'Quebec' },
  { city: 'Lisletville', province: 'Quebec' },
  { city: 'Montmagny', province: 'Quebec' },
  { city: 'Saint-Agapit', province: 'Quebec' },
  { city: 'Saint-Anselme', province: 'Quebec' },
  { city: "Saint-Antoine-de-L'Isle-aux-Grues", province: 'Quebec' },
  { city: 'Saint- phrem-De-Beauce', province: 'Quebec' },
  { city: 'Saint- tienne-De-Lauzon', province: 'Quebec' },
  { city: 'Saint-Fabien-De-Panet', province: 'Quebec' },
  { city: 'Saint-G d on-De-Beauce', province: 'Quebec' },
  { city: 'Saint-Georges', province: 'Quebec' },
  { city: 'Saint-Gervais', province: 'Quebec' },
  { city: 'Saint-Jean-Port-Joli', province: 'Quebec' },
  { city: 'Saint-Joseph-De-Beauce', province: 'Quebec' },
  { city: 'Saint-Lazare-De-Bellechasse', province: 'Quebec' },
  { city: 'Saint-Malachie', province: 'Quebec' },
  { city: 'Saint-Martin', province: 'Quebec' },
  { city: 'Saint-Nicolas', province: 'Quebec' },
  { city: 'Saint-Omer', province: 'Quebec' },
  { city: 'Saint-Pamphile', province: 'Quebec' },
  { city: 'Saint-Prosper', province: 'Quebec' },
  { city: 'Saint-Prosper-De-Dorchester', province: 'Quebec' },
  { city: 'Saint-Romuald', province: 'Quebec' },
  { city: 'Sainte-Claire', province: 'Quebec' },
  { city: 'Sainte-Croix', province: 'Quebec' },
  { city: 'Sainte-Marie', province: 'Quebec' },
  { city: 'Scott', province: 'Quebec' },
  { city: 'Thetford Mines', province: 'Quebec' },
  { city: 'Tourville', province: 'Quebec' },
  { city: 'Vall e-Jonction', province: 'Quebec' },
  { city: 'Aguanish', province: 'Quebec' },
  { city: 'Baie-Comeau', province: 'Quebec' },
  { city: 'Baie-Johan-Beetz', province: 'Quebec' },
  { city: 'Baie-Trinit ', province: 'Quebec' },
  { city: 'Chevery', province: 'Quebec' },
  { city: 'Chute-Aux-Outardes', province: 'Quebec' },
  { city: 'Essipit', province: 'Quebec' },
  { city: 'Forestville', province: 'Quebec' },
  { city: 'Franquelin', province: 'Quebec' },
  { city: 'Gethsemani', province: 'Quebec' },
  { city: 'Godbout', province: 'Quebec' },
  { city: 'Grandes-Bergeronnes', province: 'Quebec' },
  { city: 'Gros-M catina', province: 'Quebec' },
  { city: 'Harrington Harbour', province: 'Quebec' },
  { city: 'Havre-Saint-Pierre', province: 'Quebec' },
  { city: 'Kawawachikamach', province: 'Quebec' },
  { city: 'Kegaska', province: 'Quebec' },
  { city: 'La Tabati re', province: 'Quebec' },
  { city: 'Les Escoumins', province: 'Quebec' },
  { city: 'Longue-Pointe-De-Mingan', province: 'Quebec' },
  { city: 'Lourdes-De-Blanc-Sablon', province: 'Quebec' },
  { city: 'Mutton Bay', province: 'Quebec' },
  { city: 'Natashquan', province: 'Quebec' },
  { city: 'Old Fort Bay', province: 'Quebec' },
  { city: 'Pakuashipi', province: 'Quebec' },
  { city: 'Pessamit', province: 'Quebec' },
  { city: 'Port-Cartier', province: 'Quebec' },
  { city: 'Port-Menier', province: 'Quebec' },
  { city: 'Rivi re-Au-Tonnerre', province: 'Quebec' },
  { city: 'Rivi re-Saint-Jean', province: 'Quebec' },
  { city: 'Rivi re-Saint-Paul', province: 'Quebec' },
  { city: 'Sacr -Coeur', province: 'Quebec' },
  { city: 'Schefferville', province: 'Quebec' },
  { city: 'Sept- les', province: 'Quebec' },
  { city: 'T te- -La-Baleine', province: 'Quebec' },
  { city: 'Uashat', province: 'Quebec' },
  { city: 'Uashat-Maliotenam', province: 'Quebec' },
  { city: 'Asbestos', province: 'Quebec' },
  { city: 'Ascot', province: 'Quebec' },
  { city: 'Bromont', province: 'Quebec' },
  { city: 'Bromptonville', province: 'Quebec' },
  { city: 'Coaticook', province: 'Quebec' },
  { city: 'Cookshire-Eaton', province: 'Quebec' },
  { city: 'Cowansville', province: 'Quebec' },
  { city: 'Danville', province: 'Quebec' },
  { city: 'Deauville', province: 'Quebec' },
  { city: 'East Angus', province: 'Quebec' },
  { city: 'Farnham', province: 'Quebec' },
  { city: 'Fleurimont', province: 'Quebec' },
  { city: 'Granby', province: 'Quebec' },
  { city: 'La Patrie', province: 'Quebec' },
  { city: 'Lac-Brome', province: 'Quebec' },
  { city: 'Lac-Megantic', province: 'Quebec' },
  { city: 'Lac-M gantic', province: 'Quebec' },
  { city: 'Lambton', province: 'Quebec' },
  { city: 'Lennoxville', province: 'Quebec' },
  { city: 'Magog', province: 'Quebec' },
  { city: 'Rock forest', province: 'Quebec' },
  { city: 'Sherbrooke', province: 'Quebec' },
  { city: 'Sutton', province: 'Quebec' },
  { city: 'Val-des-Sources', province: 'Quebec' },
  { city: 'Valcourt', province: 'Quebec' },
  { city: 'Weedon', province: 'Quebec' },
  { city: 'Windsor,qu', province: 'Quebec' },
  { city: 'Pointe-a-la-croix', province: 'Quebec' },
  { city: 'Barachois', province: 'Quebec' },
  { city: 'Bassin', province: 'Quebec' },
  { city: 'Bonaventure', province: 'Quebec' },
  { city: 'Cap-Chat', province: 'Quebec' },
  { city: 'Caplan', province: 'Quebec' },
  { city: 'Carleton', province: 'Quebec' },
  { city: 'Grande-Rivi re-Ouest', province: 'Quebec' },
  { city: 'Grande-Vall e', province: 'Quebec' },
  { city: 'Grosse- le', province: 'Quebec' },
  { city: "L'ile-D'entree", province: 'Quebec' },
  { city: 'Maria', province: 'Quebec' },
  { city: 'Marsoui', province: 'Quebec' },
  { city: 'Matap dia', province: 'Quebec' },
  { city: 'Mont-Louis', province: 'Quebec' },
  { city: 'Murdochville', province: 'Quebec' },
  { city: 'Pasp biac', province: 'Quebec' },
  { city: 'Perc ', province: 'Quebec' },
  { city: 'Pointe- -La-Croix', province: 'Quebec' },
  { city: 'Port-Daniel-Gascons', province: 'Quebec' },
  { city: 'Rivi re-au-Renard', province: 'Quebec' },
  { city: 'Saint-Maxime-Du-Mont-Louis', province: 'Quebec' },
  { city: 'Cap-Aux-Meules', province: 'Quebec' },
  { city: 'Chandler', province: 'Quebec' },
  { city: 'Gasp ', province: 'Quebec' },
  { city: 'New Richmond', province: 'Quebec' },
  { city: 'Sainte-Anne-Des-Monts', province: 'Quebec' },
  { city: 'Crabtree', province: 'Quebec' },
  { city: "L''assomption", province: 'Quebec' },
  { city: 'Saint-charles borromee', province: 'Quebec' },
  { city: 'Saint-felix-de-valois', province: 'Quebec' },
  { city: 'Saint-lin', province: 'Quebec' },
  { city: "Saint-roch-de-l''achigan", province: 'Quebec' },
  { city: 'Saint-sulpice', province: 'Quebec' },
  { city: 'Berthierville', province: 'Quebec' },
  { city: 'Chertsey', province: 'Quebec' },
  { city: 'Joliette', province: 'Quebec' },
  { city: "L'Assomption", province: 'Quebec' },
  { city: "L' piphanie", province: 'Quebec' },
  { city: 'Lanoraie', province: 'Quebec' },
  { city: 'Lavaltrie', province: 'Quebec' },
  { city: 'Manawan', province: 'Quebec' },
  { city: 'Mascouche', province: 'Quebec' },
  { city: 'Notre-Dame-Des-Prairies', province: 'Quebec' },
  { city: 'Rawdon', province: 'Quebec' },
  { city: 'Repentigny', province: 'Quebec' },
  { city: 'Saint-Alphonse-Rodriguez', province: 'Quebec' },
  { city: 'Saint-Calixte', province: 'Quebec' },
  { city: 'Saint-Charles-Borrom e', province: 'Quebec' },
  { city: 'Saint-Donat', province: 'Quebec' },
  { city: 'Saint-Esprit', province: 'Quebec' },
  { city: 'Saint-Gabriel', province: 'Quebec' },
  { city: 'Saint-Gabriel-de-Brandon', province: 'Quebec' },
  { city: 'Saint-Jean-De-Matha', province: 'Quebec' },
  { city: 'Saint-Lin-Laurentides', province: 'Quebec' },
  { city: 'Saint-Michel-des-Saints', province: 'Quebec' },
  { city: "Saint-Roch-de-l'Achigan", province: 'Quebec' },
  { city: 'Sainte-Julienne', province: 'Quebec' },
  { city: 'Terrebonne', province: 'Quebec' },
  { city: 'Blainville', province: 'Quebec' },
  { city: 'Boisbriand', province: 'Quebec' },
  { city: 'Deux-Montagnes', province: 'Quebec' },
  { city: 'Grenville', province: 'Quebec' },
  { city: 'Labelle', province: 'Quebec' },
  { city: 'Lachute', province: 'Quebec' },
  { city: 'Mirabel', province: 'Quebec' },
  { city: 'Mont-Laurier', province: 'Quebec' },
  { city: 'Mont-Tremblant', province: 'Quebec' },
  { city: 'Notre-Dame-Du-Laus', province: 'Quebec' },
  { city: 'Oka', province: 'Quebec' },
  { city: 'Pointe-calumet', province: 'Quebec' },
  { city: 'Prevost', province: 'Quebec' },
  { city: 'Pr vost', province: 'Quebec' },
  { city: 'Rivi re-Rouge', province: 'Quebec' },
  { city: 'Rosemere', province: 'Quebec' },
  { city: 'Saint-canut', province: 'Quebec' },
  { city: 'Saint-Eustache', province: 'Quebec' },
  { city: 'Saint-Jerome', province: 'Quebec' },
  { city: 'Saint-J r me', province: 'Quebec' },
  { city: 'Saint-jovite', province: 'Quebec' },
  { city: 'Saint-Sauveur', province: 'Quebec' },
  { city: 'Sainte-adele', province: 'Quebec' },
  { city: 'Sainte-Ad le', province: 'Quebec' },
  { city: 'Sainte-Agathe-Des-Monts', province: 'Quebec' },
  { city: 'Sainte-therese', province: 'Quebec' },
  { city: 'Sainte-Th r se', province: 'Quebec' },
  { city: 'Auteuil', province: 'Quebec' },
  { city: 'Chomedey', province: 'Quebec' },
  { city: 'Duvernay', province: 'Quebec' },
  { city: 'Fabreville', province: 'Quebec' },
  { city: 'Laval', province: 'Quebec' },
  { city: 'Laval-des-Rapides', province: 'Quebec' },
  { city: 'Laval-Ouest', province: 'Quebec' },
  { city: 'Pont-Viau', province: 'Quebec' },
  { city: 'Saint-Fran ois', province: 'Quebec' },
  { city: 'Saint-Vincent-de-Paul', province: 'Quebec' },
  { city: 'Sainte-Rose', province: 'Quebec' },
  { city: 'Vimont', province: 'Quebec' },
  { city: 'Bic', province: 'Quebec' },
  { city: 'Causapscal', province: 'Quebec' },
  { city: 'La pocatiere', province: 'Quebec' },
  { city: 'Saint-epiphane', province: 'Quebec' },
  { city: 'Cap-de-la-madeleine', province: 'Quebec' },
  { city: 'Grand-Mere', province: 'Quebec' },
  { city: 'Grand-M re', province: 'Quebec' },
  { city: 'La Tuque', province: 'Quebec' },
  { city: 'Louiseville', province: 'Quebec' },
  { city: 'Notre-Dame-Du-Mont-Carmel', province: 'Quebec' },
  { city: 'Obedjiwan', province: 'Quebec' },
  { city: 'Parent', province: 'Quebec' },
  { city: 'Saint- lie-de-Caxton', province: 'Quebec' },
  { city: 'Saint-Paulin', province: 'Quebec' },
  { city: 'Saint-Tite', province: 'Quebec' },
  { city: 'Sainte-Anne-De-La-P rade', province: 'Quebec' },
  { city: 'Sainte-Genevi ve-De-Batiscan', province: 'Quebec' },
  { city: 'Sainte-Th cle', province: 'Quebec' },
  { city: 'Shawinigan', province: 'Quebec' },
  { city: 'Shawinigan-Sud', province: 'Quebec' },
  { city: 'Trois-Rivieres', province: 'Quebec' },
  { city: 'Trois-Rivi res', province: 'Quebec' },
  { city: 'Wemotaci', province: 'Quebec' },
  { city: 'Bedford,qu', province: 'Quebec' },
  { city: 'Iberville', province: 'Quebec' },
  { city: "L''ile-perrot", province: 'Quebec' },
  { city: 'Lemoyne', province: 'Quebec' },
  { city: 'Mcmasterville', province: 'Quebec' },
  { city: 'Saint-hubert', province: 'Quebec' },
  { city: 'Valleyfield', province: 'Quebec' },
  { city: 'Acton Vale', province: 'Quebec' },
  { city: 'Akwesasne', province: 'Quebec' },
  { city: 'Beauharnois', province: 'Quebec' },
  { city: 'Beloeil', province: 'Quebec' },
  { city: 'Boucherville', province: 'Quebec' },
  { city: 'Brossard', province: 'Quebec' },
  { city: 'Candiac', province: 'Quebec' },
  { city: 'Chambly', province: 'Quebec' },
  { city: 'Ch teauguay', province: 'Quebec' },
  { city: 'Contrecoeur', province: 'Quebec' },
  { city: 'Coteau-Du-Lac', province: 'Quebec' },
  { city: 'Greenfield Park', province: 'Quebec' },
  { city: 'Henryville', province: 'Quebec' },
  { city: 'Huntingdon', province: 'Quebec' },
  { city: 'Kahnawake', province: 'Quebec' },
  { city: "L' le-Perrot", province: 'Quebec' },
  { city: 'Lacolle', province: 'Quebec' },
  { city: 'Longueuil', province: 'Quebec' },
  { city: 'Marieville', province: 'Quebec' },
  { city: 'Mont-Saint-Hilaire', province: 'Quebec' },
  { city: 'Napierville', province: 'Quebec' },
  { city: 'Ormstown', province: 'Quebec' },
  { city: 'Richelieu', province: 'Quebec' },
  { city: 'Rigaud', province: 'Quebec' },
  { city: 'Saint-Amable', province: 'Quebec' },
  { city: 'Saint-Basile-Le-Grand', province: 'Quebec' },
  { city: 'Saint-Bruno-De-Montarville', province: 'Quebec' },
  { city: 'Saint-C saire', province: 'Quebec' },
  { city: 'Saint-Chrysostome', province: 'Quebec' },
  { city: 'Saint-Constant', province: 'Quebec' },
  { city: 'Saint-Hyacinthe', province: 'Quebec' },
  { city: 'Saint-Jean-Baptiste', province: 'Quebec' },
  { city: 'Saint-Jean-Sur-Richelieu', province: 'Quebec' },
  { city: 'Saint-Jude', province: 'Quebec' },
  { city: 'Saint-Lambert', province: 'Quebec' },
  { city: 'Saint-Philippe', province: 'Quebec' },
  { city: 'Saint-Polycarpe', province: 'Quebec' },
  { city: 'Saint-R mi', province: 'Quebec' },
  { city: 'Saint-Zotique', province: 'Quebec' },
  { city: "Sainte-Brigide-d'Iberville", province: 'Quebec' },
  { city: 'Sainte-Julie', province: 'Quebec' },
  { city: 'Salaberry-De-Valleyfield', province: 'Quebec' },
  { city: 'Sorel-Tracy', province: 'Quebec' },
  { city: 'Varennes', province: 'Quebec' },
  { city: 'Vaudreuil-Dorion', province: 'Quebec' },
  { city: 'Verch res', province: 'Quebec' },
  { city: 'Beaconsfield', province: 'Quebec' },
  { city: 'Dollard-des-ormeaux', province: 'Quebec' },
  { city: 'Mercier', province: 'Quebec' },
  { city: 'Outremont', province: 'Quebec' },
  { city: 'Sainte-anne-de-bellevue', province: 'Quebec' },
  { city: 'Ahuntsic-Cartierville', province: 'Quebec' },
  { city: 'Anjou', province: 'Quebec' },
  { city: 'C te-Des-Neiges-Notre-Dame-De-Gr ce', province: 'Quebec' },
  { city: 'C te-Saint-Luc', province: 'Quebec' },
  { city: 'Dollard-Des Ormeaux', province: 'Quebec' },
  { city: 'Dorval', province: 'Quebec' },
  { city: 'Kirkland', province: 'Quebec' },
  { city: "L' le-Bizard-Sainte-Genevi ve", province: 'Quebec' },
  { city: 'Lachine', province: 'Quebec' },
  { city: 'LaSalle', province: 'Quebec' },
  { city: 'Le Plateau-Mont-Royal', province: 'Quebec' },
  { city: 'Le Sud-Ouest', province: 'Quebec' },
  { city: 'Mercier-Hochelaga-Maisonneuve', province: 'Quebec' },
  { city: 'Mont-Royal', province: 'Quebec' },
  { city: 'Montreal', province: 'Quebec' },
  { city: 'Montr al', province: 'Quebec' },
  { city: 'Montr al-Est', province: 'Quebec' },
  { city: 'Montr al-Nord', province: 'Quebec' },
  { city: 'Montr al-Ouest', province: 'Quebec' },
  { city: 'Pierrefonds', province: 'Quebec' },
  { city: 'Pierrefonds-Roxboro', province: 'Quebec' },
  { city: 'Pointe-Claire', province: 'Quebec' },
  { city: 'Rivi re-des-Prairies-Pointe-aux-Trembles', province: 'Quebec' },
  { city: 'Rosemont', province: 'Quebec' },
  { city: 'Rosemont-La Petite-Patrie', province: 'Quebec' },
  { city: 'Saint-Laurent', province: 'Quebec' },
  { city: 'Saint-L onard', province: 'Quebec' },
  { city: 'Verdun', province: 'Quebec' },
  { city: 'Ville-Marie', province: 'Quebec' },
  { city: 'Villeray-Saint-Michel-Parc-Extension', province: 'Quebec' },
  { city: 'Westmount', province: 'Quebec' },
  { city: 'Ancienne lorette', province: 'Quebec' },
  { city: 'Boischatel', province: 'Quebec' },
  { city: 'Charlesbourg', province: 'Quebec' },
  { city: 'Clermont', province: 'Quebec' },
  { city: 'Limoilou', province: 'Quebec' },
  { city: 'Lorette', province: 'Quebec' },
  { city: 'Loretteville', province: 'Quebec' },
  { city: 'Neufchatel', province: 'Quebec' },
  { city: 'Saint raymond de portneuf', province: 'Quebec' },
  { city: 'Sainte-foy', province: 'Quebec' },
  { city: 'Sillery', province: 'Quebec' },
  { city: 'Vanier,qu', province: 'Quebec' },
  { city: 'Village-des-hurons', province: 'Quebec' },
  { city: 'Akulivik', province: 'Quebec' },
  { city: 'Aupaluk', province: 'Quebec' },
  { city: 'Chapais', province: 'Quebec' },
  { city: 'Chisasibi', province: 'Quebec' },
  { city: 'Eastmain', province: 'Quebec' },
  { city: 'Inukjuak', province: 'Quebec' },
  { city: 'Ivujivik', province: 'Quebec' },
  { city: 'Kangiqsualujjuaq', province: 'Quebec' },
  { city: 'Kangiqsujuaq', province: 'Quebec' },
  { city: 'Kangirsuk', province: 'Quebec' },
  { city: 'Kuujjuarapik', province: 'Quebec' },
  { city: 'Lebel-Sur-Qu villon', province: 'Quebec' },
  { city: 'Matagami', province: 'Quebec' },
  { city: 'Matimekosh Lac-John', province: 'Quebec' },
  { city: 'Mistissini', province: 'Quebec' },
  { city: 'Nemaska', province: 'Quebec' },
  { city: 'N miscau', province: 'Quebec' },
  { city: 'Ouj -Bougoumou', province: 'Quebec' },
  { city: 'Puvirnituq', province: 'Quebec' },
  { city: 'Quaqtaq', province: 'Quebec' },
  { city: 'Radisson', province: 'Quebec' },
  { city: 'Salluit', province: 'Quebec' },
  { city: 'Tasiujaq', province: 'Quebec' },
  { city: 'Terres-Cries-de-la-Baie-James', province: 'Quebec' },
  { city: 'Umiujaq', province: 'Quebec' },
  { city: 'Waskaganish', province: 'Quebec' },
  { city: 'Waswanipi', province: 'Quebec' },
  { city: 'Wemindji', province: 'Quebec' },
  { city: 'Beaucanton', province: 'Quebec' },
  { city: 'Chibougamau', province: 'Quebec' },
  { city: 'Kuujjuaq', province: 'Quebec' },
  { city: 'Baie-trinite', province: 'Quebec' },
  { city: 'Fermont', province: 'Quebec' },
  { city: 'Lourdes-du-blanc-sablon', province: 'Quebec' },
  { city: 'Pointe-aux-outardes', province: 'Quebec' },
  { city: 'Port cartier', province: 'Quebec' },
  { city: 'Ragueneau', province: 'Quebec' },
  { city: 'Sept-iles', province: 'Quebec' },
  { city: 'Lebel-sur-quevillon', province: 'Quebec' },
  { city: 'Buckingham', province: 'Quebec' },
  { city: "Campbell''s bay", province: 'Quebec' },
  { city: "Campbell's Bay", province: 'Quebec' },
  { city: 'Campbells Bay', province: 'Quebec' },
  { city: 'Cantley', province: 'Quebec' },
  { city: 'Chapeau', province: 'Quebec' },
  { city: 'Chelsea', province: 'Quebec' },
  { city: 'Davidson', province: 'Quebec' },
  { city: 'Fort-Coulonge', province: 'Quebec' },
  { city: 'Gatineau', province: 'Quebec' },
  { city: 'Gracefield', province: 'Quebec' },
  { city: 'Hull', province: 'Quebec' },
  { city: 'La P che', province: 'Quebec' },
  { city: 'Lac-Rapide', province: 'Quebec' },
  { city: 'Low', province: 'Quebec' },
  { city: 'Maniwaki', province: 'Quebec' },
  { city: 'Mansfield-Et-Pontefract', province: 'Quebec' },
  { city: 'Masham', province: 'Quebec' },
  { city: 'Montpellier', province: 'Quebec' },
  { city: 'Otter Lake', province: 'Quebec' },
  { city: 'Papineauville', province: 'Quebec' },
  { city: 'Quyon', province: 'Quebec' },
  { city: 'Rapides-des-Joachims', province: 'Quebec' },
  { city: 'Ripon', province: 'Quebec' },
  { city: 'Saint-andre-avellin', province: 'Quebec' },
  { city: 'Saint-Andr -Avellin', province: 'Quebec' },
  { city: 'Shawville', province: 'Quebec' },
  { city: 'Thurso', province: 'Quebec' },
  { city: 'Val-Des-Bois', province: 'Quebec' },
  { city: 'Val-Des-Monts', province: 'Quebec' },
  { city: 'Wakefield', province: 'Quebec' },
  { city: 'Albanel', province: 'Quebec' },
  { city: 'Alma', province: 'Quebec' },
  { city: 'Alouette', province: 'Quebec' },
  { city: 'Chambord', province: 'Quebec' },
  { city: 'Chicoutimi', province: 'Quebec' },
  { city: 'Desbiens', province: 'Quebec' },
  { city: 'Dolbeau-Mistassini', province: 'Quebec' },
  { city: 'Girardville', province: 'Quebec' },
  { city: 'Hebertville', province: 'Quebec' },
  { city: 'H bertville', province: 'Quebec' },
  { city: 'Jonquiere', province: 'Quebec' },
  { city: 'Jonqui re', province: 'Quebec' },
  { city: "L'anse-Saint-Jean", province: 'Quebec' },
  { city: "L'ascension-De-Notre-Seigneur", province: 'Quebec' },
  { city: 'La Baie', province: 'Quebec' },
  { city: 'La dore', province: 'Quebec' },
  { city: 'La Dor ', province: 'Quebec' },
  { city: 'Lac-a-la-croix', province: 'Quebec' },
  { city: 'Laterriere', province: 'Quebec' },
  { city: 'Laterri re', province: 'Quebec' },
  { city: 'Mashteuiatsh', province: 'Quebec' },
  { city: 'Metabetchouan', province: 'Quebec' },
  { city: 'M tabetchouan-Lac- -La-Croix', province: 'Quebec' },
  { city: 'Normandin', province: 'Quebec' },
  { city: 'Pointe bleue', province: 'Quebec' },
  { city: 'Roberval', province: 'Quebec' },
  { city: 'Sacre-coeur', province: 'Quebec' },
  { city: 'Sacr -Coeur-Saguenay', province: 'Quebec' },
  { city: 'Saint-Ambroise', province: 'Quebec' },
  { city: 'Saint-Andr -Du-Lac-Saint-Jean', province: 'Quebec' },
  { city: 'Saint-andre-du-lac-st-jean', province: 'Quebec' },
  { city: 'Saint-Augustin', province: 'Quebec' },
  { city: 'Saint-bruno', province: 'Quebec' },
  { city: 'Saint-Charles-De-Bourget', province: 'Quebec' },
  { city: 'Saint-David-de-Falardeau', province: 'Quebec' },
  { city: 'Saint-felicien', province: 'Quebec' },
  { city: 'Saint-F licien', province: 'Quebec' },
  { city: "Saint-felix-d''otis", province: 'Quebec' },
  { city: "Saint-Felix-D'Otis", province: 'Quebec' },
  { city: "Saint-F lix-D'Otis", province: 'Quebec' },
  { city: 'Saint-gedeon', province: 'Quebec' },
  { city: 'Saint-G d on', province: 'Quebec' },
  { city: 'Saint-honore', province: 'Quebec' },
  { city: 'Saint-Honor ', province: 'Quebec' },
  { city: 'Saint-Prime', province: 'Quebec' },
  { city: 'Saint-thomas-didyme', province: 'Quebec' },
  { city: 'Shipshaw', province: 'Quebec' },
  { city: 'Sorel', province: 'Quebec' },
  { city: 'Taschereau', province: 'Quebec' },
  { city: 'Tracy', province: 'Quebec' },
  { city: '108 Mile House', province: 'Quebec' },
  { city: 'Aggassiz', province: 'Quebec' },
  { city: 'Esquimalt', province: 'Quebec' },
  { city: 'Qualicum', province: 'Quebec' },
  { city: 'Opaskwayak', province: 'Quebec' },
  { city: 'Eel river', province: 'Quebec' },
  { city: 'Sackville', province: 'Quebec' },
  { city: "Lutsel k''e", province: 'Quebec' },
  { city: 'Rae lakes', province: 'Quebec' },
  { city: 'Millbrook', province: 'Quebec' },
  { city: 'Bloomfield Station', province: 'Quebec' },
  { city: 'Coleman', province: 'Quebec' },
  { city: 'St-Peters Bay', province: 'Quebec' },
  { city: 'Beauport', province: 'Quebec' },
  { city: 'Beaupre', province: 'Quebec' },
  { city: 'Bernieres', province: 'Quebec' },
  { city: 'Black lake,qu', province: 'Quebec' },
  { city: 'Chateauguay', province: 'Quebec' },
  { city: 'Dolbeau', province: 'Quebec' },
  { city: 'Fatima', province: 'Quebec' },
  { city: 'Gascons', province: 'Quebec' },
  { city: 'Gentilly', province: 'Quebec' },
  { city: 'Gesgapegiag', province: 'Quebec' },
  { city: 'Grande-Rivi re', province: 'Quebec' },
  { city: 'Iles-de-la-madeleine', province: 'Quebec' },
  { city: 'Kingsey-falls', province: 'Quebec' },
  { city: 'La peche', province: 'Quebec' },
  { city: 'Lac-st-charles', province: 'Quebec' },
  { city: 'Laurentides', province: 'Quebec' },
  { city: 'Le gardeur', province: 'Quebec' },
  { city: 'Listuguj', province: 'Quebec' },
  { city: 'Lyster', province: 'Quebec' },
  { city: 'Pohenegamook', province: 'Quebec' },
  { city: 'Quebec', province: 'Quebec' },
  { city: 'Riviere-au-tonnerre', province: 'Quebec' },
  { city: 'Riviere-Du-Loup', province: 'Quebec' },
  { city: 'Saint-alphonse', province: 'Quebec' },
  { city: 'Saint-basile le grand', province: 'Quebec' },
  { city: 'Saint-elie-de-caxton', province: 'Quebec' },
  { city: 'Saint- tienne', province: 'Quebec' },
  { city: 'Saint-ferreol les neige', province: 'Quebec' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Quebec' },
  { city: 'Creighton', province: 'Quebec' },
  { city: 'Spiritwood', province: 'Quebec' },
  { city: 'Beatty', province: 'Saskatchewan' },
  { city: 'Canora', province: 'Saskatchewan' },
  { city: 'Carrot River', province: 'Saskatchewan' },
  { city: 'Chorney Beach', province: 'Saskatchewan' },
  { city: 'Day Star First Nation', province: 'Saskatchewan' },
  { city: 'Hudson Bay', province: 'Saskatchewan' },
  { city: 'Imperial', province: 'Saskatchewan' },
  { city: 'Invermay', province: 'Saskatchewan' },
  { city: 'Ituna', province: 'Saskatchewan' },
  { city: 'James Smith First Nation', province: 'Saskatchewan' },
  { city: 'Kamsack', province: 'Saskatchewan' },
  { city: 'Keeseekoose First Nation', province: 'Saskatchewan' },
  { city: 'Kelvington', province: 'Saskatchewan' },
  { city: 'Key First Nation', province: 'Saskatchewan' },
  { city: 'Kinistin Saulteaux First Nation', province: 'Saskatchewan' },
  { city: 'Kinistino', province: 'Saskatchewan' },
  { city: 'Lestock', province: 'Saskatchewan' },
  { city: 'Lockwood', province: 'Saskatchewan' },
  { city: 'Melfort', province: 'Saskatchewan' },
  { city: 'Muskoday', province: 'Saskatchewan' },
  { city: 'Muskoday First Nation', province: 'Saskatchewan' },
  { city: 'Naicam', province: 'Saskatchewan' },
  { city: 'Nipawin', province: 'Saskatchewan' },
  { city: 'Norquay', province: 'Saskatchewan' },
  { city: 'Porcupine Plain', province: 'Saskatchewan' },
  { city: 'Preeceville', province: 'Saskatchewan' },
  { city: 'Punnichy', province: 'Saskatchewan' },
  { city: 'Raymore', province: 'Saskatchewan' },
  { city: 'Red Earth', province: 'Saskatchewan' },
  { city: 'Rose Valley', province: 'Saskatchewan' },
  { city: 'Saltcoats', province: 'Saskatchewan' },
  { city: 'Strasbourg', province: 'Saskatchewan' },
  { city: 'The Key First Nation', province: 'Saskatchewan' },
  { city: 'Theodore', province: 'Saskatchewan' },
  { city: 'Tisdale', province: 'Saskatchewan' },
  { city: 'Wadena', province: 'Saskatchewan' },
  { city: 'Wynyard', province: 'Saskatchewan' },
  { city: 'Yorkton', province: 'Saskatchewan' },
  { city: 'Battleford', province: 'Saskatchewan' },
  { city: 'Beardys And Okemasis First Nation', province: 'Saskatchewan' },
  { city: 'Big Shell', province: 'Saskatchewan' },
  { city: 'Biggar', province: 'Saskatchewan' },
  { city: 'Birch Hills', province: 'Saskatchewan' },
  { city: 'Cando', province: 'Saskatchewan' },
  { city: 'Cochin', province: 'Saskatchewan' },
  { city: 'Craik', province: 'Saskatchewan' },
  { city: 'Cut Knife', province: 'Saskatchewan' },
  { city: 'Delisle', province: 'Saskatchewan' },
  { city: 'Dinsmore', province: 'Saskatchewan' },
  { city: 'Duck Lake', province: 'Saskatchewan' },
  { city: 'Dundurn', province: 'Saskatchewan' },
  { city: 'Edam', province: 'Saskatchewan' },
  { city: 'Elrose', province: 'Saskatchewan' },
  { city: 'Eston', province: 'Saskatchewan' },
  { city: 'Fishing Lake First Nation', province: 'Saskatchewan' },
  { city: 'Gallivan', province: 'Saskatchewan' },
  { city: 'Gordon First Nation', province: 'Saskatchewan' },
  { city: 'Hafford', province: 'Saskatchewan' },
  { city: 'Humboldt', province: 'Saskatchewan' },
  { city: 'Kerrobert', province: 'Saskatchewan' },
  { city: 'Kindersley', province: 'Saskatchewan' },
  { city: 'Leask', province: 'Saskatchewan' },
  { city: 'Little Pine First Nation', province: 'Saskatchewan' },
  { city: 'Lloydminster,sk', province: 'Saskatchewan' },
  { city: 'Macklin', province: 'Saskatchewan' },
  { city: 'Maidstone', province: 'Saskatchewan' },
  { city: 'Marcelin', province: 'Saskatchewan' },
  { city: 'Milden', province: 'Saskatchewan' },
  { city: 'Muskeg Lake First Nation', province: 'Saskatchewan' },
  { city: 'North Battleford', province: 'Saskatchewan' },
  { city: 'Osler', province: 'Saskatchewan' },
  { city: 'Outlook', province: 'Saskatchewan' },
  { city: 'Pakwaw Lake', province: 'Saskatchewan' },
  { city: 'Paradise Hill', province: 'Saskatchewan' },
  { city: 'Paynton', province: 'Saskatchewan' },
  { city: 'Poundmaker First Nation', province: 'Saskatchewan' },
  { city: 'Rosetown', province: 'Saskatchewan' },
  { city: 'Rosthern', province: 'Saskatchewan' },
  { city: 'Saskatchewan', province: 'Saskatchewan' },
  { city: 'Saskatoon', province: 'Saskatchewan' },
  { city: 'Saulteaux First Nation', province: 'Saskatchewan' },
  { city: 'Shields', province: 'Saskatchewan' },
  { city: 'Spiritwood', province: 'Saskatchewan' },
  { city: 'St Isidore De Bellevue', province: 'Saskatchewan' },
  { city: 'Turtleford', province: 'Saskatchewan' },
  { city: 'Unity', province: 'Saskatchewan' },
  { city: 'Wakaw', province: 'Saskatchewan' },
  { city: 'Wakaw Lake', province: 'Saskatchewan' },
  { city: 'Watrous', province: 'Saskatchewan' },
  { city: 'Whitecap Dakota First Nation', province: 'Saskatchewan' },
  { city: 'Wilkie', province: 'Saskatchewan' },
  { city: 'Lampman', province: 'Saskatchewan' },
  { city: 'Glaslyn', province: 'Saskatchewan' },
  { city: 'Kivimaa-Moonlight Bay', province: 'Saskatchewan' },
  { city: 'Goodeve', province: 'Saskatchewan' },
  { city: 'Ahtahkakoop First Nation', province: 'Saskatchewan' },
  { city: 'Beauval', province: 'Saskatchewan' },
  { city: 'Big River', province: 'Saskatchewan' },
  { city: 'Birch Narrows First Nation', province: 'Saskatchewan' },
  { city: 'Black Lake', province: 'Saskatchewan' },
  { city: 'Black Lake First Nation', province: 'Saskatchewan' },
  { city: 'Black lake,sk', province: 'Saskatchewan' },
  { city: 'Brabant Lake', province: 'Saskatchewan' },
  { city: 'Buffalo Narrows', province: 'Saskatchewan' },
  { city: 'Buffalo River Dene First Nation', province: 'Saskatchewan' },
  { city: 'Canoe Lake Cree First Nation', province: 'Saskatchewan' },
  { city: 'Canoe Narrows', province: 'Saskatchewan' },
  { city: 'Canwood', province: 'Saskatchewan' },
  { city: 'Choiceland', province: 'Saskatchewan' },
  { city: 'Clearwater River', province: 'Saskatchewan' },
  { city: 'Clearwater River Dene First Nation', province: 'Saskatchewan' },
  { city: 'Cumberland House', province: 'Saskatchewan' },
  { city: 'Cumberland House Cree First Nation', province: 'Saskatchewan' },
  { city: 'Debden', province: 'Saskatchewan' },
  { city: 'Dillon', province: 'Saskatchewan' },
  { city: 'Dore Lake', province: 'Saskatchewan' },
  { city: 'English River First Nation', province: 'Saskatchewan' },
  { city: 'Flying Dust First Nation', province: 'Saskatchewan' },
  { city: 'Fond Du Lac', province: 'Saskatchewan' },
  { city: 'Garson Lake', province: 'Saskatchewan' },
  { city: 'Goodsoil', province: 'Saskatchewan' },
  { city: 'Hatchet Lake First Nation', province: 'Saskatchewan' },
  { city: 'Ile-A-La-Crosse', province: 'Saskatchewan' },
  { city: 'La Loche', province: 'Saskatchewan' },
  { city: 'La loche,sk', province: 'Saskatchewan' },
  { city: 'La Ronge', province: 'Saskatchewan' },
  { city: 'La ronge,sk', province: 'Saskatchewan' },
  { city: 'Lac La Ronge First Nation', province: 'Saskatchewan' },
  { city: 'Leoville', province: 'Saskatchewan' },
  { city: 'Loon Lake', province: 'Saskatchewan' },
  { city: 'Meadow lake', province: 'Saskatchewan' },
  { city: 'Mont Nebo', province: 'Saskatchewan' },
  { city: 'Montreal Lake', province: 'Saskatchewan' },
  { city: 'Onion Lake', province: 'Saskatchewan' },
  { city: 'Onion Lake First Nation', province: 'Saskatchewan' },
  { city: 'Patuanak', province: 'Saskatchewan' },
  { city: 'Pelican Narrows', province: 'Saskatchewan' },
  { city: 'Pierceland', province: 'Saskatchewan' },
  { city: 'Pinehouse Lake', province: 'Saskatchewan' },
  { city: 'Prince Albert', province: 'Saskatchewan' },
  { city: 'Sandy Bay', province: 'Saskatchewan' },
  { city: 'Shellbrook', province: 'Saskatchewan' },
  { city: 'Smeaton', province: 'Saskatchewan' },
  { city: 'Southend', province: 'Saskatchewan' },
  { city: 'St Georges Hill', province: 'Saskatchewan' },
  { city: 'St Walburg', province: 'Saskatchewan' },
  { city: 'Stony Rapids', province: 'Saskatchewan' },
  { city: 'Sturgeon Lake First Nation', province: 'Saskatchewan' },
  { city: 'Sturgeon Landing', province: 'Saskatchewan' },
  { city: 'Thunderchild First Nation', province: 'Saskatchewan' },
  { city: 'Turnor Lake', province: 'Saskatchewan' },
  { city: 'Uranium City', province: 'Saskatchewan' },
  { city: 'Waterhen Lake', province: 'Saskatchewan' },
  { city: 'Weyakwin', province: 'Saskatchewan' },
  { city: 'Whitewood', province: 'Saskatchewan' },
  { city: 'Wollaston Lake', province: 'Saskatchewan' },
  { city: "Fort Qu'appelle", province: 'Saskatchewan' },
  { city: 'Fort Quappelle', province: 'Saskatchewan' },
  { city: "Qu'appelle", province: 'Saskatchewan' },
  { city: 'Regina', province: 'Saskatchewan' },
  { city: 'Lanigan', province: 'Saskatchewan' },
  { city: 'Arcola', province: 'Saskatchewan' },
  { city: 'B-Say-Tah', province: 'Saskatchewan' },
  { city: 'Balcarres', province: 'Saskatchewan' },
  { city: 'Beaubier', province: 'Saskatchewan' },
  { city: 'Broadview', province: 'Saskatchewan' },
  {
    city: 'Broadview And District Centennial Lodge',
    province: 'Saskatchewan',
  },
  { city: 'Carlyle', province: 'Saskatchewan' },
  { city: 'Cowessess', province: 'Saskatchewan' },
  { city: 'Cowessess First Nation', province: 'Saskatchewan' },
  { city: 'Cupar', province: 'Saskatchewan' },
  { city: 'Edenwold', province: 'Saskatchewan' },
  { city: 'Esterhazy', province: 'Saskatchewan' },
  { city: 'Estevan', province: 'Saskatchewan' },
  { city: 'Fort San', province: 'Saskatchewan' },
  { city: 'Grenfell', province: 'Saskatchewan' },
  { city: 'Indian Head', province: 'Saskatchewan' },
  { city: 'Kahkewistahaw First Nation', province: 'Saskatchewan' },
  { city: 'Kipling', province: 'Saskatchewan' },
  { city: 'Kisbey', province: 'Saskatchewan' },
  { city: 'Langenburg', province: 'Saskatchewan' },
  { city: 'Maryfield', province: 'Saskatchewan' },
  { city: 'Melville', province: 'Saskatchewan' },
  { city: 'Moosomin', province: 'Saskatchewan' },
  { city: 'Morse', province: 'Saskatchewan' },
  { city: 'Muscowpetung First Nation', province: 'Saskatchewan' },
  { city: 'Ocean Man First Nation', province: 'Saskatchewan' },
  { city: 'Ochapowace First Nation', province: 'Saskatchewan' },
  { city: 'Okanese First Nation', province: 'Saskatchewan' },
  { city: 'Oxbow', province: 'Saskatchewan' },
  { city: 'Pangman', province: 'Saskatchewan' },
  { city: 'Pasqua', province: 'Saskatchewan' },
  { city: 'Pasqua First Nation', province: 'Saskatchewan' },
  { city: 'Pheasant Rump Nakota First Nation', province: 'Saskatchewan' },
  { city: 'Pilot butte', province: 'Saskatchewan' },
  { city: 'Quill lake', province: 'Saskatchewan' },
  { city: 'Radville', province: 'Saskatchewan' },
  { city: 'Redvers', province: 'Saskatchewan' },
  { city: 'Sakimay First Nations', province: 'Saskatchewan' },
  { city: 'Sintaluta', province: 'Saskatchewan' },
  { city: 'Southey', province: 'Saskatchewan' },
  { city: 'Stoughton', province: 'Saskatchewan' },
  { city: 'Wawota', province: 'Saskatchewan' },
  { city: 'Weyburn', province: 'Saskatchewan' },
  { city: 'White Bear First Nation', province: 'Saskatchewan' },
  { city: 'Wolseley', province: 'Saskatchewan' },
  { city: 'Zehner', province: 'Saskatchewan' },
  { city: 'Assiniboia', province: 'Saskatchewan' },
  { city: 'Beechy', province: 'Saskatchewan' },
  { city: 'Cabri', province: 'Saskatchewan' },
  { city: 'Central Butte', province: 'Saskatchewan' },
  { city: 'Climax', province: 'Saskatchewan' },
  { city: 'Coronach', province: 'Saskatchewan' },
  { city: 'Eastend', province: 'Saskatchewan' },
  { city: 'Gravelbourg', province: 'Saskatchewan' },
  { city: 'Gull Lake', province: 'Saskatchewan' },
  { city: 'Herbert', province: 'Saskatchewan' },
  { city: 'Hodgeville', province: 'Saskatchewan' },
  { city: 'Kyle', province: 'Saskatchewan' },
  { city: 'Lafleche', province: 'Saskatchewan' },
  { city: 'Leader', province: 'Saskatchewan' },
  { city: 'Lucky Lake', province: 'Saskatchewan' },
  { city: 'Mankota', province: 'Saskatchewan' },
  { city: 'Maple Creek', province: 'Saskatchewan' },
  { city: 'Moose Jaw', province: 'Saskatchewan' },
  { city: 'Mossbank', province: 'Saskatchewan' },
  { city: 'Nekaneet First Nation', province: 'Saskatchewan' },
  { city: 'Orkney', province: 'Saskatchewan' },
  { city: 'Piapot', province: 'Saskatchewan' },
  { city: 'Ponteix', province: 'Saskatchewan' },
  { city: 'Rockglen', province: 'Saskatchewan' },
  { city: 'Shaunavon', province: 'Saskatchewan' },
  { city: 'Swift Current', province: 'Saskatchewan' },
  { city: 'Vanguard', province: 'Saskatchewan' },
  { city: 'Willow Bunch', province: 'Saskatchewan' },
  { city: 'Wood Mountain First Nation', province: 'Saskatchewan' },
  { city: 'Yellow Quill', province: 'Saskatchewan' },
  { city: '108 Mile House', province: 'Saskatchewan' },
  { city: 'Aggassiz', province: 'Saskatchewan' },
  { city: 'Esquimalt', province: 'Saskatchewan' },
  { city: 'Qualicum', province: 'Saskatchewan' },
  { city: 'Opaskwayak', province: 'Saskatchewan' },
  { city: 'Eel river', province: 'Saskatchewan' },
  { city: 'Sackville', province: 'Saskatchewan' },
  { city: "Lutsel k''e", province: 'Saskatchewan' },
  { city: 'Rae lakes', province: 'Saskatchewan' },
  { city: 'Millbrook', province: 'Saskatchewan' },
  { city: 'Bloomfield Station', province: 'Saskatchewan' },
  { city: 'Coleman', province: 'Saskatchewan' },
  { city: 'St-Peters Bay', province: 'Saskatchewan' },
  { city: 'Beauport', province: 'Saskatchewan' },
  { city: 'Beaupre', province: 'Saskatchewan' },
  { city: 'Bernieres', province: 'Saskatchewan' },
  { city: 'Black lake,qu', province: 'Saskatchewan' },
  { city: 'Chateauguay', province: 'Saskatchewan' },
  { city: 'Dolbeau', province: 'Saskatchewan' },
  { city: 'Fatima', province: 'Saskatchewan' },
  { city: 'Gascons', province: 'Saskatchewan' },
  { city: 'Gentilly', province: 'Saskatchewan' },
  { city: 'Gesgapegiag', province: 'Saskatchewan' },
  { city: 'Grande-Rivi re', province: 'Saskatchewan' },
  { city: 'Iles-de-la-madeleine', province: 'Saskatchewan' },
  { city: 'Kingsey-falls', province: 'Saskatchewan' },
  { city: 'La peche', province: 'Saskatchewan' },
  { city: 'Lac-st-charles', province: 'Saskatchewan' },
  { city: 'Laurentides', province: 'Saskatchewan' },
  { city: 'Le gardeur', province: 'Saskatchewan' },
  { city: 'Listuguj', province: 'Saskatchewan' },
  { city: 'Lyster', province: 'Saskatchewan' },
  { city: 'Pohenegamook', province: 'Saskatchewan' },
  { city: 'Quebec', province: 'Saskatchewan' },
  { city: 'Riviere-au-tonnerre', province: 'Saskatchewan' },
  { city: 'Riviere-Du-Loup', province: 'Saskatchewan' },
  { city: 'Saint-alphonse', province: 'Saskatchewan' },
  { city: 'Saint-basile le grand', province: 'Saskatchewan' },
  { city: 'Saint-elie-de-caxton', province: 'Saskatchewan' },
  { city: 'Saint- tienne', province: 'Saskatchewan' },
  { city: 'Saint-ferreol les neige', province: 'Saskatchewan' },
  { city: 'Saint-Pierre-Ile-Dorleans', province: 'Saskatchewan' },
  { city: 'Creighton', province: 'Saskatchewan' },
  { city: 'Spiritwood', province: 'Saskatchewan' },
  { city: 'Beaver Creek', province: 'Yukon' },
  { city: 'Burwash Landing', province: 'Yukon' },
  { city: 'Destruction Bay', province: 'Yukon' },
  { city: 'Haines Junction', province: 'Yukon' },
  { city: 'Watson lake', province: 'Yukon' },
  { city: 'Faro', province: 'Yukon' },
  { city: 'Ross River', province: 'Yukon' },
  { city: 'Brochet', province: 'Yukon' },
  { city: 'Churchill', province: 'Yukon' },
  { city: 'Cormorant', province: 'Yukon' },
  { city: 'Cranberry Portage', province: 'Yukon' },
  { city: 'Cross Lake', province: 'Yukon' },
  { city: 'Easterville', province: 'Yukon' },
  { city: 'Flin Flon', province: 'Yukon' },
  { city: 'Gillam', province: 'Yukon' },
  { city: 'Gods Lake Narrows', province: 'Yukon' },
  { city: 'Gods River', province: 'Yukon' },
  { city: 'Grand Rapids', province: 'Yukon' },
  { city: 'Ilford', province: 'Yukon' },
  { city: 'Island Lake', province: 'Yukon' },
  { city: 'Lac Brochet', province: 'Yukon' },
  { city: 'Leaf Rapids', province: 'Yukon' },
  { city: 'Lynn Lake', province: 'Yukon' },
  { city: 'Moose Lake', province: 'Yukon' },
  { city: 'Nelson House', province: 'Yukon' },
  { city: 'Norway House', province: 'Yukon' },
  { city: 'Oxford House', province: 'Yukon' },
  { city: 'Pikwitonei', province: 'Yukon' },
  { city: 'Pukatawagan', province: 'Yukon' },
  { city: 'Red Sucker Lake', province: 'Yukon' },
  { city: 'Shamattawa', province: 'Yukon' },
  { city: 'Sherridon', province: 'Yukon' },
  { city: 'Snow Lake', province: 'Yukon' },
  { city: 'South Indian Lake', province: 'Yukon' },
  { city: 'Split Lake', province: 'Yukon' },
  { city: 'St Theresa Point', province: 'Yukon' },
  { city: 'Tadoule Lake', province: 'Yukon' },
  { city: 'The Pas', province: 'Yukon' },
  { city: 'Thicket Portage', province: 'Yukon' },
  { city: 'Thompson', province: 'Yukon' },
  { city: 'Waasagamach', province: 'Yukon' },
  { city: 'Waasagomach', province: 'Yukon' },
  { city: 'Wabowden', province: 'Yukon' },
  { city: 'York Landing', province: 'Yukon' },
  { city: 'Old crow', province: 'Yukon' },
  { city: 'Dawson', province: 'Yukon' },
  { city: 'Carmacks', province: 'Yukon' },
  { city: 'Mayo', province: 'Yukon' },
  { city: 'Pelly Crossing', province: 'Yukon' },
  { city: 'Carcross', province: 'Yukon' },
  { city: 'Whitehorse', province: 'Yukon' },
  { city: 'Teslin', province: 'Yukon' },
];

export const allCitiesMap = citiesByProvince.reduce(
  (acc: Record<string, string>, value) => {
    const city = value.city;
    acc[city] = value.province;
    return acc;
  },
  {},
);

export default citiesByProvince;
