import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalParagraph } from 'components/Shared/ModalTitles/ModalParagraph';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { STATUS_ACTIVATE, STATUS_DEACTIVATE } from 'global-constants/sites';
import { tableHiddenColumnsInitialState } from 'helpers/sites.helpers';
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { Site } from 'types/entities/sites';
import { intl } from 'utilities/i18n/intl.utility';

const StyledStatusUpperCaseStrong = styled.strong`
  text-transform: uppercase;
`;

export const UpdateStatusModal: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (rowIndex: number, values: Site) => void;
  columns: MRT_ColumnDef<Site>[];
  rowToActivateOrDeactivate: MRT_Row<Site>;
}> = ({ open, onClose, onSubmit, columns, rowToActivateOrDeactivate }) => {
  const handleSubmit = () => {
    onSubmit(
      rowToActivateOrDeactivate.index,
      rowToActivateOrDeactivate.original,
    );
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left">
        {intl.translate({
          id: 'Update Site Status',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <ModalParagraph>
          Review the following record details and confirm to{' '}
          <StyledStatusUpperCaseStrong>
            {rowToActivateOrDeactivate.original.isActive
              ? STATUS_DEACTIVATE
              : STATUS_ACTIVATE}
          </StyledStatusUpperCaseStrong>
        </ModalParagraph>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <MaterialReactTable
              columns={columns}
              defaultColumn={{
                minSize: 20,
                maxSize: 160,
                size: 50,
              }}
              data={[rowToActivateOrDeactivate.original]}
              initialState={{
                columnVisibility: { ...tableHiddenColumnsInitialState },
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>Cancel</CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {rowToActivateOrDeactivate.original.isActive
              ? STATUS_DEACTIVATE
              : STATUS_ACTIVATE}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
