import { toast } from 'components/Toast/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomTemplates } from 'types/entities/customTemplates';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchCustomTemplates(): Promise<CustomTemplates[]> {
  try {
    const response = await http.get<BaseAPIResponse<CustomTemplates[]>>(
      `/custom-template`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Custom Templates');
    }
  } catch (err: unknown) {
    toast.error('Error List  Custom Templates', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Custom Templates: ${err}`);
  }
}

export async function createTemplate(
  name: string,
  template: string,
): Promise<CustomTemplates> {
  try {
    const response = await http.post<BaseAPIResponse<CustomTemplates>>(
      `/custom-template`,
      {
        name,
        template
      },
    );

    if (response.success) {
      toast.success('New custom template creation has been requested', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Custom Template');
    }
  } catch (err: unknown) {
    toast.error('Error: Create New Custom Template. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Custom Template: ${err}`);
  }
}
