import { toast } from 'components/Toast/Toast';
import { EntityStatusEnum } from 'types/data-management';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Site } from 'types/entities/sites';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchStagedSites(
  pageIndex: number,
  pageSize: number,
): Promise<Site[]> {
  try {
    const response = await http.get<BaseAPIResponse<Site[]>>(
      `/staged-site`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Fetch Staged Sites');
    }
  } catch (err: unknown) {
    toast.error('Error: Fetch Staged Sites', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Fetch Staged Sites: ${err}`);
  }
}

export async function getSiteById(
  siteId: string,
  token: string,
  isRefresh: boolean,
): Promise<Site> {
  try {
    const response = await http.get<BaseAPIResponse<Site>>(
      `/staged-site/${siteId}/${token}/${isRefresh}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Fetch Staged Site');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Fetch Staged Site: ${err}`);
  }
}

export async function getOriginalSiteById(
  siteId: string,
  token: string,
): Promise<Site> {
  try {
    const response = await http.get<BaseAPIResponse<Site>>(
      `/staged-site/${siteId}/${token}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Fetch Original Site');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Fetch Original Site: ${err}`);
  }
}

export async function createSite(
  name: string,
  email: string,
  objectId: string,
  language: string
): Promise<Site> {
  try {
    const response = await http.post<BaseAPIResponse<Site>>(
      `/staged-site`,
      {
        name: name,
        email: email,
        type: 'site',
        objectId: objectId,
        language
      },
    );

    if (response.success) {
      toast.success('Record verification has been requested', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Create Staged Site');
    }
  } catch (err: unknown) {
    toast.error('Error: Create Staged Site. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Create Staged Site: ${err}`);
  }
}

export async function updateSite(
  siteId: string,
  site: Site,
): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/staged-site/${siteId}`,
      site,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Update Staged Site');
    }
  } catch (err: unknown) {
    toast.error('Error: Update Staged Site. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Update Staged Site: ${err}`);
  }
}

export async function updateStatusSiteToPendingApproval(
  siteId: string,
  token: string
): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/staged-site/status/${siteId}/${token}`,
      {
        status: EntityStatusEnum.PendingApproval,
      },
    );

    if (response.success) {
      toast.success('The site was sent to approval', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Update Staged Site Status');
    }
  } catch (err: unknown) {
    toast.error('Error: Update Staged Site Status. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Update Staged Site Status: ${err}`);
  }
}

export async function approveSiteByVerifier(
  siteId: string,
  token: string
): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/staged-site/status/${siteId}/${token}/verify`,
    );

    if (response.success) {
      toast.success('The site was approved successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Update Staged Site Status');
    }
  } catch (err: unknown) {
    toast.error('Error: Update Staged Site Status. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Update Staged Site Status: ${err}`);
  }
}

export async function approveSite(siteId: string): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/staged-site/status/${siteId}/approve`,
    );

    if (response.success) {
      toast.success('The Site was approved successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Update Staged Site Status');
    }
  } catch (err: unknown) {
    toast.error('Error: Update Staged Site Status. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Update Staged Site Status: ${err}`);
  }
}

export async function rejectSite(siteId: string): Promise<Site> {
  try {
    const response = await http.patch<BaseAPIResponse<Site>>(
      `/staged-site/status/${siteId}/reject`,
    );

    if (response.success) {
      toast.success('The site was rejected successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error: Update Staged Site Status');
    }
  } catch (err: unknown) {
    toast.error('Error: Update Staged Site Status. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Update Staged Site Status: ${err}`);
  }
}

export async function deleteSite(siteId: string): Promise<Site> {
  try {
    const response = await http.delete<BaseAPIResponse<Site>>(
      `/staged-site/${siteId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Delete Staged Site');
    }
  } catch (err: unknown) {
    toast.error('Error: Delete Staged Site. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Delete Staged Site: ${err}`);
  }
}
