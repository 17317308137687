/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import { getResourceById } from 'api/stagedResources/stagedResources.api';
import { Text } from 'design';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Resource } from 'types/entities/resources';
import { ResourcesForm } from './ResourcesForm';

export const VerifyResourceEntityView: FC = () => {
  const { id, token } = useParams();
  const [resource, setResource] = useState<Resource | null>(null);
  const [isSubmitted, setSubmissionStatus] = useState(false);

  useEffect(() => {
    const getResourceForVerification = async () => {
      if (!id || !token) {
        return;
      }

      try {
        const resource = await getResourceById(id, token, false);
        if (resource) {
          setResource(resource);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        }
        setSubmissionStatus(true);
      }
    };

    if (resource === null) {
      getResourceForVerification();
    }
  }, [id, token, resource]);

  const handleSubmit = async () => {
    if (!id) {
      return;
    }
    setSubmissionStatus(true);
  };

  return (
    <>
      {isSubmitted ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <Text.h1>
            Access has expired. Thank you for your verification!
          </Text.h1>
        </div>
      ) : resource ? (
        <ResourcesForm
          onSubmit={handleSubmit}
          resource={resource}
          token={token || ''}
        />
      ) : (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </>
  );
};
