import { Box, styled } from '@mui/material';
import UserManagementTable from 'components/User/UserManagementTable/UserManagementTable';
import { Text } from 'design';
import { Align } from 'layout';
import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const StyledTextH2 = styled(Text.h2)`
  font-weight: 700;
`;

export const UserManagement: FC = () => {
  return (
    <Box>
      <Box mx={0} my={2} p={0}>
        <StyledTextH2>
          {intl.translate({
            id: 'User Management',
          })}
        </StyledTextH2>
      </Box>

      <Align v-center>
        <UserManagementTable />
      </Align>
    </Box>
  );
};
