import { Check, Close, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import { getResourceById } from 'api/resources/resources.api';
import {
  approveResource,
  fetchStagedResources,
  rejectResource,
  updateResource,
} from 'api/stagedResources/stagedResources.api';
import { updateVerifications } from 'api/verifications/verifications.api';
import {
  getChangedProperties,
  tableHiddenColumnsInitialState,
} from 'helpers/resources.helpers';
import { useResources } from 'hooks/useResources';
import MaterialReactTable, { MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { Resource } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';
import { EditModal } from './Modals/EditModal/EditModal';
import { toast } from 'react-toastify';

const PendingChangesResourcesTable: FC = () => {
  const [tableData, setTableData] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<MRT_Row<Resource>>();
  const [changedProperties, setChangedProperties] = useState<string[]>([]);

  const { columns } = useResources();

  const getStagedResources = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const result = await fetchStagedResources(pageIndex + 1, pageSize);
      setRowCount(result.length);
      setTableData(result);
    } catch (error) {
      toast.error('An error occurred while fetching resources', {
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStagedResources(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleEditRow = async (row: MRT_Row<Resource>) => {
    try {
      setEditModalOpen(true);
      setRowToEdit(row);
      const originalResource = await getResourceById(row?.original?.objectId);
      setChangedProperties(
        getChangedProperties(originalResource, row?.original),
      );
    } catch (error) {
      toast.error('Failed to retrieve resource', { position: 'top-center' });
    }
  };

  const handleSaveRowEdits = async (rowIndex: number, values: Resource) => {
    try {
      // clean edit modal
      setRowToEdit(undefined);

      // change on API
      await updateResource(values?.objectId, values);

      // save item to pending changes
      tableData[rowIndex] = values;
      setTableData([...tableData]);
    } catch (error) {
      toast.error('Failed to save', { position: 'top-center' });
    }
  };

  const handleApproveClick = async (row: MRT_Row<Resource>) => {
    try {
      await approveResource(row?.original?.objectId);
      const verificationObjectId =
        row?.original?.verifications && row?.original?.verifications?.length > 0
          ? row?.original?.verifications[
              row?.original?.verifications?.length - 1
            ]?.objectId ||
            (row?.original?.verifications[
              row?.original?.verifications?.length - 1
            ]?._id as string)
          : '';
      await updateVerifications(verificationObjectId, {
        internalConfirmationDate: new Date(),
        hasVerified: true,
        updatedPropertyNames: changedProperties,
      });
    } catch (err) {
      return;
    }

    setTableData(
      tableData.filter((item) => item?.objectId !== row?.original?.objectId),
    );
  };

  const handleRejectClick = async (row: MRT_Row<Resource>) => {
    try {
      await rejectResource(row?.original?.objectId);

      const verificationObjectId =
        row?.original?.verifications && row?.original?.verifications?.length > 0
          ? row?.original?.verifications[
              row?.original?.verifications?.length - 1
            ]?.objectId ||
            (row?.original?.verifications[
              row?.original?.verifications?.length - 1
            ]?._id as string)
          : '';
      await updateVerifications(verificationObjectId, {
        internalConfirmationDate: new Date(),
        hasVerified: false,
        updatedPropertyNames: changedProperties,
      });

      setTableData(
        tableData?.filter((item) => item?.objectId !== row?.original?.objectId),
      );
    } catch (error) {
      /*empty*/
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        columns={columns}
        enableColumnResizing
        defaultColumn={{
          minSize: 20,
          maxSize: 300,
          size: 160,
        }}
        data={tableData}
        enableColumnOrdering
        enableEditing
        enableHiding
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '0rem',
              justifyContent: 'center',
            }}
          >
            <Tooltip arrow title="Confirm">
              <IconButton
                color="success"
                size="small"
                onClick={() => handleApproveClick(row)}
                aria-label="confirm request"
              >
                <Check />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Deny">
              <IconButton
                color="error"
                size="small"
                onClick={() => handleRejectClick(row)}
                aria-label="deny request"
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              title={intl.translate({
                id: 'View request',
              })}
              aria-label="view request"
            >
              <IconButton
                aria-label="view button"
                onClick={() => handleEditRow(row)}
                size="small"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50, 100],
        }}
        initialState={{
          density: 'compact',
          columnVisibility: { ...tableHiddenColumnsInitialState },
        }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading: loading,
          pagination,
        }}
      />

      {rowToEdit && (
        <EditModal
          columns={columns}
          row={rowToEdit}
          changedProperties={changedProperties}
          open={editModalOpen}
          onClose={() => {
            setRowToEdit(undefined);
            setEditModalOpen(false);
          }}
          onSubmit={handleSaveRowEdits}
        />
      )}
    </div>
  );
};

export default PendingChangesResourcesTable;
