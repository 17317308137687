import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { tableHiddenColumnsInitialState } from 'helpers/resources.helpers';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { FC } from 'react';
import { Resource } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';

export const DeleteModal: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  columns: MRT_ColumnDef<Resource>[];
  rowsToDelete: Resource[];
}> = ({ open, onClose, onSubmit, columns, rowsToDelete }) => {
  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Confirm deletion of following row(s)',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <MaterialReactTable
              columns={columns}
              defaultColumn={{
                minSize: 20,
                maxSize: 160,
                size: 50,
              }}
              data={rowsToDelete}
              initialState={{
                columnVisibility: { ...tableHiddenColumnsInitialState },
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Delete Rows',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
