import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { Paragraph } from 'design/Text/Paragraph';
import { useTaxonomies } from 'hooks/useTaxonomies';
import MaterialReactTable from 'material-react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { Taxonomy } from 'types/entities/taxonomies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledStatusUpperCaseStrong = styled.strong`
  text-transform: uppercase;
`;

export const DeleteModal: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  rowsToDelete: Taxonomy[];
}> = ({ open, onClose, onSubmit, rowsToDelete }) => {
  const { columns: taxonomiesColumns } = useTaxonomies();

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Confirm deletion of following row(s)',
        })}
      </DialogTitle>
      <DialogContent>
        <Paragraph color="light">
          {intl.translate(
            {
              id: 'Review the following record details and confirm to <strong>DELETE</strong>:',
            },
            {
              strong: (label) => (
                <StyledStatusUpperCaseStrong>
                  {label}
                </StyledStatusUpperCaseStrong>
              ),
            },
          )}
        </Paragraph>

        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <MaterialReactTable
              columns={taxonomiesColumns}
              data={rowsToDelete}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Delete Rows',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
