import { Box, Stack, TextField } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { FC, useCallback, useState } from 'react';
import { Taxonomy } from 'types/entities/taxonomies';
import { TaxonomiesSelect } from './TaxonomiesSelect';

type TaxonomyFormProps = {
  showLabel?: boolean;
  selectedTaxonomies: string[][];
  setSelectedTaxonomies: (values: string[][]) => void;
  taxonomy: Taxonomy;
  setTaxonomy: (values: Taxonomy) => void;
  columns: MRT_ColumnDef<Taxonomy>[];
  validationErrors?: { [cellId: string]: string };
};

const renderDisabledTextField = (
  column: MRT_ColumnDef<Taxonomy>,
  values: Taxonomy,
  setValues: (values: Taxonomy) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      disabled
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey]}
      variant="standard"
    />
  );
};

export const TaxonomyForm: FC<TaxonomyFormProps> = ({
  showLabel = true,
  selectedTaxonomies,
  setSelectedTaxonomies,
  taxonomy,
  setTaxonomy,
  columns,
  validationErrors = {},
}) => {
  const [showDropdown, setShowDropdown] = useState(showLabel);

  const renderTextField = (
    column: MRT_ColumnDef<Taxonomy>,
    values: Taxonomy,
    setValues: (values: Taxonomy) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      />
    );
  };

  const renderNumberTextField = (
    column: MRT_ColumnDef<Taxonomy>,
    values: Taxonomy,
    setValues: (values: Taxonomy) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        type="number"
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      />
    );
  };

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Taxonomy>;
    },
    [columns],
  );

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      {showDropdown ? (
        <TaxonomiesSelect
          selectedTaxonomies={selectedTaxonomies}
          setSelectedTaxonomies={setSelectedTaxonomies}
        />
      ) : (
        <>
          {getColumnByAccessorKey('objectId') && taxonomy['objectId'] && (
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderDisabledTextField(
                getColumnByAccessorKey('objectId'),
                taxonomy,
                setTaxonomy,
              )}
            </Box>
          )}

          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '102ch' },
            }}
          >
            {renderTextField(
              getColumnByAccessorKey('code'),
              taxonomy,
              setTaxonomy,
              true,
            )}
            {renderNumberTextField(
              getColumnByAccessorKey('level'),
              taxonomy,
              setTaxonomy,
              true,
            )}
          </Box>

          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '50ch' },
            }}
          >
            {renderTextField(
              getColumnByAccessorKey('nameEN'),
              taxonomy,
              setTaxonomy,
              true,
            )}
            {renderTextField(
              getColumnByAccessorKey('nameFR'),
              taxonomy,
              setTaxonomy,
              true,
            )}
          </Box>

          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '102ch' },
            }}
          >
            {getColumnByAccessorKey('updatedAt') &&
              taxonomy['updatedAt'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('updatedAt'),
                taxonomy,
                setTaxonomy,
              )}
            {getColumnByAccessorKey('createdAt') &&
              taxonomy['createdAt'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('createdAt'),
                taxonomy,
                setTaxonomy,
              )}
          </Box>
        </>
      )}
    </Stack>
  );
};
