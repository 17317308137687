/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import { getAgencyById } from 'api/stagedAgencies/stagedAgencies.api';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Agency } from 'types/entities/agencies';
import { AgenciesForm } from './AgenciesForm';
import { Text } from 'design';

export const VerifyAgencyEntityView: FC = () => {
  const { id, token } = useParams();
  const [agency, setAgency] = useState<Agency | null>(null);
  const [isSubmitted, setSubmissionStatus] = useState(false);

  useEffect(() => {
    const getAgencyForVerification = async () => {
      if (!id || !token) {
        return;
      }

      try {
        const agency = await getAgencyById(id, token, false);
        if (agency) {
          setAgency(agency);
        }
      } catch (err) {
        setSubmissionStatus(true);
      }
    };

    if (agency === null) {
      getAgencyForVerification();
    }
  });

  const handleSubmit = async () => {
    if (!id) {
      return;
    }
    setSubmissionStatus(true);
  };

  return (
    <>
      {isSubmitted ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <Text.h1>Access has expired. Thank you for your verification!</Text.h1>
        </div>
      ) : agency ? (
        <AgenciesForm
          onSubmit={handleSubmit}
          agency={agency}
          token={token || ''}
        />
      ) : (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </>
  );
};
