export const FILTERS = {
  NUMBER: 'Count',
  REGION_PROVINCE: 'Region (Province)',
  REGION_CITY: 'Region (City)',
  TYPE: 'Record Type',
  LEVEL: 'Record Level',
  TAXONOMY: 'Taxonomy',
};

export const CHART_MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CHART_RECORD_TYPE_LABELS = ['RAM', 'G2T', 'PSI', 'Internal'];

export const CHART_ENTITY_LABELS = ['Resources', 'Sites', 'Agencies'];

export const CHART_YEARS = [
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012',
  '2011',
  '2010',
  '2009',
  '2008',
  '2007',
  '2006',
  '2005',
];

export const CHART_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const CHART_OPTIONS_CITY_ALTERNATIVE = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
      },
    },
  },
};
