/* eslint-disable @typescript-eslint/no-unused-vars */
import { validateRequired } from 'helpers/validation.helpers';
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { useCallback, useMemo, useState } from 'react';
import { Taxonomy } from 'types/entities/taxonomies';

export const useTaxonomies = () => {
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const getCommonCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const date = cell.getValue<Date>();

    return date ? new Date(date).toLocaleDateString() : '';
  };

  const getJsonCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const json = JSON.parse(cell.getValue<string>() || '{}');
    const jsonAsString = JSON.stringify(json, null, 4);
    return (
      <textarea
        disabled
        style={{
          background: 'inherit',
          height: '100px',
          border: 'none',
        }}
        value={jsonAsString || ''}
      ></textarea>
    );
  };

  const getArrayCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    return (
      <textarea
        disabled
        style={{
          background: 'inherit',
          height: '100px',
          border: 'none',
        }}
        value={cell.getValue<string>() || ''}
      ></textarea>
    );
  };

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<Taxonomy>,
    ): MRT_ColumnDef<Taxonomy>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const getJsonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<Taxonomy>,
    ): MRT_ColumnDef<Taxonomy>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
        type: 'text',
        multiline: true,
        rows: 6,
      };
    },
    [validationErrors],
  );

  const getArrayEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<Taxonomy>,
    ): MRT_ColumnDef<Taxonomy>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
        type: 'text',
        multiline: true,
        rows: 6,
      };
    },
    [validationErrors],
  );

  const columns = useMemo<MRT_ColumnDef<Taxonomy>[]>(
    () => [
      {
        accessorKey: '_id',
        header: 'Object ID',
        enableEditing: false,
        enableHiding: false,
      },

      {
        accessorKey: 'nameEN',
        header: 'Name En',
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'nameFR',
        header: 'Name Fr',
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'code',
        header: 'Code',
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'level',
        header: 'Level',
        enableEditing: true,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: 'createdAt',
        header: 'Created At',
        enableEditing: false,
        Cell: ({ cell }) => {
          return getCommonCellProps(cell);
        },
      },
      {
        accessorKey: 'updatedAt',
        header: 'Updated At',
        enableEditing: false,
        Cell: ({ cell }) => {
          return getCommonCellProps(cell);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getArrayEditTextFieldProps,
      getCommonEditTextFieldProps,
      getJsonEditTextFieldProps,
    ],
  );

  return {
    columns,
  };
};
