import { toast } from 'components/Toast/Toast';
import { Verification } from 'types/entities/verifications';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function createVerifications(
  name: string,
  email: string,
  resourceId?: string,
  siteId?: string,
  agencyId?: string,
): Promise<Verification> {
  try {
    const request = {
      name,
      email,
      resourceId,
      siteId,
      agencyId,
      requestSent: true,
      hasVerified: false,
      completionDate: null,
      internalConfirmationDate: null,
    };

    const response = await http.post<BaseAPIResponse<Verification>>(
      `/verifications`,
      request,
    );

    if (response.success) {
      return {
        objectId: response.data.objectId || response.data._id,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        title: response.data.title,
        secondaryVerifierName: response.data.secondaryVerifierName,
        secondaryVerifierEmail: response.data.secondaryVerifierEmail,
        secondaryVerifierPhone: response.data.secondaryVerifierPhone,
        secondaryVerifierTitle: response.data.secondaryVerifierTitle,
        requestSent: response.data.requestSent,
        hasVerified: response.data.hasVerified,
        internalConfirmationDate: response.data.internalConfirmationDate,
        updatedPropertyNames: response.data.updatedPropertyNames,
        completionDate: response.data.completionDate,
        agencyId: response.data.agencyId,
        siteId: response.data.siteId,
        resourceId: response.data.resourceId,
      };
    } else {
      throw new Error('Error: New verification record creation failed');
    }
  } catch (err: unknown) {
    toast.error('Error: New verification record creation failed', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: New verification record creation failed: ${err}`);
  }
}

export async function fetchVerificationsByObjectId(
  id: string,
): Promise<Verification | null> {
  try {
    const response = await http.get<BaseAPIResponse<Verification>>(
      `/verifications/${id}`,
    );

    if (response.success) {
      if (response.data === null) {
        return null;
      }

      return {
        objectId: response.data.objectId || response.data._id,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        title: response.data.title,
        secondaryVerifierName: response.data.secondaryVerifierName,
        secondaryVerifierEmail: response.data.secondaryVerifierEmail,
        secondaryVerifierPhone: response.data.secondaryVerifierPhone,
        secondaryVerifierTitle: response.data.secondaryVerifierTitle,
        requestSent: response.data.requestSent,
        hasVerified: response.data.hasVerified || false,
        internalConfirmationDate: response.data.internalConfirmationDate,
        updatedPropertyNames: response.data.updatedPropertyNames,
        completionDate: response.data.completionDate,
        agencyId: response.data.agencyId,
        siteId: response.data.siteId,
        resourceId: response.data.resourceId,
      };
    } else {
      throw new Error('Error List Verifications');
    }
  } catch (err: unknown) {
    toast.error('Error List Verifications', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Verifications: ${err}`);
  }
}

export async function updateVerifications(
  id: string,
  verification: Verification,
): Promise<Verification> {
  try {
    const request = {
      hasVerified: verification.hasVerified,
      updatedPropertyNames: verification.updatedPropertyNames,
      completionDate: verification.completionDate,
      internalConfirmationDate: verification.internalConfirmationDate,
      phone: verification.phone,
      title: verification.title,
      secondaryVerifierName: verification.secondaryVerifierName,
      secondaryVerifierEmail: verification.secondaryVerifierEmail,
      secondaryVerifierPhone: verification.secondaryVerifierPhone,
      secondaryVerifierTitle: verification.secondaryVerifierTitle,
      requestSent: true,
    };

    const response = await http.patch<BaseAPIResponse<Verification>>(
      `/verifications/${id}`,
      request,
    );

    if (response.success) {
      return {
        objectId: response.data.objectId || response.data._id,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        title: response.data.title,
        secondaryVerifierName: response.data.secondaryVerifierName,
        secondaryVerifierEmail: response.data.secondaryVerifierEmail,
        secondaryVerifierPhone: response.data.secondaryVerifierPhone,
        secondaryVerifierTitle: response.data.secondaryVerifierTitle,
        requestSent: response.data.requestSent,
        hasVerified: response.data.hasVerified,
        internalConfirmationDate: response.data.internalConfirmationDate,
        updatedPropertyNames: response.data.updatedPropertyNames,
        completionDate: response.data.completionDate,
        agencyId: response.data.agencyId,
        siteId: response.data.siteId,
        resourceId: response.data.resourceId,
      };
    } else {
      throw new Error('Error Update Verifications');
    }
  } catch (err: unknown) {
    // toast.error('Error Update Verifications', {
    //   position: 'top-center',
    // });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Update Verifications: ${err}`);
  }
}
