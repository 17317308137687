import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';

export const CloseDialogButton: FC<{ onClose: () => void }> = (props) => {
  return (
    <IconButton
      onClick={props.onClose}
      sx={{ position: 'absolute', top: 0, right: 0, margin: '8px' }}
      aria-label="close button"
    >
      <Close />
    </IconButton>
  );
};
