/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { AgencyForm } from 'components/Shared/SharedForms/AgencyForm';
import { ResourceForm } from 'components/Shared/SharedForms/ResourceForm';
import { SiteForm } from 'components/Shared/SharedForms/SiteForm';
import { TaxonomyForm } from 'components/Shared/SharedForms/TaxonomyForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { palette } from 'design/theme/palette';
import { selectUser } from 'global-state/selectors';
import { validateRequired } from 'helpers/validation.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { useResources } from 'hooks/useResources';
import { useSites } from 'hooks/useSites';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DataManagement } from 'types/data-management';
import { Agency } from 'types/entities/agencies';
import { Resource } from 'types/entities/resources';
import { Site } from 'types/entities/sites';
import { Taxonomy } from 'types/entities/taxonomies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const CreateResourcesModal: FC<{
  onClose: () => void;
  onSubmit: (Data: DataManagement) => void;
  open: boolean;
}> = ({ open, onClose, onSubmit }) => {
  const { columns } = useResources();
  const { columns: sitesColumns } = useSites();
  const { columns: agencyColumns } = useAgencies();
  const { columns: taxonomyColumns } = useTaxonomies();

  const loggedUser = useSelector(selectUser);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [resourcesFormValues, setResourcesFormValues] = useState<Resource>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as any),
  );

  const [siteFormValues, setSiteFormValues] = useState<Site>(() =>
    sitesColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );
  const [agencyFormValues, setAgencyFormValues] = useState<Agency>(() =>
    agencyColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );

  const [selectedSite, setSelectedSite] = useState<string[]>([]);
  const [selectedSiteInfo, setSelectedSiteInfo] = useState<any[]>([]);

  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedAgencyName, setSelectedAgencyName] = useState('');

  const [taxonomyFormValues, setTaxonomyFormValues] = useState<Taxonomy>(
    taxonomyColumns.reduce((acc, column) => {
      acc[column.accessorKey || ''] = '';
      return acc;
    }, {} as any),
  );
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<string[][]>([]);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};

    // if (!(parseInt(resourcesFormValues.khpReferenceNumber) > 0)) {
    //   isValidFormOnSubmit = false;
    //   validationErrosOnSubmit = {
    //     ...validationErrosOnSubmit,
    //     ['khpReferenceNumber']: 'This field is required',
    //   };
    // }

    if (
      !validateRequired(resourcesFormValues.descriptionEn) &&
      !validateRequired(resourcesFormValues.descriptionFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionFr']: 'This field is required',
      };
    }

    if (resourcesFormValues.coverage?.length === 0) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['coverages']: 'This field is required',
      };
    }

    // if (!validateRequired(resourcesFormValues.coverageFr)) {
    //   isValidFormOnSubmit = false;
    //   validationErrosOnSubmit = {
    //     ...validationErrosOnSubmit,
    //     ['coverageFr']: 'This field is required',
    //   };
    // }

    if (
      !validateRequired(resourcesFormValues.nameEn) &&
      !validateRequired(resourcesFormValues.nameFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameFr']: 'This field is required',
      };
    }

    if (selectedTaxonomies.length === 0) {
      isValidFormOnSubmit = false;
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        intl.translate({
          id: `Please fill all required fields. ${JSON.stringify(validationErrosOnSubmit, null, 4)}`,
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    if (
      resourcesFormValues.operations &&
      resourcesFormValues.operations[0].siteId === ''
    ) {
      // if the array is empty then we send null
      resourcesFormValues.operations = null;
    }

    if (loggedUser) {
      resourcesFormValues.lastUpdatedBy = loggedUser.email;
    }

    if (resourcesFormValues.documentsRequired) {
      resourcesFormValues.documentsRequired = resourcesFormValues.documentsRequired.filter(x => x !== "");
    }

    const submitData: DataManagement = {
      Resource: resourcesFormValues,
      SelectedSiteIds: selectedSite,
      Site: siteFormValues,
      SelectedAgencyId: selectedAgency,
      Agency: agencyFormValues,
      SelectedTaxonomyIds: selectedTaxonomies,
      Taxonomy: taxonomyFormValues,
    };

    onSubmit(submitData);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Create Resource',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '80vh',
              '&  #tabpanel-0': {
                marginLeft: '120px',
              },
              '&  #tabpanel-1': {
                marginLeft: '120px',
              },
              '&  #tabpanel-2': {
                marginLeft: '120px',
              },
              '&  #tabpanel-3': {
                marginLeft: '120px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab label="Resource" {...a11yProps(0)} />
              <StyledLeftTab label="Site" {...a11yProps(1)} />
              <StyledLeftTab label="Agency" {...a11yProps(2)} />
              <StyledLeftTab label="Taxonomy" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <ResourceForm
                columns={columns}
                values={resourcesFormValues}
                setValues={setResourcesFormValues}
                validationErrors={validationErrors}
                selectedSiteInfo={selectedSiteInfo}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SiteForm
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                setSelectedSiteInfo={setSelectedSiteInfo}
                selectedSiteInfo={selectedSiteInfo}
                columns={sitesColumns}
                values={siteFormValues}
                setValues={setSiteFormValues}
                currentSites={[]}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <AgencyForm
                selectedAgency={selectedAgency}
                setSelectedAgency={setSelectedAgency}
                setSelectedAgencyName={setSelectedAgencyName}
                selectedAgencyName={selectedAgencyName}
                values={agencyFormValues}
                setValues={setAgencyFormValues}
                columns={agencyColumns}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TaxonomyForm
                selectedTaxonomies={selectedTaxonomies}
                setSelectedTaxonomies={setSelectedTaxonomies}
                taxonomy={taxonomyFormValues}
                setTaxonomy={setTaxonomyFormValues}
                columns={taxonomyColumns}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
