/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Tab, Tabs } from '@mui/material';
import { updateSite } from 'api/sites/sites.api';
import {
  approveSiteByVerifier,
  getOriginalSiteById,
  getSiteById as getStagedSiteById,
  updateSite as updateStagedSite,
  updateStatusSiteToPendingApproval,
} from 'api/stagedSites/stagedSites.api';
import { updateVerifications } from 'api/verifications/verifications.api';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalBottomSection } from 'components/Shared/ModalContent/ModalBottomSection';
import { StagedSiteForm } from 'components/Shared/SharedForms/StagedSiteForm';
import { VerificationsForm } from 'components/Shared/SharedForms/VerificationsForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { palette } from 'design/theme/palette';
import { getChangedProperties } from 'helpers/sites.helpers';
import { useSites } from 'hooks/useSites';
import { useVerifications } from 'hooks/useVerifications';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Site } from 'types/entities/sites';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';
import { ModalTitle } from 'components/Shared/ModalTitles/ModalTitle';
import { Text } from 'design';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const SitesForm: FC<{
  token: string;
  site: Site;
  onSubmit: () => void;
}> = ({ token, site: site, onSubmit }) => {
  const { columns } = useSites();
  const { columns: verificationColumns } = useVerifications();

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [sitesFormValues, setSitesFormValues] = useState<Site>(site);
  const [verificationFormValues, setVerificationFormValues] =
    useState<Verification>(() =>
      verificationColumns.reduce((acc, column) => {
        acc[column.accessorKey || ''] = '';
        return acc;
      }, {} as any),
    );

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    try {
      const originalSite = await getOriginalSiteById(
        sitesFormValues.objectId,
        token,
      );
      const changedProperties = getChangedProperties(
        originalSite,
        sitesFormValues,
      );
      await updateStagedSite(sitesFormValues.objectId, {
        ...sitesFormValues,
        lastVerifiedOn: new Date(),
      });

      if (changedProperties.length === 0) {
        await approveSiteByVerifier(sitesFormValues.objectId, token);
      } else {
        await updateStatusSiteToPendingApproval(
          sitesFormValues.objectId,
          token,
        );
      }
    } catch (err) {
      return;
    }

    try {
      await updateVerifications(
        verificationFormValues.objectId || '',
        verificationFormValues,
      );
    } catch (err) {
      return;
    }

    onSubmit();
  };

  const getSiteDataFromDB = async (siteId: string) => {
    if (!siteId) {
      return;
    }
    try {
      const site = await getStagedSiteById(siteId, token, true);
      if (site) {
        setSitesFormValues(site);
        toast.info('Properties were successfully reset', {
          position: 'top-center',
        });
      }
    } catch (_error) {
      toast.error('An error occured', { position: 'top-center' });
    }
  };

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '60vw',
        paddingTop: '2rem',
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <ModalTitle>
          <Text.h1>Verify Site</Text.h1>
        </ModalTitle>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            '& .MuiTabs-indicator': {
              backgroundColor: palette.black.main,
              left: 0,
              width: '4px',
            },
            '& .Mui-selected': {
              color: `${palette.black.main} !important`,
            },
            height: '80vh',
            '&  #tabpanel-0': {
              marginLeft: '100px',
            },
            '&  #tabpanel-1': {
              marginLeft: '100px',
            },
            '&  #tabpanel-2': {
              marginLeft: '100px',
            },
            '&  #tabpanel-3': {
              marginLeft: '100px',
            },
            overflowY: 'scroll',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{ position: 'fixed' }}
          >
            <StyledLeftTab label="Site" {...a11yProps(0)} />
            <StyledLeftTab label="Verification" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <StagedSiteForm
              columns={columns}
              values={sitesFormValues}
              setValues={setSitesFormValues}
              validationErrors={validationErrors}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <VerificationsForm
              objectId={verificationFormValues.objectId || ''}
              original={site.verifications}
              columns={verificationColumns}
              values={verificationFormValues}
              setValues={setVerificationFormValues}
            />
          </TabPanel>
        </Box>

        <Box
          sx={{
            paddingTop: '2rem',
          }}
        >
          <ModalBottomSection showUpdatedLabel={false}>
            <CancelButton
              handleClick={() => {
                getSiteDataFromDB(site.objectId);
              }}
            >
              <strong>
                {intl.translate({
                  id: 'Reset properties',
                })}
              </strong>
            </CancelButton>

            <ConfirmButton handleClick={handleSubmit}>
              <strong>
                {intl.translate({
                  id: 'Verify',
                })}
              </strong>
            </ConfirmButton>
          </ModalBottomSection>
        </Box>
      </form>
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
