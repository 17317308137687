import { toast } from 'components/Toast/Toast';
import { Taxonomy, TaxonomyDto } from 'types/entities/taxonomies';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchTaxonomies(
  pageIndex: number,
  pageSize: number,
): Promise<TaxonomyDto> {
  try {
    const response = await http.get<BaseAPIResponse<TaxonomyDto>>(
      `/taxonomy?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Taxonomies');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Taxonomies: ${err}`);
  }
}

export async function fetchTaxonomyById(
  objectId: string,
): Promise<TaxonomyDto> {
  try {
    const response = await http.get<BaseAPIResponse<TaxonomyDto>>(
      `/taxonomy/${objectId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Taxonomies');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Taxonomies: ${err}`);
  }
}

export async function createTaxonomy(taxonomy: Taxonomy): Promise<Taxonomy> {
  try {
    const response = await http.post<BaseAPIResponse<Taxonomy>>(
      `/taxonomy`,
      taxonomy,
    );

    if (response.success) {
      toast.success('The Taxonomy was created successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Taxonomies');
    }
  } catch (err: unknown) {
    toast.error('Error creating taxonomy. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Taxonomies: ${err}`);
  }
}

export async function updateTaxonomy(
  taxonomyId: string,
  taxonomy: Taxonomy,
): Promise<Taxonomy> {
  try {
    const response = await http.patch<BaseAPIResponse<Taxonomy>>(
      `/taxonomy/${taxonomyId}`,
      taxonomy,
    );

    if (response.success) {
      toast.success('The Taxonomy was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Taxonomies');
    }
  } catch (err: unknown) {
    toast.error('Error updating taxonomy. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Taxonomies: ${err}`);
  }
}

export async function deleteTaxonomy(taxonomyId: string): Promise<Taxonomy> {
  try {
    const response = await http.delete<BaseAPIResponse<Taxonomy>>(
      `/taxonomy/${taxonomyId}`,
    );

    if (response.success) {
      toast.success('The Taxonomy was deleted', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error List Taxonomies');
    }
  } catch (err: unknown) {
    toast.error('Error deleting taxonomy. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Taxonomies: ${err}`);
  }
}

type SearchTaxonomy = Omit<TaxonomyDto, 'activeCount' | 'inactiveCount'>;

export async function searchTaxonomies(
  type: string,
  value: string,
  pageIndex = 1,
  pageSize = 100,
): Promise<SearchTaxonomy> {
  try {
    const response = await http.get<BaseAPIResponse<SearchTaxonomy>>(
      `/taxonomy/search/${type}/${value}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Searching Taxonomies');
    }
  } catch (err: unknown) {
    toast.error('Error Searching Taxonomies', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Searching Taxonomies: ${err}`);
  }
}
