import { Box, MenuItem, TextField, Theme, useTheme } from '@mui/material';
import { fetchFeeStructureSource } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { FC, useEffect, useState } from 'react';
import { FeeStructureSource } from 'types/data-management';
import { intl } from 'utilities/i18n/intl.utility';

const getStyles = (
  selectedReference: string,
  references: string[],
  theme: Theme,
) => {
  return {
    fontWeight:
      references.indexOf(selectedReference) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
    color:
      references.indexOf(selectedReference) === -1
        ? theme.palette.common.black
        : theme.palette.info.light,
  };
};

const emptyFeeStructureSource: FeeStructureSource = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

const FeeStructureSourceForm: FC<{
  value: string[];
  setValue: (values: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValues: any[];
}> = ({ value, setValue, currentValues }) => {
  const theme = useTheme();
  const [feeStructureSource, setFeeStructureSource] = useState([
    { ...emptyFeeStructureSource },
  ]);
  const [selectedFeeStructureSource, setSelectedFeeStructureSource] =
    useState(currentValues);

  useEffect(() => {
    const getFeeStructureSource = async () => {
      try {
        const feeStructureSourceResponse = await fetchFeeStructureSource();
        setFeeStructureSource(feeStructureSourceResponse);
      } catch (err) {
        toast.error(
          'Error getting fee structure source options. Please, try again.',
          {
            position: 'top-center',
          },
        );
        setFeeStructureSource([{ ...emptyFeeStructureSource }]);
      }
    };

    getFeeStructureSource();
  }, []);

  useEffect(() => {
    setSelectedFeeStructureSource(
      feeStructureSource.filter((item) => value.includes(item?.objectId)) || {
        ...emptyFeeStructureSource,
      },
    );
  }, [value, feeStructureSource]);

  const handleChangeDropDownSites = (value: string) => {
    setValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'Fee Structure Source',
          })}
          select
          SelectProps={{
            multiple: true,
          }}
          onChange={(e) => handleChangeDropDownSites(e.target?.value)}
          value={value?.length > 0 ? value : []}
          variant="standard"
          fullWidth={true}
        >
          {feeStructureSource?.map((reference) => (
            <MenuItem
              key={
                reference?.objectId ||
                reference?._id ||
                `${String(new Date())}-${Math.random()}`
              }
              value={reference?.objectId || reference?._id || ''}
              style={getStyles(
                reference?.objectId || (reference?._id as string),
                value,
                theme,
              )}
            >
              {`En: ${reference?.en}[Fr: ${reference.fr}]`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {selectedFeeStructureSource?.length > 0 &&
        selectedFeeStructureSource?.map((reference) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '50ch' },
            }}
            key={
              reference?.objectId ||
              `${String(new Date().getTime())}${Math.random()}`
            }
          >
            <TextField
              label={intl.translate({
                id: 'En',
              })}
              value={reference?.en || ''}
              disabled
              variant="standard"
            />
            <TextField
              label={intl.translate({
                id: 'Fr',
              })}
              value={reference?.fr || ''}
              disabled
              variant="standard"
            />
          </Box>
        ))}
    </>
  );
};

export default FeeStructureSourceForm;
