import { StatusEnum } from 'types/utilities';
import { hasBooleanValueChanged, hasValueChanged, hasNumericValueChanged } from './validation.helpers';
import {
  PhoneNumber
} from 'types/data-management';
import { Agency } from 'types/entities/agencies';

export const isAgencyActive = (status: string): boolean => {
  return status.toLowerCase() === StatusEnum.Active.toLowerCase();
};

export const isValidEnFr = (value: Agency | string): value is Agency => {
  return typeof value === 'object' && value !== null;
};

export const newPhoneNumbers: PhoneNumber = {
  description: {
    en: '',
    fr: '',
  },
  type: 'phone',
  isPrivate: 'false',
  name: '',
  number: '',
};

export const tableHiddenColumnsInitialState = {
  // name
  nameEN: true,
  nameFR: false,

  nameDetailsEnOfficial: true,
  nameDetailsEnAlternate: true,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  // website
  websiteEn: true,
  websiteFr: false,

  // social
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  khpReferenceNumber: false,
  agencyReferenceNumber: true,

  isActive: false,

  isLocationPrivate: false,

  // location
  locationAddress1: false,
  locationAddress2: false,
  locationCity: false,
  locationCounty: false,
  locationProvince: true,
  locationCountry: false,
  locationPostalCode: false,
  locationDescription: false,
  locationLongitude: false,
  locationLatitude: false,

  email: false,

  // mailingAddress (not array)
  mailingAddressAddress1: false,
  mailingAddressAddress2: false,
  mailingAddressCity: false,
  mailingAddressCounty: false,
  mailingAddressProvince: false,
  mailingAddressCountry: false,
  mailingAddressPostalCode: false,

  phoneNumbers: false,
  phoneNumbersString: false,
  contacts: false,
  contactsString: false,
  legalStatus: false,
  createdAt: false,
  updatedAt: false,

  notes: false,

  verifications: false,

  objectId: false,

  lastVerifiedOn: false,
  lastUpdatedBy: false
};

export const tableHiddenColumnsBulkImport = {
  // name
  nameEN: true,
  nameFR: true,

  isActive: true,

  // location
  locationAddress1: true,
  locationAddress2: false,
  locationCity: true,
  locationCounty: false,
  locationProvince: true,
  locationCountry: false,
  locationPostalCode: false,
  locationDescription: false,
  locationLongitude: false,
  locationLatitude: false,

  nameDetailsEnOfficial: false,
  nameDetailsEnAlternate: false,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  // website
  websiteEn: false,
  websiteFr: false,

  // social
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  khpReferenceNumber: false,
  agencyReferenceNumber: false,

  isLocationPrivate: false,

  email: false,

  // mailingAddress (not array)
  mailingAddressAddress1: false,
  mailingAddressAddress2: false,
  mailingAddressCity: false,
  mailingAddressCounty: false,
  mailingAddressProvince: false,
  mailingAddressCountry: false,
  mailingAddressPostalCode: false,

  phoneNumbers: false,
  phoneNumbersString: false,
  contacts: false,
  contactsString: false,
  legalStatus: false,
  createdAt: false,
  updatedAt: false,

  notes: false,

  verifications: false,

  objectId: false,
};

export const getChangedProperties = (original: Agency, edited: Agency) => {
  const changedProperties = [];

  // name
  if (hasValueChanged(original.nameEN, edited.nameEN)) {
    changedProperties.push('nameEN');
  }
  if (hasValueChanged(original.nameFR, edited.nameFR)) {
    changedProperties.push('nameFR');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnOfficial,
      edited.nameDetailsEnOfficial,
    )
  ) {
    changedProperties.push('nameDetailsEnOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrOfficial,
      edited.nameDetailsFrOfficial,
    )
  ) {
    changedProperties.push('nameDetailsFrOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnAlternate,
      edited.nameDetailsEnAlternate,
    )
  ) {
    changedProperties.push('nameDetailsEnAlternate');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrAlternate,
      edited.nameDetailsFrAlternate,
    )
  ) {
    changedProperties.push('nameDetailsFrAlternate');
  }

  // location
  if (hasValueChanged(original.locationAddress1, edited.locationAddress1)) {
    changedProperties.push('locationAddress1');
  }
  if (hasValueChanged(original.locationAddress2, edited.locationAddress2)) {
    changedProperties.push('locationAddress2');
  }
  if (hasValueChanged(original.locationCountry, edited.locationCountry)) {
    changedProperties.push('locationCountry');
  }
  if (hasValueChanged(original.locationProvince, edited.locationProvince)) {
    changedProperties.push('locationProvince');
  }
  if (hasValueChanged(original.locationCounty, edited.locationCounty)) {
    changedProperties.push('locationCounty');
  }
  if (hasValueChanged(original.locationCity, edited.locationCity)) {
    changedProperties.push('locationCity');
  }
  if (hasValueChanged(original.locationDescription, edited.locationDescription)) {
    changedProperties.push('locationDescription');
  }
  if (hasNumericValueChanged(original.locationLongitude, edited.locationLongitude)) {
    changedProperties.push('locationLongitude');
  }
  if (hasNumericValueChanged(original.locationLatitude, edited.locationLatitude)) {
    changedProperties.push('locationLatitude');
  }
  if (hasBooleanValueChanged(original.isLocationPrivate, edited.isLocationPrivate)) {
    changedProperties.push('isLocationPrivate');
  }

   // social
   if (hasValueChanged(original.socialFacebook, edited.socialFacebook)) {
    changedProperties.push('socialFacebook');
  }
  if (hasValueChanged(original.socialInstagram, edited.socialInstagram)) {
    changedProperties.push('socialInstagram');
  }
  if (hasValueChanged(original.socialTwitter, edited.socialTwitter)) {
    changedProperties.push('socialTwitter');
  }

  return changedProperties;
};