const regionsByProvince = [
  { region: 'Acadia', province: 'Alberta' },
  { region: 'Airdrie', province: 'Alberta' },
  { region: 'Athabasca', province: 'Alberta' },
  { region: 'Barrhead', province: 'Alberta' },
  { region: 'Beaver', province: 'Alberta' },
  { region: 'Big Lakes', province: 'Alberta' },
  { region: 'Bighorn', province: 'Alberta' },
  { region: 'Birch Hills', province: 'Alberta' },
  { region: 'Bonnyville', province: 'Alberta' },
  { region: 'Brazeau', province: 'Alberta' },
  { region: 'Brookes', province: 'Alberta' },
  { region: 'Calgary', province: 'Alberta' },
  { region: 'Camrose', province: 'Alberta' },
  { region: 'Canmore', province: 'Alberta' },
  { region: 'Cardston', province: 'Alberta' },
  { region: 'Clear Hills', province: 'Alberta' },
  { region: 'Clearwater', province: 'Alberta' },
  { region: 'Cold Lake', province: 'Alberta' },
  { region: 'Cypress', province: 'Alberta' },
  { region: 'Edmonton', province: 'Alberta' },
  { region: 'Fairview', province: 'Alberta' },
  { region: 'Flagstaff', province: 'Alberta' },
  { region: 'Foothills', province: 'Alberta' },
  { region: 'Fort Mcleod', province: 'Alberta' },
  { region: 'Fort Mcmurray', province: 'Alberta' },
  { region: 'Fort Saskatchewan', province: 'Alberta' },
  { region: 'Forty Mile', province: 'Alberta' },
  { region: 'Grande Prairie', province: 'Alberta' },
  { region: 'Greenview', province: 'Alberta' },
  { region: 'Jasper', province: 'Alberta' },
  { region: 'Kneehill', province: 'Alberta' },
  { region: 'Lac Ste. Anne', province: 'Alberta' },
  { region: 'Lacombe', province: 'Alberta' },
  { region: 'Lakeland', province: 'Alberta' },
  { region: 'Lamont', province: 'Alberta' },
  { region: 'Leduc', province: 'Alberta' },
  { region: 'Lesser Slave River', province: 'Alberta' },
  { region: 'Lethbridge', province: 'Alberta' },
  { region: 'Lloydminster (Part)', province: 'Alberta' },
  { region: 'Mackenzie', province: 'Alberta' },
  { region: 'Medicine Hat', province: 'Alberta' },
  { region: 'Minburn', province: 'Alberta' },
  { region: 'Mountain View', province: 'Alberta' },
  { region: 'Newell', province: 'Alberta' },
  { region: 'Northern Lights', province: 'Alberta' },
  { region: 'Northern Sunrise', province: 'Alberta' },
  { region: 'Opportunity', province: 'Alberta' },
  { region: 'Paintearth', province: 'Alberta' },
  { region: 'Parkland', province: 'Alberta' },
  { region: 'Peace', province: 'Alberta' },
  { region: 'Pincher Creek', province: 'Alberta' },
  { region: 'Ponoka', province: 'Alberta' },
  { region: 'Provost', province: 'Alberta' },
  { region: 'Red Deer', province: 'Alberta' },
  { region: 'Rocky Mountain House', province: 'Alberta' },
  { region: 'Rocky View', province: 'Alberta' },
  { region: 'Saddle Hills', province: 'Alberta' },
  { region: 'Smoky Lake', province: 'Alberta' },
  { region: 'Smoky River', province: 'Alberta' },
  { region: 'Spirit River', province: 'Alberta' },
  { region: 'Spruce Grove', province: 'Alberta' },
  { region: 'St. Albert', province: 'Alberta' },
  { region: 'St. Paul', province: 'Alberta' },
  { region: 'Startland', province: 'Alberta' },
  { region: 'Stettler', province: 'Alberta' },
  { region: 'Strathcona', province: 'Alberta' },
  { region: 'Sturgeon', province: 'Alberta' },
  { region: 'Taber', province: 'Alberta' },
  { region: 'Thorhild', province: 'Alberta' },
  { region: 'Two Hills', province: 'Alberta' },
  { region: 'Vermillion River', province: 'Alberta' },
  { region: 'Vulcan', province: 'Alberta' },
  { region: 'Wainwright', province: 'Alberta' },
  { region: 'Warner', province: 'Alberta' },
  { region: 'Westlock', province: 'Alberta' },
  { region: 'Wetaskiwin', province: 'Alberta' },
  { region: 'Wheatland', province: 'Alberta' },
  { region: 'Willow Creek', province: 'Alberta' },
  { region: 'Wood Buffalo', province: 'Alberta' },
  { region: 'Woodlands', province: 'Alberta' },
  { region: 'Yellowhead', province: 'Alberta' },
  { region: 'Alberni-Clayoquot', province: 'British Columbia' },
  { region: 'Bulkley-Nechako', province: 'British Columbia' },
  { region: 'Capital', province: 'British Columbia' },
  { region: 'Cariboo', province: 'British Columbia' },
  { region: 'Central Coast', province: 'British Columbia' },
  { region: 'Central Kootenay', province: 'British Columbia' },
  { region: 'Columbia-Shuswap', province: 'British Columbia' },
  { region: 'Comox Valley', province: 'British Columbia' },
  { region: 'Coquitlam', province: 'British Columbia' },
  { region: 'Cowichan Valley', province: 'British Columbia' },
  { region: 'East Kootenay', province: 'British Columbia' },
  { region: 'Fraser-Fort George', province: 'British Columbia' },
  { region: 'Fraser Valley', province: 'British Columbia' },
  { region: 'Greater Vancouver', province: 'British Columbia' },
  { region: 'Kitimat-Stikine', province: 'British Columbia' },
  { region: 'Kootenay Boundary', province: 'British Columbia' },
  { region: 'Maple Ridge', province: 'British Columbia' },
  { region: 'Metro Vancouver', province: 'British Columbia' },
  { region: 'Mount Waddington', province: 'British Columbia' },
  { region: 'Nanaimo', province: 'British Columbia' },
  { region: 'New Westminster', province: 'British Columbia' },
  { region: 'North Cowichan', province: 'British Columbia' },
  { region: 'North Okanagan', province: 'British Columbia' },
  { region: 'Northern Rockies', province: 'British Columbia' },
  { region: 'Okanagan-Similkameen', province: 'British Columbia' },
  { region: 'Peace River', province: 'British Columbia' },
  { region: 'Powell River', province: 'British Columbia' },
  { region: 'Skeena-Queen Charlotte', province: 'British Columbia' },
  { region: 'Squamish-Lillooet', province: 'British Columbia' },
  { region: 'Stikine', province: 'British Columbia' },
  { region: 'Strathcona', province: 'British Columbia' },
  { region: 'Sunshine Coast', province: 'British Columbia' },
  { region: 'Thompson-Nicola', province: 'British Columbia' },
  { region: 'Vancouver', province: 'British Columbia' },
  { region: 'Vancouver Metro', province: 'British Columbia' },
  { region: 'Altona', province: 'Manitoba' },
  { region: 'Arborg', province: 'Manitoba' },
  { region: 'Beausejour', province: 'Manitoba' },
  { region: 'Bifrost-Riverton', province: 'Manitoba' },
  { region: 'Brandon', province: 'Manitoba' },
  { region: 'Brenda-Waskada', province: 'Manitoba' },
  { region: 'Carberry', province: 'Manitoba' },
  { region: 'Carman', province: 'Manitoba' },
  { region: 'Cartwright-Roblin', province: 'Manitoba' },
  { region: 'Central Manitoba', province: 'Manitoba' },
  { region: 'Central Plains', province: 'Manitoba' },
  { region: 'Churchill', province: 'Manitoba' },
  { region: 'Churchill And Northern Manitoba', province: 'Manitoba' },
  { region: 'Clanwilliam-Erickson', province: 'Manitoba' },
  { region: 'Dauphin', province: 'Manitoba' },
  { region: 'Deloraine-Winchester', province: 'Manitoba' },
  { region: 'Eastern Manitoba', province: 'Manitoba' },
  { region: 'Eastman', province: 'Manitoba' },
  { region: 'Emerson-Franklin', province: 'Manitoba' },
  { region: 'Ethelbert', province: 'Manitoba' },
  { region: 'Flin Flon', province: 'Manitoba' },
  { region: 'Flin Flon and North West', province: 'Manitoba' },
  { region: 'Gilbert Plains', province: 'Manitoba' },
  { region: 'Gillam', province: 'Manitoba' },
  { region: 'Glenboro-South Cypress', province: 'Manitoba' },
  { region: 'Glenella-Lansdowne', province: 'Manitoba' },
  { region: 'Grand Rapids', province: 'Manitoba' },
  { region: 'Grandview', province: 'Manitoba' },
  { region: 'Grassland', province: 'Manitoba' },
  { region: 'Hamiota', province: 'Manitoba' },
  { region: 'Harrison Park', province: 'Manitoba' },
  { region: 'Indigenous and Municipal Relations', province: 'Manitoba' },
  { region: 'Interlake', province: 'Manitoba' },
  { region: 'Killarney-Turtle Mountain', province: 'Manitoba' },
  { region: 'Lac Du Bonnet', province: 'Manitoba' },
  { region: 'Leaf Rapids', province: 'Manitoba' },
  { region: 'Lgd Of Pinawa', province: 'Manitoba' },
  { region: 'Lorne', province: 'Manitoba' },
  { region: 'Louise', province: 'Manitoba' },
  { region: 'Lynn Lake', province: 'Manitoba' },
  { region: 'Mccreary', province: 'Manitoba' },
  { region: 'Melita', province: 'Manitoba' },
  { region: 'Minitonas-Bowsman', province: 'Manitoba' },
  { region: 'Minnedose', province: 'Manitoba' },
  { region: 'Morden', province: 'Manitoba' },
  { region: 'Morris', province: 'Manitoba' },
  { region: 'Morton-Boissevain', province: 'Manitoba' },
  { region: 'Mossey River', province: 'Manitoba' },
  { region: 'Neepawa', province: 'Manitoba' },
  { region: 'Niverville', province: 'Manitoba' },
  { region: 'Norfolk Treherne', province: 'Manitoba' },
  { region: 'North Cypress-Langford', province: 'Manitoba' },
  { region: 'North East', province: 'Manitoba' },
  { region: 'North Interlake', province: 'Manitoba' },
  { region: 'North Norfolk', province: 'Manitoba' },
  { region: 'Northern', province: 'Manitoba' },
  { region: 'Oakland-Wawanesa', province: 'Manitoba' },
  { region: 'Parkland', province: 'Manitoba' },
  { region: 'Pembina', province: 'Manitoba' },
  { region: 'Pembina Valley', province: 'Manitoba' },
  { region: 'Pilot Mound', province: 'Manitoba' },
  { region: 'Portage La Prairie', province: 'Manitoba' },
  { region: 'Powerview-Pine Falls', province: 'Manitoba' },
  { region: 'Prairie View', province: 'Manitoba' },
  { region: 'Rhineland', province: 'Manitoba' },
  { region: 'Riverdale', province: 'Manitoba' },
  { region: 'Rm Of Alexander', province: 'Manitoba' },
  { region: 'Rm Of Alonsa', province: 'Manitoba' },
  { region: 'Rm Of Argyle', province: 'Manitoba' },
  { region: 'Rm Of Armstrong', province: 'Manitoba' },
  { region: 'Rm Of Brokenhead', province: 'Manitoba' },
  { region: 'Rm Of Cartier', province: 'Manitoba' },
  { region: 'Rm Of Coldwell', province: 'Manitoba' },
  { region: 'Rm Of Cornwallis', province: 'Manitoba' },
  { region: 'Rm Of Dauphin', province: 'Manitoba' },
  { region: 'Rm Of De Salaberry', province: 'Manitoba' },
  { region: 'Rm Of Dufferin', province: 'Manitoba' },
  { region: 'Rm Of East St. Paul', province: 'Manitoba' },
  { region: 'Rm Of Ellice-Archie', province: 'Manitoba' },
  { region: 'Rm Of Elton', province: 'Manitoba' },
  { region: 'Rm Of Fisher', province: 'Manitoba' },
  { region: 'Rm Of Gimli', province: 'Manitoba' },
  { region: 'Rm Of Grahamdale', province: 'Manitoba' },
  { region: 'Rm Of Grey', province: 'Manitoba' },
  { region: 'Rm Of Hanover', province: 'Manitoba' },
  { region: 'Rm Of Headingley', province: 'Manitoba' },
  { region: 'Rm Of Kelset', province: 'Manitoba' },
  { region: 'Rm Of La Broquerie', province: 'Manitoba' },
  { region: 'Rm Of Lac du Bonnet', province: 'Manitoba' },
  { region: 'Rm Of Lakeshore', province: 'Manitoba' },
  { region: 'Rm Of Macdonald', province: 'Manitoba' },
  { region: 'Rm Of Minto-Odanah', province: 'Manitoba' },
  { region: 'Rm Of Montcalm', province: 'Manitoba' },
  { region: 'Rm Of Morris', province: 'Manitoba' },
  { region: 'Rm Of Mountain', province: 'Manitoba' },
  { region: 'Rm Of Oakview', province: 'Manitoba' },
  { region: 'Rm Of Piney', province: 'Manitoba' },
  { region: 'Rm Of Pipestone', province: 'Manitoba' },
  { region: 'Rm Of Portage La Prairie', province: 'Manitoba' },
  { region: 'Rm Of Prairie Lakes', province: 'Manitoba' },
  { region: 'Rm Of Reynolds', province: 'Manitoba' },
  { region: 'Rm Of Riding Mountain West', province: 'Manitoba' },
  { region: 'Rm Of Ritchot', province: 'Manitoba' },
  { region: 'Rm Of Roland', province: 'Manitoba' },
  { region: 'Rm Of Rosedale', province: 'Manitoba' },
  { region: 'Rm Of Rosser', province: 'Manitoba' },
  { region: 'Rm Of Sifton', province: 'Manitoba' },
  { region: 'Rm Of Springfield', province: 'Manitoba' },
  { region: 'Rm Of St. Andrews', province: 'Manitoba' },
  { region: 'Rm Of St. Clements', province: 'Manitoba' },
  { region: 'Rm Of St. Francois Xavier', province: 'Manitoba' },
  { region: 'Rm Of St. Laurent', province: 'Manitoba' },
  { region: 'Rm Of Stanley', province: 'Manitoba' },
  { region: 'Rm Of Ste. Anne', province: 'Manitoba' },
  { region: 'Rm Of Stuartburn', province: 'Manitoba' },
  { region: 'Rm Of Taché', province: 'Manitoba' },
  { region: 'Rm Of Thompson', province: 'Manitoba' },
  { region: 'Rm Of Victoria', province: 'Manitoba' },
  { region: 'Rm Of Victoria Beach', province: 'Manitoba' },
  { region: 'Rm Of Wallace-Woodworth', province: 'Manitoba' },
  { region: 'Rm Of West Interlake', province: 'Manitoba' },
  { region: 'Rm Of West St, Paul', province: 'Manitoba' },
  { region: 'Rm Of Whitehead', province: 'Manitoba' },
  { region: 'Rm Of Whitemouth', province: 'Manitoba' },
  { region: 'Rm Of Woodlands', province: 'Manitoba' },
  { region: 'Rm Of Yellowhead', province: 'Manitoba' },
  { region: 'Roblin', province: 'Manitoba' },
  { region: 'Roblin, Russell, Rossburn', province: 'Manitoba' },
  { region: 'Rossburn', province: 'Manitoba' },
  { region: 'Russell-Binscarth', province: 'Manitoba' },
  { region: 'Selkirk', province: 'Manitoba' },
  { region: 'Snow Lake', province: 'Manitoba' },
  { region: 'Souris-Glenwood', province: 'Manitoba' },
  { region: 'South Interlake', province: 'Manitoba' },
  { region: 'South West', province: 'Manitoba' },
  { region: 'Ste. Rose', province: 'Manitoba' },
  { region: 'Steinbach', province: 'Manitoba' },
  { region: 'Stonewall', province: 'Manitoba' },
  { region: 'Swan River', province: 'Manitoba' },
  { region: 'Swan Valley West', province: 'Manitoba' },
  { region: 'Teulon', province: 'Manitoba' },
  { region: 'The Pas', province: 'Manitoba' },
  { region: 'Thompson', province: 'Manitoba' },
  { region: 'Thompson And North Central', province: 'Manitoba' },
  { region: 'Two Borders', province: 'Manitoba' },
  { region: 'Village Of Dunnottar', province: 'Manitoba' },
  { region: 'Village Of St. Pierre-Jolys', province: 'Manitoba' },
  { region: 'Virden', province: 'Manitoba' },
  { region: 'Western Manitoba', province: 'Manitoba' },
  { region: 'Westlake-Gladstone', province: 'Manitoba' },
  { region: 'Westman', province: 'Manitoba' },
  { region: 'Whitehorse Plains', province: 'Manitoba' },
  { region: 'Wrinkler', province: 'Manitoba' },
  { region: 'Winnipeg', province: 'Manitoba' },
  { region: 'Winnipeg Beach', province: 'Manitoba' },
  { region: 'Winnipeg Capital', province: 'Manitoba' },
  { region: 'Albert', province: 'New Brunswick' },
  { region: 'Carleton', province: 'New Brunswick' },
  { region: 'Charlotte', province: 'New Brunswick' },
  { region: 'Gloucester', province: 'New Brunswick' },
  { region: 'Kent', province: 'New Brunswick' },
  { region: 'Kings', province: 'New Brunswick' },
  { region: 'Madawaska', province: 'New Brunswick' },
  { region: 'Northumberland', province: 'New Brunswick' },
  { region: 'Queens', province: 'New Brunswick' },
  { region: 'Restigouche', province: 'New Brunswick' },
  { region: 'Saint John', province: 'New Brunswick' },
  { region: 'Sudbury', province: 'New Brunswick' },
  { region: 'Victoria', province: 'New Brunswick' },
  { region: 'Westmorland', province: 'New Brunswick' },
  { region: 'York', province: 'New Brunswick' },
  { region: 'Avalon', province: 'Newfoundland and Labrador' },
  { region: 'Burin Peninsula', province: 'Newfoundland and Labrador' },
  { region: 'Central', province: 'Newfoundland and Labrador' },
  { region: 'Clarenville', province: 'Newfoundland and Labrador' },
  {
    region: 'Conception Bay - St. Johns',
    province: 'Newfoundland and Labrador',
  },
  { region: 'Corner Brook', province: 'Newfoundland and Labrador' },
  { region: 'Eastern', province: 'Newfoundland and Labrador' },
  { region: 'Grand-Sault', province: 'Newfoundland and Labrador' },
  { region: 'Harbour Breton', province: 'Newfoundland and Labrador' },
  { region: 'Hopedale', province: 'Newfoundland and Labrador' },
  { region: 'Labrador', province: 'Newfoundland and Labrador' },
  { region: 'Labrador City', province: 'Newfoundland and Labrador' },
  { region: 'Stephenville', province: 'Newfoundland and Labrador' },
  { region: 'Western', province: 'Newfoundland and Labrador' },
  { region: 'Wiltondale', province: 'Newfoundland and Labrador' },
  { region: 'Woodstock', province: 'Newfoundland and Labrador' },
  { region: 'Dehcho', province: 'Northwest Territories' },
  { region: 'Inuvik', province: 'Northwest Territories' },
  { region: 'North Slave', province: 'Northwest Territories' },
  { region: 'Region 1', province: 'Northwest Territories' },
  { region: 'Region 2', province: 'Northwest Territories' },
  { region: 'Region 4', province: 'Northwest Territories' },
  { region: 'Region 5', province: 'Northwest Territories' },
  { region: 'Region 6', province: 'Northwest Territories' },
  { region: 'Sahtu', province: 'Northwest Territories' },
  { region: 'South Slave', province: 'Northwest Territories' },
  { region: 'Annapolis', province: 'Nova Scotia' },
  { region: 'Antigonish', province: 'Nova Scotia' },
  { region: 'Cape Breton', province: 'Nova Scotia' },
  { region: 'Colchester', province: 'Nova Scotia' },
  { region: 'Cumberland', province: 'Nova Scotia' },
  { region: 'Digby', province: 'Nova Scotia' },
  { region: 'Guysborough', province: 'Nova Scotia' },
  { region: 'Halifax', province: 'Nova Scotia' },
  { region: 'Hants', province: 'Nova Scotia' },
  { region: 'Inverness', province: 'Nova Scotia' },
  { region: 'Kings', province: 'Nova Scotia' },
  { region: 'Lunenburg', province: 'Nova Scotia' },
  { region: 'Pictou', province: 'Nova Scotia' },
  { region: 'Queens', province: 'Nova Scotia' },
  { region: 'Richmond', province: 'Nova Scotia' },
  { region: 'Shelburne', province: 'Nova Scotia' },
  { region: 'Victoria', province: 'Nova Scotia' },
  { region: 'Yarmouth', province: 'Nova Scotia' },
  { region: 'Kitikmeot', province: 'Nunavut' },
  { region: 'Kivallia', province: 'Nunavut' },
  { region: 'Qikiqtaaluk', province: 'Nunavut' },
  { region: 'Algoma', province: 'Ontario' },
  { region: 'Brant', province: 'Ontario' },
  { region: 'Bruce', province: 'Ontario' },
  { region: 'Chatham-Kent', province: 'Ontario' },
  { region: 'Cochrane', province: 'Ontario' },
  { region: 'Dufferin', province: 'Ontario' },
  { region: 'Elgin', province: 'Ontario' },
  { region: 'Essex', province: 'Ontario' },
  { region: 'Frontenac', province: 'Ontario' },
  { region: 'Grey', province: 'Ontario' },
  { region: 'Haldimand-Norfolk', province: 'Ontario' },
  { region: 'Haliburton', province: 'Ontario' },
  { region: 'Halton', province: 'Ontario' },
  { region: 'Hamilton', province: 'Ontario' },
  { region: 'Huron', province: 'Ontario' },
  { region: 'Kawartha', province: 'Ontario' },
  { region: 'Kawartha Lakes', province: 'Ontario' },
  { region: 'Kenora', province: 'Ontario' },
  { region: 'Lambton', province: 'Ontario' },
  { region: 'Lanark', province: 'Ontario' },
  { region: 'Leeds And Grenville', province: 'Ontario' },
  { region: 'Manitoulin', province: 'Ontario' },
  { region: 'Middlesex', province: 'Ontario' },
  { region: 'Muskoka', province: 'Ontario' },
  { region: 'Niagara', province: 'Ontario' },
  { region: 'Nipissing', province: 'Ontario' },
  { region: 'Northumberland', province: 'Ontario' },
  { region: 'Ottawa', province: 'Ontario' },
  { region: 'Parry Sound', province: 'Ontario' },
  { region: 'Peel County', province: 'Ontario' },
  { region: 'Perth', province: 'Ontario' },
  { region: 'Peterborough', province: 'Ontario' },
  { region: 'Prescott And Russell', province: 'Ontario' },
  { region: 'Prince Edward', province: 'Ontario' },
  { region: 'Quebec', province: 'Ontario' },
  { region: 'Oxford', province: 'Ontario' },
  { region: 'Hastings', province: 'Ontario' },
  { region: 'Durham', province: 'Ontario' },
  { region: 'Lennox And Addington', province: 'Ontario' },
  { region: 'Rainy River', province: 'Ontario' },
  { region: 'Renfrew', province: 'Ontario' },
  { region: 'Simcoe', province: 'Ontario' },
  { region: 'Stormont, Dundas And Glengarry', province: 'Ontario' },
  { region: 'Sudbury', province: 'Ontario' },
  { region: 'Thunder Bay', province: 'Ontario' },
  { region: 'Timiskaming', province: 'Ontario' },
  { region: 'Toronto', province: 'Ontario' },
  { region: 'Waterloo', province: 'Ontario' },
  { region: 'Wellington', province: 'Ontario' },
  { region: 'York', province: 'Ontario' },
  { region: 'Kings', province: 'Prince Edward Island' },
  { region: 'Prince', province: 'Prince Edward Island' },
  { region: 'Queens', province: 'Prince Edward Island' },
  { region: 'Abitibi-Témiscamingue', province: 'Quebec' },
  { region: 'Bas-Saint-Laurent', province: 'Quebec' },
  { region: 'Capitale-Nationale', province: 'Quebec' },
  { region: 'Centre-du-Québec', province: 'Quebec' },
  { region: 'Chaudière-Appalaches', province: 'Quebec' },
  { region: 'Côte-Nord', province: 'Quebec' },
  { region: 'Estrie', province: 'Quebec' },
  { region: 'Gaspésie-Îles-de-La-Madeleine', province: 'Quebec' },
  { region: 'Lanaudière', province: 'Quebec' },
  { region: 'Laurentides', province: 'Quebec' },
  { region: 'Laval', province: 'Quebec' },
  { region: 'Longueuil', province: 'Quebec' },
  { region: 'Mauricie', province: 'Quebec' },
  { region: 'Montérégie', province: 'Quebec' },
  { region: 'Montréal', province: 'Quebec' },
  { region: 'Montrégie', province: 'Quebec' },
  { region: 'Nord-du-Québec', province: 'Quebec' },
  { region: 'Outaouais', province: 'Quebec' },
  { region: 'Saguenay-Lac-Saint-Jean', province: 'Quebec' },
  { region: 'Assiniboia', province: 'Saskatchewan' },
  { region: 'Battleford North', province: 'Saskatchewan' },
  { region: 'Cadillac', province: 'Saskatchewan' },
  { region: 'Central East', province: 'Saskatchewan' },
  { region: 'Central', province: 'Saskatchewan' },
  { region: 'West', province: 'Saskatchewan' },
  { region: 'Estevan', province: 'Saskatchewan' },
  { region: 'Kindersley', province: 'Saskatchewan' },
  { region: 'Lloydminster', province: 'Saskatchewan' },
  { region: 'Melville', province: 'Saskatchewan' },
  { region: 'Moose Jaw', province: 'Saskatchewan' },
  { region: 'Nipawin', province: 'Saskatchewan' },
  { region: 'North', province: 'Saskatchewan' },
  { region: 'Prince Albert', province: 'Saskatchewan' },
  { region: 'Regina', province: 'Saskatchewan' },
  { region: 'Rosetown Saskatoon', province: 'Saskatchewan' },
  { region: 'South East', province: 'Saskatchewan' },
  { region: 'South West', province: 'Saskatchewan' },
  { region: 'Swift Current', province: 'Saskatchewan' },
  { region: 'Wadena', province: 'Saskatchewan' },
  { region: 'Weyburn', province: 'Saskatchewan' },
  { region: 'Yorkton', province: 'Saskatchewan' },
  { region: 'Kluane', province: 'Yukon' },
  { region: 'Liard', province: 'Yukon' },
  { region: 'Northern', province: 'Yukon' },
  { region: 'Northern Tuchone', province: 'Yukon' },
  { region: 'Southern Lakes', province: 'Yukon' },
  { region: 'Yukon', province: 'Yukon' },
];

export const allRegionsMap = regionsByProvince.reduce(
  (acc: Record<string, string>, value) => {
    const region = value.region;
    acc[region] = value.province;
    return acc;
  },
  {},
);

export default regionsByProvince;
