import { intl } from 'utilities/i18n/intl.utility';

const ResourcesString = intl.translate({
  id: 'Resources',
});
const SitesString = intl.translate({
  id: 'Sites',
});
const AgenciesString = intl.translate({
  id: 'Agencies',
});
const TaxonomiesString = intl.translate({
  id: 'Taxonomies',
});

export enum DataManagementBaseEnum {
  Resources,
  Sites,
  Agencies,
}

export enum DataManagementEnum {
  Resources,
  Sites,
  Agencies,
  Taxonomies,
}

export const DataManagementBasicsAsObject = {
  [DataManagementEnum.Resources]: ResourcesString,
  [DataManagementEnum.Sites]: SitesString,
  [DataManagementEnum.Agencies]: AgenciesString,
};

export const DataManagementEnumsAsObject = {
  [DataManagementEnum.Taxonomies]: TaxonomiesString,
  ...DataManagementBasicsAsObject,
};

export const getDataManagementString = (dataManagement: DataManagementEnum) => {
  switch (dataManagement) {
    case DataManagementEnum.Resources:
      return 'resource';
    case DataManagementEnum.Sites:
      return 'site';
    case DataManagementEnum.Agencies:
      return 'agency';
    case DataManagementEnum.Taxonomies:
      return 'taxonomy';
  }
};
