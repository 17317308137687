/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Remove, Search, Update } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { searchAgencies } from 'api/agencies/agencies.api';
import { getGeocodingData } from 'api/geocoding/geocoding.api';
import citiesByProvince from 'assets/constants/cities-by-province';
import countries from 'assets/constants/countries';
import provinces from 'assets/constants/provinces';
import PhoneNumbersForm from 'components/Form/Sections/PhoneNumbersForm';
import { newPhoneNumbers } from 'helpers/resources.helpers';
import { MRT_ColumnDef } from 'material-react-table';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Contact, PhoneNumber } from 'types/data-management';
import { Agency } from 'types/entities/agencies';
import { YesNoEnum } from 'types/utilities';
import { intl } from 'utilities/i18n/intl.utility';
import { BoxContent } from '../ModalContent/BoxContent';
import { ModalParagraph } from '../ModalTitles/ModalParagraph';
import { toast } from 'react-toastify';

type AgencyFormProps = {
  showLabel?: boolean;
  selectedAgency?: string;
  selectedAgencyName?: string;

  setSelectedAgency?: (values: string) => void;
  setSelectedAgencyName?: (values: string) => void;
  columns: MRT_ColumnDef<Agency>[];
  values: Agency;
  setValues: (values: Agency) => void;
  validationErrors?: { [cellId: string]: string };
  resource?: any;
  changedProperties?: string[];
};

const newContact: Contact = {
  type: '',
  name: '',
  title: '',
  phone: '',
  email: '',
};

const renderDisabledTextField = (
  column: MRT_ColumnDef<Agency>,
  values: Agency,
  setValues: (values: Agency) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      disabled
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey]}
      variant="standard"
    />
  );
};

const renderYesNoDropdownField = (
  column: MRT_ColumnDef<Agency>,
  values: Agency,
  setValues: (values: Agency) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {Object.values(YesNoEnum).map((item) => (
        <MenuItem key={item} value={item}>
          {item === YesNoEnum.Yes ? 'Yes' : 'No'}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderCountryDropdownField = (
  column: MRT_ColumnDef<Agency>,
  values: Agency,
  setValues: (values: Agency) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {countries.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderProvinceDropdownField = (
  column: MRT_ColumnDef<Agency>,
  values: Agency,
  setValues: (values: Agency) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {provinces.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderCityDropdownField = (
  column: MRT_ColumnDef<Agency>,
  values: Agency,
  setValues: (values: Agency) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {values.locationProvince ? (
        citiesByProvince
          .filter((city) => city.province === values.locationProvince)
          .map((city, index) => (
            <MenuItem key={index} value={city.city}>
              {city.city}
            </MenuItem>
          ))
      ) : (
        <MenuItem value={''}>
          {intl.translate({
            id: 'Select a province first',
          })}
        </MenuItem>
      )}
    </TextField>
  );
};

const ContactsForm: FC<{
  column: MRT_ColumnDef<Agency>;
  values: Agency;
  setValues: (values: Agency) => void;
}> = ({ column, values, setValues }) => {
  if (!column.accessorKey) {
    return null;
  }

  const [contacts, setContacts] = useState(
    (values[column.accessorKey] as unknown[] as Contact[])?.length > 0
      ? (values[column.accessorKey] as unknown[] as Contact[])
      : [{ ...newContact }],
  );

  const handleAddContact = () => {
    const arrayWithNewItem = [...contacts, { ...newContact }];
    setContacts(arrayWithNewItem);
  };

  const handleRemoveContact = (index: number) => {
    const arrayRemovedInex = [...contacts];
    arrayRemovedInex.splice(index, 1);
    setContacts(arrayRemovedInex);
  };

  return (
    <Grid container>
      {contacts.map((contact, index) => (
        <React.Fragment key={index}>
          <Grid item xs={10}>
            <TextField
              label={'Type'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          type: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={contact.type || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Add item"
              component="label"
              onClick={handleAddContact}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Remove Item"
              component="label"
              onClick={() => handleRemoveContact(index)}
            >
              <Remove />
            </IconButton>
          </Grid>

          <Grid item xs={10}>
            <TextField
              label={'Name'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          name: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={contact.name || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Title'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          title: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={contact.title || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Phone'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          phone: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={contact.phone || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Email'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          email: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={contact.email || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {index !== contacts.length - 1 && (
            <Grid item mt={2} mb={1} xs={12}>
              <Divider />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export const AgencyForm: FC<AgencyFormProps> = ({
  showLabel = true,
  selectedAgencyName,
  setSelectedAgency,
  setSelectedAgencyName,
  columns,
  values,
  setValues,
  validationErrors = {},
  resource,
  changedProperties,
}) => {
  const [showDropdown, setShowDropdown] = useState(showLabel);
  const [loading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState<{ label: string; value: string }[]>(
    [],
  );
  const [filteredAgencies, setFilteredAgencies] = useState<
    { label: string; value: string }[]
  >([]);

  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>(
    values.phoneNumbers !== null && typeof values.phoneNumbers !== 'string'
      ? values.phoneNumbers
      : [{ ...newPhoneNumbers }],
  );

  const renderTextField = (
    column: MRT_ColumnDef<Agency>,
    values: Agency,
    setValues: (values: Agency) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    if (changedProperties && changedProperties.includes(column.accessorKey)) {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={isRequired}
          error={!!validationErrors[column.accessorKey]}
          helperText={validationErrors[column.accessorKey]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
              ...values,
              [e.target.name]: e.target?.value,
            });
          }}
          value={values[column.accessorKey]}
          variant="filled"
          color="success"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Update />
              </InputAdornment>
            ),
          }}
          focused
        />
      );
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      />
    );
  };

  const renderNumberTextField = (
    column: MRT_ColumnDef<Agency>,
    values: Agency,
    setValues: (values: Agency) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        type="number"
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
        style={{ width: '100%' }}
      />
    );
  };

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Agency>;
    },
    [columns],
  );

  const handleFilterChange = async (searchText: string) => {
    const filtered = agencies.filter((agency) =>
      agency.label.toLowerCase().includes(searchText.toLowerCase()),
    );
    let searchedAgencies: any[] = [];

    if (filtered.length === 0) {
      // search for agencies with the searchtext
      try {
        if (searchText.includes('En') && searchText.includes('Fr')) {
          const search = searchText.split('/')[0].split(': ')[1].trimEnd();
          const { agencies } = await searchAgencies('nameEN', search);
          searchedAgencies = agencies;
        } else {
          const { agencies } = await searchAgencies('nameEN', searchText);
          searchedAgencies = agencies;
        }
      } catch (err) {
        toast.error('Failed to search', { position: 'top-center' });
      }

      const filteredAgencies = searchedAgencies.map((agency) => {
        const id = agency?.objectId || agency?._id;
        return {
          label: `En: ${agency.nameEN} / Fr: ${agency.nameFR} -  (${id}) `,
          value: id,
        };
      });

      setFilteredAgencies(filteredAgencies);
      // setSelectedAgency(filteredAgencies[0]?.value)
    } else {
      setFilteredAgencies(filtered);
    }
  };

  const handleSelectAgencies = (selectedOption: any) => {
    const value =
      filteredAgencies.find((agency) => agency.label === selectedOption)
        ?.value || '';
    setSelectedAgency && setSelectedAgency(value);
    if (setSelectedAgencyName) {
      setSelectedAgencyName(
        filteredAgencies.find((agency) => agency.label === selectedOption)
          ?.label || '',
      );
    }
  };

  const handleLatitudeLongitudeLookup = async (values: any) => {
    try {
      const res = await getGeocodingData({
        address1: values['locationAddress1'] || '',
        address2: values['locationAddress2'] || '',
        country: values['locationCountry'] || '',
        province: values['locationProvince'] || '',
        county: values['locationCounty'] || '',
        city: values['locationCity'] || '',
        postalCode: values['locationPostalCode'] || '',
      });
      const { latitude, longitude } = res;

      setValues({
        ...values,
        ['locationLongitude']: longitude,
        ['locationLatitude']: latitude,
      });
    } catch (error) {
      toast.error('An error occured', {
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    const getAgencies = async () => {
      setLoading(true);
      // const agenciesList = (await fetchAgencies(0, 10000)).agencies;
      const agenciesList = [resource?.agency];
      const agenciesLabelValue = agenciesList?.map((agency) => {
        if (agency?.nameEN) {
          return {
            label: `En: ${agency?.nameEN} / Fr: ${agency?.nameFR} - (${
              agency?._id || agency?.objectId
            })`,
            value: agency?._id || agency?.objectId,
          };
        } else return { label: '', value: '' };
      });
      setAgencies(agenciesLabelValue);
      setFilteredAgencies(agenciesLabelValue);
      setLoading(false);
    };

    getAgencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      phoneNumbers,
    });
    // disable warning as we only want to trigger update for accessibilities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumbers]);

  // const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   e.preventDefault();
  //   setShowDropdown(!showDropdown);
  // };

  const [inputValue, setInputValue] = React.useState('');

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      {/* TODO: create new one */}
      {/* {showLabel && (
        <ModalParagraph>
          {intl.translate(
            {
              id: 'Select an existing Agency, or <a>create a new one.</a>',
            },
            {
              a: (label) => (
                <ModalCreateNewAnchor onClick={(e) => handleClick(e)}>
                  {label}
                </ModalCreateNewAnchor>
              ),
            },
          )}
        </ModalParagraph>
      )} */}

      {showDropdown ? (
        <>
          <ModalParagraph>
            {intl.translate({
              id: 'Select existing Agency',
            })}
          </ModalParagraph>
          {/* <Select
            isLoading={loading}
            options={filteredAgencies}
            value={agencies?.filter((agency) =>
              selectedAgency?.includes(agency.value),
            ) || ""}
            onChange={handleSelectAgencies}
            onInputChange={handleFilterChange}
            filterOption={() => true}
            placeholder={intl.translate({
              id: 'Select an option',
            })}
          /> */}
          <Autocomplete
            options={filteredAgencies}
            noOptionsText="Enter an agency name to search"
            getOptionLabel={(option) => option.label}
            onInputChange={(e, newValue) => {
              setInputValue(newValue);
              handleFilterChange(newValue);
              try {
                handleSelectAgencies(newValue);
              } catch (e) {
                console.log(e);
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={
                    selectedAgencyName ||
                    filteredAgencies?.[0]?.label ||
                    'Select'
                  }
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      filteredAgencies.findIndex(
                        (o) => o.label === inputValue,
                      ) === -1
                    ) {
                      setFilteredAgencies((o) =>
                        o.concat([
                          {
                            label: inputValue,
                            value:
                              filteredAgencies.find(
                                (value) => value.label === inputValue,
                              )?.value || '',
                          },
                        ]),
                      );
                    }
                  }}
                />
              );
            }}
          />
        </>
      ) : (
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
            padding: 2,
          }}
        >
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '102ch' },
            }}
          >
            {renderDisabledTextField(
              getColumnByAccessorKey('agencyReferenceNumber'),
              values,
              setValues,
            )}

            {renderDisabledTextField(
              getColumnByAccessorKey('khpReferenceNumber'),
              values,
              setValues,
            )}
            {getColumnByAccessorKey('lastVerifiedOn') &&
              values['lastVerifiedOn'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('lastVerifiedOn'),
                values,
                setValues,
              )}
            {getColumnByAccessorKey('lastUpdatedBy') &&
              values['lastUpdatedBy'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('lastUpdatedBy'),
                values,
                setValues,
              )}
            {getColumnByAccessorKey('updatedAt') &&
              values['updatedAt'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('updatedAt'),
                values,
                setValues,
              )}
            {/* {getColumnByAccessorKey('createdAt') &&
              values['createdAt'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('createdAt'),
                values,
                setValues,
              )} */}
          </Box>

          <BoxContent
            title={intl.translate({
              id: 'Name Details',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '50ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('nameEN'),
                values,
                setValues,
                true,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameFR'),
                values,
                setValues,
                true,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsEnOfficial'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsFrOfficial'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsEnAlternate'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsFrAlternate'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Location',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('locationAddress1'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationAddress2'),
                values,
                setValues,
              )}
              {renderCountryDropdownField(
                getColumnByAccessorKey('locationCountry'),
                values,
                setValues,
              )}
              {renderProvinceDropdownField(
                getColumnByAccessorKey('locationProvince'),
                values,
                setValues,
              )}

              {renderTextField(
                getColumnByAccessorKey('locationCounty'),
                values,
                setValues,
              )}
              {renderCityDropdownField(
                getColumnByAccessorKey('locationCity'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationPostalCode'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationDescription'),
                values,
                setValues,
              )}
              <Box
                style={{
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {renderNumberTextField(
                  getColumnByAccessorKey('locationLongitude'),
                  values,
                  setValues,
                )}
                {renderNumberTextField(
                  getColumnByAccessorKey('locationLatitude'),
                  values,
                  setValues,
                )}
                <Box>
                  <Tooltip title="Lookup Latitude and Longitude">
                    <IconButton
                      color="primary"
                      aria-label="Lookup Latitude and Longitude"
                      component="label"
                      onClick={() => handleLatitudeLongitudeLookup(values)}
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {renderYesNoDropdownField(
                getColumnByAccessorKey('isLocationPrivate'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Mailing',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('mailingAddressAddress1'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('mailingAddressAddress2'),
                values,
                setValues,
              )}
              {renderCountryDropdownField(
                getColumnByAccessorKey('mailingAddressCountry'),
                values,
                setValues,
              )}
              {renderProvinceDropdownField(
                getColumnByAccessorKey('mailingAddressProvince'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('mailingAddressCounty'),
                values,
                setValues,
              )}
              {renderCityDropdownField(
                getColumnByAccessorKey('mailingAddressCity'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('mailingAddressPostalCode'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Social Media',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('email'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '49ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('websiteEn'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('websiteFr'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('socialFacebook'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('socialInstagram'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Phone Number',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              <PhoneNumbersForm
                value={phoneNumbers}
                setValue={setPhoneNumbers}
              />
            </Box>
          </BoxContent>
          <BoxContent
            title={intl.translate({
              id: 'Main Contact Information',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('mainContactName'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '50ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('mainContactTitleEn'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('mainContactTitleFr'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('mainContactPhone'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('mainContactEmail'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>
          <BoxContent
            title={intl.translate({
              id: 'Contacts',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              <ContactsForm
                column={getColumnByAccessorKey('contacts')}
                values={values}
                setValues={setValues}
              />
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Miscellaneous',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('legalStatus'),
                values,
                setValues,
              )}
            </Box>
            {getColumnByAccessorKey('objectId') && values['objectId'] && (
              <Box
                sx={{
                  '& > :not(style)': { m: 1, width: '102ch' },
                }}
              >
                {renderDisabledTextField(
                  getColumnByAccessorKey('objectId'),
                  values,
                  setValues,
                )}
              </Box>
            )}
          </BoxContent>
        </Stack>
      )}
    </Stack>
  );
};
