const languagesList = [
  { language: 'Abkhazian', code: 'ab' },
  { language: 'Afar', code: 'aa' },
  { language: 'Afrikaans', code: 'af' },
  { language: 'Akan', code: 'ak' },
  { language: 'Albanian', code: 'sq' },
  { language: 'Amharic', code: 'am' },
  { language: 'Arabic', code: 'ar' },
  { language: 'Aragonese', code: 'an' },
  { language: 'Armenian', code: 'hy' },
  { language: 'Assamese', code: 'as' },
  { language: 'Avaric', code: 'av' },
  { language: 'Avestan', code: 'ae' },
  { language: 'Aymara', code: 'ay' },
  { language: 'Azerbaijani', code: 'az' },
  { language: 'Bambara', code: 'bm' },
  { language: 'Bashkir', code: 'ba' },
  { language: 'Basque', code: 'eu' },
  { language: 'Belarusian', code: 'be' },
  { language: 'Bengali', code: 'bn' },
  { language: 'Bihari', code: 'bh' },
  { language: 'Bislama', code: 'bi' },
  { language: 'Bosnian', code: 'bs' },
  { language: 'Breton', code: 'br' },
  { language: 'Bulgarian', code: 'bg' },
  { language: 'Burmese', code: 'my' },
  { language: 'Catalan', code: 'ca' },
  { language: 'Chamorro', code: 'ch' },
  { language: 'Chechen', code: 'ce' },
  { language: 'Chichewa', code: 'ny' },
  { language: 'Chinese', code: 'zh' },
  { language: 'Church Slavic', code: 'cu' },
  { language: 'Chuvash', code: 'cv' },
  { language: 'Cornish', code: 'kw' },
  { language: 'Corsican', code: 'co' },
  { language: 'Cree', code: 'cr' },
  { language: 'Croatian', code: 'hr' },
  { language: 'Czech', code: 'cs' },
  { language: 'Danish', code: 'da' },
  { language: 'Divehi', code: 'dv' },
  { language: 'Dutch', code: 'nl' },
  { language: 'Dzongkha', code: 'dz' },
  { language: 'English', code: 'en' },
  { language: 'Esperanto', code: 'eo' },
  { language: 'Estonian', code: 'et' },
  { language: 'Ewe', code: 'ee' },
  { language: 'Faroese', code: 'fo' },
  { language: 'Fijian', code: 'fj' },
  { language: 'Finnish', code: 'fi' },
  { language: 'French', code: 'fr' },
  { language: 'Fulah', code: 'ff' },
  { language: 'Galician', code: 'gl' },
  { language: 'Georgian', code: 'ka' },
  { language: 'German', code: 'de' },
  { language: 'Greek', code: 'el' },
  { language: 'Guarani', code: 'gn' },
  { language: 'Gujarati', code: 'gu' },
  { language: 'Haitian Creole', code: 'ht' },
  { language: 'Hausa', code: 'ha' },
  { language: 'Hebrew', code: 'he' },
  { language: 'Herero', code: 'hz' },
  { language: 'Hindi', code: 'hi' },
  { language: 'Hiri Motu', code: 'ho' },
  { language: 'Hungarian', code: 'hu' },
  { language: 'Icelandic', code: 'is' },
  { language: 'Ido', code: 'io' },
  { language: 'Igbo', code: 'ig' },
  { language: 'Indonesian', code: 'id' },
  { language: 'Interlingua', code: 'ia' },
  { language: 'Interlingue', code: 'ie' },
  { language: 'Inuktitut', code: 'iu' },
  { language: 'Inupiaq', code: 'ik' },
  { language: 'Irish', code: 'ga' },
  { language: 'Italian', code: 'it' },
  { language: 'Japanese', code: 'ja' },
  { language: 'Javanese', code: 'jv' },
  { language: 'Kalaallisut', code: 'kl' },
  { language: 'Kannada', code: 'kn' },
  { language: 'Kanuri', code: 'kr' },
  { language: 'Kashmiri', code: 'ks' },
  { language: 'Kazakh', code: 'kk' },
  { language: 'Khmer', code: 'km' },
  { language: 'Kikuyu', code: 'ki' },
  { language: 'Kinyarwanda', code: 'rw' },
  { language: 'Kirghiz', code: 'ky' },
  { language: 'Komi', code: 'kv' },
  { language: 'Kongo', code: 'kg' },
  { language: 'Korean', code: 'ko' },
  { language: 'Kurdish', code: 'ku' },
  { language: 'Kwanyama', code: 'kj' },
  { language: 'Lao', code: 'lo' },
  { language: 'Latin', code: 'la' },
  { language: 'Latvian', code: 'lv' },
  { language: 'Limburgish', code: 'li' },
  { language: 'Lingala', code: 'ln' },
  { language: 'Lithuanian', code: 'lt' },
  { language: 'Luxembourgish', code: 'lb' },
  { language: 'Macedonian', code: 'mk' },
  { language: 'Malagasy', code: 'mg' },
  { language: 'Malay', code: 'ms' },
  { language: 'Malayalam', code: 'ml' },
  { language: 'Maltese', code: 'mt' },
  { language: 'Manx', code: 'gv' },
  { language: 'Maori', code: 'mi' },
  { language: 'Marathi', code: 'mr' },
  { language: 'Marshallese', code: 'mh' },
  { language: 'Mongolian', code: 'mn' },
  { language: 'Nauru', code: 'na' },
  { language: 'Navajo', code: 'nv' },
  { language: 'Ndebele (South)', code: 'nr' },
  { language: 'Ndonga', code: 'ng' },
  { language: 'Nepali', code: 'ne' },
  { language: 'Northern Sami', code: 'se' },
  { language: 'Norwegian', code: 'no' },
  { language: 'Norwegian Bokmål', code: 'nb' },
  { language: 'Norwegian Nynorsk', code: 'nn' },
  { language: 'Nuosu', code: 'ii' },
  { language: 'Occitan', code: 'oc' },
  { language: 'Ojibwa', code: 'oj' },
  { language: 'Oriya', code: 'or' },
  { language: 'Oromo', code: 'om' },
  { language: 'Ossetian', code: 'os' },
  { language: 'Pali', code: 'pi' },
  { language: 'Panjabi', code: 'pa' },
  { language: 'Pashto', code: 'ps' },
  { language: 'Persian', code: 'fa' },
  { language: 'Polish', code: 'pl' },
  { language: 'Portuguese', code: 'pt' },
  { language: 'Quechua', code: 'qu' },
  { language: 'Romansh', code: 'rm' },
  { language: 'Romanian', code: 'ro' },
  { language: 'Russian', code: 'ru' },
  { language: 'Samoan', code: 'sm' },
  { language: 'Sango', code: 'sg' },
  { language: 'Sanskrit', code: 'sa' },
  { language: 'Sardinian', code: 'sc' },
  { language: 'Scottish Gaelic', code: 'gd' },
  { language: 'Serbian', code: 'sr' },
  { language: 'Shona', code: 'sn' },
  { language: 'Sindhi', code: 'sd' },
  { language: 'Sinhala', code: 'si' },
  { language: 'Slovak', code: 'sk' },
  { language: 'Slovenian', code: 'sl' },
  { language: 'Somali', code: 'so' },
  { language: 'Sotho, Southern', code: 'st' },
  { language: 'Spanish', code: 'es' },
  { language: 'Sundanese', code: 'su' },
  { language: 'Swahili', code: 'sw' },
  { language: 'Swati', code: 'ss' },
  { language: 'Swedish', code: 'sv' },
  { language: 'Tagalog', code: 'tl' },
  { language: 'Tahitian', code: 'ty' },
  { language: 'Tajik', code: 'tg' },
  { language: 'Tamil', code: 'ta' },
  { language: 'Tatar', code: 'tt' },
  { language: 'Telugu', code: 'te' },
  { language: 'Thai', code: 'th' },
  { language: 'Tibetan', code: 'bo' },
  { language: 'Tigrinya', code: 'ti' },
  { language: 'Tonga', code: 'to' },
  { language: 'Tsonga', code: 'ts' },
  { language: 'Tswana', code: 'tn' },
  { language: 'Turkish', code: 'tr' },
  { language: 'Turkmen', code: 'tk' },
  { language: 'Twi', code: 'tw' },
  { language: 'Uighur', code: 'ug' },
  { language: 'Ukrainian', code: 'uk' },
  { language: 'Urdu', code: 'ur' },
  { language: 'Uzbek', code: 'uz' },
  { language: 'Venda', code: 've' },
  { language: 'Vietnamese', code: 'vi' },
  { language: 'Volapük', code: 'vo' },
  { language: 'Walloon', code: 'wa' },
  { language: 'Welsh', code: 'cy' },
  { language: 'Wolof', code: 'wo' },
  { language: 'Xhosa', code: 'xh' },
  { language: 'Yiddish', code: 'yi' },
  { language: 'Yoruba', code: 'yo' },
  { language: 'Zhuang', code: 'za' },
  { language: 'Zulu', code: 'zu' },
];
export const languageCodeMap = languagesList.reduce(
  (acc, { code, language }) => {
    acc[code] = language;
    return acc;
  },
  {} as Record<string, string>,
);
export default languagesList;
