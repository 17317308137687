export type BaseAPIResponse<T> = {
  success: boolean;
  data: T;
};

export type FileAPIResponse<T> = {
  status: string;
  data: T;
};

export type Simplify<T> = {
  [P in keyof T]: T[P];
};

// entities generic
export enum YesNoEnum {
  Yes = 'true',
  No = 'false',
}

export enum StatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type EnFr = {
  en: string;
  fr: string;
};
