import { OperationSite, PhoneNumber } from 'types/data-management';
import { StatusEnum } from 'types/utilities';
import { hasBooleanValueChanged, hasValueChanged, hasNumericValueChanged } from './validation.helpers';
import { Site } from 'types/entities/sites';

export const isSiteActive = (status: string): boolean => {
  return status.toLowerCase() === StatusEnum.Active.toLowerCase();
};

export const isValidEnFr = (value: Site | string): value is Site => {
  return typeof value === 'object' && value !== null;
};

export const newPhoneNumbers: PhoneNumber = {
  description: {
    en: '',
    fr: '',
  },
  type: 'phone',
  isPrivate: 'false',
  name: '',
  number: '',
};

export const newOperationSite: OperationSite = {
  0: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  1: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  2: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  3: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  4: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  5: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  6: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
};

export const tableHiddenColumnsInitialState = {
  objectId: false,
  isActive: false,

  // name
  nameEN: true,
  nameFR: false,

  //website
  websiteEn: true,
  websiteFr: false,

  // name details
  nameDetailsEnOfficial: true,
  nameDetailsEnAlternate: false,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  // transportation
  transportationEn: false,
  transportationFr: false,

  // social
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  siteReferenceNumber: true,

  khpReferenceNumber: false,
  isLocationPrivate: false,

  operations: false,
  operationsEn: false,
  operationsFr: false,

  // location
  locationAddress1: false,
  locationAddress2: false,
  locationCity: false,
  locationCounty: false,
  locationProvince: true,
  locationCountry: false,
  locationPostalCode: false,
  locationDescription: false,
  locationLongitude: false,
  locationLatitude: false,

  accessibility: false, // ID
  accessibilityEn: false,
  accessibilityFr: false,

  email: false,
  website: false,
  phoneNumbers: false,
  phoneNumbersString: false,

  contacts: false,
  contactsString: false,
  createdAt: false,
  updatedAt: false,
  notes: false, // Array
  verifications: false, // Array

  agencyNameFr: false,
  lastVerifiedOn: false,
  lastUpdatedBy: false
};

export const tableHiddenColumnsBulkImport = {
  // name
  nameEN: true,
  nameFR: true,

  isActive: true,

  // location
  locationAddress1: true,
  locationAddress2: false,
  locationCity: true,
  locationCounty: false,
  locationProvince: true,
  locationCountry: false,
  locationPostalCode: false,
  locationDescription: false,
  locationLongitude: false,
  locationLatitude: false,

  objectId: false,

  //website
  websiteEn: false,
  websiteFr: false,

  // name details
  nameDetailsEnOfficial: false,
  nameDetailsEnAlternate: false,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  // transportation
  transportationEn: false,
  transportationFr: false,

  // social
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  siteReferenceNumber: false,

  khpReferenceNumber: false,
  isLocationPrivate: false,

  operations: false,
  operationsEn: false,
  operationsFr: false,

  accessibility: false, // ID
  accessibilityEn: false,
  accessibilityFr: false,

  email: false,
  website: false,
  phoneNumbers: false,
  phoneNumbersString: false,

  contacts: false,
  contactsString: false,
  createdAt: false,
  updatedAt: false,
  notes: false, // Array
  verifications: false, // Array

  agencyNameEn: false,
  agencyNameFr: false,
};

export const getChangedProperties = (original: Site, edited: Site) => {
  // return [];
  const changedProperties = [];

  // name
  if (hasValueChanged(original.nameEN, edited.nameEN)) {
    changedProperties.push('nameEN');
  }
  if (hasValueChanged(original.nameFR, edited.nameFR)) {
    changedProperties.push('nameFR');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnOfficial,
      edited.nameDetailsEnOfficial,
    )
  ) {
    changedProperties.push('nameDetailsEnOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrOfficial,
      edited.nameDetailsFrOfficial,
    )
  ) {
    changedProperties.push('nameDetailsFrOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnAlternate,
      edited.nameDetailsEnAlternate,
    )
  ) {
    changedProperties.push('nameDetailsEnAlternate');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrAlternate,
      edited.nameDetailsFrAlternate,
    )
  ) {
    changedProperties.push('nameDetailsFrAlternate');
  }

  // location
  if (hasValueChanged(original.locationAddress1, edited.locationAddress1)) {
    changedProperties.push('locationAddress1');
  }
  if (hasValueChanged(original.locationAddress2, edited.locationAddress2)) {
    changedProperties.push('locationAddress2');
  }
  if (hasValueChanged(original.locationCountry, edited.locationCountry)) {
    changedProperties.push('locationCountry');
  }
  if (hasValueChanged(original.locationProvince, edited.locationProvince)) {
    changedProperties.push('locationProvince');
  }
  if (hasValueChanged(original.locationCounty, edited.locationCounty)) {
    changedProperties.push('locationCounty');
  }
  if (hasValueChanged(original.locationCity, edited.locationCity)) {
    changedProperties.push('locationCity');
  }
  if (hasValueChanged(original.locationDescription, edited.locationDescription)) {
    changedProperties.push('locationDescription');
  }
  if (hasNumericValueChanged(original.locationLongitude, edited.locationLongitude)) {
    changedProperties.push('locationLongitude');
  }
  if (hasNumericValueChanged(original.locationLatitude, edited.locationLatitude)) {
    changedProperties.push('locationLatitude');
  }
  if (hasBooleanValueChanged(original.isLocationPrivate, edited.isLocationPrivate)) {
    changedProperties.push('isLocationPrivate');
  }

  // transportation
  if (
    hasValueChanged(
      original.transportationEn,
      edited.transportationEn,
    )
  ) {
    changedProperties.push('transportationEn');
  }
  if (
    hasValueChanged(
      original.transportationFr,
      edited.transportationFr,
    )
  ) {
    changedProperties.push('transportationFr');
  }

    // social
    if (hasValueChanged(original.socialFacebook, edited.socialFacebook)) {
      changedProperties.push('socialFacebook');
    }
    if (hasValueChanged(original.socialInstagram, edited.socialInstagram)) {
      changedProperties.push('socialInstagram');
    }
    if (hasValueChanged(original.socialTwitter, edited.socialTwitter)) {
      changedProperties.push('socialTwitter');
    }


  return changedProperties;
};