export enum AppRoute {
  LogIn = '/',
  UserManagement = '/user-management',
  DataManagement = '/data-management',
  PendingChanges = '/pending-changes',
  VerifyResource = '/verify/resource/:id/:token',
  VerifySite = '/verify/site/:id/:token',
  VerifyAgency = '/verify/agency/:id/:token',
  SetPassword = '/set-password/:token',
  Dashboard = '/dashboard',
}
