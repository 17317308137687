import {
  DocumentsRequired,
  Operations,
  PhoneNumber,
  Reference,
  TargetPopulations,
} from 'types/data-management';
import {
  Coverage,
  Language,
  MailingAddress,
  PhysicalAddress,
  Resource,
} from 'types/entities/resources';
import { StatusEnum } from 'types/utilities';
import {
  hasBooleanValueChanged,
  hasValueChanged,
  hasNumericValueChanged,
} from './validation.helpers';

export const isResourceActive = (status: string): boolean => {
  return status.toLowerCase() === StatusEnum.Active.toLowerCase();
};

export const isValidEnFr = (value: Reference | string): value is Reference => {
  return typeof value === 'object' && value !== null;
};

export const isValidEnFrArray = (
  value: Reference[] | string[],
): value is Reference[] => {
  return typeof value === 'object' && value !== null;
};

export const newMailingAddress: MailingAddress = {
  address1: '',
  address2: '',
  isPrivate: 'false',
  city: '',
  province: '',
  country: '',
  postalCode: '',
};

export const newPhysicalAddress: PhysicalAddress = {
  address1: '',
  address2: '',
  isPrivate: 'false',
  city: '',
  county: '',
  province: '',
  country: '',
  postalCode: '',
  description: '',
  latitude: 0,
  longitude: 0,
};

export const newPhoneNumbers: PhoneNumber = {
  description: {
    en: '',
    fr: '',
  },
  type: 'phone',
  isPrivate: 'false',
  name: '',
  number: '',
};

// export const newVolunteer: Volunteer = {
//   en: {
//     opportunities: '',
//     duties: '',
//   },
//   fr: {
//     opportunities: '',
//     duties: '',
//   },
// };

export const newOperations: Operations = {
  0: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  1: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  2: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  3: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  4: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  5: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  6: {
    fr: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
    en: {
      day: '',
      hoursOfOperation: '',
      descriptionOfHours: '',
    },
  },
  siteId: '',
  siteLabel: '',
};

export const newTargetPopulations: TargetPopulations = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

export const newDocumentsRequired: DocumentsRequired = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

export const newLanguage: Language = {
  code: '',
  language: '',
};

export const newCoverage: Coverage = {
  postalCode: '',
  country: '',
  province: '',
  value: '',
  siteId: null,
  city: '',
  region: '',
};

export const tableHiddenColumnsInitialState = {
  objectId: false,
  notes: false,
  verifications: false,

  mailingAddresses: false,
  mailingAddressesString: false,
  physicalAddresses: false,
  physicalAddressesString: false,

  nameFr: false,

  recordType: false,

  descriptionEn: false,
  descriptionFr: false,

  nameDetailsEnOfficial: false,
  nameDetailsEnAlternate: false,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  applicationProcessEn: false,
  applicationProcessFr: false,

  capacityEnType: false,
  capacityEnValue: false,
  capacityFrType: false,
  capacityFrValue: false,
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  eligibilityDetailsPhraseEn: false,
  eligibilityDetailsPhraseFr: false,
  eligibilityDetailsAdult: false,
  eligibilityDetailsChild: false,
  eligibilityDetailsFamily: false,
  eligibilityDetailsGender: false,
  eligibilityDetailsTeen: false,

  metadataIsHotline: false,
  metadataIsHelpline: false,
  metadataEn: false,
  metadataFr: false,

  transportationEn: false,
  transportationFr: false,

  seniorOrgContactTitleEn: false,
  seniorOrgContactTitleFr: false,
  seniorOrgContactEmail: false,
  seniorOrgContactIsPrivate: false,
  seniorOrgContactName: false,
  seniorOrgContactPhone: false,

  khpReferenceNumber: true,
  isActive: false,

  primaryLocationCity: false,
  primaryLocationCounty: false,
  primaryLocationPostalCode: false,
  primaryLocationPhone: false,
  primaryLocationAddress1: false,
  primaryLocationAddress2: false,
  primaryLocationProvince: true,
  primaryLocationIsPrivate: false,

  coverageEn: true,
  coverageFr: false,
  targetPopulations: false,

  eligibilityMinAge: false,
  eligibilityMaxAge: false,

  phoneNumbers: false,
  phoneNumbersString: false,

  mainContactTitleEn: false,
  mainContactTitleFr: false,
  mainContactEmail: false,
  mainContactIsPrivate: false,
  mainContactName: false,
  mainContactPhone: false,

  documentsRequired: false,

  paymentMethod: false,

  operations: false,
  operationsEn: false,
  operationsFr: false,

  languages: false,

  websiteEn: true,
  websiteFr: false,

  interpretationTranslationServicesAvailable: false,

  available247: false,

  feeStructureSource: false,
  feeStructureSourceFreeTextEn: false,
  feeStructureSourceFreeTextFr: false,

  howIsServiceOffered: false,

  accessibility: false,

  // volunteer: false,
  // volunteerEn: false,
  // volunteerFr: false,

  howToAccessSupport: false,

  isHighlighted: false,
  keywords: false,

  updatedAt: false,
  createdAt: false,
  lastUpdatedBy: false,
  lastVerifiedOn: false,

  siteNamesFr: false,
  agencyNameFr: false,
  taxonomyNamesFr: false,

  targetPopulationsEn: false,
  targetPopulationsFr: false,

  documentsRequiredEn: false,
  documentsRequiredFr: false,

  feeStructureSourceEn: false,
  feeStructureSourceFr: false,

  accessibilityEn: false,
  accessibilityFr: false,

  howIsServiceOfferedEn: false,
  howIsServiceOfferedFr: false,

  howToAccessSupportEn: false,
  howToAccessSupportFr: false,
};

export const tableHiddenColumnsBulkImport = {
  // enabled
  nameEn: true,
  nameFr: true,

  primaryLocationCity: true,
  primaryLocationCounty: false,
  primaryLocationPostalCode: false,
  primaryLocationPhone: false,
  primaryLocationAddress1: true,
  primaryLocationAddress2: false,
  primaryLocationProvince: true,
  primaryLocationIsPrivate: false,

  isActive: true,

  recordType: false,

  // disabled
  objectId: false,
  notes: false,
  verifications: false,

  mailingAddresses: false,
  mailingAddressesString: false,
  physicalAddresses: false,
  physicalAddressesString: false,

  descriptionEn: false,
  descriptionFr: false,

  nameDetailsEnOfficial: false,
  nameDetailsEnAlternate: false,
  nameDetailsFrOfficial: false,
  nameDetailsFrAlternate: false,

  applicationProcessEn: false,
  applicationProcessFr: false,

  capacityEnType: false,
  capacityEnValue: false,
  capacityFrType: false,
  capacityFrValue: false,
  socialFacebook: false,
  socialInstagram: false,
  socialTwitter: false,

  eligibilityDetailsPhraseEn: false,
  eligibilityDetailsPhraseFr: false,
  eligibilityDetailsAdult: false,
  eligibilityDetailsChild: false,
  eligibilityDetailsFamily: false,
  eligibilityDetailsGender: false,
  eligibilityDetailsTeen: false,

  metadataIsHotline: false,
  metadataIsHelpline: false,
  metadataEn: false,
  metadataFr: false,

  transportationEn: false,
  transportationFr: false,

  seniorOrgContactTitleEn: false,
  seniorOrgContactTitleFr: false,
  seniorOrgContactEmail: false,
  seniorOrgContactIsPrivate: false,
  seniorOrgContactName: false,
  seniorOrgContactPhone: false,

  khpReferenceNumber: false,

  coverageEn: false,
  coverageFr: false,
  targetPopulations: false,

  eligibilityMinAge: false,
  eligibilityMaxAge: false,

  phoneNumbers: false,
  phoneNumbersString: false,

  mainContactTitleEn: false,
  mainContactTitleFr: false,
  mainContactEmail: false,
  mainContactIsPrivate: false,
  mainContactName: false,
  mainContactPhone: false,

  documentsRequired: false,

  paymentMethod: false,

  operations: false,
  operationsEn: false,
  operationsFr: false,

  languages: false,

  websiteEn: false,
  websiteFr: false,

  interpretationTranslationServicesAvailable: false,

  available247: false,

  feeStructureSource: false,
  feeStructureSourceFreeTextEn: false,
  feeStructureSourceFreeTextFr: false,

  howIsServiceOffered: false,

  accessibility: false,

  // volunteer: false,
  // volunteerEn: false,
  // volunteerFr: false,

  howToAccessSupport: false,

  isHighlighted: false,
  keywords: false,

  updatedAt: false,
  createdAt: false,
  lastUpdatedBy: false,
  lastVerifiedOn: false,

  siteNamesEn: false,
  siteNamesFr: false,
  agencyNameEn: false,
  agencyNameFr: false,
  taxonomyNamesEn: false,
  taxonomyNamesFr: false,

  targetPopulationsEn: false,
  targetPopulationsFr: false,

  documentsRequiredEn: false,
  documentsRequiredFr: false,

  feeStructureSourceEn: false,
  feeStructureSourceFr: false,

  accessibilityEn: false,
  accessibilityFr: false,

  howIsServiceOfferedEn: false,
  howIsServiceOfferedFr: false,

  howToAccessSupportEn: false,
  howToAccessSupportFr: false,
};

export const getChangedProperties = (original: Resource, edited: Resource) => {
  const changedProperties = [];

  // name
  if (hasValueChanged(original.nameEn, edited.nameEn)) {
    changedProperties.push('nameEn');
  }
  if (hasValueChanged(original.nameFr, edited.nameFr)) {
    changedProperties.push('nameFr');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnOfficial,
      edited.nameDetailsEnOfficial,
    )
  ) {
    changedProperties.push('nameDetailsEnOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrOfficial,
      edited.nameDetailsFrOfficial,
    )
  ) {
    changedProperties.push('nameDetailsFrOfficial');
  }
  if (
    hasValueChanged(
      original.nameDetailsEnAlternate,
      edited.nameDetailsEnAlternate,
    )
  ) {
    changedProperties.push('nameDetailsEnAlternate');
  }
  if (
    hasValueChanged(
      original.nameDetailsFrAlternate,
      edited.nameDetailsFrAlternate,
    )
  ) {
    changedProperties.push('nameDetailsFrAlternate');
  }

  // desc
  if (hasValueChanged(original.descriptionEn, edited.descriptionEn)) {
    changedProperties.push('descriptionEn');
  }
  if (hasValueChanged(original.descriptionFr, edited.descriptionFr)) {
    changedProperties.push('descriptionFr');
  }

  // eligibility
  if (
    hasNumericValueChanged(original.eligibilityMinAge, edited.eligibilityMinAge)
  ) {
    changedProperties.push('eligibilityMinAge');
  }
  if (
    hasNumericValueChanged(original.eligibilityMaxAge, edited.eligibilityMaxAge)
  ) {
    changedProperties.push('eligibilityMaxAge');
  }

  if (
    hasValueChanged(
      original.eligibilityDetailsPhraseEn,
      edited.eligibilityDetailsPhraseEn,
    )
  ) {
    changedProperties.push('eligibilityDetailsPhraseEn');
  }
  if (
    hasValueChanged(
      original.eligibilityDetailsPhraseFr,
      edited.eligibilityDetailsPhraseFr,
    )
  ) {
    changedProperties.push('eligibilityDetailsPhraseFr');
  }
  if (
    hasBooleanValueChanged(
      original.eligibilityDetailsAdult,
      edited.eligibilityDetailsAdult,
    )
  ) {
    changedProperties.push('eligibilityDetailsAdult');
  }
  if (
    hasBooleanValueChanged(
      original.eligibilityDetailsChild,
      edited.eligibilityDetailsChild,
    )
  ) {
    changedProperties.push('eligibilityDetailsChild');
  }
  if (
    hasBooleanValueChanged(
      original.eligibilityDetailsFamily,
      edited.eligibilityDetailsFamily,
    )
  ) {
    changedProperties.push('eligibilityDetailsFamily');
  }
  if (
    hasValueChanged(
      original.eligibilityDetailsGender,
      edited.eligibilityDetailsGender,
    )
  ) {
    changedProperties.push('eligibilityDetailsGender');
  }
  if (
    hasBooleanValueChanged(
      original.eligibilityDetailsTeen,
      edited.eligibilityDetailsTeen,
    )
  ) {
    changedProperties.push('eligibilityDetailsTeen');
  }

  // application process
  if (
    hasValueChanged(original.applicationProcessEn, edited.applicationProcessEn)
  ) {
    changedProperties.push('applicationProcessEn');
  }
  if (
    hasValueChanged(original.applicationProcessFr, edited.applicationProcessFr)
  ) {
    changedProperties.push('applicationProcessFr');
  }

  // main contact
  if (hasValueChanged(original.mainContactTitleEn, edited.mainContactTitleEn)) {
    changedProperties.push('mainContactTitleEn');
  }
  if (hasValueChanged(original.mainContactTitleFr, edited.mainContactTitleFr)) {
    changedProperties.push('mainContactTitleFr');
  }
  if (hasValueChanged(original.mainContactEmail, edited.mainContactEmail)) {
    changedProperties.push('mainContactEmail');
  }
  if (hasValueChanged(original.mainContactPhone, edited.mainContactPhone)) {
    changedProperties.push('mainContactPhone');
  }
  if (
    hasBooleanValueChanged(
      original.mainContactIsPrivate,
      edited.mainContactIsPrivate,
    )
  ) {
    changedProperties.push('mainContactIsPrivate');
  }
  if (hasValueChanged(original.mainContactName, edited.mainContactName)) {
    changedProperties.push('mainContactName');
  }

  // social
  if (hasValueChanged(original.websiteEn, edited.websiteEn)) {
    changedProperties.push('websiteEn');
  }
  if (hasValueChanged(original.websiteFr, edited.websiteFr)) {
    changedProperties.push('websiteFr');
  }
  if (hasValueChanged(original.socialFacebook, edited.socialFacebook)) {
    changedProperties.push('socialFacebook');
  }
  if (hasValueChanged(original.socialInstagram, edited.socialInstagram)) {
    changedProperties.push('socialInstagram');
  }
  if (hasValueChanged(original.socialTwitter, edited.socialTwitter)) {
    changedProperties.push('socialTwitter');
  }

  if (
    hasValueChanged(
      original.phoneNumbers.toString(),
      edited.phoneNumbers.toString(),
    )
  ) {
    changedProperties.push('phoneNumbers');
  }

  return changedProperties;
};
