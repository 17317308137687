import { toast } from 'components/Toast/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Resource, ResourceDto } from 'types/entities/resources';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchResources(
  pageIndex: number,
  pageSize: number,
): Promise<ResourceDto> {
  try {
    const response = await http.get<BaseAPIResponse<ResourceDto>>(
      `/resource?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error List Resources');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error List Resources: ${err}`);
  }
}
type SearchResource = Omit<ResourceDto, 'activeCount' | 'inactiveCount'>;

export async function searchResources(
  type: string,
  value: string,
  pageIndex: number,
  pageSize: number,
): Promise<SearchResource> {
  try {
    const response = await http.get<BaseAPIResponse<SearchResource>>(
      `/resource/search/${type}/${value}?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Searching Resources');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Searching Resources: ${err}`);
  }
}

export async function getResourceById(resourceId: string): Promise<Resource> {
  try {
    const response = await http.get<BaseAPIResponse<Resource>>(
      `/resource/${resourceId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Resource');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get Resources: ${err}`);
  }
}

export async function getResourceAnalytics(
  startDate: string,
  endDate: string,
  type: string,
  year: string,
  category: string,
  status?: string,
  verificationStatus?: string,
  language?: string,
  province?: string,
  city?: string,
  taxonomies?: any,
): Promise<any> {
  try {
    const lastYear = new Date(new Date().setFullYear(Number(year) - 1));

    let query = '';
    if (status) {
      query += `status=${status}`;
    }
    if (verificationStatus) {
      query += `&verificationStatus=${verificationStatus}`;
    }
    if (language) {
      query += `&language=${language}`;
    }
    if (province) {
      query += `&province=${province}`;
    }
    if (city) {
      query += `&city=${city}`;
    }
    if (taxonomies && taxonomies !== '') {
      query += `&taxonomyIds=${taxonomies}`;
    }

    const response = await http.get<BaseAPIResponse<Resource>>(
      `/resource/analytics/${
        startDate !== '' ? startDate : lastYear.toISOString()
      }/${
        endDate !== '' ? endDate : new Date().toISOString()
      }/${type}/${category}?${query}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Get Resource analytics');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Get Resource analytics: ${err}`);
  }
}

export async function createResource(resource: Resource): Promise<Resource> {
  try {
    const response = await http.post<BaseAPIResponse<Resource>>(
      `/resource`,
      resource,
    );

    if (response.success) {
      toast.success('The Resource was created successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error create Resources');
    }
  } catch (err: unknown) {
    toast.error('Error creating resource. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error create Resources: ${err}`);
  }
}

export async function updateStatusResource(
  resourceId: string,
  isActive: boolean,
): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/resource/status/${resourceId}`,
      {
        isActive,
      },
    );

    if (response.success) {
      toast.success('The Resource was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error update Resources');
    }
  } catch (err: unknown) {
    toast.error('Error updating resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error updating Resources: ${err}`);
  }
}

export async function updateResource(
  resourceId: string,
  resource: Resource,
): Promise<Resource> {
  try {
    const response = await http.patch<BaseAPIResponse<Resource>>(
      `/resource/${resourceId}`,
      resource,
    );

    if (response.success) {
      toast.success('The Resource was updated', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error updating Resources');
    }
  } catch (err: unknown) {
    toast.error('Error updating resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error update Resources: ${err}`);
  }
}

export async function deleteResource(resourceId: string): Promise<Resource> {
  try {
    const response = await http.delete<BaseAPIResponse<Resource>>(
      `/resource/${resourceId}`,
    );

    if (response.success) {
      toast.success('The Resource was deleted', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error delete Resources');
    }
  } catch (err: unknown) {
    toast.error('Error deleting resource. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error delete Resources: ${err}`);
  }
}
