import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { TaxonomyForm } from 'components/Shared/SharedForms/TaxonomyForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { toast } from 'components/Toast/Toast';
import { palette } from 'design/theme/palette';
import { validateRequired } from 'helpers/validation.helpers';
import { useTaxonomies } from 'hooks/useTaxonomies';
import { MRT_Row } from 'material-react-table';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { DataManagement } from 'types/data-management';
import { Taxonomy } from 'types/entities/taxonomies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const EditModal: FC<{
  onClose: () => void;
  onSubmit: (indes: number, values: DataManagement) => void;
  open: boolean;
  row: MRT_Row<Taxonomy>;
}> = ({ row, open, onClose, onSubmit }) => {
  const { columns: taxonomyColumns } = useTaxonomies();
  const [taxonomyFormValues, setTaxonomyFormValues] = useState<Taxonomy>(
    row.original,
  );
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<string[][]>([]);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};

    if (!validateRequired(taxonomyFormValues.code)) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['code']: 'This field is required',
      };
    }

    if (!(taxonomyFormValues.level > 0)) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['level']: 'This field is required',
      };
    }

    if (
      !validateRequired(taxonomyFormValues.nameEN) &&
      !validateRequired(taxonomyFormValues.nameFR)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameEN']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameFR']: 'This field is required',
      };
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        intl.translate({
          id: 'Please fill all required fields',
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    onSubmit(row.index, {
      Taxonomy: taxonomyFormValues,
    });

    toast.info(
      'Your changes have been staged. Select “confirm changes” button to finalize updates.',
      {
        position: 'top-center',
      },
    );

    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Edit Taxonomy',
        })}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '60vh',
              '&  #tabpanel-0': {
                marginLeft: '100px',
              },
              '&  #tabpanel-1': {
                marginLeft: '100px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab
                label={intl.translate({
                  id: 'Taxonomy',
                })}
                {...a11yProps(0)}
              />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <TaxonomyForm
                showLabel={false}
                selectedTaxonomies={selectedTaxonomies}
                setSelectedTaxonomies={setSelectedTaxonomies}
                taxonomy={taxonomyFormValues}
                setTaxonomy={setTaxonomyFormValues}
                columns={taxonomyColumns}
                validationErrors={validationErrors}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save Changes',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
