/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { CSVDownload } from 'react-csv';

import { getBulkExport } from 'api/bulk/bulk.api';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { Label } from 'design/Text/Label';
import { Paragraph } from 'design/Text/Paragraph';
import {
  DataManagementEnum,
  DataManagementEnumsAsObject,
} from 'global-constants/data-management';
import { DateFormat, formatDate } from 'helpers/date.helpers';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledStatusUpperCaseStrong = styled.strong`
  text-transform: uppercase;
`;

export const StyledLabel = styled(Label)`
  font-weight: 500;
  font-size: 1rem;
`;

const StyledButtonContainerDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.green.dark};
`;

type DownloadProps = {
  dataType: DataManagementEnum;
};

export const Download: FC<DownloadProps> = ({ dataType }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [csvData, setCsvData] = useState<any>();
  const [dateType, setDateType] = useState<string>('0');
  const [isActive, setIsActive] = useState<boolean>(true);
  const [downloadAllData, setDownloadAllData] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const validateForm = () => {
    let hasError = false;
    const errors = {
      startDate: '',
      endDate: '',
      dateType: '',
    };

    if (!startDate) {
      errors.startDate = intl.translate({
        id: 'Start date is required',
      });

      hasError = true;
    }

    if (!endDate) {
      errors.endDate = intl.translate({
        id: 'End date is required',
      });

      hasError = true;
    }

    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      errors.startDate = intl.translate({
        id: 'Start date must be before end date',
      });
      errors.endDate = intl.translate({
        id: 'End date must be after start date',
      });

      hasError = true;
    }

    if (dateType == '0') {
      errors.dateType = intl.translate({
        id: 'Please select a date type',
      });

      hasError = true;
    }

    if (hasError) {
      setValidationErrors(errors);
    }

    return hasError;
  };

  const handleClear = () => {
    setDateType('0');
    setStartDate('');
    setEndDate('');
  };

  const handleExport = async () => {
    if (!downloadAllData && (isLoading || validateForm())) {
      return;
    }

    setValidationErrors({});

    try {
      setIsLoading(true);
      const result = await getBulkExport(
        dataType,
        startDate,
        endDate,
        dateType,
        isActive,
        downloadAllData,
      );

      // exportData(dateType, result);
      // saveData(result, 'test');
      setCsvData(result);
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const exportData = (action: string, data: string) => {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   console.log('blob: ', blob);
  //   const url = URL.createObjectURL(blob);
  //   console.log('url: ', url);
  //   const link = document.createElement('a');
  //   console.log('document: ', document);
  //   const current = document.getElementById("root");
  //   link.download = generateFileName();
  //   link.href = url;
  //   link.onclick = () => {
  //     console.log('link clicked');
  //   }
  //   document.body.insertBefore(link, current)

  //   console.log('link: ', link);

  //   link.click();
  //   URL.revokeObjectURL(url);

  //   function generateFileName(): string {
  //     // format: {entity}-Export-{action}-{date}
  //     return `${DataManagementEnumsAsObject[dataType]
  //       }-Export-${action}-${formatDate(
  //         new Date(),
  //         DateFormat.combinationOfLocalizadDateAndTime,
  //       )}`;
  //   }
  // };

  // const saveData = (function () {
  //   const a = document.createElement("a");
  //   document.body.appendChild(a);

  //   const arrayToCSV = (arr: any[], delimiter = ',') =>
  //     arr
  //       .map((v: any) => {
  //         // console.log('v: ', v.split(','));

  //         return v;
  //         // return v.split(',').map((x: string) => (`"${x.replace(/"/g, '""')}"`)).join(delimiter)
  //       }
  //       )
  //       .join('\n');

  //   return async function (data: any, fileName: string) {
  //     // console.log('data: ', data);
  //     const rows = String(data.split(/[\r\n]/));
  //     console.log('rows: ', data.split('\n'));
  //     // console.log('rows: ', rows);

  //     const csvArray = arrayToCSV(data.split('\n'))
  //     console.log('csvArray: ', csvArray.split('\n'));

  //     const blob = new Blob(csvArray.split('\n'), { type: "text/plain;charset=utf-8" });
  //     const url = window.URL.createObjectURL(blob);
  //     // console.log('blob: ', blob);

  //     a.href = url;
  //     a.target = "_blank";
  //     a.download = fileName;
  //     a.onclick = (e) => {
  //       console.log('e: ', e);
  //       e.preventDefault();
  //       console.log('link clicked');
  //       window.open(url);
  //     }
  //     console.log('a: ', a);
  //     const stream = await blob.text();
  //     console.log('stream: ', stream);
  //     a.click();

  //     // console.log('read: ', read);
  //     // window.URL.revokeObjectURL(url);
  //   };
  // }());

  return (
    <>
      <StyledParagraph>
        {intl.translate(
          {
            id: 'Select valid date range to export all corresponding <strong>{data}</strong> records.',
          },
          {
            data: DataManagementEnumsAsObject[dataType],
            strong: (label) => (
              <StyledStatusUpperCaseStrong>{label}</StyledStatusUpperCaseStrong>
            ),
          },
        )}
      </StyledParagraph>

      <Box
        component="form"
        my={2}
        sx={{
          maxWidth: 400,
          '& .MuiInputBase-root': { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <StyledLabel>
              {intl.translate({
                id: 'All Data',
              })}
            </StyledLabel>
          </Grid>
          <Grid item xs={9}>
            <Checkbox
              checked={downloadAllData}
              onChange={(event) => {
                setDownloadAllData(event.target.checked);
              }}
            />
          </Grid>

          {!downloadAllData && (
            <>
              <Grid item xs={3}>
                <StyledLabel>
                  {intl.translate({
                    id: 'Start Date:',
                  })}
                </StyledLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  error={!!validationErrors.startDate}
                  helperText={validationErrors.startDate}
                  name="startDate"
                  type="date"
                  onChange={(e) => setStartDate(e.target?.value)}
                  value={startDate}
                  variant="standard"
                  fullWidth
                  hiddenLabel
                />
              </Grid>
              <Grid item xs={3}>
                <StyledLabel>
                  {intl.translate({
                    id: 'End Date:',
                  })}
                </StyledLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  error={!!validationErrors.endDate}
                  helperText={validationErrors.endDate}
                  name="endDate"
                  type="date"
                  onChange={(e) => setEndDate(e.target?.value)}
                  value={endDate}
                  variant="standard"
                  fullWidth
                  hiddenLabel
                />
              </Grid>
              <Grid item xs={3}>
                <StyledLabel>
                  {intl.translate({
                    id: 'Date Type:',
                  })}
                </StyledLabel>
              </Grid>
              <Grid item xs={9}>
                <Select
                  id="demo-simple-select"
                  value={dateType}
                  onChange={(event) => setDateType(event.target.value)}
                  fullWidth
                  error={!!validationErrors.dateType}
                >
                  <MenuItem value={'0'}>
                    {intl.translate({
                      id: 'Select',
                    })}
                  </MenuItem>
                  <MenuItem value={'created'}>
                    {intl.translate({
                      id: 'Created At',
                    })}
                  </MenuItem>
                  <MenuItem value={'updated'}>
                    {intl.translate({
                      id: 'Updated At',
                    })}
                  </MenuItem>
                </Select>
                {validationErrors && (
                  <FormHelperText>{validationErrors.dateType}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={3}>
                <StyledLabel>
                  {intl.translate({
                    id: 'Is Active:',
                  })}
                </StyledLabel>
              </Grid>
              <Grid item xs={9}>
                <Select
                  id="demo-simple-select"
                  value={isActive}
                  onChange={(event) =>
                    setIsActive(event.target.value === 'true' ? true : false)
                  }
                  fullWidth
                >
                  <MenuItem value={`true`}>
                    {intl.translate({
                      id: 'True',
                    })}
                  </MenuItem>
                  <MenuItem value={'false'}>
                    {intl.translate({
                      id: 'False',
                    })}
                  </MenuItem>
                </Select>
                {validationErrors && (
                  <FormHelperText>{validationErrors.dateType}</FormHelperText>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <StyledButtonContainerDiv>
          <CancelButton handleClick={handleClear}>
            {intl.translate({
              id: 'Clear',
            })}
          </CancelButton>
          <ConfirmButton handleClick={handleExport}>
            {!isLoading ? (
              <strong>
                {intl.translate({
                  id: 'Export',
                })}
              </strong>
            ) : (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </ConfirmButton>
          {csvData && <CSVDownload data={csvData} target="_blank" />}
        </StyledButtonContainerDiv>
      </Box>
    </>
  );
};
