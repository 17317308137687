import { Box } from '@mui/material';
import { Router } from 'Router';
import { SideNavBar } from 'components/AppBar/SideNavBar';
import { useContext } from 'react';
import styled from 'styled-components';
import { IntlContext } from 'utilities/i18n/intl.context';

const drawerWidth = 300;

const StyledMainBox = styled(Box)`
  padding: 1rem 2rem 2rem 2rem;
  min-height: 100vh;
  width: calc(100% - 300px);

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

function App() {
  // necessary to force a render when locale is updated
  useContext(IntlContext);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <SideNavBar drawerWidth={drawerWidth} />
      </Box>
      <StyledMainBox>
        <main>
          <Router />
        </main>
      </StyledMainBox>
    </Box>
  );
}

export default App;
