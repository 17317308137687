export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const yearsArray = (years_back = 15) => {
  const presentYear = new Date().getFullYear();
  const yearsArray = [];
  for (let i = 0; i < years_back; i++) {
    yearsArray.push(`${presentYear - i}`);
  }
  return yearsArray;
};
