export const provincesToAbbreviationsMap = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT',
};

export const AbbreviationsToProvinceMap = Object.fromEntries(
  Object.entries(provincesToAbbreviationsMap).map(([key, value]) => [
    value,
    key,
  ]),
);

export const provinces = Object.keys(provincesToAbbreviationsMap);
export const provinceAbbreviations = Object.values(provincesToAbbreviationsMap);

export default provinces;
