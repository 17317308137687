import { ExitToApp, Person } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import { editUser } from 'api/user/user.api';
import { EditUserModal } from 'components/User/UserManagementTable/EditUserModal';
import { SettingsIcon } from 'design/Icon/SettingsIcon';
import { Paragraph } from 'design/Text/Paragraph';
import { palette } from 'design/theme/palette';
import { AppRoute } from 'enums/app-route.enum';
import { removeAuthTokenAction, removeLoggedUser } from 'global-state/actions';
import { selectAuthToken, selectUser } from 'global-state/selectors';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserEditApi } from 'types/user-edit-api.dto';
import { User } from 'types/user.dto';
import { intl } from 'utilities/i18n/intl.utility';
import { navItems } from './nav-items';
import logo from '../../assets/khp-logo.svg';

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

type Props = {
  drawerWidth: number;
};

// Set new tabs here
const mapMenuTabs: { [key: string]: number } = {
  [AppRoute.Dashboard]: 0,
  [AppRoute.DataManagement]: 1,
  [AppRoute.UserManagement]: 2,
};

export const SideNavBar: FC<Props> = ({ drawerWidth }) => {
  const navigate = useNavigate();
  const [activeMenuTab, setActiveMenuTab] = useState(
    mapMenuTabs[window.location.pathname]
      ? mapMenuTabs[window.location.pathname]
      : 0,
  );

  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const loggedUser = useSelector(selectUser);
  const [preferencesMenu, setPreferencesMenu] = useState(null);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);

  const handleTabChange = (
    event: React.SyntheticEvent,
    selectedTab: number,
  ) => {
    event.preventDefault();
    setActiveMenuTab(selectedTab);
  };

  const handleLogout = () => {
    setPreferencesMenu(null);
    dispatch(removeAuthTokenAction());
    dispatch(removeLoggedUser());
  };

  const openPreferencesMenu = (e: any) => {
    setPreferencesMenu(e.currentTarget);
  };

  const closePreferencesMenu = () => {
    setPreferencesMenu(null);
  };

  const handleEditMyProfile = () => {
    if (loggedUser) {
      setUserToEdit(loggedUser);
      setEditUserModalOpen(true);
    }
  };

  const handleEditUser = async (userData: UserEditApi) => {
    try {
      if (userToEdit) {
        await editUser(userToEdit.id, userData);
        toast.success('The user has been updated', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  return (
    <Drawer
      sx={{
        backgroundColor: palette.grey.dark,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: palette.grey.dark,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: palette.white.main,
          left: 10,
          width: '4px',
        },
        '& .Mui-selected': {
          color: `${palette.white.main} !important`,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      {authToken && (
        <>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "0 0 10px 0" }}>
          <img src={logo} style={{ width: "50%" }} />
        </div>
          <Divider light />
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeMenuTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            {navItems.map((item, index) => (
              <Tab
                key={index}
                label={item.label}
                {...a11yProps(index)}
                style={{
                  fontSize: '14px',
                  fontWeight: activeMenuTab === index ? 800 : 500,
                  color: palette.white.main,
                }}
                onClick={() => navigate(item.to)}
              />
            ))}
          </Tabs>

          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={false}
            sx={{
              marginTop: '95vh',
              position: 'fixed',
              width: '300px',
            }}
          >
            <Paragraph
              style={{
                fontSize: '14px',
                color: palette.white.main,
                textAlign: 'center',
                margin: 'auto',
              }}
            >
              {loggedUser?.fullName}
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                style={{
                  color: palette.white.main,
                }}
                onClick={(e) => openPreferencesMenu(e)}
                aria-label="open profile settings"
              >
                <SettingsIcon />
              </IconButton>
            </Paragraph>
          </Tabs>

          <Menu
            sx={{ ml: 4 }}
            anchorEl={preferencesMenu}
            keepMounted
            open={Boolean(preferencesMenu)}
            onClose={closePreferencesMenu}
          >
            <MenuItem onClick={() => handleEditMyProfile()}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              My Profile
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ color: '#d32f2f' }}>
              <ListItemIcon>
                <ExitToApp color="error" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          {userToEdit && (
            <EditUserModal
              open={editUserModalOpen}
              userToEdit={userToEdit}
              onClose={() => setEditUserModalOpen(false)}
              onSubmit={handleEditUser}
            />
          )}
        </>
      )}

      {!authToken && 
        <div style={{ 
          width: "100%", 
          display: "flex", 
          justifyContent: "center", 
          padding: "70px 0 0 0", 
          position: "absolute",
          bottom: "30px" }}>
          <img src={logo} style={{ width: "50%" }} />
        </div>
      }
    </Drawer>
  );
};

export default SideNavBar;
