import { Button } from 'design';
import { palette } from 'design/theme/palette';
import { FC } from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: ${palette.white.main} !important;
  color: ${palette.black.main} !important;
`;

type ButtonProps = {
  handleClick: () => void;
  children: React.ReactNode;
};

export const ConfirmButton: FC<ButtonProps> = ({ handleClick, children }) => {
  return <StyledButton onClick={handleClick}>{children}</StyledButton>;
};
