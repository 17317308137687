/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, MenuItem, TextField } from '@mui/material';
import { fetchAccessibility } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { FC, useEffect, useState } from 'react';
import { Accessibility } from 'types/data-management';
import { intl } from 'utilities/i18n/intl.utility';

const emptyAccessibility: Accessibility = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

const AccessibilityForm: FC<{
  value: string;
  setValue: (values: string) => void;
  currentValue: any;
}> = ({ value, setValue, currentValue }) => {
  const [accessibilities, setAccessibilities] = useState([
    { ...emptyAccessibility },
  ]);
  const [selectedAccessibility, setSelectedAccessibility] =
    useState(currentValue);

  useEffect(() => {
    const getAccessibilities = async () => {
      try {
        const accessibilitiesResponse = await fetchAccessibility();
        setAccessibilities(accessibilitiesResponse);
      } catch (err) {
        toast.error('Error getting accesibility options. Please, try again.', {
          position: 'top-center',
        });
        setAccessibilities([{ ...emptyAccessibility }]);
      }
    };

    getAccessibilities();
  }, []);

  useEffect(() => {
    setSelectedAccessibility(
      accessibilities.find((item) => item.objectId === value) || {
        ...emptyAccessibility,
      },
    );
  }, [value, accessibilities]);

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'Accessibility',
          })}
          select
          onChange={(e) => setValue(e.target?.value)}
          value={value || ''}
          variant="standard"
          fullWidth={true}
        >
          {accessibilities.map((item) => (
            <MenuItem
              key={
                item?.objectId ||
                item?._id ||
                `${String(new Date())}-${Math.random()}`
              }
              value={item?.objectId || item?._id || ''}
            >
              {`En: ${item?.en}[Fr: ${item?.fr}]`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '50ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'En',
          })}
          value={selectedAccessibility?.en || ''}
          disabled
          variant="standard"
        />
        <TextField
          label={intl.translate({
            id: 'Fr',
          })}
          value={selectedAccessibility?.fr || ''}
          disabled
          variant="standard"
        />
      </Box>
    </>
  );
};

export default AccessibilityForm;
