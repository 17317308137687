import { toast } from 'components/Toast/Toast';
import { EntityStatusEnum } from 'types/data-management';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Agency } from 'types/entities/agencies';
import { BaseAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function fetchStagedAgencies(
  pageIndex: number,
  pageSize: number,
): Promise<Agency[]> {
  try {
    const response = await http.get<BaseAPIResponse<Agency[]>>(
      `/staged-agency`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error: Fetch Staged Agencies');
    }
  } catch (err: unknown) {
    toast.error('Error: Fetching Staged Agencies', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error: Fetch Staged Agencies: ${err}`);
  }
}

export async function getAgencyById(
  agencyId: string,
  token: string,
  isRefresh: boolean,
): Promise<Agency> {
  try {
    const response = await http.get<BaseAPIResponse<Agency>>(
      `/staged-agency/${agencyId}/${token}/${isRefresh}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error fetching staged agency by ID');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error fetching staged agency by ID: ${err}`);
  }
}

export async function getOriginalAgencyById(
  agencyId: string,
  token: string,
): Promise<Agency> {
  try {
    const response = await http.get<BaseAPIResponse<Agency>>(
      `/staged-agency/${agencyId}/${token}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error fetching original agency by ID');
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error fetching original agency by ID: ${err}`);
  }
}

export async function createAgency(
  name: string,
  email: string,
  objectId: string,
  language: string,
): Promise<Agency> {
  try {
    const response = await http.post<BaseAPIResponse<Agency>>(
      `/staged-agency`,
      {
        name: name,
        email: email,
        type: 'agency',
        objectId: objectId,
        language,
      },
    );

    if (response.success) {
      toast.success(
        'Record verification has been requested. Staged agency record created.',
        {
          position: 'top-center',
        },
      );
      return response.data;
    } else {
      throw new Error('Error creating the staged agency record.');
    }
  } catch (err: unknown) {
    toast.error('Error creating the staged agency record. Please, try again.', {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error creating the staged agency record: ${err}`);
  }
}

export async function updateAgency(
  agencyId: string,
  agency: Agency,
): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/staged-agency/${agencyId}`,
      agency,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error updating staged agency record');
    }
  } catch (err: unknown) {
    toast.error('Error updating staged agency record. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error updating staged agency record: ${err}`);
  }
}

export async function updateStatusAgencyToPendingApproval(
  agencyId: string,
  token: string,
): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/staged-agency/status/${agencyId}/${token}`,
      {
        status: EntityStatusEnum.PendingApproval,
      },
    );

    if (response.success) {
      toast.success('Record was sent for review.', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error updating staged agency record');
    }
  } catch (err: unknown) {
    toast.error('Error updating staged agency record. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error updating the agencies: ${err}`);
  }
}

export async function approveAgencyByVerifier(
  agencyId: string,
  token: string,
): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/staged-agency/status/${agencyId}/${token}/verify`,
    );

    if (response.success) {
      toast.success('Agency updates approved successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error approving the agency record');
    }
  } catch (err: unknown) {
    toast.error('Error approving the agency record. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error approving the agency record: ${err}`);
  }
}

export async function approveAgency(agencyId: string): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/staged-agency/status/${agencyId}/approve`,
    );

    if (response.success) {
      toast.success('The Agency was approved successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error approving the agency');
    }
  } catch (err: unknown) {
    toast.error('Error approving the agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error approving the agency: ${err}`);
  }
}

export async function rejectAgency(agencyId: string): Promise<Agency> {
  try {
    const response = await http.patch<BaseAPIResponse<Agency>>(
      `/staged-agency/status/${agencyId}/reject`,
    );

    if (response.success) {
      toast.success('The Agency was rejected successfully', {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error('Error rejecting the agency');
    }
  } catch (err: unknown) {
    toast.error('Error rejecting the agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error rejecting the agency: ${err}`);
  }
}

export async function deleteAgency(agencyId: string): Promise<Agency> {
  try {
    const response = await http.delete<BaseAPIResponse<Agency>>(
      `/staged-agency/${agencyId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error deleting agency');
    }
  } catch (err: unknown) {
    toast.error('Error deleting agency. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error deleting agency: ${err}`);
  }
}
