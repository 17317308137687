/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import { getSiteById } from 'api/stagedSites/stagedSites.api';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Site } from 'types/entities/sites';
import { SitesForm } from './SitesForm';
import { Text } from 'design';

export const VerifySiteEntityView: FC = () => {
  const { id, token } = useParams();
  const [site, setSite] = useState<Site | null>(null);
  const [isSubmitted, setSubmissionStatus] = useState(false);

  useEffect(() => {
    const getSiteForVerification = async () => {
      if (!id || !token) {
        return;
      }

      try {
        const site = await getSiteById(id, token, false);
        if (site) {
          setSite(site);
        }
      } catch (err) {
        setSubmissionStatus(true);
      }
      
    };

    if (site === null) {
      getSiteForVerification();
    }
  });

  const handleSubmit = async () => {
    if (!id) {
      return;
    }
    setSubmissionStatus(true);
  };

  return (
    <>
      {isSubmitted ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh',
          }}
        >
          <Text.h1>Access has expired. Thank you for your verification!</Text.h1>
        </div>
      ) : site ? (
        <SitesForm onSubmit={handleSubmit} site={site} token={token || ''} />
      ) : (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </>
  );
};
