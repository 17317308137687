import { Box, MenuItem, TextField, Theme, useTheme } from '@mui/material';
import { fetchDocumentsRequired } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { newDocumentsRequired } from 'helpers/resources.helpers';
import { FC, useEffect, useState } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const getStyles = (
  selectedReference: string,
  references: string[],
  theme: Theme,
) => {
  return {
    fontWeight:
      references.indexOf(selectedReference) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
    color:
      references.indexOf(selectedReference) === -1
        ? theme.palette.common.black
        : theme.palette.info.light,
  };
};

const DocumentsRequiredForm: FC<{
  value: string[];
  setValue: (values: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValues: any[];
}> = ({ value, setValue, currentValues }) => {
  const theme = useTheme();
  const [documentsRequired, setDocumentsRequired] = useState([
    { ...newDocumentsRequired },
  ]);
  const [selectedDocumentsRequired, setSelectedDocumentsRequired] =
    useState(currentValues);

  useEffect(() => {
    const getDocumentsRequired = async () => {
      try {
        const documentsRequiredResponse = await fetchDocumentsRequired();
        setDocumentsRequired(documentsRequiredResponse);
      } catch (err) {
        toast.error('Error getting documents required. Please, try again.', {
          position: 'top-center',
        });
        setSelectedDocumentsRequired([{ ...newDocumentsRequired }]);
      }
    };

    getDocumentsRequired();
  }, []);

  useEffect(() => {
    setSelectedDocumentsRequired(
      documentsRequired.filter((item) => value.includes(item?.objectId)) || {
        ...newDocumentsRequired,
      },
    );
  }, [value, documentsRequired]);

  const handleChangeDropDownSites = (value: string) => {
    setValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'Documents Required',
          })}
          select
          SelectProps={{
            multiple: true,
          }}
          onChange={(e) => handleChangeDropDownSites(e.target?.value)}
          value={value.length > 0 ? value : []}
          variant="standard"
          fullWidth={true}
        >
          {documentsRequired?.map((reference) => (
            <MenuItem
              key={
                reference?.objectId ||
                reference?._id ||
                `${String(new Date())}-${Math.random()}`
              }
              value={reference?.objectId || reference?._id || ''}
              style={getStyles(
                reference?.objectId || (reference?._id as string),
                value,
                theme,
              )}
            >
              {`En: ${reference?.en}[Fr: ${reference?.fr}]`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {selectedDocumentsRequired?.length > 0 &&
        selectedDocumentsRequired?.map((item) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '50ch' },
            }}
            key={item?.objectId || `${String(new Date())}-${Math.random()}`}
          >
            <TextField
              label={intl.translate({
                id: 'En',
              })}
              value={item?.en || ''}
              disabled
              variant="standard"
            />
            <TextField
              label={intl.translate({
                id: 'Fr',
              })}
              value={item?.fr || ''}
              disabled
              variant="standard"
            />
          </Box>
        ))}
    </>
  );
};

export default DocumentsRequiredForm;
