export const validateRequired = (value: string) => !!value?.length;

export const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const hasValueChanged = (valueOne: string, valueTwo: string) => {
  if (
    (valueOne === undefined && valueTwo === null) ||
    (valueOne === null && valueTwo === undefined)
  ) {
    return false;
  }

  return valueOne !== valueTwo;
};

export const hasNumericValueChanged = (valueOne: number, valueTwo: number) => {
  if (
    (valueOne === undefined && valueTwo === null) ||
    (valueOne === null && valueTwo === undefined)
  ) {
    return false;
  }

  return valueOne !== valueTwo;
};

export const hasBooleanValueChanged = (
  valueOne: boolean | null | undefined,
  valueTwo: boolean | null | undefined,
) => {
  if (
    (valueOne === undefined && valueTwo === null) ||
    (valueOne === null && valueTwo === undefined)
  ) {
    return false;
  }

  return valueOne !== valueTwo;
};

export const hasArrayValueChanged = (
  valueOne: string[],
  valueTwo: string[],
) => {
  if (
    (valueOne === undefined && valueTwo === null) ||
    (valueOne === null && valueTwo === undefined)
  ) {
    return false;
  }

  if (valueOne.length !== valueTwo.length) {
    return true;
  }

  for (let i = 0; i < valueOne.length; i++) {
    if (valueOne[i] !== valueTwo[i]) {
      return true;
    }
  }

  return false;
};
