import { UserRoleEnum } from 'types/user-role.enum';
import { User } from 'types/user.dto';

export const isSuper = (loggedUser: User | null): boolean =>
  loggedUser?.role === UserRoleEnum.SuperAdmin;

export const isAdmin = (loggedUser: User | null): boolean =>
  loggedUser?.role === UserRoleEnum.Admin;

export const isUser = (loggedUser: User | null): boolean =>
  loggedUser?.role === UserRoleEnum.User;
