/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import BulkImport from 'assets/BulkImport.csv';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { FileInputButton } from 'components/Shared/Buttons/FileInputButton/FileInputButton';
import { ModalParagraph } from 'components/Shared/ModalTitles/ModalParagraph';
import { ModalTitle } from 'components/Shared/ModalTitles/ModalTitle';
import { Label } from 'design/Text/Label';
import Papa from 'papaparse';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BulkImportData } from 'types/bulk-import';
import { intl } from 'utilities/i18n/intl.utility';

const StyledFileLabel = styled(Label)`
  color: ${({ theme }) => theme.palette.green.dark};
  font-weight: 700;
`;

const translatedUploadLabel = intl.translate({
  id: 'Upload File',
});

export const BulkImportModal: FC<{
  onClose: () => void;
  onSubmit: (result: BulkImportData) => void;
  open: boolean;
}> = ({ open, onClose, onSubmit }) => {
  const [taxonomiesFile, setTaxonomiesFile] = useState<File | null>(null);
  const [agenciesFile, setAgenciesFile] = useState<File | null>(null);
  const [sitesFile, setSitesFile] = useState<File | null>(null);
  const [resourcesFile, setResourcesFile] = useState<File | null>(null);

  const papaPromise = (file: File) =>
    new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results: any) {
          resolve(results.data);
        },
        error: function (error: any) {
          reject(error);
        },
      });
    });

  const testFileContent = async () => {
    const result: BulkImportData = {
      Resources: null,
      Sites: null,
      Agencies: null,
      Taxonomies: null,
      ResourcesFile: resourcesFile,
      SitesFile: sitesFile,
      AgenciesFile: agenciesFile,
      TaxonomiesFile: taxonomiesFile,
    };

    if (taxonomiesFile) {
      result.Taxonomies = await papaPromise(taxonomiesFile);
    }
    if (agenciesFile) {
      result.Agencies = await papaPromise(agenciesFile);

      try {
        result.Agencies = result.Agencies.map((agency: any) => {
          const locationAsJson = JSON.parse(agency.location);

          return {
            ...agency,
            locationAddress1: locationAsJson.address1,
            locationCity: locationAsJson.city,
            locationProvince: locationAsJson.province,
          };
        });
      } catch (err: any) {
        console.log(err);
      }
    }
    if (sitesFile) {
      result.Sites = await papaPromise(sitesFile);

      try {
        result.Sites = result.Sites.map((site: any) => {
          const locationAsJson = JSON.parse(site.location);

          return {
            ...site,
            locationAddress1: locationAsJson.address1,
            locationCity: locationAsJson.city,
            locationProvince: locationAsJson.province,
          };
        });
      } catch (err: any) {
        console.log(err);
      }
    }
    if (resourcesFile) {
      result.Resources = await papaPromise(resourcesFile);

      try {
        result.Resources = result.Resources.map((resource: any) => {
          const namesAsJson = JSON.parse(resource.name);

          return {
            ...resource,
            nameEn: namesAsJson.en,
            nameFr: namesAsJson.fr,
            languages: JSON.parse(resource.languages),
          };
        });
      } catch (err: any) {
        console.log(err);
      }
    }

    return result;
  };

  const handleSubmit = async () => {
    try {
      const result = await testFileContent();
      onSubmit(result);
      onClose();
    } catch (err) {
      toast.error('An error occurred', { position: 'top-center' });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        <ModalTitle>
          {intl.translate({
            id: 'Bulk Import',
          })}
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <ModalParagraph>
          {intl.translate(
            {
              id: 'Upload files to import corresponding data. <a>Download</a> sample CSV files for formatting rules.',
            },
            {
              a: (label) => (
                <a
                  href={BulkImport}
                  download="Bulk_Import_Template"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {label}
                </a>
              ),
            },
          )}
        </ModalParagraph>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            p={3}
            sx={{
              height: '80%',
              width: '80%',
              marginX: 'auto',
              marginTop: '4rem',
              marginBottom: '4rem',
              border: '2px dashed #004947',
              borderRadius: '20px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <StyledFileLabel>
                  {intl.translate({
                    id: 'Taxonomies',
                  })}
                </StyledFileLabel>
              </Grid>
              <Grid item xs={10}>
                <FileInputButton>
                  {taxonomiesFile?.name
                    ? taxonomiesFile.name
                    : translatedUploadLabel}
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={(event) => {
                      if (event.target.files !== null) {
                        setTaxonomiesFile(event.target.files[0]);
                      }
                    }}
                  />
                </FileInputButton>
              </Grid>

              <Grid item xs={2}>
                <StyledFileLabel>
                  {intl.translate({
                    id: 'Agencies',
                  })}
                </StyledFileLabel>
              </Grid>
              <Grid item xs={10}>
                <FileInputButton>
                  {agenciesFile?.name
                    ? agenciesFile.name
                    : translatedUploadLabel}
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={(event) => {
                      if (event.target.files !== null) {
                        setAgenciesFile(event.target.files[0]);
                      }
                    }}
                  />
                </FileInputButton>
              </Grid>

              <Grid item xs={2}>
                <StyledFileLabel>
                  {intl.translate({
                    id: 'Sites',
                  })}
                </StyledFileLabel>
              </Grid>
              <Grid item xs={10}>
                <FileInputButton>
                  {sitesFile?.name ? sitesFile.name : translatedUploadLabel}
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={(event) => {
                      if (event.target.files !== null) {
                        setSitesFile(event.target.files[0]);
                      }
                    }}
                  />
                </FileInputButton>
              </Grid>

              <Grid item xs={2}>
                <StyledFileLabel>
                  {intl.translate({
                    id: 'Resources',
                  })}
                </StyledFileLabel>
              </Grid>
              <Grid item xs={10}>
                <FileInputButton>
                  {resourcesFile?.name
                    ? resourcesFile.name
                    : translatedUploadLabel}
                  <input
                    hidden
                    accept=".csv"
                    type="file"
                    onChange={(event) => {
                      if (event.target.files !== null) {
                        setResourcesFile(event.target.files[0]);
                      }
                    }}
                  />
                </FileInputButton>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Upload',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
