import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { Text } from 'design/Text';
import { Paragraph } from 'design/Text/Paragraph';
import { tableHiddenColumnsInitialState } from 'helpers/resources.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { useResources } from 'hooks/useResources';
import { useSites } from 'hooks/useSites';
import MaterialReactTable from 'material-react-table';
import { FC } from 'react';
import styled from 'styled-components';
import { Agency } from 'types/entities/agencies';
import { intl } from 'utilities/i18n/intl.utility';

const StyledStatusUpperCaseStrong = styled.strong`
  text-transform: uppercase;
`;

const StyledTextH2 = styled(Text.h2)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey.dark};
`;

export const DeleteModal: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  rowsToDelete: Agency[];
}> = ({ open, onClose, onSubmit, rowsToDelete }) => {
  const { columns: resourcesColumns } = useResources();
  const { columns: sitesColumns } = useSites();
  const { columns: agenciesColumns } = useAgencies();

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Confirm deletion of following row(s)',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <Paragraph color="light">
          {intl.translate(
            {
              id: 'Review the following record details and confirm to <strong>DELETE</strong>:',
            },
            {
              strong: (label) => (
                <StyledStatusUpperCaseStrong>
                  {label}
                </StyledStatusUpperCaseStrong>
              ),
            },
          )}
        </Paragraph>

        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <MaterialReactTable
              columns={agenciesColumns}
              data={rowsToDelete}
              initialState={{
                columnVisibility: { ...tableHiddenColumnsInitialState },
              }}
            />

            <StyledTextH2>
              {intl.translate({
                id: 'Sites',
              })}
            </StyledTextH2>
            <MaterialReactTable
              columns={sitesColumns}
              data={[]}
              initialState={{
                columnVisibility: { ...tableHiddenColumnsInitialState },
              }}
            />

            <StyledTextH2>
              {intl.translate({
                id: 'Resources',
              })}
            </StyledTextH2>
            <MaterialReactTable
              columns={resourcesColumns}
              data={[]}
              initialState={{
                columnVisibility: { ...tableHiddenColumnsInitialState },
              }}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Delete Rows',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
