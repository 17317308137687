/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalBottomSection } from 'components/Shared/ModalContent/ModalBottomSection';
import { ResourceForm } from 'components/Shared/SharedForms/ResourceForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { palette } from 'design/theme/palette';
import { selectUser } from 'global-state/selectors';
import { validateRequired } from 'helpers/validation.helpers';

import { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { Resource } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const EditModal: FC<{
  columns: MRT_ColumnDef<Resource>[];
  onClose: () => void;
  onSubmit: (rowIndex: number, values: Resource) => void;
  open: boolean;
  row: MRT_Row<Resource>;
  changedProperties: string[];
}> = ({ row, open, columns, onClose, onSubmit, changedProperties }) => {
  const loggedUser = useSelector(selectUser);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [resourcesFormValues, setResourcesFormValues] = useState<Resource>(
    row?.original,
  );

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [selectedSite, setSelectedSite] = useState<string[]>([]);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedTaxonomies, setSelectedTaxonomies] = useState<string[][]>([]);

  useEffect(() => {
    if (!resourcesFormValues) {
      return;
    }

    const data: any = resourcesFormValues;
    // sites []
    if (data?.sites) {
      setSelectedSite(data?.sites?.map((x: any) => x?.objectId));
    }

    // agency
    if (data?.agency) {
      setSelectedAgency(data?.agency?.objectId);
    }

    // taxonomies [][]

    if (data?.taxonomies) {
      const taxonomiesIdsArray = data?.taxonomies?.map((row: any) => {
        if (Array.isArray(row)) {
          return row?.map((value: any) => {
            return value?.objectId;
          });
        }
        return row?.objectId;
      });
      setSelectedTaxonomies(taxonomiesIdsArray);
    }
  }, [resourcesFormValues]);

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};
    const fields = [];

    if (
      !validateRequired(resourcesFormValues?.descriptionEn) &&
      !validateRequired(resourcesFormValues?.descriptionFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['descriptionFr']: 'This field is required',
      };
      !resourcesFormValues.descriptionEn && fields.push('descriptionEn');
      !resourcesFormValues.descriptionFr && fields.push('descriptionFr');
    }

    if (resourcesFormValues.coverage?.length === 0) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['coverage']: 'This field is required',
      };
      fields.push('coverages');
    }

    if (
      !validateRequired(resourcesFormValues?.nameEn) &&
      !validateRequired(resourcesFormValues?.nameFr)
    ) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameEn']: 'This field is required',
      };
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['nameFr']: 'This field is required',
      };

      !resourcesFormValues.nameEn && fields.push('nameEn');
      !resourcesFormValues.nameFr && fields.push('nameFr');
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);

      toast.error(
        `Fields: ${fields.join(', ')} ${
          fields.length > 1 ? 'are' : 'is'
        } required`,
        {
          position: 'top-center',
        },
      );
      return;
    }

    const updatedResource: Resource = {
      ...resourcesFormValues,
      lastUpdatedBy: loggedUser?.email || '',
    };
    onSubmit(row?.index, updatedResource);

    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Edit Resource',
        })}

        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              '& .MuiTabs-indicator': {
                backgroundColor: palette.black.main,
                left: 0,
                width: '4px',
              },
              '& .Mui-selected': {
                color: `${palette.black.main} !important`,
              },
              height: '80vh',
              '&  #tabpanel-0': {
                marginLeft: '100px',
              },
              '&  #tabpanel-1': {
                marginLeft: '100px',
              },
              '&  #tabpanel-2': {
                marginLeft: '100px',
              },
              '&  #tabpanel-3': {
                marginLeft: '100px',
              },
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabValue}
              onChange={handleChange}
              aria-label="Vertical tabs"
              sx={{ position: 'fixed' }}
            >
              <StyledLeftTab label="Resource" {...a11yProps(0)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <ResourceForm
                columns={columns}
                values={resourcesFormValues}
                setValues={setResourcesFormValues}
                validationErrors={validationErrors}
                changedProperties={changedProperties}
              />
            </TabPanel>
          </Box>
        </form>
      </DialogContent>

      <DialogActions sx={{ p: '1.25rem' }}>
        <ModalBottomSection showUpdatedLabel={true}>
          <CancelButton handleClick={onClose}>
            {intl.translate({
              id: 'Cancel',
            })}
          </CancelButton>
          <ConfirmButton handleClick={handleSubmit}>
            <strong>
              {intl.translate({
                id: 'Save Changes',
              })}
            </strong>
          </ConfirmButton>
        </ModalBottomSection>
      </DialogActions>
    </Dialog>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
