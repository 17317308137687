/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Add, Remove } from '@mui/icons-material';
import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { intl } from 'utilities/i18n/intl.utility';
import { ModalParagraph } from '../ModalTitles/ModalParagraph';

import { Site } from 'types/entities/sites';
import { getSiteById, searchSites } from 'api/sites/sites.api';
import { toast } from 'react-toastify';

type SiteSelectProps = {
  selectedSites: (string | null)[];
  currentSites: Site[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedSites: (values: any[]) => void;
  setSelectedSiteInfo?: (values: any[]) => void;
  agencyId?: string | undefined;
  selectedSiteInfo?: any[];
};
type SiteOption = {
  label: string;
  value: string;
};

export const SitesSelect: FC<SiteSelectProps> = ({
  selectedSites,
  currentSites,
  selectedSiteInfo,
  agencyId,
  setSelectedSiteInfo,
  setSelectedSites,
}) => {
  const [filteredSites, setFilteredSites] = useState<SiteOption[][]>(
    selectedSites.map(() => []),
  );

  const [hasAvailableSites, setHasAvailableSites] = useState(true);
  const [availableSites, setAvailableSites] = useState<Site[]>([]);

  const handleFilterChange = async (searchText: string, index: number) => {
    let searchedSites: any[] = [];

    if (searchText.length < 3) return;

    try {
      if (searchText.includes('En') && searchText.includes('Fr')) {
        const search = searchText.split('/')[0].split(': ')[1].trimEnd();
        const { sites } = await searchSites('nameEN', search, agencyId);
        searchedSites = sites;
      } else {
        const { sites } = await searchSites('nameEN', searchText, agencyId);
        searchedSites = sites;
      }
      if (searchedSites?.length > 0) {
        const filtered = searchedSites?.map((site) => {
          const id = site?.objectId || site?._id;
          return {
            label: `En: ${site.nameEN} / Fr: ${site.nameFR} - (${id})`,
            value: id,
          };
        });

        if (filtered.length > 0) {
          setFilteredSites((prev) =>
            prev.map((arr, idx) => (idx === index ? filtered : arr)),
          );
        }
      }
    } catch (error) {
      toast.error('Failed to search', { position: 'top-center' });
    }
  };

  const handleSelectSites = async (selectedOptions: any, index: number) => {
    const allSelectedSites = [...selectedSites];
    const existIdx = allSelectedSites.indexOf(selectedOptions?.value);
    if (existIdx === -1 || existIdx === index) {
      const newSite = await getSiteById(selectedOptions?.value);
      const siteInfo = (selectedSiteInfo && [...selectedSiteInfo]) || [];
      siteInfo[index] = newSite;
      selectedSiteInfo && setSelectedSiteInfo && setSelectedSiteInfo(siteInfo);
      allSelectedSites[index] = selectedOptions.value;
      setSelectedSites(allSelectedSites);
    } else {
      toast.error(`Site is within the available options `, {
        position: 'top-center',
      });
      handleRemoveSiteGroup(index);
    }
  };

  const handleAddSiteGroup = () => {
    const newData = [...selectedSites, null];
    const newFilteredSite = [...filteredSites, []];

    setSelectedSites(newData);
    setFilteredSites(newFilteredSite);
  };

  const handleRemoveSiteGroup = (index: number) => {
    const newSiteIds = [...selectedSites];
    newSiteIds.splice(index, 1);

    const newFilteredSite = [...filteredSites];
    newFilteredSite.splice(index, 1);

    setSelectedSites(newSiteIds);
    setFilteredSites(newFilteredSite);
    if (
      setSelectedSiteInfo &&
      selectedSiteInfo &&
      selectedSiteInfo.length > 0
    ) {
      const newSites = selectedSiteInfo.filter((s) =>
        newSiteIds.includes(s?._id),
      );
      setSelectedSiteInfo(newSites);
    }
  };
  useEffect(() => {
    const getSites = async () => {
      try {
        const { sites } = await searchSites('nameEN', 'dummy-search', agencyId);
        setHasAvailableSites(!!sites.length);
        setAvailableSites(sites);
      } catch (error) {
        console.log(error);
        // empty
      }
    };

    getSites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSites = () => {
      const allSites =
        selectedSiteInfo && selectedSiteInfo.length > 0
          ? selectedSiteInfo
          : currentSites || [];

      const mappedSites =
        Array.isArray(allSites) && allSites.length > 0
          ? allSites.map((site) => [
              {
                label: `En: ${site.nameEN} / Fr: ${site.nameFR} - (${site?._id})`,
                value: site?._id,
              },
            ])
          : [];
      setSelectedSiteInfo && setSelectedSiteInfo(allSites);
      setFilteredSites(
        selectedSites.map((_, index) => mappedSites[index] || []),
      );
    };

    getSites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateFilteredSites = () => {
      setFilteredSites((prev) =>
        selectedSites.map((_, index) => prev[index] || []),
      );
    };

    updateFilteredSites();
  }, [selectedSites]);

  return (
    <>
      <>
        <ModalParagraph>
          {intl.translate({
            id: 'Select existing Site(s)',
          })}
          <IconButton
            color="primary"
            aria-label="Add item"
            component="label"
            onClick={handleAddSiteGroup}
            disabled={!hasAvailableSites}
          >
            <Tooltip
              arrow
              title={
                hasAvailableSites
                  ? intl.translate({
                      id: 'Add group',
                    })
                  : 'No sites available'
              }
            >
              <Add />
            </Tooltip>
          </IconButton>
        </ModalParagraph>
        {!hasAvailableSites &&
          'No sites Available  [Please delete any site listed and save changes]'}

        {selectedSites?.length > 0 &&
          selectedSites?.map((element, index) => (
            <Grid item xs={1} key={`grid-${index}`}>
              <IconButton
                key={`icon-${index}`}
                size="small"
                color="error"
                aria-label="Remove Item"
                component="label"
                onClick={() => handleRemoveSiteGroup(index)}
              >
                <Tooltip
                  key={`tooltip-${index}`}
                  arrow
                  title={intl.translate({
                    id: 'Delete group',
                  })}
                >
                  <Remove />
                </Tooltip>
              </IconButton>

              <Autocomplete
                options={
                  filteredSites[index]?.length > 0
                    ? filteredSites[index]
                    : availableSites.map((site) => ({
                        label: `En: ${site.nameEN} / Fr: ${site.nameFR} - (${
                          site?._id || site.objectId
                        })`,
                        value: site.objectId || site?._id,
                      }))
                }
                disableClearable={true}
                sx={{ width: '100%' }}
                noOptionsText="Enter a site name to search"
                // value={filteredSites?.[index]?.[0]?.label || ""}
                getOptionLabel={((option) => option?.label) || ''}
                value={
                  (selectedSites[index] &&
                    filteredSites[index]?.find(
                      (option) => option.value === selectedSites[index],
                    )) || {
                    label: '',
                    value: '',
                  }
                }
                onChange={(e, newValue) => {
                  handleSelectSites(newValue, index);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                onInputChange={(e, newValue) => {
                  handleFilterChange(newValue, index);
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                      }}
                      label={filteredSites?.[index]?.[0]?.label || 'Select'}
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          ))}
      </>
    </>
  );
};
