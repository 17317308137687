import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { useTaxonomies } from 'hooks/useTaxonomies';
import MaterialReactTable from 'material-react-table';
import { FC } from 'react';
import { Taxonomy } from 'types/entities/taxonomies';
import { intl } from 'utilities/i18n/intl.utility';

export const SaveEditedRowsModal: FC<{
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  rowsToSave: Taxonomy[];
}> = ({ open, onClose, onSubmit, onCancel, rowsToSave }) => {
  const { columns: taxonomiesColumn } = useTaxonomies();
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left" mb={2}>
        {intl.translate({
          id: 'Confirm changes on following row(s)',
        })}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <MaterialReactTable columns={taxonomiesColumn} data={rowsToSave} />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={handleCancel}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
