/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Add, Remove, Search } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { getGeocodingData } from 'api/geocoding/geocoding.api';
import citiesByProvince from 'assets/constants/cities-by-province';
import countries from 'assets/constants/countries';
import provinces from 'assets/constants/provinces';
import AccessibilityForm from 'components/Form/References/AccessibilityForm';
import OperationSiteForm from 'components/Form/Sections/OperationSiteForm';
import PhoneNumbersForm from 'components/Form/Sections/PhoneNumbersForm';
import { isValidEnFr, newPhoneNumbers } from 'helpers/resources.helpers';
import { newOperationSite } from 'helpers/sites.helpers';
import { MRT_ColumnDef } from 'material-react-table';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { Contact, OperationSite, PhoneNumber } from 'types/data-management';
import { Site } from 'types/entities/sites';
import { YesNoEnum } from 'types/utilities';
import { intl } from 'utilities/i18n/intl.utility';
import { BoxContent } from '../ModalContent/BoxContent';

import { toast } from 'react-toastify';
import { SitesSelect } from './SitesSelect';

type SiteFormProps = {
  showLabel?: boolean;
  selectedSite: string[];
  setSelectedSite: (values: string[]) => void;
  setSelectedSiteInfo?: (values: any[]) => void;
  selectedSiteInfo?: any[];
  columns: MRT_ColumnDef<Site>[];
  values: Site;
  agencyId?: string | undefined;
  setValues: (values: Site) => void;
  validationErrors?: { [cellId: string]: string };
  currentSites?: any;
};

const newContact: Contact = {
  type: '',
  name: '',
  title: '',
  phone: '',
  email: '',
};

const renderDisabledTextField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      disabled
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey]}
      variant="standard"
    />
  );
};

const renderYesNoDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {Object.values(YesNoEnum).map((item) => (
        <MenuItem key={item} value={item}>
          {item === YesNoEnum.Yes ? 'Yes' : 'No'}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderProvinceDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {provinces.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderCityDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {values.locationProvince ? (
        citiesByProvince
          .filter((city) => city.province === values.locationProvince)
          .map((city, index) => (
            <MenuItem key={index} value={city.city}>
              {city.city}
            </MenuItem>
          ))
      ) : (
        <MenuItem value={''}>
          {intl.translate({
            id: 'Select a province first',
          })}
        </MenuItem>
      )}
    </TextField>
  );
};

const ContactsForm: FC<{
  column: MRT_ColumnDef<Omit<Site, 'operations'>>;
  values: Site;
  setValues: (values: Site) => void;
}> = ({ column, values, setValues }) => {
  if (!column.accessorKey) {
    return null;
  }

  const [contacts, setContacts] = useState(
    (values[column.accessorKey] as unknown[] as Contact[])?.length > 0
      ? (values[column.accessorKey] as unknown[] as Contact[])
      : [{ ...newContact }],
  );

  const handleAddContact = () => {
    const arrayWithNewItem = [...contacts, { ...newContact }];
    setContacts(arrayWithNewItem);
  };

  const handleRemoveContact = (index: number) => {
    const arrayRemovedInex = [...contacts];
    arrayRemovedInex.splice(index, 1);
    setContacts(arrayRemovedInex);
  };

  return (
    <Grid container>
      {contacts.map((mailingAddress, index) => (
        <React.Fragment key={index}>
          <Grid item xs={10}>
            <TextField
              label={'Type'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          type: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.type || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Add item"
              component="label"
              onClick={handleAddContact}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Remove Item"
              component="label"
              onClick={() => handleRemoveContact(index)}
            >
              <Remove />
            </IconButton>
          </Grid>

          <Grid item xs={10}>
            <TextField
              label={'Name'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          name: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.name || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Title'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          title: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.title || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Phone'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          phone: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.phone || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Email'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          email: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.email || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {index !== contacts.length - 1 && (
            <Grid item mt={2} mb={1} xs={12}>
              <Divider />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export const SiteForm: FC<SiteFormProps> = ({
  showLabel = true,
  selectedSite,
  setSelectedSite,
  setSelectedSiteInfo,
  selectedSiteInfo,
  columns,
  values,
  agencyId,
  setValues,
  validationErrors = {},
  currentSites,
}) => {
  const [accessibility, setAccessibilityId] = useState<string>(
    isValidEnFr(values.accessibility) ? values.accessibility.en : '',
  );
  useEffect(() => {
    if (currentSites && currentSites.length > 0) {
      const currentSiteIds: any[] = [];
      for (const cs of currentSites) {
        if (cs?._id && !currentSiteIds.includes(cs?._id)) {
          currentSiteIds.push(cs?._id);
        }
      }

      setSelectedSite(currentSiteIds);
    }
  }, [currentSites, setSelectedSite]);

  const [showDropdown, setShowDropdown] = useState(showLabel);
  const [loading, setLoading] = useState(false);

  const [sites, setSites] = useState<{ label: string; value: string }[]>([]);
  const [filteredSites, setFilteredSites] = useState<
    { label: string; value: string }[]
  >([]);

  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>(
    values.phoneNumbers !== null && typeof values.phoneNumbers !== 'string'
      ? values.phoneNumbers
      : [{ ...newPhoneNumbers }],
  );
  const [operations, setOperations] = useState<OperationSite>(
    values.operations && typeof values.operations !== 'string'
      ? values.operations
      : { ...newOperationSite },
  );

  const renderTextField = (
    column: MRT_ColumnDef<Omit<Site, 'operations'>>,
    values: Site,
    setValues: (values: Site) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      />
    );
  };

  const renderNumberTextField = (
    column: MRT_ColumnDef<Omit<Site, 'operations'>>,
    values: Site,
    setValues: (values: Site) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        type="number"
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
        style={{ width: '100%' }}
      />
    );
  };

  const renderCountryDropdownField = (
    column: MRT_ColumnDef<Omit<Site, 'operations'>>,
    values: Site,
    setValues: (values: Site) => void,
    isRequired?: boolean,
  ) => {
    if (!column.accessorKey) {
      return null;
    }

    return (
      <TextField
        key={column.accessorKey}
        select
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      >
        {countries.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Omit<Site, 'operations'>>;
    },
    [columns],
  );

  // const handleFilterChange = async (searchText: string) => {
  //   const filtered = sites?.filter((site) =>
  //     site.label.toLowerCase().includes(searchText.toLowerCase()),
  //   );
  //   let searchedSites: any[] = [];
  //   if (filtered?.length === 0) {
  //     try {
  //       if (searchText.includes('En') && searchText.includes('Fr')) {
  //         const search = searchText.split('/')[0].split(': ')[1].trimEnd();
  //         searchedSites = await searchSites('nameEN', search);
  //       } else {
  //         searchedSites = await searchSites('nameEN', searchText);
  //       }
  //     } catch (error) {
  //       toast.error('Failed to search', { position: 'top-center' });
  //     }

  //     if (searchedSites && searchedSites.length > 0) {
  //       const filteredSites = searchedSites?.map((agency) => {
  //         return {
  //           label: `En: ${agency.nameEN} / Fr: ${agency.nameFR}`,
  //           value: agency.objectId,
  //         };
  //       });

  //       setFilteredSites(filteredSites);
  //       // setSelectedSite(filteredSites[0].value)
  //     }
  //   } else {
  //     setFilteredSites(filtered);
  //   }

  //   // setFilteredSites(filtered);
  // };

  // const handleSelectSites = (selectedOptions: any) => {
  //   const values = [];
  //   const selectedSiteInfoL: any = [];
  //   for (const option of selectedOptions) {
  //     const value = filteredSites?.find((site) =>
  //       option.label.includes(site.label),
  //     )?.value;
  //     if (value) {
  //       values.push(value);
  //       selectedSiteInfoL.push(
  //         filteredSites?.find((site) => option.label.includes(site.label)),
  //       );
  //     }
  //     const relatedValue = currentSites?.find((site: any) =>
  //       option.label.includes(site.nameEN),
  //     )?._id;
  //     if (relatedValue) {
  //       values.push(relatedValue);
  //       selectedSiteInfoL.push(
  //         currentSites?.find((site: any) => option.label.includes(site.nameEN)),
  //       );
  //     }
  //   }
  //   if (setSelectedSiteInfo) {
  //     if (selectedSiteInfo && selectedSiteInfo?.length > 0) {
  //       setSelectedSiteInfo([...selectedSiteInfoL, ...selectedSiteInfo]);
  //       setSelectedSite([...values, ...selectedSite]);
  //     } else {
  //       setSelectedSiteInfo([...selectedSiteInfoL]);
  //       setSelectedSite([...values]);
  //     }
  //   } else {
  //     if (selectedSite?.length > 0) {
  //       setSelectedSite([...values, ...selectedSite]);
  //     } else {
  //       setSelectedSite([...values]);
  //     }
  //   }
  // };
  const handleLatitudeLongitudeLookup = async (values: any) => {
    try {
      const res = await getGeocodingData({
        address1: values['locationAddress1'] || '',
        address2: values['locationAddress2'] || '',
        country: values['locationCountry'] || '',
        province: values['locationProvince'] || '',
        county: values['locationCounty'] || '',
        city: values['locationCity'] || '',
        postalCode: values['locationPostalCode'] || '',
      });
      const { latitude, longitude } = res;

      setValues({
        ...values,
        ['locationLongitude']: longitude,
        ['locationLatitude']: latitude,
      });
    } catch (error) {
      toast.error('An error occurred', { position: 'top-center' });
    }
  };

  useEffect(() => {
    const getSites = async (loading = true) => {
      if (loading) setLoading(true);
      // const sitesList = (await fetchSites(0, 10000)).sites;
      const sitesList = currentSites;
      const sitesLabelValue = sitesList?.map((site: any) => {
        const id = site?.objectId || site?._id;
        return {
          label: `En: ${site.nameEN} / Fr: ${site.nameFR} -  (${id})`,
          value: site?._id,
        };
      });
      setSites(sitesLabelValue);
      setFilteredSites(sitesLabelValue);
      setLoading(false);
    };

    getSites();
  }, [currentSites]);

  useEffect(() => {
    setValues({
      ...values,
      operations,
      accessibility,
      phoneNumbers,
    });
    // disable warning as we only want to trigger update for accessibilities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operations, accessibility, phoneNumbers]);

  const [inputValue, setInputValue] = React.useState('');

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      {showDropdown ? (
        <SitesSelect
          selectedSites={selectedSite}
          currentSites={currentSites}
          setSelectedSites={setSelectedSite}
          setSelectedSiteInfo={setSelectedSiteInfo}
          selectedSiteInfo={selectedSiteInfo}
          agencyId={agencyId}
        />
      ) : (
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
            padding: 2,
          }}
        >
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '102ch' },
            }}
          >
            {renderDisabledTextField(
              getColumnByAccessorKey('siteReferenceNumber'),
              values,
              setValues,
            )}

            {renderDisabledTextField(
              getColumnByAccessorKey('khpReferenceNumber'),
              values,
              setValues,
            )}
            {getColumnByAccessorKey('updatedAt') &&
              values['updatedAt'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('updatedAt'),
                values,
                setValues,
              )}
            {getColumnByAccessorKey('lastVerifiedOn') &&
              values['lastVerifiedOn'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('lastVerifiedOn'),
                values,
                setValues,
              )}
            {getColumnByAccessorKey('lastUpdatedBy') &&
              values['lastUpdatedBy'] &&
              renderDisabledTextField(
                getColumnByAccessorKey('lastUpdatedBy'),
                values,
                setValues,
              )}
          </Box>

          <BoxContent
            title={intl.translate({
              id: 'Name Details',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '50ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('nameEN'),
                values,
                setValues,
                true,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameFR'),
                values,
                setValues,
                true,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsEnOfficial'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsFrOfficial'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsEnAlternate'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('nameDetailsFrAlternate'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Location',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('locationAddress1'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationAddress2'),
                values,
                setValues,
              )}
              {renderCountryDropdownField(
                getColumnByAccessorKey('locationCountry'),
                values,
                setValues,
              )}
              {renderProvinceDropdownField(
                getColumnByAccessorKey('locationProvince'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationCounty'),
                values,
                setValues,
              )}
              {renderCityDropdownField(
                getColumnByAccessorKey('locationCity'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationDescription'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('locationPostalCode'),
                values,
                setValues,
              )}
              <Box
                style={{
                  width: '98%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {renderNumberTextField(
                  getColumnByAccessorKey('locationLongitude'),
                  values,
                  setValues,
                )}
                {renderNumberTextField(
                  getColumnByAccessorKey('locationLatitude'),
                  values,
                  setValues,
                )}
                <Box>
                  <Tooltip title="Lookup Latitude and Longitude">
                    <IconButton
                      color="primary"
                      aria-label="Lookup Latitude and Longitude"
                      component="label"
                      onClick={() => handleLatitudeLongitudeLookup(values)}
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {renderYesNoDropdownField(
                getColumnByAccessorKey('isLocationPrivate'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Transportation',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '50ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('transportationEn'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('transportationFr'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Accessibility',
            })}
          >
            <AccessibilityForm
              value={accessibility}
              setValue={setAccessibilityId}
              currentValue={values.accessibility}
            />
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Social Media',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('email'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '49ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('websiteEn'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('websiteFr'),
                values,
                setValues,
              )}
            </Box>
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              {renderTextField(
                getColumnByAccessorKey('socialFacebook'),
                values,
                setValues,
              )}
              {renderTextField(
                getColumnByAccessorKey('socialInstagram'),
                values,
                setValues,
              )}
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Numbers',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              <PhoneNumbersForm
                value={phoneNumbers}
                setValue={setPhoneNumbers}
              />
            </Box>
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Hours of Operation',
            })}
          >
            <OperationSiteForm value={operations} setValue={setOperations} />
          </BoxContent>

          <BoxContent
            title={intl.translate({
              id: 'Main Contact Information',
            })}
          >
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '102ch' },
              }}
            >
              <ContactsForm
                column={getColumnByAccessorKey('contacts')}
                values={values}
                setValues={setValues}
              />
            </Box>
          </BoxContent>

          {getColumnByAccessorKey('objectId') && values['objectId'] && (
            <BoxContent
              title={intl.translate({
                id: 'Miscellaneous',
              })}
            >
              <Box
                sx={{
                  '& > :not(style)': { m: 1, width: '102ch' },
                }}
              >
                {renderDisabledTextField(
                  getColumnByAccessorKey('objectId'),
                  values,
                  setValues,
                )}
              </Box>
            </BoxContent>
          )}
        </Stack>
      )}
    </Stack>
  );
};
