import { Update } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';
import { Paragraph } from 'design/Text/Paragraph';
import { Align } from 'layout';
import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

export const ModalBottomSection: FC<{
  showUpdatedLabel?: boolean;
  children: React.ReactNode;
}> = ({ showUpdatedLabel, children }) => {
  return (
    <Grid container>
      <Grid item xs={8}>
        {showUpdatedLabel && (
          <Align v-center h-start>
            <InputAdornment position="start">
              <Update />
            </InputAdornment>
            <Paragraph>
              {intl.translate({
                id: 'This field has been updated',
              })}
            </Paragraph>
          </Align>
        )}
      </Grid>
      <Grid item xs={4}>
        <Align v-center h-end>
          {children}
        </Align>
      </Grid>
    </Grid>
  );
};
