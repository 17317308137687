import { Add, Remove } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, TextField } from '@mui/material';
import React, { FC } from 'react';

const KeywordsForm: FC<{
  value: string[];
  setValue: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ value, setValue }) => {
  const handleAddMailingAddress = () => {
    const arrayWithNewItem = [...value, ''];
    setValue(arrayWithNewItem);
  };

  const handleRemoveMailingAddress = (index: number) => {
    const arrayRemovedInex = [...value];
    arrayRemovedInex.splice(index, 1);
    setValue(arrayRemovedInex);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <Grid container>
          {value.map((keyword, index) => (
            <React.Fragment key={index}>
              <Grid item xs={10}>
                <TextField
                  label={'Keyword'}
                  onChange={(e) => {
                    setValue((prev) => {
                      return prev.map((x, i) =>
                        i === index ? e.target?.value : x,
                      );
                    });
                  }}
                  value={keyword || ''}
                  variant="standard"
                  fullWidth={true}
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  aria-label="Add item"
                  component="label"
                  onClick={handleAddMailingAddress}
                >
                  <Add />
                </IconButton>
              </Grid>

              {index > 0 && (
                <Grid item xs={1}>
                  <IconButton
                    color="primary"
                    aria-label="Remove Item"
                    component="label"
                    onClick={() => handleRemoveMailingAddress(index)}
                  >
                    <Remove />
                  </IconButton>
                </Grid>
              )}

              {index !== value.length - 1 && (
                <Grid item mt={2} mb={1} xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default KeywordsForm;
