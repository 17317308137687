import { Box, Grid, TextField } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import { OperationSite } from 'types/data-management';

const StyledTitle = styled.h3`
  position: relative;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 18px;
  font-weight: 700;
  text-align: left;
`;
const OperationSiteForm: FC<{
  value: OperationSite;
  setValue: React.Dispatch<React.SetStateAction<OperationSite>>;
}> = ({ value, setValue }) => {
  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <Grid container spacing={1}>
          {/* 0 */}
          <Grid item xs={6}>
            <StyledTitle>Monday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Lundi</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 0 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '0': {
                      ...prev['0'],
                      en: {
                        ...prev['0'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[0]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 0 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '0': {
                      ...prev['0'],
                      fr: {
                        ...prev['0'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[0]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 0 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '0': {
                      ...prev['0'],
                      en: {
                        ...prev['0'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[0]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 0 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '0': {
                      ...prev['0'],
                      fr: {
                        ...prev['0'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[0]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 1 */}
          <Grid item xs={6}>
            <StyledTitle>Tuesday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Mardi</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 1 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '1': {
                      ...prev['1'],
                      en: {
                        ...prev['1'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[1]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 1 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '1': {
                      ...prev['1'],
                      fr: {
                        ...prev['1'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[1]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 1 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '1': {
                      ...prev['1'],
                      en: {
                        ...prev['1'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[1]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 1 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '1': {
                      ...prev['1'],
                      fr: {
                        ...prev['1'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[1]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 2 */}
          <Grid item xs={6}>
            <StyledTitle>Wednesday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Mercredi</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 2 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '2': {
                      ...prev['2'],
                      en: {
                        ...prev['2'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[2]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 2 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '2': {
                      ...prev['2'],
                      fr: {
                        ...prev['2'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[2]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 2 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '2': {
                      ...prev['2'],
                      en: {
                        ...prev['2'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[2]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 2 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '2': {
                      ...prev['2'],
                      fr: {
                        ...prev['2'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[2]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 3 */}
          <Grid item xs={6}>
            <StyledTitle>Thursday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Jeudi</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 3 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '3': {
                      ...prev['3'],
                      en: {
                        ...prev['3'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[3]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 3 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '3': {
                      ...prev['3'],
                      fr: {
                        ...prev['3'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[3]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 3 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '3': {
                      ...prev['3'],
                      en: {
                        ...prev['3'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[3]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 3 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '3': {
                      ...prev['3'],
                      fr: {
                        ...prev['3'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[3]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 4 */}
          <Grid item xs={6}>
            <StyledTitle>Friday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Vendredi</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 4 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '4': {
                      ...prev['4'],
                      en: {
                        ...prev['4'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[4]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 4 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '4': {
                      ...prev['4'],
                      fr: {
                        ...prev['4'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[4]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 4 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '4': {
                      ...prev['4'],
                      en: {
                        ...prev['4'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[4]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 4 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '4': {
                      ...prev['4'],
                      fr: {
                        ...prev['4'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[4]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 5 */}
          <Grid item xs={6}>
            <StyledTitle>Saturday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>MonSamediday</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 5 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '5': {
                      ...prev['5'],
                      en: {
                        ...prev['5'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[5]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 5 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '5': {
                      ...prev['5'],
                      fr: {
                        ...prev['5'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[5]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 5 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '5': {
                      ...prev['5'],
                      en: {
                        ...prev['5'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[5]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 5 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '5': {
                      ...prev['5'],
                      fr: {
                        ...prev['5'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[5]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {/* 6 */}
          <Grid item xs={6}>
            <StyledTitle>Sunday</StyledTitle>
          </Grid>
          <Grid item xs={6}>
            <StyledTitle>Dimanche</StyledTitle>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 6 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '6': {
                      ...prev['6'],
                      en: {
                        ...prev['6'].en,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[6]?.en.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Description of Hours 6 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '6': {
                      ...prev['6'],
                      fr: {
                        ...prev['6'].fr,
                        descriptionOfHours: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[6]?.fr.descriptionOfHours || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 6 En'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '6': {
                      ...prev['6'],
                      en: {
                        ...prev['6'].en,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[6]?.en.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Hours of Operation 6 Fr'}
              onChange={(e) => {
                setValue((prev) => {
                  return {
                    ...prev,
                    '6': {
                      ...prev['6'],
                      fr: {
                        ...prev['6'].fr,
                        hoursOfOperation: e.target?.value,
                      },
                    },
                  };
                });
              }}
              value={value[6]?.fr.hoursOfOperation || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OperationSiteForm;
