import { Box, Grid } from '@mui/material';
import { Paragraph } from 'design/Text/Paragraph';
import { palette } from 'design/theme/palette';
import { selectUser } from 'global-state/selectors';
import { Align } from 'layout';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { UserRoleEnum } from 'types/user-role.enum';
import { intl } from 'utilities/i18n/intl.utility';

const StyledDiv = styled.div`
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  height: 43px;
  cursor: pointer;
`;

const StyledUpperCaseParagraph = styled(Paragraph)`
  text-transform: uppercase;
`;

type DataManagementTabsProps = {
  tabValue: number;
  setTabValue: (value: number) => void;
};

export const DataManagementTabs: FC<DataManagementTabsProps> = ({
  tabValue,
  setTabValue,
}) => {
  const loggedUser = useSelector(selectUser);

  return (
    <Box
      sx={{ flexGrow: 1 }}
      mx={0}
      my={2}
      p={0}
      borderBottom={1}
      borderColor={palette.grey.light}
    >
      <Grid container spacing={1}>
        <Grid item xs={loggedUser?.role === UserRoleEnum.User ? 6 : 4}>
          <StyledDiv
            onClick={() => setTabValue(0)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setTabValue(0);
              }
            }}
            role="button"
            tabIndex={0}
            style={{
              backgroundColor:
                tabValue === 0 ? palette.pink.light : palette.white.main,
            }}
          >
            <Align v-center h-center>
              <StyledUpperCaseParagraph
                style={{
                  fontWeight: tabValue === 0 ? 'bold' : 'normal',
                }}
              >
                {intl.translate({
                  id: 'Review',
                })}
              </StyledUpperCaseParagraph>
            </Align>
          </StyledDiv>
        </Grid>
        {loggedUser?.role === UserRoleEnum.Admin ||
          (loggedUser?.role === UserRoleEnum.SuperAdmin && (
            <Grid item xs={4}>
              <StyledDiv
                onClick={() => setTabValue(1)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setTabValue(1);
                  }
                }}
                role="button"
                tabIndex={0}
                style={{
                  backgroundColor:
                    tabValue === 1 ? palette.pink.light : palette.white.main,
                }}
              >
                <Align v-center h-center>
                  <StyledUpperCaseParagraph
                    style={{
                      fontWeight: tabValue === 1 ? 'bold' : 'normal',
                    }}
                  >
                    {intl.translate({
                      id: 'Create',
                    })}
                  </StyledUpperCaseParagraph>
                </Align>
              </StyledDiv>
            </Grid>
          ))}
        <Grid item xs={loggedUser?.role === UserRoleEnum.User ? 6 : 4}>
          <StyledDiv
            onClick={() => setTabValue(2)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setTabValue(2);
              }
            }}
            role="button"
            tabIndex={0}
            style={{
              backgroundColor:
                tabValue === 2 ? palette.pink.light : palette.white.main,
            }}
          >
            <Align v-center h-center>
              <StyledUpperCaseParagraph
                style={{
                  fontWeight: tabValue === 2 ? 'bold' : 'normal',
                }}
              >
                {intl.translate({
                  id: 'Download',
                })}
              </StyledUpperCaseParagraph>
            </Align>
          </StyledDiv>
        </Grid>
      </Grid>
    </Box>
  );
};
