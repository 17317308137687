import {
  AdminPanelSettings,
  CancelScheduleSend,
  Delete,
  MarkEmailRead,
  PersonOff,
  RotateLeft,
} from '@mui/icons-material';
import { Box, Button, ListItemIcon, MenuItem } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import {
  activateUser,
  createUser,
  deactivateUser,
  deleteUser,
  editUser,
  listUser,
  resetUserPassword,
  revokeInvitation,
} from 'api/user/user.api';
import { selectUser } from 'global-state/selectors';
import { validateEmail, validateRequired } from 'helpers/validation.helpers';
import MaterialReactTable, {
  MRT_Cell,
  MRT_ColumnDef,
} from 'material-react-table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Edit } from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserEditApi } from 'types/user-edit-api.dto';
import { UserRequestApi } from 'types/user-request-api.dto';
import { UserResetPass } from 'types/user-reset-pass.dto';
import { UserRoleEnum } from 'types/user-role.enum';
import { UserStatusEnum } from 'types/user-status.enum';
import { User } from 'types/user.dto';
import { intl } from 'utilities/i18n/intl.utility';
import { EditUserModal } from './EditUserModal';
import { InviteUserModal } from './InviteUserModal';

const UserManagementTable: FC = () => {
  const [tableData, setTableData] = useState<User[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const loggedUser = useSelector(selectUser);

  const getUsers = async () => {
    try {
      setLoading(true);
      const users = await listUser();
      setTableData(users);
    } catch (error) {
      toast.error('Failed to list users', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleInviteUser = async (userTableData: User) => {
    const user: UserRequestApi = {
      fullName: userTableData.fullName,
      email: userTableData.email.toLowerCase(),
      role: userTableData.role,
    };

    try {
      await createUser(user);
      toast.info(
        intl.translate({
          id: 'The user has been created',
        }),
        {
          position: 'top-center',
        },
      );
      await getUsers();
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleEditButton = (userSelectedRow: User) => {
    setUserToEdit(userSelectedRow);
    setEditUserModalOpen(true);
  };

  const handleEditUser = async (userData: UserEditApi) => {
    try {
      if (userToEdit) {
        await editUser(userToEdit.id, userData);
        toast.success('The user has been updated', {
          position: 'top-center',
        });
        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleDeactivate = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `${intl.translate({
          id: 'Do you want to deactivate',
        })} ${userSelectedRow.email}?`,
      );

      if (result) {
        await deactivateUser(userSelectedRow.id);
        toast.info(
          intl.translate({
            id: 'The user has been deactivated',
          }),
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleActivate = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `${intl.translate({
          id: 'Do you want to activate',
        })} ${userSelectedRow.email}?`,
      );

      if (result) {
        await activateUser(userSelectedRow.id);
        toast.info(
          intl.translate({
            id: 'The user has been activated',
          }),
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleRevoke = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `${intl.translate({
          id: 'Do you want revoke invitation of',
        })} ${userSelectedRow.email}?`,
      );

      if (result) {
        await revokeInvitation(userSelectedRow.id);
        toast.info(
          intl.translate({
            id: 'User invitation has been revoked',
          }),
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleDelete = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `${intl.translate({
          id: 'Do you want to delete',
        })} ${userSelectedRow.email}?`,
      );

      if (result) {
        await deleteUser(userSelectedRow.id);
        toast.info(
          intl.translate({
            id: 'The user has been deleted',
          }),
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const handleResetPassword = async (userSelectedRow: User) => {
    try {
      const result = confirm(
        `${intl.translate({
          id: 'Do you want to send link to reset password to',
        })} ${userSelectedRow.email}?`,
      );

      if (result) {
        const user: UserResetPass = {
          email: userSelectedRow.email,
        };
        await resetUserPassword(user);
        toast.info(
          intl.translate({
            id: 'An email has been sent to reset the password',
          }),
          {
            position: 'top-center',
          },
        );

        await getUsers();
      }
    } catch (error) {
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<User>,
    ): MRT_ColumnDef<User>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === 'email'
              ? validateEmail(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'fullName',
        header: intl.translate({
          id: 'Name',
        }),
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'email',
        header: intl.translate({
          id: 'Email',
        }),
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'email',
        }),
      },
      {
        accessorKey: 'role',
        header: intl.translate({
          id: 'Role',
        }),
        size: 140,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableSorting: false,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: Object.values(UserRoleEnum).map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: 'status',
        header: intl.translate({
          id: 'Status',
        }),
        size: 140,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        muiTableBodyCellEditTextFieldProps: {
          select: true, //change to select for a dropdown
          children: Object.values(UserStatusEnum).map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          )),
        },
      },
      {
        accessorKey: 'lastActive',
        header: intl.translate({
          id: 'Last Active',
        }),
        size: 80,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>()) {
            const dateString = new Date(
              // eslint-disable-next-line react/prop-types
              props.cell.getValue<string>(),
            ).toLocaleDateString();
            return <span>{dateString}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <div style={{ width: '100%' }}>
      <Box mb={2}>
        <Button
          startIcon={<MarkEmailRead />}
          onClick={() => setInviteUserModalOpen(true)}
          variant="outlined"
        >
          {intl.translate({
            id: 'Invite New User',
          })}
        </Button>
      </Box>

      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        columns={columns}
        defaultColumn={{
          minSize: 20,
          maxSize: 160,
          size: 50,
        }}
        data={tableData}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        enableRowActions={
          loggedUser?.role === UserRoleEnum.Admin ||
          loggedUser?.role === UserRoleEnum.SuperAdmin
        }
        enableRowSelection={false}
        enableRowOrdering={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableMultiRowSelection={false}
        positionActionsColumn="last"
        renderRowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              handleEditButton(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            {intl.translate({
              id: 'Edit',
            })}
          </MenuItem>,

          <MenuItem
            key={1}
            onClick={() => {
              handleActivate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status !== UserStatusEnum.Deactivated}
          >
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            {intl.translate({
              id: 'Activate',
            })}
          </MenuItem>,

          <MenuItem
            key={2}
            onClick={() => {
              handleDeactivate(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status !== UserStatusEnum.Active}
          >
            <ListItemIcon>
              <PersonOff />
            </ListItemIcon>
            {intl.translate({
              id: 'Deactivate',
            })}
          </MenuItem>,

          <MenuItem
            key={3}
            onClick={() => {
              handleResetPassword(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status !== UserStatusEnum.Active}
          >
            <ListItemIcon>
              <RotateLeft />
            </ListItemIcon>
            {intl.translate({
              id: 'Reset Password',
            })}
          </MenuItem>,

          <MenuItem
            key={4}
            onClick={() => {
              handleRevoke(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
            disabled={row.original.status !== UserStatusEnum.Pending}
          >
            <ListItemIcon>
              <CancelScheduleSend />
            </ListItemIcon>
            {intl.translate({
              id: 'Revoke Invitation',
            })}
          </MenuItem>,

          <MenuItem
            key={5}
            onClick={() => {
              handleDelete(row.original);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            {intl.translate({
              id: 'Delete',
            })}
          </MenuItem>,
        ]}
        onPaginationChange={setPagination}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        state={{
          isLoading: loading,
          pagination,
          columnVisibility: { id: false },
        }}
      />

      <InviteUserModal
        columns={columns}
        open={inviteUserModalOpen}
        onClose={() => setInviteUserModalOpen(false)}
        onSubmit={handleInviteUser}
      />

      {userToEdit && (
        <EditUserModal
          open={editUserModalOpen}
          userToEdit={userToEdit}
          onClose={() => setEditUserModalOpen(false)}
          onSubmit={handleEditUser}
        />
      )}
    </div>
  );
};

export default UserManagementTable;
