import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  TextareaAutosize,
  Button,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalParagraph } from 'components/Shared/ModalTitles/ModalParagraph';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { validateEmail, validateRequired } from 'helpers/validation.helpers';
import { FC, useState, useEffect } from 'react';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';
import {
  fetchCustomTemplates,
  createTemplate,
} from 'api/customTemplates/customTemplates.api';
import { toast } from 'react-toastify';

export const VerifyModal: FC<{
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string, email: string, language: string) => void;
  verifications: Verification[];
  siteName: string;
}> = ({ open, onClose, onSubmit, verifications, siteName }) => {
  const [values, setValues] = useState<{
    name: string;
    email: string;
    language: string;
    newTemplateName: string;
    newTemplateHTML: string;
  }>({
    name: '',
    email: '',
    language: 'english',
    newTemplateName: '',
    newTemplateHTML: '',
  });
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [templates, setTemplates] = useState<
    { displayName: string; name: string }[]
  >([
    { displayName: 'Generic - English', name: 'english' },
    { displayName: 'Generic - French', name: 'french' },
    { displayName: 'Create New Template', name: 'new' },
  ]);

  useEffect(() => {
    if (verifications.length > 0) {
      const latestVerification = verifications[verifications.length - 1];
      setValues({
        name: latestVerification.name || '',
        email: latestVerification.email || '',
        language: 'english',
        newTemplateName: '',
        newTemplateHTML: '',
      });
    } else {
      setValues({
        name: '',
        email: '',
        language: 'english',
        newTemplateName: '',
        newTemplateHTML: '',
      });
    }
  }, [setValues, verifications]);

  const getTemplates = async () => {
    try {
      const result = await fetchCustomTemplates();
      const mappedResult = result.map((r) => ({
        displayName: r.name,
        name: r.name,
      }));
      mappedResult.unshift(
        { displayName: 'Generic - English', name: 'english' },
        { displayName: 'Generic - French', name: 'french' },
      );
      mappedResult.push({ displayName: 'Create New Template', name: 'new' });
      setTemplates(mappedResult);
    } catch (error) {
      toast.error('Error getting templates', { position: 'top-center' });
    }
  };
  useEffect(() => {
    getTemplates();
  }, []);

  const createNewTemplate = async () => {
    try {
      if (
        validateRequired(values.newTemplateName) &&
        validateRequired(values.newTemplateHTML)
      ) {
        await createTemplate(values.newTemplateName, values.newTemplateHTML);
        await getTemplates();
        setValues({
          ...values,
          language: values.newTemplateName,
        });
      }
    } catch (error) {
      /*empty*/
    }
  };

  const handleSubmit = () => {
    let isValidFormOnSubmit = true;
    let validationErrosOnSubmit: { [cellId: string]: string } = {};

    if (!validateRequired(values.name)) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['name']: 'This field is required',
      };
    }

    if (!validateRequired(values.email)) {
      isValidFormOnSubmit = false;
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['email']: 'This field is required',
      };
    }

    if (!isValidFormOnSubmit) {
      setValidationErrors(validationErrosOnSubmit);
      return;
    }

    onSubmit(values.name, values.email, values.language);
    onClose();
  };
  const validateField = (value: string, fieldtype: string) => {
    const errorMessage: Record<string, string> = {};
    errorMessage[fieldtype] = validateRequired(value)
      ? ''
      : 'The field is required';
    if (fieldtype === 'email') {
      errorMessage[fieldtype] = validateEmail(value)
        ? ''
        : 'A valid email is required';
    }
    setValidationErrors({
      ...validationErrors,
      ...errorMessage,
    });
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <DialogTitle textAlign="left">
        {intl.translate({
          id: 'Send Verification Request',
        })}
        : {siteName}
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <ModalParagraph>
          Send a verification request to the contact below to verify this record
          (and related records):
        </ModalParagraph>
        <ModalParagraph>
          <strong>{siteName}</strong>
        </ModalParagraph>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px' },
              gap: '1.5rem',
              padding: 2,
            }}
          >
            <TextField
              label={"Contact Person's Name"}
              name={'name'}
              required={true}
              error={!!validationErrors['name']}
              helperText={validationErrors['name']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                validateField(e.target?.value, 'name');
                setValues({
                  ...values,
                  name: e.target?.value,
                });
              }}
              value={values.name || ''}
              variant="standard"
            />

            <TextField
              label={"Contact Person's Email"}
              name={'email'}
              required={true}
              error={!!validationErrors['email']}
              helperText={validationErrors['email']}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                validateField(e.target?.value, 'email');
                setValues({
                  ...values,
                  email: e.target?.value,
                });
              }}
              value={values.email || ''}
              variant="standard"
            />
            <TextField
              label={'Language'}
              name={'Language'}
              select
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValues({
                  ...values,
                  language: e.target?.value,
                });
              }}
              value={values.language || ''}
              variant="standard"
            >
              {templates.map((t) => (
                <MenuItem key={t.name} value={t.name}>
                  {t.displayName}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </form>
        {values.language === 'new' && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px' },
                gap: '1.5rem',
                padding: 2,
                border: '1px solid grey',
              }}
            >
              <TextField
                label={'New Template Name'}
                name={'newTemplateName'}
                required={true}
                error={!!validationErrors['newTemplateName']}
                helperText={validationErrors['newTemplateName']}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValues({
                    ...values,
                    newTemplateName: e.target?.value,
                  });
                }}
                value={values.newTemplateName || ''}
                variant="standard"
              />
              <TextareaAutosize
                name={'newTemplateHTML'}
                minRows={3}
                required={true}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setValues({
                    ...values,
                    newTemplateHTML: e.target?.value,
                  });
                }}
                value={values.newTemplateHTML || ''}
              />
              <Button onClick={createNewTemplate}>Save New Template</Button>
            </Stack>
          </form>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>Cancel</CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>Send Verification Request</strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
