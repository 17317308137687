import { Button } from '@mui/material';
import { palette } from 'design/theme/palette';
import { FC } from 'react';

export const FileInputButton: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Button
      color="secondary"
      variant="contained"
      component="label"
      fullWidth
      style={{
        borderRadius: '20px',
        color: palette.grey.main,
      }}
    >
      {children}
    </Button>
  );
};
