import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';
import { Dashboard } from 'views/Dashboard/Dashboard';
import { DataManagement } from 'views/DataManagement/DataManagement';
import { VerifyAgencyEntityView } from 'views/External/VerifyEntity/Agencies/VerifyAgencyEntityView';
import { VerifyResourceEntityView } from 'views/External/VerifyEntity/Resources/VerifyResourceEntityView';
import { VerifySiteEntityView } from 'views/External/VerifyEntity/Sites/VerifySiteEntityView';
import { Login } from 'views/Login/Login';
import { PendingChanges } from 'views/PendingChanges/PendingChanges';
import { SetPassword } from 'views/SetPassword/SetPassoword';
import { UserManagement } from 'views/UserManagement/UserManagement';

export const Router: FC = () => {
  return (
    <Routes>
      <Route path={AppRoute.LogIn} element={<Login />} />
      <Route path={AppRoute.SetPassword} element={<SetPassword />} />
      <Route
        path={AppRoute.UserManagement}
        element={
          <ProtectedRoute>
            <UserManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.DataManagement}
        element={
          <ProtectedRoute>
            <DataManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.PendingChanges}
        element={
          <ProtectedRoute>
            <PendingChanges />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.Dashboard}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={AppRoute.VerifyResource}
        element={<VerifyResourceEntityView />}
      />
      <Route path={AppRoute.VerifySite} element={<VerifySiteEntityView />} />
      <Route
        path={AppRoute.VerifyAgency}
        element={<VerifyAgencyEntityView />}
      />
      <Route
        path="*"
        element={
          <>
            <Text.h2>{intl.translate({ id: 'Error 404' })}</Text.h2>

            <Text.p>
              {intl.translate(
                {
                  id: "We couldn't find what you were looking for. If you wish you can go <a>Data Management</a>",
                },
                {
                  a: (label) => <a href={AppRoute.DataManagement}>{label}</a>,
                },
              )}
            </Text.p>
          </>
        }
      />
    </Routes>
  );
};
