import { ArrowForwardIos } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { createAgency } from 'api/agencies/agencies.api';
import { bulkImport } from 'api/bulk/bulk.api';
import { createResource } from 'api/resources/resources.api';
import { createSite } from 'api/sites/sites.api';
import { createTaxonomy } from 'api/taxonomies/taxonomies.api';
import { palette } from 'design/theme/palette';
import {
  DataManagementEnum,
  getDataManagementString,
} from 'global-constants/data-management';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { BulkImportData } from 'types/bulk-import';
import { DataManagement } from 'types/data-management';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';
import { BulkFinishImportModal } from '../Modals/BulkFinishImportModal/BulkFinishImportModal';
import { BulkImportModal } from '../Modals/BulkImportModal/BulkImportModal';
import { CreateAgenciesModal } from '../Modals/CreateAgenciesModal/CreateAgenciesModal';
import { CreateResourcesModal } from '../Modals/CreateResourcesModal/CreateResourcesModal';
import { CreateSitesModal } from '../Modals/CreateSitesModal/CreateSitesModal';
import { CreateTaxonomiesModal } from '../Modals/CreateTaxonomiesModal/CreateTaxonomiesModal';
import { POSITION } from 'react-toastify/dist/utils';
import { toast } from 'react-toastify';

const StyledActionButton = styled(Button)`
  color: ${palette.primary.main} !important;
  font-weight: 600 !important;
  background-color: ${palette.green.light} !important;
  justify-content: left !important;
  height: 50px !important;
  border-radius: 20px !important;
  & .MuiButton-endIcon {
    position: absolute;
    right: 20px;
  }
`;

type CreationSectionProps = {
  selectedType: DataManagementEnum;
};

const CreationSection: FC<CreationSectionProps> = ({ selectedType }) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [bulkFinishImportModal, setBulkFinishImportModal] = useState(false);
  const [bulkFinishImportData, setBulkFinishImportData] =
    useState<BulkImportData | null>(null);

  const handleCreateIndividualClick = () => {
    setCreateModalOpen(!createModalOpen);
  };

  const handleImportClick = () => {
    setImportModalOpen(!importModalOpen);
  };

  const handleCloseModal = () => {
    setCreateModalOpen(false);
  };

  const handleCloseBulkImportModal = () => {
    setImportModalOpen(false);
  };

  const handleCloseBulkFinishImportModal = () => {
    setBulkFinishImportModal(false);
  };

  const handleCreateSubmit = async (submitData: DataManagement) => {
    try {
      switch (selectedType) {
        case DataManagementEnum.Resources:
          logger.info('Create new resource');

          if (submitData.Resource) {
            submitData.Resource.siteIds = submitData.SelectedSiteIds;
            submitData.Resource.agencyId = submitData.SelectedAgencyId;
            submitData.Resource.taxonomyIds = submitData.SelectedTaxonomyIds;
            await createResource(submitData.Resource);
          }
          break;
        case DataManagementEnum.Sites:
          logger.info('Create new site');
          if (submitData.Site) {
            submitData.Site.agencyId = submitData.SelectedAgencyId;
            await createSite(submitData.Site);
          }
          break;
        case DataManagementEnum.Agencies:
          if (submitData.Agency) {
            await createAgency(submitData.Agency);
          }
          logger.info('Create new agency');
          break;
        case DataManagementEnum.Taxonomies:
          logger.info('Create new taxonomy');
          if (submitData.Taxonomy) {
            await createTaxonomy(submitData.Taxonomy);
          }
          break;
      }
    } catch (error) {
      toast.error('An error occurred while creating', {
        position: 'top-center',
      });
    }
  };

  const handleBulkImportModalSubmit = (result: BulkImportData) => {
    setBulkFinishImportData(result);
    setBulkFinishImportModal(true);
  };

  const handleBulkFinishImportModalSubmit = async (data: BulkImportData) => {
    try {
      if (data.ResourcesFile) {
        await bulkImportFile(
          getDataManagementString(DataManagementEnum.Resources),
          data.ResourcesFile,
        );
      }
      if (data.SitesFile) {
        await bulkImportFile(
          getDataManagementString(DataManagementEnum.Sites),
          data.SitesFile,
        );
      }
      if (data.AgenciesFile) {
        await bulkImportFile(
          getDataManagementString(DataManagementEnum.Agencies),
          data.AgenciesFile,
        );
      }
      if (data.TaxonomiesFile) {
        await bulkImportFile(
          getDataManagementString(DataManagementEnum.Taxonomies),
          data.TaxonomiesFile,
        );
      }
    } catch (error) {
      toast.error('Failed to import', { position: 'top-center' });
    }
    // Create entities in bulk

    setBulkFinishImportModal(false);
  };

  const bulkImportFile = async (dataType: string, file: File) => {
    try {
      await bulkImport(dataType, file);
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error(error);
      }
    }
  };

  const renderCreationModal = () => {
    switch (selectedType) {
      case DataManagementEnum.Resources:
        return (
          <CreateResourcesModal
            open={createModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleCreateSubmit}
          />
        );
      case DataManagementEnum.Sites:
        return (
          <CreateSitesModal
            open={createModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleCreateSubmit}
          />
        );
      case DataManagementEnum.Agencies:
        return (
          <CreateAgenciesModal
            open={createModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleCreateSubmit}
          />
        );
      case DataManagementEnum.Taxonomies:
        return (
          <CreateTaxonomiesModal
            open={createModalOpen}
            onClose={handleCloseModal}
            onSubmit={handleCreateSubmit}
          />
        );
    }
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <StyledActionButton
          variant="contained"
          endIcon={<ArrowForwardIos />}
          onClick={handleCreateIndividualClick}
        >
          {intl.translate({
            id: 'Create Individual Record',
          })}
        </StyledActionButton>
        <StyledActionButton
          variant="contained"
          endIcon={<ArrowForwardIos />}
          onClick={handleImportClick}
        >
          {intl.translate({
            id: 'Bulk Upload Records',
          })}
        </StyledActionButton>
      </Stack>

      {renderCreationModal()}
      <BulkImportModal
        open={importModalOpen}
        onClose={handleCloseBulkImportModal}
        onSubmit={handleBulkImportModalSubmit}
      />
      {bulkFinishImportData && (
        <BulkFinishImportModal
          open={bulkFinishImportModal}
          onClose={handleCloseBulkFinishImportModal}
          onSubmit={handleBulkFinishImportModalSubmit}
          data={bulkFinishImportData}
        />
      )}
    </>
  );
};

export default CreationSection;
