import React, { FC, useCallback, useEffect, useState } from 'react';
import { Update, Add, Remove } from '@mui/icons-material';
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  MenuItem,
  Grid,
  Divider,
  IconButton,
} from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { intl } from 'utilities/i18n/intl.utility';

import countries from 'assets/constants/countries';
import citiesByProvince from 'assets/constants/cities-by-province';
import provinces from 'assets/constants/provinces';

import AccessibilityForm from 'components/Form/References/AccessibilityForm';
import OperationSiteForm from 'components/Form/Sections/OperationSiteForm';
import PhoneNumbersForm from 'components/Form/Sections/PhoneNumbersForm';

import { BoxContent } from '../ModalContent/BoxContent';
import {
  newOperationSite,
  newPhoneNumbers,
  isValidEnFr,
} from 'helpers/sites.helpers';
import { PhoneNumber, OperationSite, Contact } from 'types/data-management';
import { Site } from 'types/entities/sites';
import { YesNoEnum } from 'types/utilities';

type StagedSiteFormProps = {
  columns: MRT_ColumnDef<Site>[];
  values: Site;
  setValues: (values: Site) => void;
  validationErrors: { [cellId: string]: string };
  changedProperties?: string[];
};

const newContact: Contact = {
  type: '',
  name: '',
  title: '',
  phone: '',
  email: '',
};

const ContactsForm: FC<{
  column: MRT_ColumnDef<Omit<Site, 'operations'>>;
  values: Site;
  setValues: (values: Site) => void;
}> = ({ column, values, setValues }) => {
  if (!column.accessorKey) {
    return null;
  }

  const [contacts, setContacts] = useState(
    (values[column.accessorKey] as unknown[] as Contact[])?.length > 0
      ? (values[column.accessorKey] as unknown[] as Contact[])
      : [{ ...newContact }],
  );

  const handleAddContact = () => {
    const arrayWithNewItem = [...contacts, { ...newContact }];
    setContacts(arrayWithNewItem);
  };

  const handleRemoveContact = (index: number) => {
    const arrayRemovedInex = [...contacts];
    arrayRemovedInex.splice(index, 1);
    setContacts(arrayRemovedInex);
  };

  return (
    <Grid container>
      {contacts.map((mailingAddress, index) => (
        <React.Fragment key={index}>
          <Grid item xs={10}>
            <TextField
              label={'Type'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          type: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.type || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Add item"
              component="label"
              onClick={handleAddContact}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              color="primary"
              aria-label="Remove Item"
              component="label"
              onClick={() => handleRemoveContact(index)}
            >
              <Remove />
            </IconButton>
          </Grid>

          <Grid item xs={10}>
            <TextField
              label={'Name'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          name: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.name || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Title'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          title: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.title || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Phone'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          phone: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.phone || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              label={'Email'}
              name={column.accessorKey}
              onChange={(e) => {
                setContacts((prev) =>
                  prev.map((x, i) =>
                    i === index
                      ? {
                          ...contacts[index],
                          email: e.target?.value,
                        }
                      : x,
                  ),
                );

                setValues({
                  ...values,
                  [e.target.name]: contacts,
                });
              }}
              value={mailingAddress.email || ''}
              variant="standard"
              fullWidth={true}
            />
          </Grid>

          {index !== contacts.length - 1 && (
            <Grid item mt={2} mb={1} xs={12}>
              <Divider />
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

const renderCountryDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
  isRequired?: boolean,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      required={isRequired}
      // error={!!validationErrors[column.accessorKey]}
      // helperText={validationErrors[column.accessorKey]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {countries.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderProvinceDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {provinces.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </TextField>
  );
};

const renderCityDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      select
      label={column.header}
      name={column.accessorKey}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    >
      {values.locationProvince ? (
        citiesByProvince
          .filter((city) => city.province === values.locationProvince)
          .map((city, index) => (
            <MenuItem key={index} value={city.city}>
              {city.city}
            </MenuItem>
          ))
      ) : (
        <MenuItem value={''}>
          {intl.translate({
            id: 'Select a province first',
          })}
        </MenuItem>
      )}
    </TextField>
  );
};

const renderNumberTextField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
  isRequired?: boolean,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      type="number"
      required={isRequired}
      // error={!!validationErrors[column.accessorKey]}
      // helperText={validationErrors[column.accessorKey]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        });
      }}
      value={values[column.accessorKey]}
      variant="standard"
    />
  );
};

const renderYesNoDropdownField = (
  column: MRT_ColumnDef<Omit<Site, 'operations'>>,
  values: Site,
  setValues: (values: Site) => void,
) => {
  if (!column.accessorKey) {
    return null;
  }

  return (
    <TextField
      key={column.accessorKey}
      label={column.header}
      name={column.accessorKey}
      select
      onChange={(e) =>
        setValues({
          ...values,
          [e.target.name]: e.target?.value,
        })
      }
      value={values[column.accessorKey] || YesNoEnum.No}
      variant="standard"
      fullWidth={true}
    >
      {Object.values(YesNoEnum).map((item) => (
        <MenuItem key={item} value={item}>
          {item === YesNoEnum.Yes ? 'Yes' : 'No'}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const StagedSiteForm: FC<StagedSiteFormProps> = ({
  columns,
  values,
  setValues,
  validationErrors,
  changedProperties,
}) => {
  /**
   * Properties to verify:
   * - Name Details
   * - Location
   * - Transportation
   * - Wheelchair Accessibility
   * - Social Media
   * - Phone Numbers
   * - Hours of Operations
   * - Main Contact
   */

  // references
  const [accessibility, setAccessibilityId] = useState<string>(
    isValidEnFr(values.accessibility) ? values.accessibility.objectId : '',
  );

  // arrays
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>(
    values.phoneNumbers !== null && typeof values.phoneNumbers !== 'string'
      ? values.phoneNumbers
      : [{ ...newPhoneNumbers }],
  );
  const [operations, setOperations] = useState<OperationSite>(
    values.operations && typeof values.operations !== 'string'
      ? values.operations
      : { ...newOperationSite },
  );

  const getColumnByAccessorKey = useCallback(
    (accessorKey: string) => {
      return columns.find(
        (x) => x.accessorKey === accessorKey,
      ) as MRT_ColumnDef<Omit<Site, 'operations'>>;
    },
    [columns],
  );

  useEffect(() => {
    setValues({
      ...values,
      accessibility,
      phoneNumbers,
      operations: operations,
    });
    // disable warning as we only want to trigger update for accessibilities
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibility, phoneNumbers, operations]);

  const renderTextField = (
    column: MRT_ColumnDef<Omit<Site, 'operations'>>,
    values: Site,
    setValues: (values: Site) => void,
    isRequired?: boolean,
  ) => {
    if (!column?.accessorKey) {
      return null;
    }

    if (changedProperties && changedProperties.includes(column.accessorKey)) {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={isRequired}
          error={!!validationErrors[column.accessorKey]}
          helperText={validationErrors[column.accessorKey]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValues({
              ...values,
              [e.target.name]: e.target?.value,
            });
          }}
          value={values[column.accessorKey]}
          variant="filled"
          color="success"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Update />
              </InputAdornment>
            ),
          }}
          focused
        />
      );
    }

    return (
      <TextField
        key={column.accessorKey}
        label={column.header}
        name={column.accessorKey}
        required={isRequired}
        error={!!validationErrors[column.accessorKey]}
        helperText={validationErrors[column.accessorKey]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValues({
            ...values,
            [e.target.name]: e.target?.value,
          });
        }}
        value={values[column.accessorKey]}
        variant="standard"
      />
    );
  };

  return (
    <Stack
      sx={{
        width: '100%',
        minWidth: { xs: '300px', sm: '360px', md: '400px' },
        gap: '1.5rem',
        padding: 2,
      }}
    >
      <BoxContent
        title={intl.translate({
          id: 'Name Details',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('nameEN'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameFR'),
            values,
            setValues,
            true,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsEnOfficial'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsFrOfficial'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsEnAlternate'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('nameDetailsFrAlternate'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>
      <BoxContent
        title={intl.translate({
          id: 'Location',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('locationAddress1'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('locationAddress2'),
            values,
            setValues,
          )}
          {renderCountryDropdownField(
            getColumnByAccessorKey('locationCountry'),
            values,
            setValues,
          )}
          {renderProvinceDropdownField(
            getColumnByAccessorKey('locationProvince'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('locationCounty'),
            values,
            setValues,
          )}
          {renderCityDropdownField(
            getColumnByAccessorKey('locationCity'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('locationDescription'),
            values,
            setValues,
          )}
          {renderNumberTextField(
            getColumnByAccessorKey('locationLongitude'),
            values,
            setValues,
          )}
          {renderNumberTextField(
            getColumnByAccessorKey('locationLatitude'),
            values,
            setValues,
          )}
          {renderYesNoDropdownField(
            getColumnByAccessorKey('isLocationPrivate'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>
      <BoxContent
        title={intl.translate({
          id: 'Transportation',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '50ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('transportationEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('transportationFr'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>
      <BoxContent
        title={intl.translate({
          id: 'Accessibility',
        })}
      >
        <AccessibilityForm
          value={accessibility}
          setValue={setAccessibilityId}
          currentValue={
            values.accessibility !== '' ? values.accessibility : accessibility
          }
        />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Social Media',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(getColumnByAccessorKey('email'), values, setValues)}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '49ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('websiteEn'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('websiteFr'),
            values,
            setValues,
          )}
        </Box>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {renderTextField(
            getColumnByAccessorKey('socialFacebook'),
            values,
            setValues,
          )}
          {renderTextField(
            getColumnByAccessorKey('socialInstagram'),
            values,
            setValues,
          )}
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Phone Numbers',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          <PhoneNumbersForm value={phoneNumbers} setValue={setPhoneNumbers} />
        </Box>
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Hours of Operation',
        })}
      >
        <OperationSiteForm value={operations} setValue={setOperations} />
      </BoxContent>

      <BoxContent
        title={intl.translate({
          id: 'Main Contact Information',
        })}
      >
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          <ContactsForm
            column={getColumnByAccessorKey('contacts')}
            values={values}
            setValues={setValues}
          />
        </Box>
      </BoxContent>
    </Stack>
  );
};
