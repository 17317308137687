export const getPreviousYearDate = (
  dateString: Date | string,
  years_back = 1,
) => {
  const date = new Date(dateString);
  return new Date(date.setFullYear(date.getFullYear() - years_back))
    .toISOString()
    .substring(0, 10);
};

export const getDateString = (date: Date | string): string => {
  return new Date(date).toISOString().substring(0, 10);
};
export const getFullYear = (date: Date | string): string => {
  return new Date(date).getFullYear().toString();
};
