import { Box, Collapse, IconButton } from '@mui/material';
import { ExpandLessIcon } from 'design/Icon/ExpandLessIcon';
import { ExpandMoreIcon } from 'design/Icon/ExpandMoreIcon';
import { Paragraph } from 'design/Text/Paragraph';
import { FC, useState } from 'react';
import styled from 'styled-components';

const StyledContainerDiv = styled.div`
  width: 102ch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledLeftDiv = styled.div`
  align-self: flex-start;
`;

const StyledRightDiv = styled.div`
  align-self: flex-end;
`;

const StyledParagraph = styled(Paragraph)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.black.main};
`;

const StyledContentBox = styled(Box)`
  background: rgba(0, 0, 0, 0.06);
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  width: 104ch;
`;

export const BoxContent: FC<{
  children: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      <StyledContainerDiv>
        <StyledLeftDiv>
          <StyledParagraph>{title}</StyledParagraph>
        </StyledLeftDiv>
        <StyledRightDiv>
          <IconButton
            onClick={() => setChecked(!checked)}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={checked ? 'collapsed' : 'collapse menu'}
            aria-expanded={checked ? 'true' : false}
            ariab-aria-label={checked ? 'collapse' : 'expand'}
          >
            {checked ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </StyledRightDiv>
      </StyledContainerDiv>
      <StyledContentBox>
        <Collapse in={checked}>{children}</Collapse>
      </StyledContentBox>
    </>
  );
};
