/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Tab, Tabs } from '@mui/material';
import { updateAgency } from 'api/agencies/agencies.api';
import {
  approveAgencyByVerifier,
  getOriginalAgencyById,
  getAgencyById as getStagedAgencyById,
  updateAgency as updateStagedAgency,
  updateStatusAgencyToPendingApproval,
} from 'api/stagedAgencies/stagedAgencies.api';
import { updateVerifications } from 'api/verifications/verifications.api';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { ModalBottomSection } from 'components/Shared/ModalContent/ModalBottomSection';

import { VerificationsForm } from 'components/Shared/SharedForms/VerificationsForm';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { palette } from 'design/theme/palette';
import { getChangedProperties } from 'helpers/agencies.helpers';
import { useAgencies } from 'hooks/useAgencies';
import { useVerifications } from 'hooks/useVerifications';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Agency } from 'types/entities/agencies';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';
import { ModalTitle } from 'components/Shared/ModalTitles/ModalTitle';
import { Text } from 'design';
import { AgencyForm } from 'components/Shared/SharedForms/AgencyForm';

const StyledLeftTab = styled(Tab)`
  padding: 0 0 0 2 !important;
  margin: 0 !important;
  align-items: baseline !important;
`;

export const AgenciesForm: FC<{
  token: string;
  agency: Agency;
  onSubmit: () => void;
}> = ({ token, agency, onSubmit }) => {
  const { columns } = useAgencies();
  const { columns: verificationColumns } = useVerifications();

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const [agenciesFormValues, setAgenciesFormValues] = useState<Agency>(agency);
  const [verificationFormValues, setVerificationFormValues] =
    useState<Verification>(() =>
      verificationColumns.reduce((acc, column) => {
        acc[column.accessorKey || ''] = '';
        return acc;
      }, {} as any),
    );
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSubmit = async () => {
    try {
      const originalAgency = await getOriginalAgencyById(
        agenciesFormValues.objectId,
        token,
      );
      const changedProperties = getChangedProperties(
        originalAgency,
        agenciesFormValues,
      );
      await updateStagedAgency(agenciesFormValues.objectId, {
        ...agenciesFormValues,
        lastVerifiedOn: new Date(),
      });

      if (changedProperties.length === 0) {
        await approveAgencyByVerifier(agenciesFormValues.objectId, token);
      } else {
        await updateStatusAgencyToPendingApproval(
          agenciesFormValues.objectId,
          token,
        );
      }

      const objectId =
        verificationFormValues?.objectId ||
        (Array.isArray(agenciesFormValues.verifications) &&
          agenciesFormValues.verifications.length > 0)
          ? agenciesFormValues.verifications[
              agenciesFormValues.verifications.length - 1
            ]._id || ''
          : '';
      const verificationValues = verificationFormValues?.objectId
        ? verificationFormValues
        : Array.isArray(agenciesFormValues.verifications) &&
          agenciesFormValues.verifications.length > 0
        ? agenciesFormValues.verifications[
            agenciesFormValues.verifications.length - 1
          ]
        : verificationFormValues;
      // Mark external form submission date
      verificationValues['completionDate'] = new Date();
      // Set changed properties
      verificationValues['updatedPropertyNames'] = changedProperties;
      verificationValues['hasVerified'] = Boolean(
        verificationValues['hasVerified'],
      );
      await updateVerifications(objectId || '', verificationValues);
      onSubmit();
    } catch (err) {
      return;
    }
  };

  const getAgencyDataFromDB = async (agencyId: string) => {
    if (!agencyId) {
      return;
    }
    try {
      const agency = await getStagedAgencyById(agencyId, token, true);
      if (agency) {
        setAgenciesFormValues(agency);
        toast.info('Properties were successfully reset', {
          position: 'top-center',
        });
      }
    } catch (err) {
      toast.error('Failed to get agency by the provided ID', {
        position: 'top-center',
      });
    }
  };

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '60vw',
        paddingTop: '2rem',
      }}
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <ModalTitle>
          <Text.h1>Verify Agency</Text.h1>
        </ModalTitle>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            '& .MuiTabs-indicator': {
              backgroundColor: palette.black.main,
              left: 0,
              width: '4px',
            },
            '& .Mui-selected': {
              color: `${palette.black.main} !important`,
            },
            height: '80vh',
            '&  #tabpanel-0': {
              marginLeft: '100px',
            },
            '&  #tabpanel-1': {
              marginLeft: '100px',
            },
            '&  #tabpanel-2': {
              marginLeft: '100px',
            },
            '&  #tabpanel-3': {
              marginLeft: '100px',
            },
            overflowY: 'scroll',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleChange}
            aria-label="Vertical tabs"
            sx={{ position: 'fixed' }}
          >
            <StyledLeftTab label="Agency" {...a11yProps(0)} />
            <StyledLeftTab label="Verification" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            <AgencyForm
              showLabel={false}
              columns={columns}
              values={agenciesFormValues}
              setValues={setAgenciesFormValues}
              validationErrors={validationErrors}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <VerificationsForm
              objectId={agenciesFormValues.objectId}
              original={agency.verifications}
              columns={verificationColumns}
              values={verificationFormValues}
              setValues={setVerificationFormValues}
            />
          </TabPanel>
        </Box>

        <Box
          sx={{
            paddingTop: '2rem',
          }}
        >
          <ModalBottomSection showUpdatedLabel={false}>
            <CancelButton
              handleClick={() => {
                getAgencyDataFromDB(agency.objectId);
              }}
            >
              <strong>
                {intl.translate({
                  id: 'Reset properties',
                })}
              </strong>
            </CancelButton>

            <ConfirmButton handleClick={handleSubmit}>
              <strong>
                {intl.translate({
                  id: 'Verify',
                })}
              </strong>
            </ConfirmButton>
          </ModalBottomSection>
        </Box>
      </form>
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-modal-tab-${index}`,
    'aria-controls': `vertical-modal-tabpanel-${index}`,
  };
};
