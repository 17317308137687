import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Button } from 'design';
import { RestartIcon } from 'design/Icon/RestartIcon';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledSearchButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.white.main} !important;
  color: ${({ theme }) => theme.palette.black.main} !important;
`;

const TableSearch: FC<{
  onSubmit: (type: string, value: string) => void;
  reset: () => void;
  onlyNameSearch?: boolean;
  taxonomySearch?: boolean;
}> = ({ onSubmit, reset, onlyNameSearch, taxonomySearch }) => {
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const [select, setSelect] = useState('name');
  const [search, setSearch] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value);
  };

  const handleSubmit = () => {
    let validationErrosOnSubmit: { [cellId: string]: string } = {};

    if (!select) {
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['select']: 'This field is required',
      };
    }

    if (!search) {
      validationErrosOnSubmit = {
        ...validationErrosOnSubmit,
        ['search']: 'This field is required',
      };
    }

    setValidationErrors(validationErrosOnSubmit);

    if (!select || !search) return;

    onSubmit(select, search);
  };

  const handleReset = () => {
    setSelect('');
    setSearch('');
    reset();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Grid item>
        <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
          <InputLabel id="select-label">
            {intl.translate({
              id: 'Select',
            })}
          </InputLabel>
          <Select
            id="select"
            label={intl.translate({
              id: 'Select',
            })}
            labelId="select-label"
            value={select}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'name'}>Name</MenuItem>
            {!onlyNameSearch && (
              <MenuItem value={'location'}>Location - Primary City</MenuItem>
            )}
            {!onlyNameSearch && (
              <MenuItem value={'locationProvince'}>
                Location - Primary Province
              </MenuItem>
            )}
            {!onlyNameSearch && (
              <MenuItem value={'taxonomyCode'}>Taxonomy Code</MenuItem>
            )}

            {taxonomySearch && (
              <MenuItem value={'taxonomyCode'}>Taxonomy Code</MenuItem>
            )}
          </Select>
          <FormHelperText>{validationErrors['select']}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
          <TextField
            id="search"
            label={intl.translate({
              id: 'Search',
            })}
            value={search}
            onChange={(e) => setSearch(e.target?.value)}
            variant="standard"
            error={!!validationErrors['search']}
          />
          <FormHelperText>{validationErrors['search']}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <StyledSearchButton onClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Search',
            })}
          </strong>
        </StyledSearchButton>
      </Grid>
      <Grid item>
        <IconButton
          title="Reset form"
          size="small"
          sx={{ ml: 2 }}
          onClick={handleReset}
          aria-label="Restart button"
        >
          <RestartIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TableSearch;
