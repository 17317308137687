import { Box, MenuItem, TextField, Theme, useTheme } from '@mui/material';
import { fetchHowToAccessSupport } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { FC, useEffect, useState } from 'react';
import { HowToAccessSupport } from 'types/data-management';
import { intl } from 'utilities/i18n/intl.utility';

const getStyles = (
  selectedReference: string,
  references: string[],
  theme: Theme,
) => {
  return {
    fontWeight:
      references.indexOf(selectedReference) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
    color:
      references.indexOf(selectedReference) === -1
        ? theme.palette.common.black
        : theme.palette.info.light,
  };
};

const emptyHowToAccessSupport: HowToAccessSupport = {
  name: '',
  en: '',
  fr: '',
  createdAt: '',
  updatedAt: '',
  objectId: '',
};

const HowToAccessSupportForm: FC<{
  value: string[];
  setValue: (values: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValues: any[];
}> = ({ value, setValue, currentValues }) => {
  const theme = useTheme();
  const [howToAccessSupport, setHowToAccessSupport] = useState([
    { ...emptyHowToAccessSupport },
  ]);
  const [selectedHowToAccessSupport, setSelectedHowToAccessSupport] =
    useState(currentValues);

  useEffect(() => {
    const getHowToAccessSupport = async () => {
      try {
        const howToAccessSupportResponse = await fetchHowToAccessSupport();
        setHowToAccessSupport(howToAccessSupportResponse);
      } catch (err) {
        toast.error('Error getting how to access support.', {
          position: 'top-center',
        });
        setHowToAccessSupport([{ ...emptyHowToAccessSupport }]);
      }
    };

    getHowToAccessSupport();
  }, []);

  useEffect(() => {
    setSelectedHowToAccessSupport(
      howToAccessSupport.filter((item) => value?.includes(item.objectId)) || {
        ...emptyHowToAccessSupport,
      },
    );
  }, [value, howToAccessSupport]);

  const handleChangeDropDownSites = (value: string) => {
    setValue(typeof value === 'string' ? value.split(',') : value);
  };

  try {
    return (
      <>
        <Box
          sx={{
            '& > :not(style)': { m: 1, width: '102ch' },
          }}
        >
          {' '}
          {value && (
            <TextField
              label={intl.translate({
                id: 'How To Access Support',
              })}
              select
              SelectProps={{
                multiple: true,
              }}
              onChange={(e) => handleChangeDropDownSites(e.target?.value)}
              value={value?.length > 0 ? value : []}
              variant="standard"
              fullWidth={true}
            >
              {howToAccessSupport?.map((reference) => (
                <MenuItem
                  key={
                    reference.objectId ||
                    reference?._id ||
                    `${String(new Date().getTime())}${Math.random()}`
                  }
                  value={reference?.objectId || reference?._id || ''}
                  style={getStyles(
                    reference.objectId || (reference?._id as string),
                    value,
                    theme,
                  )}
                >
                  {`En: ${reference.en}[Fr: ${reference.fr}]`}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        {selectedHowToAccessSupport?.length > 0 &&
          selectedHowToAccessSupport?.map((reference) => (
            <Box
              sx={{
                '& > :not(style)': { m: 1, width: '50ch' },
              }}
              key={
                reference?.objectId ||
                `${String(new Date().getTime())}${Math.random()}`
              }
            >
              <TextField
                label={intl.translate({
                  id: 'En',
                })}
                value={reference?.en || ''}
                disabled
                variant="standard"
              />
              <TextField
                label={intl.translate({
                  id: 'Fr',
                })}
                value={reference?.fr || ''}
                disabled
                variant="standard"
              />
            </Box>
          ))}
      </>
    );
  } catch (e) {
    console.log('e: ', e);
    return <></>;
  }
};

export default HowToAccessSupportForm;
