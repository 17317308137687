import { Box, MenuItem, TextField } from '@mui/material';
import { fetchTargetPopulations } from 'api/references/references.api';
import { toast } from 'components/Toast/Toast';
import { newTargetPopulations } from 'helpers/resources.helpers';
import { FC, useEffect, useState } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const TargetPopulationForm: FC<{
  value: string[];
  setValue: (values: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentValues: any[];
}> = ({ value, setValue, currentValues }) => {
  const [targetPopulations, setTargetPopulations] = useState([
    { ...newTargetPopulations },
  ]);
  const [selectedTargetPopulations, setSelectedTargetPopulations] =
    useState(currentValues);

  useEffect(() => {
    const getTargetPopulations = async () => {
      try {
        const targetPopulationsResponse = await fetchTargetPopulations();
        setTargetPopulations(targetPopulationsResponse);
      } catch (err) {
        toast.error(
          'Error getting target population options. Please, try again.',
          {
            position: 'top-center',
          },
        );
        setTargetPopulations([{ ...newTargetPopulations }]);
      }
    };

    getTargetPopulations();
  }, []);

  useEffect(() => {
    setSelectedTargetPopulations(
      targetPopulations.filter((item) => value?.includes(item?.objectId)) || [
        { ...newTargetPopulations },
      ],
    );
  }, [value, targetPopulations]);

  const handleChangeDropDownSites = (value: string) => {
    setValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'Target Populations',
          })}
          select
          SelectProps={{
            multiple: true,
          }}
          onChange={(e) => handleChangeDropDownSites(e.target?.value)}
          value={value?.length > 0 ? value : []}
          variant="standard"
          fullWidth={true}
        >
          {targetPopulations?.map((reference) => (
            <MenuItem
              key={
                reference?.objectId ||
                reference?._id ||
                `${String(new Date())}-${Math.random()}`
              }
              value={reference?.objectId || reference?._id || ''}
              // style={getStyles(reference?.objectId, value, theme)}
            >
              {`En: ${reference?.en}[Fr: ${reference?.fr}]`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {selectedTargetPopulations?.length > 0 &&
        selectedTargetPopulations?.map((item) => (
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '50ch' },
            }}
            key={item?.objectId || `${String(new Date())}-${Math.random()}`}
          >
            <TextField
              label={intl.translate({
                id: 'En',
              })}
              value={item?.en || ''}
              disabled
              variant="standard"
            />
            <TextField
              label={intl.translate({
                id: 'Fr',
              })}
              value={item?.fr || ''}
              disabled
              variant="standard"
            />
          </Box>
        ))}
    </>
  );
};

export default TargetPopulationForm;
