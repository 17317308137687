import { Check, Close, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import { getAgencyById } from 'api/agencies/agencies.api';
import {
  approveAgency,
  fetchStagedAgencies,
  rejectAgency,
  updateAgency,
} from 'api/stagedAgencies/stagedAgencies.api';
import { updateVerifications } from 'api/verifications/verifications.api';
import {
  getChangedProperties,
  tableHiddenColumnsInitialState,
} from 'helpers/agencies.helpers';
import { useAgencies } from 'hooks/useAgencies';
import MaterialReactTable, { MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { Agency } from 'types/entities/agencies';
import { intl } from 'utilities/i18n/intl.utility';
import { EditModal } from './Modals/EditModal/EditModal';
import { toast } from 'react-toastify';

const PendingChangesAgenciesTable: FC = () => {
  const [tableData, setTableData] = useState<Agency[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<MRT_Row<Agency>>();
  const [changedProperties, setChangedProperties] = useState<string[]>([]);

  const { columns } = useAgencies();

  const getStagedAgences = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const result = await fetchStagedAgencies(pageIndex + 1, pageSize);

      setRowCount(result.length);
      setTableData(result);
    } catch (error) {
      toast.error('Error fetching Agencies', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStagedAgences(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleEditRow = async (row: MRT_Row<Agency>) => {
    // get original agency
    try {
      const originalAgency = await getAgencyById(row.original.objectId);
      setChangedProperties(getChangedProperties(originalAgency, row.original));

      setRowToEdit(row);
      setEditModalOpen(true);
    } catch (err) {
      toast.error('Failed to get agency by the provided ID', {
        position: 'top-center',
      });
    }
  };

  const handleSaveRowEdits = async (rowIndex: number, values: Agency) => {
    // clean edit modal
    try {
      setRowToEdit(undefined);

      await updateAgency(values.objectId, values);

      // save item to pending changes
      tableData[rowIndex] = values;
      setTableData([...tableData]);
    } catch (error) {
      /*empty*/
    }
  };

  const handleApproveClick = async (row: MRT_Row<Agency>) => {
    try {
      await approveAgency(row.original.objectId);
      const verificationObjectId =
        row.original.verifications && row.original.verifications.length > 0
          ? row.original.verifications[row.original.verifications.length - 1]
              .objectId || row.original.objectId
          : row.original.objectId;
      await updateVerifications(verificationObjectId, {
        internalConfirmationDate: new Date(),
        hasVerified: true,
        updatedPropertyNames: changedProperties,
      });
      setTableData(
        tableData.filter((item) => item.objectId !== row.original.objectId),
      );
    } catch (err) {
      return;
    }
  };

  const handleRejectClick = async (row: MRT_Row<Agency>) => {
    try {
      await rejectAgency(row.original.objectId);
      const verificationObjectId =
        row.original.verifications && row.original.verifications.length > 0
          ? row.original.verifications[row.original.verifications.length - 1]
              .objectId || ''
          : '';
      await updateVerifications(verificationObjectId, {
        hasVerified: false,
        internalConfirmationDate: new Date(),
        updatedPropertyNames: changedProperties,
      });
      setTableData(
        tableData.filter((item) => item.objectId !== row.original.objectId),
      );
    } catch (err) {
      return;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        columns={columns}
        enableColumnResizing
        defaultColumn={{
          minSize: 20,
          maxSize: 300,
          size: 160,
        }}
        data={tableData}
        enableColumnOrdering
        enableEditing
        enableHiding
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '0rem',
              justifyContent: 'center',
            }}
          >
            <Tooltip arrow title="Confirm">
              <IconButton
                color="success"
                size="small"
                onClick={() => handleApproveClick(row)}
                aria-label="confirm request"
              >
                <Check />
              </IconButton>
            </Tooltip>

            <Tooltip arrow title="Deny">
              <IconButton
                color="error"
                size="small"
                onClick={() => handleRejectClick(row)}
                aria-label="deny request"
              >
                <Close />
              </IconButton>
            </Tooltip>
            <Tooltip
              arrow
              title={intl.translate({
                id: 'View request',
              })}
              aria-label="view request"
            >
              <IconButton
                aria-label="view button"
                onClick={() => handleEditRow(row)}
                size="small"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50, 100],
        }}
        initialState={{
          density: 'compact',
          columnVisibility: { ...tableHiddenColumnsInitialState },
        }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading: loading,
          pagination,
        }}
      />

      {rowToEdit && (
        <EditModal
          columns={columns}
          row={rowToEdit}
          changedProperties={changedProperties}
          open={editModalOpen}
          onClose={() => {
            setRowToEdit(undefined);
            setEditModalOpen(false);
          }}
          onSubmit={handleSaveRowEdits}
        />
      )}
    </div>
  );
};

export default PendingChangesAgenciesTable;
