import { MRT_Cell, MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { Verification } from 'types/entities/verifications';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

export const useVerifications = () => {
  const getCommonDateCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const date = cell.getValue<Date>();

    return date ? new Date(date).toLocaleDateString() : '';
  };

  const getBooleanCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    const value = cell.getValue<boolean>() || false;
    return value ? 'Yes' : 'No';
  };

  const getListCellProps = <T extends object>(cell: MRT_Cell<T>) => {
    try {
      const value = cell.getValue<string>();
      return (
        <textarea
          disabled
          style={{
            background: 'inherit',
            height: '50px',
            border: 'none',
          }}
          value={value || ''}
        ></textarea>
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        logger.error(e);
      }
      return null;
    }
  };

  const columns = useMemo<MRT_ColumnDef<Verification>[]>(
    () => [
      {
        accessorKey: '_id',
        header: intl.translate({
          id: 'Object ID',
        }),
        enableEditing: false,
        enableHiding: false,
      },
      {
        accessorKey: 'name',
        header: intl.translate({
          id: 'Contact Name',
        }),
      },
      {
        accessorKey: 'email',
        header: intl.translate({
          id: 'Contact Email',
        }),
      },
      {
        accessorKey: 'phone',
        header: intl.translate({
          id: 'Contact Phone',
        }),
      },
      {
        accessorKey: 'title',
        header: intl.translate({
          id: 'Contact Title',
        }),
      },
      {
        accessorKey: 'secondaryVerifierName',
        header: intl.translate({
          id: 'Secondary Verifier Name',
        }),
      },
      {
        accessorKey: 'secondaryVerifierEmail',
        header: intl.translate({
          id: 'Secondary Verifier Email',
        }),
      },
      {
        accessorKey: 'secondaryVerifierPhone',
        header: intl.translate({
          id: 'Secondary Verifier Phone',
        }),
      },
      {
        accessorKey: 'secondaryVerifierTitle',
        header: intl.translate({
          id: 'Secondary Verifier Title',
        }),
      },
      {
        accessorKey: 'requestSent',
        header: intl.translate({
          id: 'Verification Request Sent',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'hasVerified',
        header: intl.translate({
          id: 'Has Been Verified',
        }),
        Cell: ({ cell }) => {
          return getBooleanCellProps(cell);
        },
      },
      {
        accessorKey: 'email',
        header: intl.translate({
          id: 'Email',
        }),
      },
      {
        accessorKey: 'completionDate',
        header: intl.translate({
          id: 'Completion Date',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'internalConfirmationDate',
        header: intl.translate({
          id: 'Internal Confirmation Date',
        }),
        Cell: ({ cell }) => {
          return getCommonDateCellProps(cell);
        },
      },
      {
        accessorKey: 'resourceId',
        header: intl.translate({
          id: 'Resource ID',
        }),
      },
      {
        accessorKey: 'siteId',
        header: intl.translate({
          id: 'Site ID',
        }),
      },
      {
        accessorKey: 'agencyId',
        header: intl.translate({
          id: 'Agency ID',
        }),
      },
      {
        accessorKey: 'updatedPropertyNames',
        header: intl.translate({
          id: 'Updated Property Names',
        }),
        Cell: ({ cell }) => {
          return getListCellProps(cell);
        },
      },
    ],
    [],
  );

  return {
    columns,
  };
};
