import { AppRoute } from 'enums/app-route.enum';
import { intl } from 'utilities/i18n/intl.utility';

export const navItems = [
  {
    label: intl.translate({
      id: 'Dashboard',
    }),
    to: AppRoute.Dashboard,
  },
  {
    label: intl.translate({
      id: 'Data Management',
    }),
    to: AppRoute.DataManagement,
  },
  {
    label: intl.translate({
      id: 'User Management',
    }),
    to: AppRoute.UserManagement,
  },
  {
    label: intl.translate({
      id: 'Verification Request Responses',
    }),
    to: AppRoute.PendingChanges,
  },
];
