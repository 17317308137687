import { Box, Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { Text } from 'design';
import { SettingsIcon } from 'design/Icon/SettingsIcon';
import { palette } from 'design/theme/palette';
import {
  DataManagementEnum,
  DataManagementEnumsAsObject,
} from 'global-constants/data-management';
import { Align } from 'layout';
import { FC, useState } from 'react';
import styled from 'styled-components';

const StyledTextH2 = styled(Text.h2)`
  font-weight: 700;
`;

const StyledMenuItem = styled(MenuItem)`
  justify-content: 'center';
  font-weight: '700';
`;

const StyledDivider = styled(Divider)`
  border: 1px solid !important;
  border-color: palette.white.main !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

type DataManagementHeaderProps = {
  dataType: DataManagementEnum;
  setDataType: (dataType: DataManagementEnum) => void;
};

export const DataManagementHeader: FC<DataManagementHeaderProps> = ({
  dataType,
  setDataType,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const DataManagementKeys = Object.keys(DataManagementEnumsAsObject);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectedData = (selectedData: DataManagementEnum) => {
    setDataType(selectedData);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} mx={0} my={2} p={0}>
        <Grid container spacing={1}>
          <Grid item xs={11}>
            <StyledTextH2>
              Data Management: {DataManagementEnumsAsObject[dataType]}
            </StyledTextH2>
          </Grid>
          <Grid item xs={1}>
            <Align v-center h-end>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="display entities menu"
              >
                <SettingsIcon />
              </IconButton>
            </Align>
          </Grid>
        </Grid>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 160,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            color: palette.white.main,
            backgroundColor: palette.green.main,
            borderRadius: '20px 0px 20px 20px',
            mt: 1.5,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {DataManagementKeys.map((data, index) => (
          <div key={data}>
            <StyledMenuItem onClick={() => handleSelectedData(index)}>
              {DataManagementEnumsAsObject[index as DataManagementEnum]}
            </StyledMenuItem>
            {DataManagementKeys.length - 1 !== index && <StyledDivider />}
          </div>
        ))}
      </Menu>
    </>
  );
};
