/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Add, Remove } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import citiesByProvince, {
  allCitiesMap,
} from 'assets/constants/cities-by-province';
import countries from 'assets/constants/countries';
import provinces, {
  AbbreviationsToProvinceMap,
  provinceAbbreviations,
} from 'assets/constants/provinces';
import regionsByProvince, {
  allRegionsMap,
} from 'assets/constants/regions-by-province';
import { Paragraph } from 'design/Text/Paragraph';
import { newCoverage } from 'helpers/resources.helpers';
import React, { FC, useEffect, useState } from 'react';
import { Coverage } from 'types/entities/resources';

const CoverageForm: FC<{
  value: Coverage[];
  setValue: React.Dispatch<React.SetStateAction<Coverage[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentSites?: any;
}> = ({ value, setValue, currentSites }) => {
  const [sites, setSites] = useState<Record<string, string>>({});

  const handleAddCoverage = () => {
    const arrayWithNewItem = [...value, { ...newCoverage }];
    setValue(arrayWithNewItem);
  };

  useEffect(() => {
    if (value?.length > 0) {
      const coverage = value.map((cov) => {
        if (provinceAbbreviations.includes(cov.province)) {
          cov.province = AbbreviationsToProvinceMap[cov.province];
        }
        if (!allCitiesMap[cov.city]) {
          cov.city = '';
        }
        if (!allRegionsMap[cov.region]) {
          cov.region = '';
        }
        return cov;
      });
      setValue(coverage);
    } else {
      handleAddCoverage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveCoverage = (index: number) => {
    const arrayRemovedInex = [...value];
    arrayRemovedInex.splice(index, 1);
    setValue(arrayRemovedInex);
  };

  useEffect(() => {
    const getSites = async () => {
      // TODO: add pagination for fetching sites
      try {
        if (currentSites) {
          const sitesList = currentSites;
          // const sitesList =
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const sitesLabelValue = sitesList?.reduce((acc: any, site: any) => {
            const id = site?._id || site?.objectId;
            const label = site?.label
              ? site?.label
              : `En: ${site?.nameEN} / Fr: ${site?.nameFR}  -  (${id})`;
            acc[id] = label;
            return acc;
          }, {});
          setSites(sitesLabelValue || {});
        }
      } catch (e) {
        console.log(e);
      }
    };

    getSites();
  }, [currentSites]);

  return (
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '102ch' },
      }}
    >
      <Grid container spacing={1}>
        {value.map((coverage, index) => (
          <React.Fragment key={index}>
            <Grid item xs={10}>
              <TextField
                label={'Postal Code'}
                onChange={(e) => {
                  setValue((prev) => {
                    return prev.map((x: Coverage, i: number) =>
                      i === index
                        ? {
                            ...x,
                            postalCode: e.target?.value,
                          }
                        : x,
                    );
                  });
                }}
                value={coverage.postalCode || ''}
                variant="standard"
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton
                color="primary"
                aria-label="Add item"
                component="label"
                onClick={handleAddCoverage}
              >
                <Add />
              </IconButton>
            </Grid>

            {index > 0 && (
              <Grid item xs={1}>
                <IconButton
                  color="primary"
                  aria-label="Remove Item"
                  component="label"
                  onClick={() => handleRemoveCoverage(index)}
                >
                  <Remove />
                </IconButton>
              </Grid>
            )}

            <Grid item mt={2} mb={1} xs={12}>
              <Divider>
                <Paragraph>OR</Paragraph>
              </Divider>
            </Grid>

            <Grid item xs={10}>
              <TextField
                select
                label={'Country'}
                name={'Country'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue((prev) => {
                    return prev.map((x: Coverage, i: number) =>
                      i === index
                        ? {
                            ...x,
                            country: e.target?.value,
                          }
                        : x,
                    );
                  });
                }}
                value={coverage.country || ''}
                variant="standard"
                fullWidth={true}
              >
                {!coverage.country &&
                  countries.map((item, index) => (
                    <MenuItem key={index} value={item || ''}>
                      {item}
                    </MenuItem>
                  ))}
                {coverage.country && (
                  <MenuItem key={index} value={coverage.country || ''}>
                    {coverage.country}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField
                select
                label={'Province'}
                name={'Province'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue((prev) => {
                    return prev.map((x: Coverage, i: number) =>
                      i === index
                        ? {
                            ...x,
                            province: e.target?.value,
                            region: '',
                            value: '',
                          }
                        : x,
                    );
                  });
                }}
                value={(coverage.country && coverage.province) || ''}
                variant="standard"
                fullWidth={true}
              >
                {!coverage.country && (
                  <MenuItem
                    key={index}
                    value={coverage.province || ''}
                    disabled
                  >
                    Select a Country
                  </MenuItem>
                )}
                {coverage.country && coverage.province && (
                  <MenuItem key={index} value={coverage.province || ''}>
                    {coverage.province}
                  </MenuItem>
                )}
                {coverage.country &&
                  provinces.map((item, index) => (
                    <MenuItem key={index} value={item || ''}>
                      {item}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <TextField
                select
                label={'Region'}
                name={'Region'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue((prev) => {
                    return prev.map((x: Coverage, i: number) =>
                      i === index
                        ? {
                            ...x,
                            region: e.target?.value,

                            value: e.target?.value,
                          }
                        : x,
                    );
                  });
                }}
                value={
                  (coverage.country &&
                    coverage?.province &&
                    coverage?.region) ||
                  ''
                }
                variant="standard"
                fullWidth={true}
              >
                {coverage.country &&
                  coverage.province &&
                  regionsByProvince
                    .filter((item) => item.province === coverage.province)
                    .map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.region || ''}>
                          {item.region}
                        </MenuItem>
                      );
                    })}
                {!coverage.country && !coverage.province && (
                  <MenuItem value={''} disabled>
                    Select a province first
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <TextField
                select
                label={'City'}
                name={'City'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue((prev) => {
                    return prev.map((x: Coverage, i: number) =>
                      i === index
                        ? {
                            ...x,
                            city: e.target?.value,
                            value: e.target?.value,
                          }
                        : x,
                    );
                  });
                }}
                value={
                  (coverage.country && coverage.province && coverage.city) || ''
                }
                variant="standard"
                fullWidth={true}
              >
                {coverage.country &&
                  coverage.province &&
                  citiesByProvince
                    .filter((item) => item.province === coverage.province)
                    .map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.city || ''}>
                          {item.city}
                        </MenuItem>
                      );
                    })}
                {!coverage.country && !coverage.province && (
                  <MenuItem value={''} disabled>
                    Select a province first
                  </MenuItem>
                )}
              </TextField>
            </Grid>

            <Grid item xs={10}>
              <TextField
                label={
                  (currentSites &&
                    currentSites?.find(
                      (st: { _id: string | null }) =>
                        st?._id === coverage.siteId,
                    )?.nameEN) ||
                  'Select site for coverage'
                }
                select
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target?.value;
                  if (value) {
                    setValue((prev) => {
                      return prev.map((x: Coverage, i: number) =>
                        i === index
                          ? {
                              ...x,
                              siteId: value,
                            }
                          : x,
                      );
                    });
                  }
                }}
                value={coverage?.siteId || ''}
                variant="standard"
                fullWidth={true}
              >
                {Object.keys(sites)?.length > 0 &&
                  Object.keys(sites).map((key) => (
                    <MenuItem key={key} value={key}>
                      {sites[key]}
                    </MenuItem>
                  ))}
                {!(Object.keys(sites)?.length > 0) && (
                  <MenuItem key={Math.random()} value="" disabled>
                    There are no sites associated to this resource
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            {index !== value?.length - 1 && (
              <Grid item mt={2} mb={1} xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default CoverageForm;
