import { Box, MenuItem, TextField, Theme, useTheme } from '@mui/material';
import languagesList, { languageCodeMap } from 'assets/constants/languages';
import { newLanguage } from 'helpers/resources.helpers';
import { FC, useEffect, useState } from 'react';
import { Language } from 'types/entities/resources';
import { intl } from 'utilities/i18n/intl.utility';

const getStyles = (
  selectedReference: string,
  references: string[],
  theme: Theme,
) => {
  return {
    fontWeight:
      references.indexOf(selectedReference) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
    color:
      references.indexOf(selectedReference) === -1
        ? theme.palette.common.black
        : theme.palette.info.light,
  };
};

const LanguagesForm: FC<{
  value: Language[];
  setValue: (values: Language[]) => void;
}> = ({ value, setValue }) => {
  const theme = useTheme();
  const [languages, setLanguages] = useState([{ ...newLanguage }]);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    value.length > 0 ? value.map((x) => x.code) : [],
  );

  useEffect(() => {
    const getLanguages = async () => {
      // TODO: in the future could be a reference (await fetchLanguages())
      setLanguages(languagesList);
    };

    getLanguages();
  }, []);

  const handleChangeDropDown = (selectedVal: string) => {
    const languageCodeSelected =
      (typeof selectedVal === 'string'
        ? selectedVal.split(',')
        : selectedVal) || [];
    setSelectedLanguages(languageCodeSelected);

    const newLanguages: Language[] = languageCodeSelected.map((code) => ({
      code,
      language: languageCodeMap[code]?.toLocaleLowerCase() || '',
    }));

    setValue(newLanguages || []);
  };

  return (
    <>
      <Box
        sx={{
          '& > :not(style)': { m: 1, width: '102ch' },
        }}
      >
        <TextField
          label={intl.translate({
            id: 'Languages',
          })}
          select
          SelectProps={{
            multiple: true,
          }}
          onChange={(e) => handleChangeDropDown(e.target?.value)}
          value={selectedLanguages?.length > 0 ? selectedLanguages : []}
          variant="standard"
          fullWidth={true}
        >
          {languages.map((reference, i) => (
            <MenuItem
              key={`${Math.random()}-${reference?.code}` || i}
              value={reference?.code || ''}
              style={getStyles(reference?.code, selectedLanguages, theme)}
            >
              {`${reference.language}[Code: ${reference.code}]`}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </>
  );
};

export default LanguagesForm;
