import { Text } from 'design/Text';
import { FC } from 'react';
import styled from 'styled-components';

const StyledTextH3 = styled(Text.h3)`
  font-weight: 700;
  color: ${({ theme }) => theme.palette.green.dark};
  text-transform: uppercase;
`;

export const ModalSubTitle: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <StyledTextH3>{children}</StyledTextH3>;
};
