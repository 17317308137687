import { setUserPassword } from 'api/user/user.api';
import { Form } from 'components/Form/Form';
import { toast } from 'components/Toast/Toast';
import { Button, Card, TextInput } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FormikHelpers } from 'formik';
import { selectAuthToken } from 'global-state/selectors';
import { genericErrorHandler } from 'helpers/error.helpers';
import { isPath } from 'helpers/navigation.helpers';
import { Align } from 'layout';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FcDefaultProps } from 'types/fc-default-props';
import { intl } from 'utilities/i18n/intl.utility';
import * as Yup from 'yup';

const StyledAlign = styled(Align)`
  height: calc(100vh - 9rem);
  width: 100%;
`;

const initialValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Must be at least 6 characters long')
    .required('Required field'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
});

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const SetPassword: FC<FcDefaultProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authToken = useSelector(selectAuthToken);
  const { token } = useParams();

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    try {
      if (token) {
        await setUserPassword(token, values.password);
        toast.success('The password has been changed', {
          position: 'top-center',
        });
        navigate(AppRoute.LogIn);
      }

      helpers.resetForm();
    } catch (error) {
      genericErrorHandler(error);
      toast.error(
        intl.translate({
          id: 'An error has occurred',
        }),
        { position: 'top-center' },
      );
    }
  };

  useEffect(() => {
    if (authToken) {
      const path = (location.state as { origin: unknown })?.origin;

      if (isPath(path)) {
        navigate(path);
      } else {
        navigate(AppRoute.UserManagement);
      }
    }
  }, [authToken, location.state, navigate]);

  return (
    <StyledAlign v-center h-start>
      <Card.Base>
        <Form
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <>
              <Card.Body>
                <Align column gap={1} style={{ minWidth: '350px' }}>
                  <TextInput
                    label={intl.translate({
                      id: 'New Password',
                    })}
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password || ''}
                    error={formik.errors.password}
                    variant="outlined"
                    fullWidth
                  />

                  <TextInput
                    label={intl.translate({
                      id: 'Confirm New Password',
                    })}
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.passwordConfirmation || ''}
                    error={formik.errors.passwordConfirmation}
                    variant="outlined"
                    fullWidth
                  />

                  <Button type="submit" variant="outlined" fullWidth>
                    {intl.translate({
                      id: 'Reset',
                    })}
                  </Button>
                </Align>
              </Card.Body>
            </>
          )}
        </Form>
      </Card.Base>
    </StyledAlign>
  );
};
