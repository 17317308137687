/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Check,
  Close,
  DeleteOutline,
  Edit,
  Save,
  Verified,
} from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import type { PaginationState } from '@tanstack/react-table';
import {
  deleteResource,
  fetchResources,
  searchResources,
  updateResource,
  updateStatusResource,
} from 'api/resources/resources.api';
import { createResource } from 'api/stagedResources/stagedResources.api';
import { createVerifications } from 'api/verifications/verifications.api';
import { selectUser } from 'global-state/selectors';
import { tableHiddenColumnsInitialState } from 'helpers/resources.helpers';
import { useResources } from 'hooks/useResources';
import MaterialReactTable, { MRT_Row } from 'material-react-table';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Resource } from 'types/entities/resources';
import { UserRoleEnum } from 'types/user-role.enum';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';
import { DeleteModal } from '../Modals/DeleteModal/DeleteModal';
import { EditModal } from '../Modals/EditModal/EditModal';
import { SaveEditedRowsModal } from '../Modals/EditModal/SaveEditedRowsModal';
import { UpdateStatusModal } from '../Modals/UpdateStatusModal/UpdateStatusModal';
import { VerifyModal } from '../Modals/VerifyModal/VerifyModal';
import TableSearch from './TableSearch';
import { toast } from 'components/Toast/Toast';

const ResourcesTable: FC = () => {
  const [tableData, setTableData] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [rowCount, setRowCount] = useState(0);
  const [resourcesActiveCount, setResourcesActiveCount] = useState<number>();
  const [resourcesInactiveCount, setResourcesInactiveCount] =
    useState<number>();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState<MRT_Row<Resource>>();

  const [updateStatusModalOpen, setUpdateStatusModalOpen] = useState(false);
  const [rowToUpdateStatus, setRowToUpdateStatus] =
    useState<MRT_Row<Resource>>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState<Resource[]>(() => []);

  const [saveEditModalOpen, setSaveEditModalOpen] = useState(false);
  const [rowsEditedToSave, setRowsEditedToSave] = useState<Resource[]>([]);
  const { columns } = useResources();

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [resourceToVerify, setResourceToVerify] = useState<Resource>();
  const [isSearch, setIsSearch] = useState(false);

  const loggedUser = useSelector(selectUser);

  const getResources = async (pageIndex: number, pageSize: number) => {
    try {
      setLoading(true);
      const result = await fetchResources(pageIndex + 1, pageSize);
      setRowCount(result.totalResults);
      setTableData(result.resources);
      setResourcesActiveCount(result.activeCount);
      setResourcesInactiveCount(result.inactiveCount);
    } catch (error) {
      setRowCount(0);
      setTableData([]);
      setResourcesActiveCount(undefined);
      setResourcesInactiveCount(undefined);
      toast.error('Unable to list resources. Try again shortly', {
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const getSearchedResources = async (
    type: string,
    value: string,
    pageIndex: number,
    pageSize: number,
  ) => {
    try {
      setLoading(true);
      const result = await searchResources(
        type,
        value,
        pageIndex + 1,
        pageSize,
      );
      setRowCount(result.totalResults);
      setTableData(result.resources);
      setResourcesActiveCount(undefined);
      setResourcesInactiveCount(undefined);
    } catch (error) {
      setRowCount(0);
      setTableData([]);
      setResourcesActiveCount(undefined);
      setResourcesInactiveCount(undefined);
      toast.error('Unable to list resources. Try again shortly', {
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isSearch) {
      getResources(pagination.pageIndex, pagination.pageSize);
    } else {
      getSearchedResources(
        type,
        value,
        pagination.pageIndex,
        pagination.pageSize,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, isSearch]);

  const handleEditRow = (row: MRT_Row<Resource>) => {
    setRowToEdit(row);
    setEditModalOpen(true);
  };

  const handleSaveRowEdits = (rowIndex: number, values: Resource) => {
    // clean edit modal
    setRowToEdit(undefined);

    // save item to pending changes
    tableData[rowIndex] = values;
    setTableData([...tableData]);
    setRowsEditedToSave([...rowsEditedToSave, values]);
  };

  const handleSaveChanges = () => {
    setSaveEditModalOpen(true);
  };

  const modalSaveChanges = async () => {
    const failedToSave = [];
    for (let i = 0; i < rowsEditedToSave.length; i++) {
      const row = rowsEditedToSave[i];
      const id = row?._id || row.objectId;

      try {
        await updateResource(id, row);
      } catch (error) {
        failedToSave.push(row);
        toast.error(`An error occurred while saving ${row.objectId}`, {
          position: 'top-center',
        });
      }
    }
    try {
      await getResources(pagination.pageIndex, pagination.pageSize);
    } catch (error) {
      toast.error('An error occured while getting Resources', {
        position: 'top-center',
      });
    } finally {
      setRowsEditedToSave(failedToSave);
    }
  };

  const modalCancelSaveChanges = async () => {
    setRowsEditedToSave([]);
    try {
      await getResources(pagination.pageIndex, pagination.pageSize);
    } catch (error) {
      toast.error('An error occured while getting Resources', {
        position: 'top-center',
      });
    }
  };

  const handleUpdateStatusRow = (row: MRT_Row<Resource>) => {
    setRowToUpdateStatus(row);
    setUpdateStatusModalOpen(true);
  };

  const modalUpdateStatusRows = async (rowIndex: number, values: Resource) => {
    const updatedStatusResource = await updateStatusResource(
      values.objectId,
      !values.isActive,
    );

    values = {
      ...updatedStatusResource,
    };
    tableData[rowIndex] = values;
    setTableData([...tableData]);
  };

  const handleDeleteRows = (rows: MRT_Row<Resource>[]) => {
    const rowsOriginalData = rows.map((row) => row.original);

    setRowsToDelete(rowsOriginalData);
    setDeleteModalOpen(true);
  };

  const modalDeleteRows = async () => {
    for (let i = 0; i < tableData.length; i++) {
      const obj = tableData[i];
      try {
        if (rowsToDelete.indexOf(obj) !== -1) {
          await deleteResourceEntity(obj.objectId);
          tableData.splice(i, 1);
          i--;
        }
      } catch (err) {
        toast.error(`An error occurred while deleting ${obj.objectId}`, {
          position: 'top-center',
        });
      }
    }

    setTableData([...tableData]);
  };

  const deleteResourceEntity = async (objectId: string) => {
    try {
      await deleteResource(objectId);
    } catch (error: unknown) {
      if (error instanceof Error) {
        logger.error(error);
      }
    }
  };

  const showVerifyModal = (row: Resource) => {
    setVerifyModalOpen(true);
    setResourceToVerify(row);
  };

  const createVerifyResource = async (
    name: string,
    email: string,
    language: string,
  ) => {
    try {
      await createVerifications(
        name,
        email,
        (resourceToVerify && resourceToVerify?._id) ||
          resourceToVerify?.objectId ||
          '',
        undefined,
        undefined,
      );
      await createResource(
        name,
        email,
        (resourceToVerify && resourceToVerify?._id) ||
          resourceToVerify?.objectId ||
          '',
        language,
      );
    } catch (error: any) {
      if (error instanceof Error) {
        logger.error(error);
      }
    }
  };

  const searchFiltersSubmit = async (type: string, value: string) => {
    try {
      setLoading(true);
      setType(type);
      setValue(value);
      setIsSearch(!!value);
      const pageIndex = 0;
      const pageSize = 10;
      const result = await searchResources(
        type,
        value,
        pageIndex + 1,
        pageSize,
      );
      setPagination((page) => {
        return { ...page, pageIndex, pageSize };
      });
      setRowCount(result.totalResults);
      setTableData(result.resources);
      setResourcesActiveCount(undefined);
      setResourcesInactiveCount(undefined);
    } catch (err: unknown) {
      if (err instanceof Error) {
        logger.error(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setType('');
    setValue('');
    setIsSearch(false);
    getResources(pagination.pageIndex, pagination.pageSize);
  };

  return (
    <div style={{ width: '100%' }}>
      `{' '}
      <TableSearch
        onSubmit={searchFiltersSubmit}
        reset={resetForm}
        onlyNameSearch={false}
      />
      `
      <MaterialReactTable
        enableStickyHeader
        enableStickyFooter
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '0',
          },
        }}
        columns={columns}
        enableColumnResizing
        defaultColumn={{
          minSize: 20,
          maxSize: 300,
          size: 160,
        }}
        data={tableData}
        enableColumnOrdering
        enableEditing
        enableHiding
        enableRowSelection
        positionActionsColumn="last"
        renderRowActions={
          loggedUser?.role === UserRoleEnum.Admin ||
          loggedUser?.role === UserRoleEnum.SuperAdmin
            ? ({ row }: any) => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0rem',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip
                    arrow
                    title={intl.translate({
                      id: 'Edit',
                    })}
                  >
                    <IconButton
                      aria-label="edit button"
                      onClick={() => handleEditRow(row)}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  {!row.original.isActive ? (
                    <>
                      <Tooltip arrow title="Activate">
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => handleUpdateStatusRow(row)}
                          aria-label="activate button"
                        >
                          <Close />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip arrow title="Deactivate">
                        <IconButton
                          color="success"
                          size="small"
                          onClick={() => handleUpdateStatusRow(row)}
                          aria-label="deactivate button"
                        >
                          <Check />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip
                    arrow
                    title={intl.translate({
                      id: 'Verify',
                    })}
                  >
                    <IconButton
                      aria-label="Verify"
                      onClick={() => showVerifyModal(row.original)}
                      size="small"
                    >
                      <Verified />
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            : () => <></>
        }
        renderTopToolbarCustomActions={
          loggedUser?.role === UserRoleEnum.Admin ||
          loggedUser?.role === UserRoleEnum.SuperAdmin
            ? ({ table }: any) => (
                <div style={{ display: 'grid' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      p: '0.5rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      color="success"
                      disabled={!(rowsEditedToSave.length > 0)}
                      startIcon={<Save />}
                      onClick={handleSaveChanges}
                      variant="contained"
                    >
                      {intl.translate({
                        id: 'Confirm changes',
                      })}
                    </Button>
                    <Button
                      color="error"
                      disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                      }
                      onClick={() =>
                        handleDeleteRows(table.getSelectedRowModel().rows)
                      }
                      startIcon={<DeleteOutline />}
                      variant="outlined"
                      aria-label="delete"
                    >
                      {intl.translate({
                        id: 'Delete selected',
                      })}
                    </Button>
                  </Box>
                  {/* <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                p: '0.5rem',
                flexWrap: 'wrap',
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export All Data',
                })}
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleExportRows(table.getPrePaginationRowModel().rows)
                }
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export All Filtered Rows',
                })}
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export Page Rows',
                })}
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownload />}
                variant="outlined"
              >
                {intl.translate({
                  id: 'Export Selected Rows',
                })}
              </Button>
            </Box> */}
                </div>
              )
            : () => <></>
        }
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 50, 100],
        }}
        initialState={{
          density: 'compact',
          columnVisibility: { ...tableHiddenColumnsInitialState },
        }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={rowCount}
        state={{
          isLoading: loading,
          pagination,
        }}
      />
      {rowToEdit && (
        <EditModal
          columns={columns}
          row={rowToEdit}
          open={editModalOpen}
          onClose={() => {
            setRowToEdit(undefined);
            setEditModalOpen(false);
          }}
          onSubmit={handleSaveRowEdits}
        />
      )}
      <DeleteModal
        columns={columns}
        rowsToDelete={rowsToDelete}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={modalDeleteRows}
      />
      <SaveEditedRowsModal
        columns={columns}
        rowsToSave={rowsEditedToSave}
        open={saveEditModalOpen}
        onClose={() => {
          setSaveEditModalOpen(false);
        }}
        onCancel={modalCancelSaveChanges}
        onSubmit={modalSaveChanges}
      />
      {rowToUpdateStatus && (
        <UpdateStatusModal
          columns={columns}
          rowToActivateOrDeactivate={rowToUpdateStatus}
          open={updateStatusModalOpen}
          onClose={() => {
            setUpdateStatusModalOpen(false);
          }}
          onSubmit={modalUpdateStatusRows}
        />
      )}
      <VerifyModal
        open={verifyModalOpen}
        onClose={() => {
          setResourceToVerify(undefined);
          setVerifyModalOpen(false);
        }}
        onSubmit={createVerifyResource}
        verifications={resourceToVerify?.verifications || []}
        resourceName={resourceToVerify?.nameEn || 'N/A'}
        resourceToVerify={resourceToVerify}
      />
      <Tooltip
        title={
          <div>
            <p>Active resources: {resourcesActiveCount}</p>
            <p>Inactive resources: {resourcesInactiveCount}</p>
          </div>
        }
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ResourcesTable;
