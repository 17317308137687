import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import { CancelButton } from 'components/Shared/Buttons/CancelButton';
import { ConfirmButton } from 'components/Shared/Buttons/ConfirmButton';
import { Select } from 'design';
import { CloseDialogButton } from 'design/Button/CloseDialogButton';
import { Paragraph } from 'design/Text/Paragraph';
import { selectUser } from 'global-state/selectors';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { UserRoleEnum } from 'types/user-role.enum';
import { User } from 'types/user.dto';
import { intl } from 'utilities/i18n/intl.utility';
import { isAdmin, isSuper } from 'utilities/user/check-user';

export const EditUserModal: FC<{
  userToEdit: User;
  onClose: () => void;
  onSubmit: (values: {
    fullName: string;
    role: UserRoleEnum;
    password: string;
  }) => void;
  open: boolean;
}> = ({ open, userToEdit, onClose, onSubmit }) => {
  const [userFullName, setUserFullName] = useState<string>(userToEdit.fullName);
  const [userRole, setUserRole] = useState<UserRoleEnum>(userToEdit.role);
  const [userPassword, setUserPassword] = useState<string>('');
  const [userRepPass, setUserRepPass] = useState<string>('');

  const loggedUser = useSelector(selectUser);

  const isEditMyProfile: boolean = loggedUser?.id === userToEdit.id;
  const isRoleAdmin = isSuper(loggedUser) || isAdmin(loggedUser);

  useEffect(() => {
    if (!userToEdit.fullName || !userToEdit.role) {
      return;
    }

    setUserFullName(userToEdit.fullName);
    setUserRole(userToEdit.role);
    setUserPassword('');
    setUserRepPass('');
  }, [userToEdit]);

  const handleSubmit = () => {
    if (isEditMyProfile) {
      if (!userFullName) {
        toast.error(
          intl.translate({
            id: 'Full Name is required',
          }),
          {
            position: 'top-center',
          },
        );
        return;
      }

      if (userPassword) {
        if (userPassword.length < 6) {
          toast.error(
            intl.translate({
              id: 'Must be at least 6 characters long',
            }),
            {
              position: 'top-center',
            },
          );
          return;
        }
        if (userPassword !== userRepPass) {
          toast.error(
            intl.translate({
              id: 'Passwords must match',
            }),
            {
              position: 'top-center',
            },
          );
          return;
        }
      }
    }

    if (isRoleAdmin && !userRole) {
      toast.error(
        intl.translate({
          id: 'Role is required',
        }),
        {
          position: 'top-center',
        },
      );
      return;
    }

    onSubmit({
      fullName: userFullName,
      role: userRole,
      password: userPassword,
    });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle textAlign="left" mb={2}>
        Edit
        {isEditMyProfile ? ' My Profile' : ` ${userToEdit.email}`}
        <CloseDialogButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            mt={1}
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
              padding: '2',
            }}
          >
            <TextField
              disabled={!isEditMyProfile}
              key="fullName"
              label={intl.translate({
                id: 'Name',
              })}
              name="fullName"
              value={userFullName || ''}
              onChange={(e) => setUserFullName(e.target?.value)}
            />

            {isRoleAdmin && (
              <Select
                variant="outlined"
                key="role"
                label={intl.translate({
                  id: 'Role',
                })}
                name="role"
                value={userRole}
                options={Object.values(UserRoleEnum).map((role) => {
                  return {
                    label: role,
                    value: role,
                  };
                })}
                onChange={(e) => setUserRole(e.target?.value)}
              />
            )}

            {isEditMyProfile && (
              <>
                <Divider></Divider>
                <Paragraph>
                  {intl.translate({
                    id: 'Change Password',
                  })}
                </Paragraph>
                <TextField
                  label={intl.translate({
                    id: 'New Password',
                  })}
                  id="password"
                  name="password"
                  type="password"
                  value={userPassword || ''}
                  onChange={(e) => setUserPassword(e.target?.value)}
                />

                <TextField
                  label={intl.translate({
                    id: 'Confirm New Password',
                  })}
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  value={userRepPass || ''}
                  onChange={(e) => setUserRepPass(e.target?.value)}
                />
              </>
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <CancelButton handleClick={onClose}>
          {intl.translate({
            id: 'Cancel',
          })}
        </CancelButton>
        <ConfirmButton handleClick={handleSubmit}>
          <strong>
            {intl.translate({
              id: 'Save',
            })}
          </strong>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};
