import { Paragraph } from 'design/Text/Paragraph';
import { FC } from 'react';
import styled from 'styled-components';

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.palette.green.dark};
`;

export const ModalParagraph: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <StyledParagraph>{children}</StyledParagraph>;
};
