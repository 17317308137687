/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DataManagementEnum,
  getDataManagementString,
} from 'global-constants/data-management';
import { toast } from 'react-toastify';
import { Taxonomy } from 'types/entities/taxonomies';
import { BaseAPIResponse, FileAPIResponse } from 'types/utilities';
import { http } from 'utilities/http/http';

export async function getBulkExport(
  dataType: DataManagementEnum,
  startDate: string,
  endDate: string,
  dateType: string,
  isActive: boolean,
  downloadAllData: boolean,
): Promise<string> {
  try {
    const dataTypeValue = getDataManagementString(dataType);

    const response = downloadAllData
      ? await http.get<BaseAPIResponse<string>>(`/bulk/export/${dataTypeValue}`)
      : await http.get<BaseAPIResponse<string>>(
          `/bulk/export/${dataTypeValue}?startDate=${startDate}&endDate=${endDate}&dateType=${dateType}&isActive=${isActive}`,
        );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('Error Export Resources');
    }
  } catch (err: unknown) {
    toast.error('Error exporting resources. Please, try again.', {
      position: 'top-center',
    });
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error Export Resources: ${err}`);
  }
}

export async function bulkImport(
  dataType: string,
  file: File,
): Promise<Taxonomy[]> {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await http.post<FileAPIResponse<Taxonomy[]>>(
      `/bulk/import/${dataType}`,
      formData,
    );

    if (response.status === 'success') {
      toast.success(`Successfully uploaded ${dataType} file`, {
        position: 'top-center',
      });
      return response.data;
    } else {
      throw new Error(`Error uploading ${dataType} file`);
    }
  } catch (err: unknown) {
    toast.error(`Error uploading ${dataType} file. Please, try again.`, {
      position: 'top-center',
    });

    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error(`Error uploading ${dataType}: ${err}`);
  }
}
